import { Button, NokPrice } from "@naf/teamscheme";
import { ButtonLink } from "@naf/teamscheme";
import { type TrackEventProps } from "@naf/teamscheme-core";
import { captureException } from "@sentry/core";
import {
  Dispatch,
  type MutableRefObject,
  type SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { Role } from "#vehicle-contract/common/lib/model/contract/Role";
import { SCHEMA_TYPES } from "#vehicle-contract/common/lib/model/contract/SchemaType";
import { EVENTS } from "../../Tracking";
import { roleName } from "../../admin/shared/roleName";
import { ApiError } from "../../api/ApiClient";
import FormGroup from "../../forms/FormGroup";
import { LabeledInputComponent } from "../../forms/LabeledInputComponent";
import { RadioGroup } from "../../forms/RadioGroup";
import { AppPages } from "../../routing/AppPages";
import { getAbsolutePath } from "../../routing/AppRoutes";
import ErrorMessage from "../../shared/error/ErrorMessage";
import VehicleSearch, {
  VehicleSearchState,
} from "../../vehicles/VehicleSearch";
import { LabelHint } from "../form/fields/LabelHint";
import TermsCheckbox from "../invitation/TermsCheckbox";
import { CreateSalesContractRequest } from "./CreateContract";
import CustomVehicleData from "./CustomVehicleData";
import { LICENSE_PLATE_NUMBER_REGEX } from "./licensePlateNumberPattern";
import styled from "styled-components";
import { choice } from "../../utils";

const FINN_CODE_PATTERN = "^\\d{8,9}$";
const FINN_CODE_REGEX = new RegExp(FINN_CODE_PATTERN);
const FINN_URL_REGEX =
  /^https:\/\/www\.finn\.no\/(car\/used\/ad\.html\?finnkode=)?(\d{8,9})$/;

const submitText = choice([
  "Start",
  "Neste",
  "Fortsett",
  "Kom i gang",
  "Opprett kjøpekontrakt",
  "Opprett ny kontrakt",
  "Sett i gang",
  "Fyll ut",
]);

const BodyTextParagraph = styled.p`
margin-top: ${({ theme }) => theme.spacing.space8};
  ${({ theme }) => theme.fontStyle.bodyText.bodyText}
`;

export interface CreateContractFormProps {
  onChangeRole?: Dispatch<SetStateAction<Role>>;
  onSubmit: (props: CreateSalesContractRequest) => Promise<void>;
  inputRef?: MutableRefObject<HTMLInputElement | null>;
  autoFocus?: boolean;
}

interface Search {
  licensePlateNumber?: string;
  finnId?: string;
}

export default function CreateContractForm({
  onChangeRole,
  onSubmit,
  inputRef,
  autoFocus,
}: CreateContractFormProps) {
  const [role, setRole] = useState<Role | null>(null);
  const [identifier, setIdentifier] = useState("");
  const [search, setSearch] = useState<Search | null>(null);
  const [vehicle, setVehicle] = useState<VehicleSearchState | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<ApiError | null>(null);
  useEffect(() => {
    if (onChangeRole) onChangeRole(role || Role.Seller);
  }, [role, onChangeRole]);
  const lpnInput = useRef<HTMLInputElement | null>(null);
  const sellerRadio = useRef<HTMLInputElement | null>(null);
  const [vehicleValidationError, setVehicleValidationError] = useState(false);
  const [roleValidationError, setRoleValidationError] = useState(false);
  const [customVehicleData, setCustomVehicleData] = useState({
    schemaType: SCHEMA_TYPES.Car,
  });
  const [showCustomVehicleData, setShowCustomVehicleData] = useState(false);
  const eventBase: TrackEventProps = {
    ...EVENTS.LANDING_PAGE.CREATE_CONTRACT,
    label: submitText,
  };
  const trackContract: TrackEventProps = role
    ? { ...eventBase, additionalProps: { role: roleName(role) } }
    : eventBase;
  const [acceptedTerms, setAcceptedTerms] = useState(false);

  return (
    <form
      onSubmit={async (event) => {
        event.preventDefault();
        if (role === null) {
          setRoleValidationError(true);
          sellerRadio.current?.focus();
          return;
        }
        setRoleValidationError(false);
        if (!showCustomVehicleData && (!vehicle || vehicle.error)) {
          setVehicleValidationError(true);
          lpnInput.current?.focus();
          return;
        }
        setVehicleValidationError(false);
        setIsLoading(true);
        try {
          const request = {
            role,
            licensePlateNumber:
              (showCustomVehicleData
                ? identifier
                : vehicle?.result?.autoTechData.licensePlateNumber) ||
              undefined,
            finnId:
              !showCustomVehicleData && vehicle?.result?.finnEntry
                ? vehicle?.result?.finnEntry.identifier
                : undefined,
            customVehicleData: showCustomVehicleData
              ? customVehicleData
              : undefined,
          };
          await onSubmit(request);
        } catch (error) {
          console.log("CreateContractForm submit error", error);
          setError(error as ApiError);
          setIsLoading(false);
          captureException(error);
        }
      }}
    >
      <FormGroup>
        <LabeledInputComponent
          id="licensePlateNumber"
          label={
            showCustomVehicleData
              ? "Registreringsnummer"
              : "Hent opplysninger for kjøretøyet:"
          }
          description={
            'Vi kan hente opplysninger for alle registrerte kjøretøy hos Statens vegvesen. Kjøretøyopplysninger kan også legges inn manuelt ved å trykke på "Finner ikke kjøretøyet"'
          }
          hasError={!showCustomVehicleData && !!vehicle?.error}
        >
          <input
            ref={(ref) => {
              lpnInput.current = ref;
              if (inputRef) inputRef.current = ref;
            }}
            name="licensePlateNumber"
            value={identifier}
            type="text"
            onChange={(event) => {
              const identifier = event.target.value.replace(/\s/g, "");
              if (LICENSE_PLATE_NUMBER_REGEX.test(identifier)) {
                setSearch({
                  licensePlateNumber: identifier
                    .toUpperCase()
                    .replace(/-/g, ""),
                });
              }
              if (FINN_CODE_REGEX.test(identifier)) {
                setSearch({ finnId: identifier });
              }
              const finnUrlMatch = FINN_URL_REGEX.exec(identifier);
              if (finnUrlMatch) {
                setSearch({ finnId: finnUrlMatch[2] });
              }
              setIdentifier(identifier);
            }}
            placeholder={
              showCustomVehicleData
                ? "Reg.nr. (hvis tilgjengelig)"
                : "Reg.nr. / FINN-kode"
            }
            required={!showCustomVehicleData}
          />
        </LabeledInputComponent>
        {showCustomVehicleData ? (
          <div className="LandingPage-vehicle">
            <ButtonLink
              variant="primary"
              type="button"
              onClick={() => setShowCustomVehicleData(false)}
            >
              Hent kjøretøydata
            </ButtonLink>
          </div>
        ) : (
          <div className="LandingPage-vehicle">
            <VehicleSearch
              {...search}
              onChange={setVehicle}
              placeholder={
                vehicleValidationError ? (
                  <ErrorMessage>
                    Fyll inn et gyldig reg. nr. eller FINN-kode.
                  </ErrorMessage>
                ) : null
              }
            />
            <ButtonLink
              variant="secondary"
              type="button"
              onClick={() => setShowCustomVehicleData(true)}
            >
              Finner ikke kjøretøyet
            </ButtonLink>
          </div>
        )}
      </FormGroup>
      {showCustomVehicleData ? (
        <CustomVehicleData
          fields={customVehicleData}
          setFields={setCustomVehicleData}
        />
      ) : null}
      <FormGroup>
        <p>
          Jeg er <LabelHint>(påkrevd)</LabelHint>:
          {roleValidationError ? (
            <ErrorMessage component="span" style={{ fontWeight: "normal" }}>
              {" "}
              Velg en rolle.
            </ErrorMessage>
          ) : null}
        </p>
        <RadioGroup
          name="role"
          options={[
            { key: "seller", value: Role.Seller, label: "Selger" },
            { key: "buyer", value: Role.Buyer, label: "Kjøper" },
          ]}
          inputProps={{ required: true }}
          value={role}
          onChange={(next) => setRole(next)}
        />
      </FormGroup>
      <FormGroup>
        <TermsCheckbox
          accepted={acceptedTerms}
          setAccepted={setAcceptedTerms}
          terms={{
            linkTo: getAbsolutePath(AppPages.AboutTerms),
            name: "NAF Kjøpekontrakt",
          }}
        />
        <Button
          type="submit"
          variant="primary"
          isLoading={isLoading}
          block
          trackEvent={trackContract}
        >
          <span suppressHydrationWarning>{submitText}</span>
        </Button>
        {error ? (
          <ErrorMessage>En feil har oppstått. Prøv igjen.</ErrorMessage>
        ) : null}
        <BodyTextParagraph>
          Signering av kontrakten koster fra <NokPrice value={49} />, og er
          gratis for NAF-medlemmer.
        </BodyTextParagraph>
      </FormGroup>
    </form>
  );
}
