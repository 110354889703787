import { Role } from "#vehicle-contract/common/lib/model/contract/Role";
import { ContractType } from "#vehicle-contract/common/lib/model/contract/SalesContract";
import { useContract } from "../../ContractContext";

type UpgradedContractInfo = {
  upgradedBy?: Role;
  contractType: ContractType;
};

export function useUpgradedContractInfo(): UpgradedContractInfo {
  const contract = useContract();
  const { contractType } = contract;
  if (!contractType) {
    throw new Error("No contract type info found!");
  }
  const currentType = contractType.current;
  const isUpgraded =
    typeof currentType === "number" &&
    [ContractType.Premium, ContractType.Member].includes(currentType);
  if (!isUpgraded) {
    throw new Error("Contract is not upgraded!");
  }
  const historyItem = [...contractType.history]
    .reverse()
    .find((x) => x.contractType === currentType);
  const upgradedBy = historyItem?.role;
  return {
    upgradedBy,
    contractType: contractType.current,
  };
}
