import { ImgHTMLAttributes } from "react";
import Logo from "./graphics/naf-logo_56x56.svg";

export const DEFAULT_SIZE = 64;

interface NafLogoProps
  extends Omit<
    ImgHTMLAttributes<HTMLImageElement>,
    "width" | "height" | "src" | "alt"
  > {
  size?: number;
}

export function NafLogo({ size = DEFAULT_SIZE, ...props }: NafLogoProps) {
  return <img width={size} height={size} {...props} src={Logo} alt="NAF" />;
}
