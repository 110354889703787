import { useCallback } from "react";
import { useClientBase } from "../../../../api/ClientBase";
import { useContractClient } from "../../../../api/ContractClient";
import { useContractContext } from "../../../ContractContext";
import { useLoadContractContext } from "../../../LoadContractContext";
import {
  FinnUpdateStatusEnum,
  UpdateSalesContractFromFinnResponse,
} from "./UpdateSalesContractFromFinnResponse";

export class FinnNoCodeError extends Error {
  constructor() {
    super("No Finn code found");
  }
}

export class FinnNotFoundError extends Error {
  noMatch = true;
  constructor() {
    super("No match for Finn code");
  }
}

export class FinnMismatchLicenseplateNumberError extends Error {
  noMatch = true;
  constructor() {
    super("Wrong vehicle for Finn code");
  }
}

export function useFinnClient() {
  const {
    contract: { id: contractId },
  } = useContractContext();
  const { updateContract } = useLoadContractContext();
  const { post } = useContractClient();

  const clientBase = useClientBase<
    string | null,
    UpdateSalesContractFromFinnResponse
  >(
    useCallback(
      async function (finnId) {
        if (!finnId) {
          throw new FinnNoCodeError();
        }

        const promise = post<UpdateSalesContractFromFinnResponse>(
          `contract/${contractId}/from-finn/${finnId}`,
          {},
        );

        const x = await promise;
        if (x.status === FinnUpdateStatusEnum.Success) {
          updateContract(x.updateResponse.contract);
        } else if (x.status === FinnUpdateStatusEnum.MissingFinnCode) {
          throw new FinnNotFoundError();
        } else if (
          x.status === FinnUpdateStatusEnum.MismatchLicenseplateNumber
        ) {
          throw new FinnMismatchLicenseplateNumberError();
        }
        return x;
      },
      [contractId, post, updateContract],
    ),
  );

  return clientBase;
}
