import { ButtonLink } from "@naf/teamscheme";
import { LoadingText } from "@naf/teamscheme";
import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
  useContext,
  createContext,
} from "react";
import BottomPanel from "../layout/BottomPanel";

type Unsubscribe = () => void;

type OnOpen = (event: Event | null) => void;
type OnMessage = (event: MessageEvent) => void;
interface WebSocketClientContextProps {
  onMessage(callback: OnMessage): Unsubscribe;
  onOpen(callback: OnOpen): Unsubscribe;
  send<T>(message: T): Promise<void>;
  status: ConnectionStatus;
}

export const WebSocketClientContext =
  createContext<WebSocketClientContextProps | null>(null);

export enum ConnectionStatus {
  Connecting = 0,
  Connected = 1,
  Disconnected = 2,
  Reconnecting = 3,
  Failed = 4,
}

function getStatusText(status: ConnectionStatus) {
  switch (status) {
    case ConnectionStatus.Connecting:
      return <LoadingText text="Kobler til" />;
    case ConnectionStatus.Reconnecting:
      return <LoadingText text="Kobler til på nytt" />;
    case ConnectionStatus.Connected:
      return "Tilkoblet";
    case ConnectionStatus.Disconnected:
      return "Frakoblet";
    default:
      throw new Error(`Unrecognized status ${status}!`);
  }
}

function useOnOpen(socket: WebSocket | null, status: ConnectionStatus) {
  const [active, setActive] = useState<OnOpen[]>([]);
  const [inactive, setInactive] = useState<OnOpen[]>([]);

  useEffect(() => {
    if (!inactive.length) return;
    if (status !== ConnectionStatus.Connected) return;

    setInactive([]);
    setActive((x) => [...x, ...inactive]);

    for (const callback of inactive) {
      callback(null);
    }
  }, [inactive, status]);

  const deactivate = useMemo(() => {
    if (!active.length) return null;

    return () => {
      setActive([]);
      setInactive((x) => [...x, ...active]);
    };
  }, [active]);

  useEffect(() => {
    if (!socket) return;

    function onCloseSocket() {
      if (deactivate) {
        deactivate();
      }
    }

    socket.addEventListener("close", onCloseSocket);
    socket.addEventListener("error", onCloseSocket);

    return () => {
      socket.removeEventListener("close", onCloseSocket);
      socket.removeEventListener("error", onCloseSocket);
    };
  }, [socket, deactivate]);

  const onOpen = useCallback((callback: OnOpen) => {
    setInactive((subscribers) => [...subscribers, callback]);

    return () => {
      setInactive((subscribers) =>
        subscribers.filter((sub) => sub !== callback),
      );

      setActive((subscribers) => subscribers.filter((sub) => sub !== callback));
    };
  }, []);

  return onOpen;
}

export function WebSocketClientProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [status, setStatus] = useState(ConnectionStatus.Connecting);
  const socket = useRef<WebSocket | null>(null);

  const [onMessageSubscribers, setOnMessageSubscribers] = useState<OnMessage[]>(
    [],
  );
  const [queue, setQueue] = useState<
    { resolve(): void; reject(): void; message: unknown }[]
  >([]);

  const connectionAttempts = useRef(0);

  const createSocket = useMemo(() => {
    function attemptReconnect() {
      console.log(
        `Attempting reconnect after ${connectionAttempts.current} attempts...`,
      );

      if (connectionAttempts.current < 3) {
        connectionAttempts.current += 1;
        socket.current = createSocketInner(ConnectionStatus.Reconnecting);
      } else {
        setStatus(ConnectionStatus.Disconnected);
      }
    }

    function createSocketInner(status?: ConnectionStatus) {
      const {
        location: { protocol, hostname, port },
      } = document;

      const wsPort = port || (protocol === "https:" ? "443" : "80");

      const uri = `${protocol.replace("http", "ws")}//${hostname}:${wsPort}/ws`;

      console.log(`Connecting to WebSocket on ${uri}`);

      try {
        const newSocket = new WebSocket(uri);

        setStatus(status || ConnectionStatus.Connecting);

        newSocket.onopen = () => {
          connectionAttempts.current = 0;
          setStatus(ConnectionStatus.Connected);
        };

        newSocket.onclose = (event) => {
          const NORMAL_CLOSURE_CODE = 1000; // normal closure only happens when we explicitly call .close() which we only call when we unmount the component
          if (event.code !== NORMAL_CLOSURE_CODE) {
            attemptReconnect();
          }
        };

        newSocket.onerror = (event: Event) => {
          console.log("onerror", event);

          setStatus(ConnectionStatus.Disconnected);
        };

        return newSocket;
      } catch (error) {
        setStatus(ConnectionStatus.Failed);
        console.error("Could not create WebSocket", error);
        return null;
      }
    }

    return createSocketInner;
  }, []);

  useEffect(() => {
    const newSocket = createSocket();

    socket.current = newSocket;

    return () => {
      if (newSocket) {
        if (newSocket.readyState === 1) {
          newSocket.close();
        } else {
          newSocket.addEventListener("open", () => newSocket.close());
        }
      }
    };
  }, [createSocket]);

  useEffect(() => {
    if (!socket) return;

    const onMessage = (event: MessageEvent) => {
      for (const callback of onMessageSubscribers) callback(event);
    };

    socket.current?.addEventListener("message", onMessage);

    return () => socket.current?.removeEventListener("message", onMessage);
  }, [onMessageSubscribers]);

  useEffect(() => {
    if (socket && status === ConnectionStatus.Connected && queue.length) {
      for (const x of queue) {
        socket.current?.send(JSON.stringify(x.message));
        x.resolve();
      }

      setQueue([]);
    }
  }, [status, queue]);

  const onMessage = useCallback((callback: (event: MessageEvent) => void) => {
    setOnMessageSubscribers((subscribers) => [...subscribers, callback]);

    return () =>
      setOnMessageSubscribers((subscribers) =>
        subscribers.filter((sub) => sub !== callback),
      );
  }, []);

  const onOpen = useOnOpen(socket.current, status);

  const send = useCallback(function <T>(message: T) {
    return new Promise<void>((resolve, reject) =>
      setQueue((q) => [...q, { resolve, reject, message }]),
    );
  }, []);

  const client: WebSocketClientContextProps = useMemo(
    () => ({
      onMessage,
      onOpen,
      send,
      status,
    }),
    [onMessage, onOpen, send, status],
  );

  return (
    <WebSocketClientContext.Provider value={client}>
      {children}
      {status === ConnectionStatus.Disconnected ||
      status === ConnectionStatus.Reconnecting ? (
        <BottomPanel>
          <span>{getStatusText(status)}</span>
          {status === ConnectionStatus.Disconnected ? (
            <ButtonLink
              variant="secondary"
              onClick={() => {
                socket.current = createSocket();
              }}
            >
              Koble til på nytt
            </ButtonLink>
          ) : null}
        </BottomPanel>
      ) : null}
      {status === ConnectionStatus.Failed ? (
        <BottomPanel>
          <span>
            Kunne ikke opprette tilkobling mot serveren. Du må laste siden på
            nytt for å se endringer.
          </span>
        </BottomPanel>
      ) : null}
    </WebSocketClientContext.Provider>
  );
}

export function useWebSocketClient() {
  const context = useContext(WebSocketClientContext);

  if (context == null)
    throw new Error(
      "No WebSocketClientContext found! Have you wrapped your app in a WebSocketClientProvider?",
    );

  return context;
}
