import { GetProductsResponse } from "@dtp/membership-service-types";
import { ReactNode, createContext, useContext, useMemo } from "react";
import { ResultClient } from "../../../api/ClientBase";
import { useAuth } from "../../../auth/AuthProvider";
import { useCustomer } from "../../../auth/CustomerProvider";
import { useMembershipServiceProducts } from "../../../membership/membershipService/useMembershipService";
import {
  TrialMembershipProduct,
  useTrialMembershipProductClient,
} from "./TrialMembershipProductClient";

export interface TrialMembershipContextResult {
  membershipProducts: GetProductsResponse;
  trialMembershipProduct: TrialMembershipProduct | null;
  email: string | null;
}

export interface ITrialMembershipContext {
  result: TrialMembershipContextResult | null;
}

export const TrialMembershipContext =
  createContext<TrialMembershipContextResult | null>(null);

export function useTrialMembershipContext() {
  const context = useContext(TrialMembershipContext);
  if (!context) throw new Error("No TrialMembershipContext result found!");
  return context;
}

export function useTrialMembershipContextClient(): ResultClient<TrialMembershipContextResult> {
  const products = useMembershipServiceProducts();
  const trialMembershipProduct = useTrialMembershipProductClient();
  const customer = useCustomer();
  const auth = useAuth();

  const isLoading =
    products.isLoading ||
    trialMembershipProduct.isLoading ||
    customer.isLoading;

  const error = useMemo(
    () => products.error || trialMembershipProduct.error || customer.error,
    [products.error, trialMembershipProduct.error, customer.error],
  );

  const result = useMemo(
    () =>
      products.result
        ? {
            membershipProducts: products.result,
            email: auth.email,
            trialMembershipProduct: trialMembershipProduct.result,
          }
        : null,
    [products.result, trialMembershipProduct.result, auth.email],
  );

  return useMemo(
    () => ({ isLoading, error, result }),
    [isLoading, error, result],
  );
}

export function TrialMembershipProvider({
  children,
  result,
}: {
  children: ReactNode;
  result: TrialMembershipContextResult | null;
}) {
  return (
    <TrialMembershipContext.Provider value={result}>
      {children}
    </TrialMembershipContext.Provider>
  );
}
