import { type ITheme, onDesktop } from "@naf/teamscheme-core";
import { css } from "styled-components";

export function defaultHeaderPaddingHorizontal(theme: ITheme) {
  const { spacing } = theme;
  return css`
    padding-left: ${spacing.space16};
    padding-right: ${spacing.space16};
  `;
}

export function flowHeaderPaddingHorizontal(theme: ITheme) {
  const { spacing } = theme;
  return css`
    padding-left: ${spacing.space16};
    padding-right: ${spacing.space16};

    ${onDesktop`
      padding-left: ${spacing.space16};
      padding-right: ${spacing.space16};
    `}
  `;
}
