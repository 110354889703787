import { InfoButtonProps } from "@naf/teamscheme";
import { HTMLAttributes, ReactNode, useContext } from "react";
import styled, { css } from "styled-components";
import StyledInfoButton from "./InfoButton";
import { InputDescriptionModalContext } from "./InputDescriptionModalContext";

export const InputDescriptionStyling = css`
  color: ${({ theme }) => theme.nafColor.signature.black};

  > p,
  > ul {
    line-height: 1.5;
    margin-top: 0;
    font-weight: lighter;
    letter-spacing: 0px;
  }
`;

const Description = styled((props: HTMLAttributes<HTMLDivElement>) => (
  <div {...props} />
))`
  ${InputDescriptionStyling}
  margin-bottom: 10px;
`;

export interface InputDescriptionProps {
  description: ReactNode;
}

export default function InputDescription({
  description,
}: InputDescriptionProps) {
  if (!description) return null;
  if (typeof description === "string") {
    // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
    return <Description dangerouslySetInnerHTML={{ __html: description }} />;
  }
  return <Description>{description}</Description>;
}

export interface DescriptionInfoButtonProps
  extends InputDescriptionProps,
    Omit<InfoButtonProps, "type" | "onClick"> {
  label?: ReactNode;
}

export function DescriptionInfoButton({
  label,
  description,
  ...props
}: DescriptionInfoButtonProps) {
  const context = useContext(InputDescriptionModalContext);
  if (!context) throw new Error("No InputDescriptionModalContext found!");
  const content = (
    <>
      {label ? <h3>{label}</h3> : null}
      <InputDescription description={description} />
    </>
  );
  return (
    <StyledInfoButton
      {...props}
      type="button"
      onClick={(e) => {
        e.preventDefault();
        context.open(content);
      }}
    />
  );
}
