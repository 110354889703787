import { type LocationDescriptor } from "@naf/teamscheme";
import { useContractToken } from "../ContractParams";

export function useHasMembershipPath(returnPath?: string): LocationDescriptor {
  const token = useContractToken();
  return {
    pathname: returnPath ?? location.pathname,
    search: new URLSearchParams({ token: token }).toString(),
  };
}
