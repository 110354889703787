import type { DateString } from "@naf/teamscheme";
import { leftpad } from "./utils";

export function formatDate(
  date: Date | DateString | string | null | undefined,
) {
  if (!date) return null;

  const realDate = new Date(date);

  return [
    leftpad(realDate.getDate()),
    leftpad(realDate.getMonth() + 1),
    realDate.getFullYear(),
  ].join(".");
}

export function formatTime(
  date: Date | DateString | string | null | undefined,
) {
  if (!date) return null;

  const realDate = new Date(date);

  return [leftpad(realDate.getHours()), leftpad(realDate.getMinutes())].join(
    ":",
  );
}
