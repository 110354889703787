import FlowLoadingPage from "../../shared/FlowLoadingPage";
import ErrorPage from "../../shared/error/ErrorPage";
import { ConfirmAuthenticatedUserPage } from "../auth/ConfirmAuthenticatedUserPage";
import { useLinkCustomerToContractClient } from "../auth/LinkCustomerToContractClient";
import { Role } from "#vehicle-contract/common/lib/model/contract/Role";
import { CustomerReference } from "#vehicle-contract/common/lib/model/contract/CustomerReference";

export function LinkCustomer({
  token,
  role,
  licensePlateNumber,
  customerReferences,
  refetchContract,
}: {
  token: string;
  role: Role;
  licensePlateNumber?: string;
  customerReferences: CustomerReference[];
  refetchContract(): void;
}) {
  const { error, isLoading, link } = useLinkCustomerToContractClient(token);

  if (isLoading) return <FlowLoadingPage />;
  if (error) {
    return (
      <ErrorPage
        message="Det skjedde en feil da vi prøvde å koble din profil til kontrakten.
              Last siden på nytt for å prøve igjen."
      />
    );
  }
  return (
    <ConfirmAuthenticatedUserPage
      onClickConfirm={async () => {
        await link().promise;
        refetchContract();
      }}
      role={role}
      licensePlateNumber={licensePlateNumber}
      customerReferences={customerReferences}
    />
  );
}
