import { ButtonLink } from "@naf/teamscheme";
import { FlyoutModal } from "@naf/teamscheme";
import { onDesktop } from "@naf/teamscheme-core";
import { type ReactNode, useState } from "react";
import styled, { css } from "styled-components";
import { type ContractUpdate } from "./ContractUpdate";
import ContractUpdatesList from "./ContractUpdatesList";

export interface RenderChildrenParams {
  open(): void;
}

export interface ContractUpdatesModalProps {
  updates: ContractUpdate[];
  children(params: RenderChildrenParams): ReactNode;
  onRead(): void;
}

const Wrapper = styled.div`
  flex: 1;
  position: relative;
  min-height: 0;
  margin-bottom: ${({ theme }) => theme.spacing.space16};
`;
const StyledModal = styled(FlyoutModal)`
  display: flex;
  flex-direction: column;

  ${ContractUpdatesList} {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: auto;

    ${({ theme }) => css`
      margin: 0 -${theme.spacing.space24};
      padding: 0 ${theme.spacing.space24};

      ${onDesktop`
        margin: 0 -${theme.spacing.space48};
        padding: 0 ${theme.spacing.space48};
      `}
    `}
  }
`;

export default function ContractUpdatesModal({
  updates,
  children,
  onRead,
}: ContractUpdatesModalProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      {children({
        open: () => {
          setIsOpen(true);
        },
      })}
      <StyledModal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <h1>Endringer</h1>
        <p>Her kan du se endringer din motpart har gjort i kontrakten</p>
        <Wrapper>
          <ContractUpdatesList updates={updates} />
        </Wrapper>
        <div>
          <ButtonLink
            variant="primary"
            onClick={() => {
              onRead();
              setIsOpen(false);
            }}
          >
            Lukk
          </ButtonLink>
        </div>
      </StyledModal>
    </>
  );
}
