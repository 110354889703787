import { FlyoutModal, ModalProps } from "@naf/teamscheme";
import { Loading } from "./Loading";
import UpgradeModalContent from "./UpgradeModalContent";

interface UpgradeContractModalProps
  extends Omit<ModalProps, "title" | "children"> {}

export default function UpgradeContractModal({
  isOpen,
  onClose,
  ...props
}: UpgradeContractModalProps) {
  return (
    <FlyoutModal isOpen={isOpen} onClose={onClose} {...props}>
      {isOpen ? <UpgradeModalContent closeModal={onClose} /> : <Loading />}
    </FlyoutModal>
  );
}
