import { HTMLAttributes } from "react";
import styled from "styled-components";
import { capitalizeFirst } from "../../../utils";
import { NokPrice } from "@naf/teamscheme";

export interface TypeCardPriceElement {
  price: number;
  prefix?: string;
  highlight?: boolean;
}

export interface TypeCardPriceProps {
  elements: TypeCardPriceElement[];
}

export const TypeCardPrice = styled(function (
  props: HTMLAttributes<HTMLDivElement> & TypeCardPriceProps,
) {
  const { elements, ...divProps } = props;
  if (!elements.length) {
    return null;
  }
  return (
    <div {...divProps}>
      {elements.map((element, i) => (
        <span
          key={`${element.price}-${i}`}
          style={element.highlight ? { fontWeight: "bold" } : undefined}
        >
          {element.prefix ? `${capitalizeFirst(element.prefix)}: ` : null}
          {typeof element.price === "number" ? (
            <NokPrice value={element.price} />
          ) : (
            element.price
          )}
        </span>
      ))}
    </div>
  );
})`
    margin-bottom: ${({ theme }) => theme.spacing.space16};
    display: flex;
    flex-direction: column;
  `;
