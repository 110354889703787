import { Country } from "@naf/teamscheme";
import { HTMLAttributes } from "react";
import styled from "styled-components";

export const NorwegianPhoneNumberRegex = "^[4-9][0-9]{7}$";

export function formatPhoneNumber(number?: string) {
  if (!number) return number;
  if (number.length === 5) {
    return [number.slice(0, 2), number.slice(2, 5)].join(" ");
  }
  if (/^\+47/.test(number) && number.length === 11) {
    if (isMobilePhoneNumber(number.substring(3))) {
      return [
        number.slice(0, 3),
        number.slice(3, 6),
        number.slice(6, 8),
        number.slice(8),
      ].join(" ");
    }
    return [
      number.slice(0, 3),
      number.slice(3, 5),
      number.slice(5, 7),
      number.slice(7, 9),
      number.slice(9),
    ].join(" ");
  }

  if (number.length !== 8) return number;

  if (isMobilePhoneNumber(number)) {
    return [number.slice(0, 3), number.slice(3, 5), number.slice(5)].join(" ");
  }
  return [
    number.slice(0, 2),
    number.slice(2, 4),
    number.slice(4, 6),
    number.slice(6, 8),
  ].join(" ");
}

export function isNorwgeianPhoneNumber(number: string) {
  return number.startsWith("+47");
}

export function selectedPhoneCountryIsNorway(country?: Country) {
  return country?.name === "Norge";
}

function isMobilePhoneNumber(number: string) {
  return number.startsWith("4") || number.startsWith("9");
}

export interface PhoneNumberProps
  extends Omit<HTMLAttributes<HTMLSpanElement>, "children"> {
  number?: string;
}

const PhoneNumber = styled(({ number, ...props }: PhoneNumberProps) => {
  return <span {...props}>{formatPhoneNumber(number)}</span>;
})`
  white-space: nowrap;
`;

export default PhoneNumber;
