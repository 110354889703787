import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { PaginationResult } from "../../admin/api/PaginationResult";
import { useContractClient } from "../../api/ContractClient";
import { useContract } from "../ContractContext";
import { ContractViewModel } from "../model/ContractViewModel";
import { ContractUpdate } from "./ContractUpdate";
import UpdateType from "./UpdateType";
import { Unsubscribe } from "./Unsubscribe";
import { useClientBase } from "../../api/ClientBase";

export type UpdateSubscriberCallback = (contract: ContractViewModel) => void;

export interface ContractUpdateSubscriber {
  updateType: UpdateType;
  callback: UpdateSubscriberCallback;
}

export interface IContractUpdatesContext {
  onUpdate(subscriber: ContractUpdateSubscriber): Unsubscribe;
}

export function useOnUpdate(
  updateType: UpdateType,
  callback: UpdateSubscriberCallback,
) {
  const { onUpdate } = useContractUpdatesContext();
  useEffect(() => {
    const unsubscribe = onUpdate({ updateType, callback });

    return unsubscribe;
  }, [updateType, callback, onUpdate]);
}

export const ContractUpdatesContext =
  React.createContext<IContractUpdatesContext | null>(null);

export function useContractUpdatesContext() {
  const context = useContext(ContractUpdatesContext);
  if (!context) throw new Error("No ContractUpdatesContext found!");
  return context;
}

export function useContractUpdatesClient() {
  const { get } = useContractClient();
  const contract = useContract();

  return useClientBase(
    useCallback(
      ({ from }: { from: Date }) =>
        get<PaginationResult<ContractUpdate>>(
          `contract/${contract.id}/updates?after=${from.toISOString()}`,
        ),
      [get, contract.id],
    ),
  );
}

export function useGetContractClient() {
  const { get } = useContractClient();
  const contract = useContract();

  return useClientBase<void, ContractViewModel>(
    useCallback(() => get(`contract/${contract.id}`), [get, contract.id]),
  );
}
