import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  useCallback,
  ReactNode,
  forwardRef,
  HTMLAttributes,
} from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { useLayoutContext } from "./LayoutContext";

type RenderFunction = () => ReactNode;

interface Panel {
  id: number;
  render: RenderFunction;
}

interface BottomPanelRegistration {
  id: number;
  remove(): void;
  update(render: RenderFunction): void;
}

interface IBottomPanelContext {
  register(children: ReactNode): BottomPanelRegistration;
}

const BottomPanelContext = React.createContext<IBottomPanelContext | null>(
  null,
);

const ViewContainer = styled(
  forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
    function (props, ref) {
      return <div ref={ref} {...props} />;
    },
  ),
)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.25);
  background-color: white;
  justify-content: flex-start;
  align-items: center;
  z-index: 1;
`;

const ViewRow = styled(
  forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
    function (props, ref) {
      return <div ref={ref} {...props} />;
    },
  ),
)`
  padding: 0.5em 0;
  display: flex;
  justify-content: center;
  align-items: center;

  & > * {
    margin: 0 0.5em;
  }
`;

function BottomPanelView({ panels }: { panels: Panel[] }) {
  const [display, setDisplay] = useState<string | null>(null);
  const viewRef = useRef<HTMLDivElement | null>(null);
  const { setPaddingBottom } = useLayoutContext();
  const visible = panels.length > 0;

  function hide() {
    setDisplay("none");
  }

  function show() {
    setDisplay(null);
  }

  useEffect(() => {
    let timeout: number | null = null;

    if (visible) {
      timeout = window.setTimeout(show, 500);
    } else {
      hide();
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [visible]);

  // TODO: subsribe to panels and display changes
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!viewRef.current) return;

      const { height } = viewRef.current.getBoundingClientRect();

      setPaddingBottom(height);
    });

    return () => clearTimeout(timeout);
  }, [panels, display, setPaddingBottom]);

  const [hasMounted, setHasMounted] = useState(false);
  useEffect(() => setHasMounted(true), []);
  if (!hasMounted) return null;

  return ReactDOM.createPortal(
    <ViewContainer style={{ display: display || undefined }} ref={viewRef}>
      {panels.map((p) => (
        <ViewRow key={p.id}>{p.render()}</ViewRow>
      ))}
    </ViewContainer>,
    document.body,
  );
}

export function BottomPanelProvider({ children }: { children: ReactNode }) {
  const [panels, setPanels] = useState<Panel[]>([]);

  const idRef = useRef(0);

  const register = useCallback(function (children: ReactNode) {
    const id = ++idRef.current;
    const panel = { id, render: () => children };

    setPanels((p) => [...p, panel]);

    return {
      id,
      remove: () => setPanels((p) => p.filter((x) => x.id !== id)),
      update: (render: RenderFunction) =>
        setPanels((p) => [
          ...p.filter((x) => x.id !== id),
          { ...panel, render },
        ]),
    };
  }, []);

  return (
    <BottomPanelContext.Provider value={{ register }}>
      {children}
      <BottomPanelView panels={panels} />
    </BottomPanelContext.Provider>
  );
}

export default function BottomPanel({ children }: { children: ReactNode }) {
  const panel = useRef<BottomPanelRegistration | null>();
  const bottomPanelContext = useContext(BottomPanelContext);
  if (!bottomPanelContext) throw new Error("No BottomPanelContext found!");
  const { register } = bottomPanelContext;

  useEffect(() => {
    if (children) {
      panel.current = register(children);

      return () => panel.current?.remove();
    }
  }, [register, children]);

  return null;
}
