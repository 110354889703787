import { Button, ButtonButtonElementProps, ButtonProps } from "@naf/teamscheme";
import { LocationDescriptor } from "@naf/teamscheme";
import { EVENTS } from "../../Tracking";
import { loginWithRedirect } from "../../auth/AuthProvider";
import {
  getLocationState,
  getLocationString,
} from "../../shared/utils/Location";
import { ReactNode } from "react";

export interface LogInProps<T> {
  linkTo: LocationDescriptor<T>;
  email?: string;
}

export interface LogInButtonProps<T>
  extends LogInProps<T>,
    Omit<ButtonButtonElementProps, "onClick" | "trackEvent" | "children"> {
  children?: ReactNode;
}

export function LogInButton<T>({
  linkTo,
  email,
  variant,
  children,
  ...props
}: LogInButtonProps<T>) {
  const targetUrl = getLocationString(linkTo);
  const locationState = getLocationState(linkTo);
  return (
    <Button
      {...props}
      variant={variant ?? "outline"}
      trackEvent={EVENTS.COMMON.LOG_IN}
      onClick={() =>
        loginWithRedirect({
          targetUrl,
          locationState,
          options: {
            authorizationParams: {
              login_hint: email || "",
            },
          },
        })
      }
    >
      {children ?? "Logg inn"}
    </Button>
  );
}
