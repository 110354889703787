import {
  type ReactNode,
  type SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";
import { type Dispatch } from "react";
import { type ContractValidationResult } from "./ContractValidationResult";

export interface IContractValidationResultContext {
  result: ContractValidationResult | null;
  setResult: Dispatch<SetStateAction<ContractValidationResult | null>>;
}

const ContractValidationResultContext =
  createContext<IContractValidationResultContext | null>(null);

export default ContractValidationResultContext;

export function ContractValidationResultProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [result, setResult] = useState<ContractValidationResult | null>(null);

  return (
    <ContractValidationResultContext.Provider value={{ result, setResult }}>
      {children}
    </ContractValidationResultContext.Provider>
  );
}

export function useContractValidationResult() {
  const context = useContext(ContractValidationResultContext);

  if (!context) throw new Error("No ContractValidationResultContext found!");

  return context;
}
