import { Suspense, useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Main from "../../Main";
import { FaqFlyoutProvider } from "../../about/faq/FaqFlyout";
import { ContractClientProvider } from "../../api/ContractClient";
import FlowLoadingPage from "../../shared/FlowLoadingPage";
import { NotFoundScreen } from "../../shared/error/NotFoundScreen";
import { ContractProvider } from "../ContractProvider";
import { ContractReducerProvider } from "../ContractReducer";
import { LoadContract } from "../LoadContract";
import { AbTestingProvider } from "../ab-testing/AbTestingContext";
import { AttachmentUrlCacheProvider } from "../form/attachments/AttachmentUrlCache";
import ContractHeader from "../header/ContractHeader";
import { ContractHeaderContextProvider } from "../header/ContractHeaderContext";
import { DocumentPreviewProvider } from "../preview/DocumentPreviewContext";
import { PreviewFlyoutProvider } from "../preview/PreviewFlyout";
import ContractUpdatesProvider from "../updates/ContractUpdatesProvider";
import { ContractValidationProvider } from "../validation/ContractValidationProvider";
import { ContractValidationResultProvider } from "../validation/ContractValidationResultContext";
import { InputValidationProvider } from "../validation/InputValidationContext";
import { useContractStateRedirects } from "./ContractStateRedirects";
import { ContractUrlsProvider } from "./ContractUrls";
import { useAuth } from "../../auth/AuthProvider";
import { StaticLoadingScreen } from "../../shared/LoadingScreen";
import { ContractTokenContextProvider } from "../ContractTokenContext";
import { ContractFieldChangesProvider } from "../updates/ContractFieldChangesContext";
import { ContractToken } from "../model/ContractViewModel";

const LOADED_CONTRACT_PROVIERS = [
  Main,
  ContractClientProvider,
  InputValidationProvider,
  ContractHeaderContextProvider,
  ContractProvider,
  ContractFieldChangesProvider,
  ContractUrlsProvider,
  ContractValidationResultProvider,
  AbTestingProvider,
  ContractValidationProvider,
  DocumentPreviewProvider,
  AttachmentUrlCacheProvider,
  PreviewFlyoutProvider,
  FaqFlyoutProvider,
];

function Providers({ children }: { children: React.ReactNode }) {
  return (
    <>
      {LOADED_CONTRACT_PROVIERS.reduceRight(
        (acc, Provider) => (
          <Provider>{acc}</Provider>
        ),
        children,
      )}
    </>
  );
}

const InnerContractRouteWrapper = () => {
  useContractStateRedirects();

  return <Outlet />;
};

export function ContractRoutes() {
  const auth = useAuth();
  const { search } = useLocation();
  const [mounted, setMounted] = useState(false);
  useEffect(() => setMounted(true), []);

  if (!mounted) return <StaticLoadingScreen />;
  if (!search) return <NotFoundScreen />;
  const token = new URLSearchParams(search).get(
    "token",
  ) as ContractToken | null;
  if (!token) return <NotFoundScreen />;

  if (auth.isLoading || !auth.isInitialized) {
    return <StaticLoadingScreen />;
  }

  return (
    <ContractTokenContextProvider token={token}>
      <ContractReducerProvider>
        <ContractUpdatesProvider>
          <LoadContract>
            <Providers>
              <ContractHeader />
              <Suspense fallback={<FlowLoadingPage />}>
                <InnerContractRouteWrapper />
              </Suspense>
            </Providers>
          </LoadContract>
        </ContractUpdatesProvider>
      </ContractReducerProvider>
    </ContractTokenContextProvider>
  );
}
