import { useMemo, useState } from "react";
import { Outlet } from "react-router";
import { AppPages } from "../../../routing/AppPages";
import { AppRoutes, getChildrenPaths } from "../../../routing/AppRoutes";
import {
  LocationCollection,
  getChildLocations,
} from "../../../routing/ChildRoutes";
import { PathRoutes } from "../../../routing/PathRoutes";
import { useContractUrls } from "../../routing/ContractUrls";
import {
  ProgressBar,
  ProgressContext,
  ProgressWrapper,
} from "../progress/TrackProgress";

export function ContractFormRoutes() {
  const [progress, setProgress] = useState(0);

  return (
    <ProgressContext.Provider value={{ progress, setProgress }}>
      <ProgressWrapper>
        {typeof progress === "number" ? (
          <ProgressBar
            style={{
              transform: `scaleX(${progress})`,
            }}
          />
        ) : null}
      </ProgressWrapper>
      <Outlet />
    </ProgressContext.Provider>
  );
}

export type ContractFormLocations = LocationCollection<Partial<AppPages>>;

export function useContractFormLocations(): ContractFormLocations {
  const { [AppPages.ContractForm]: base } = useContractUrls();

  return useMemo(
    () =>
      getChildLocations(
        base,
        getChildrenPaths(
          AppRoutes[AppPages.ContractForm].absolute ?? "",
        ) as PathRoutes<AppPages>,
      ),
    [base],
  );
}
