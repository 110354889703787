import { Button, type ButtonProps } from "@naf/teamscheme";
import { type LocationDescriptor } from "@naf/teamscheme";
import { type ComponentType } from "react";
import styled from "styled-components";
import { EVENTS } from "../../../Tracking";
import { useContract } from "../../ContractContext";
import TrialMembershipInfoModalTextLink from "./TrialMembershipInfoModalTextLink";
import Banner from "../../../shared/elements/Banner";
import BenefitList from "../../../shared/elements/BenefitList";
import { useClientBase } from "../../../api/ClientBase";
import { useContractClient } from "../../../api/ContractClient";

export function ActivateTrialMembershipAlert({
  offerId,
  activateTrialMembershipLocation,
}: {
  offerId: string;
  activateTrialMembershipLocation: LocationDescriptor;
}) {
  const contract = useContract();
  const client = useContractClient();
  const reject = useClientBase(() =>
    client.post(
      `contract/${contract.id}/trialMembership/offer/${offerId}/reject`,
    ),
  );

  if (reject.result) {
    return (
      <Banner variant="green">
        <h2>Her er ditt prøvemedlemskap!</h2>
        <p>
          Du har takket nei til prøvemedlemskap. Ta kontakt med kundeservice
          hvis du ombestemmer deg.
        </p>
      </Banner>
    );
  }

  return (
    <Banner variant="green">
      <h2>Her er ditt prøvemedlemskap!</h2>
      <p>
        Kontrakten er oppgradert til Premium! Du får derfor et NAF
        prøvemedlemskap helt kostnadsfritt. Gyldig i 30 dager fra aktivering.{" "}
        <TrialMembershipInfoModalTextLink />
      </p>
      <BenefitList
        benefits={[
          "Veihjelp i hele Europa i 30 dager",
          "Teknisk og juridisk hjelp med den nye bilen",
          "Gode rabatter på bilhold",
        ]}
      />
      <Buttons>
        <ActivateButton
          variant="dark"
          linkTo={activateTrialMembershipLocation}
          trackEvent={EVENTS.TRIAL_MEMBERSHIP_ALERT.TO_ACTIVATION}
        >
          Til aktivering
        </ActivateButton>
        <Button
          trackEvent={EVENTS.TRIAL_MEMBERSHIP_ALERT.REJECT}
          variant="outline"
          isLoading={reject.isLoading}
          onClick={reject.fetch}
        >
          Nei takk
        </Button>
      </Buttons>
    </Banner>
  );
}

const ActivateButton = styled(Button)`
  margin-right: ${({ theme }) => theme.spacing.space24};
` as ComponentType<ButtonProps>;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
