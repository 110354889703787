import { ChangeEvent, Ref } from "react";
import { MembershipServiceContactInfo } from "../../../../membership/MembershipServiceContactInfo";
import { StringProfileFieldName } from "../../../../membership/StringProfileFieldName";
import { useValidationContextMessage } from "../OnboardingFormValidationContext";
import FormGroup from "../../../../forms/FormGroup";
import LabeledInput from "../../../../forms/LabeledInput";
import ErrorMessage from "../../../../shared/error/ErrorMessage";
import { onboardingFormCreateClassName } from "../OnboardingFormInputs";
import { OnboardingFormCustomInputWrapper } from "./OnboardingFormCustomInputWrapper";

export type OnboardingFormTextInputCustomType = "intString";

export function OnboardingFormTextInput({
  name,
  label,
  placeholder,
  contactInfo,
  setContactInfo,
  readOnly,
  inputRef,
  disabled,
  pattern,
  errorMessage,
  customType,
  maxLength,
}: {
  name: StringProfileFieldName;
  label: string;
  placeholder: string;
  contactInfo: MembershipServiceContactInfo;
  setContactInfo: React.Dispatch<
    React.SetStateAction<MembershipServiceContactInfo>
  >;
  readOnly?: boolean;
  inputRef?: Ref<HTMLInputElement>;
  disabled?: boolean;
  pattern?: string;
  errorMessage?: string;
  customType?: OnboardingFormTextInputCustomType;
  maxLength?: number;
}) {
  function onChange(
    fieldName: StringProfileFieldName,
    inputValue?: string,
    customType?: OnboardingFormTextInputCustomType,
  ) {
    let value = inputValue;

    if (customType === "intString" && value !== undefined) {
      value = value.replace(/[^0-9]/g, "");
    }

    setContactInfo((x) => ({
      ...x,
      [fieldName]: value || undefined,
    }));
  }

  const className = onboardingFormCreateClassName(name);
  const value = contactInfo[name] || "";
  const validationMessage = useValidationContextMessage(name);

  if (readOnly) {
    return (
      <OnboardingFormCustomInputWrapper
        name={name}
        label={label}
        className={className}
      >
        <span>{value || "--"}</span>
      </OnboardingFormCustomInputWrapper>
    );
  }

  return (
    <FormGroup className={className}>
      <LabeledInput
        label={label}
        name={name}
        inputRef={inputRef}
        id={`membership.${name}`}
        hasError={!!validationMessage}
        error={validationMessage}
        inputProps={{
          type: "text",
          placeholder,
          pattern,
          value,
          onChange: ({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
            onChange(name, value, customType),
          onBlur: ({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
            onChange(name, value ? value.trim() : undefined, customType),
          required: true,
          disabled: disabled,
          maxLength,
        }}
      />
      {errorMessage ? <ErrorMessage>{errorMessage}</ErrorMessage> : null}
    </FormGroup>
  );
}
