import { onDesktop } from "@naf/teamscheme-core";
import { Check as CheckIcon } from "@styled-icons/material/Check";
import { Remove as IndeterminateIcon } from "@styled-icons/material/Remove";
import React, {
  forwardRef,
  ReactNode,
  InputHTMLAttributes,
  HTMLAttributes,
} from "react";
import styled, { css } from "styled-components";
import { DescriptionInfoButton } from "./InputDescription";

type BooleanCheckboxProps = {
  name: string;
  label: ReactNode;
  description?: ReactNode;
  value: boolean;
  onChange: (value: boolean) => void;
} & Omit<
  Omit<React.InputHTMLAttributes<HTMLInputElement>, "value">,
  "onChange"
>;

const ButtonWrap = styled.div`
  display: inline-flex;
  width: fit-content;
  height: calc(
    ${({ theme }) => theme.fontStyle.article.articleText["font-size"]} *
      ${({ theme }) => theme.fontStyle.article.articleText["line-height"]}
  );
  align-items: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.s}) {
    calc(
      ${({ theme }) => theme.fontStyle.bodyText.bodyText["font-size"]} *
        ${({ theme }) => theme.fontStyle.bodyText.bodyText["line-height"]}
    )
  }
`;

const LabelWrapper = styled(function (
  props: { disabled?: boolean } & HTMLAttributes<HTMLSpanElement>,
) {
  return <span {...props} />;
})`
  padding-left: ${({ theme }) => theme.spacing.space16};
  ${(props) =>
    props.disabled &&
    css`
      color: ${props.theme.themeLight.typography.disabledText};
    `}
`;

const PseudoCheckbox = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 0;
  min-width: ${({ theme }) => theme.spacing.space24};
  width: ${({ theme }) => theme.spacing.space24};
  height: ${({ theme }) => theme.spacing.space24};
  border-radius: ${({ theme }) => theme.radius.s};
  box-sizing: border-box;
  transition: background 0.2s ease;
  border: 2px solid;
  border-color: ${({ theme }) => theme.themeLight.border.heavy};
  background: ${({ theme }) => theme.themeLight.background.default};
  color: ${({ theme }) => theme.themeLight.typography.defaultText};
  margin-top: 3px; /* Align with top of text */

  svg {
    position: absolute;
    display: none;
    font-size: ${({ theme }) => theme.spacing.space20};
  }
`;

const HoverStateWrapper = styled.div`
  display: inline-flex;
  align-items: flex-start;

  &:hover {
    cursor: pointer;
  }
`;

export const CheckboxWrapper = styled.div`
  label {
    display: inline-flex;
    align-items: center;

    em {
      text-decoration: underline;
    }
  }

  & > label:not(:last-of-type) {
    margin-bottom: 0.5em;
  }

  input {
    opacity: 0;
    position: absolute;
    width: ${({ theme }) => theme.spacing.space24};
    height: ${({ theme }) => theme.spacing.space24};
  }

  input:not([disabled]):focus-visible
    + ${HoverStateWrapper}
    > ${ButtonWrap}
    > ${PseudoCheckbox} {
    box-shadow: ${({ theme }) => theme.focus.color} 0px 0px 0px 3px;
  }

  input:not([disabled]) + ${HoverStateWrapper} {
    &:active {
      > ${ButtonWrap} > ${PseudoCheckbox} {
        border-color: ${({ theme }) => theme.focus.color};
      }
    }
    &:hover {
      > ${ButtonWrap} > ${PseudoCheckbox} {
        border-color: ${({ theme }) => theme.themeLight.border.default};
      }
    }
  }

  input:checked + ${HoverStateWrapper} > ${ButtonWrap} > ${PseudoCheckbox} {
    display: block;
    border-color: ${(props) =>
      props.theme.themeLight.componentColors.interactiveElement.active};
    background: ${(props) =>
      props.theme.themeLight.componentColors.interactiveElement.active};

    svg {
      display: initial;
    }
  }

  input[disabled] + ${HoverStateWrapper} {
    &:hover {
      cursor: not-allowed;
    }

    > ${ButtonWrap} > ${PseudoCheckbox} {
      border-color: ${({ theme }) => theme.themeLight.border.default};
      background: ${({ theme }) => theme.themeLight.background.default};
      color: ${({ theme }) => theme.themeLight.typography.disabledText};

      &:hover {
        border-color: ${({ theme }) => theme.themeLight.border.default};
      }
    }

    ${LabelWrapper} > span {
      color: ${({ theme }) => theme.themeLight.typography.disabledText};
    }
  }

  input[disabled]:checked
    + ${HoverStateWrapper}
    > ${ButtonWrap}
    > ${PseudoCheckbox} {
    border-color: ${({ theme }) => theme.themeLight.border.default};
    background: ${(props) =>
      props.theme.themeLight.componentColors.interactiveElement.disabled};
  }

  & {
    display: flex;
    flex-direction: column;

    ${onDesktop`
      flex-direction: row;
      & > label:not(:last-of-type) {
        margin-bottom: 0;
      }
    `}
  }
`;

export interface CheckboxInputProps
  extends Omit<
    InputHTMLAttributes<HTMLInputElement>,
    "checked" | "onChange" | "name" | "type"
  > {
  description?: ReactNode;
  label: ReactNode;
  name: string;
  checked: boolean;
  required?: boolean;
  indeterminate?: boolean;
  onChange: (checked: boolean) => void;
}

export const CheckboxInput = forwardRef<HTMLInputElement, CheckboxInputProps>(
  function (
    {
      description,
      name,
      label,
      checked,
      onChange,
      indeterminate = false,
      disabled,
      ...props
    },
    ref,
  ) {
    return (
      <span>
        <label>
          <input
            {...props}
            ref={ref}
            name={name}
            type="checkbox"
            checked={checked}
            disabled={disabled}
            onChange={(event) => {
              onChange(event.target.checked);
            }}
          />
          <HoverStateWrapper>
            <ButtonWrap>
              <PseudoCheckbox>
                {indeterminate ? <IndeterminateIcon /> : <CheckIcon />}
              </PseudoCheckbox>
            </ButtonWrap>
            <LabelWrapper disabled={disabled}>{label}</LabelWrapper>
          </HoverStateWrapper>
          {description ? (
            <DescriptionInfoButton label={label} description={description} />
          ) : null}
        </label>
      </span>
    );
  },
);

const LabeledBooleanCheckbox = forwardRef<
  HTMLInputElement,
  BooleanCheckboxProps
>(function ({ value, onChange, name, label, description, ...props }, ref) {
  return (
    <CheckboxWrapper>
      <CheckboxInput
        {...props}
        ref={ref}
        name={name}
        checked={value}
        onChange={onChange}
        description={description}
        label={label}
      />
    </CheckboxWrapper>
  );
});

export default LabeledBooleanCheckbox;
