import { SchemaType } from "#vehicle-contract/common/lib/model/contract/SchemaType";

export const SCHEMA_TYPE_LABELS = {
  [SchemaType.Car]: "Personbil",
  [SchemaType.Motorbike]: "Motorsykkel",
  [SchemaType.Camper]: "Bobil",
  [SchemaType.CampingTrailer]: "Campingvogn",
  [SchemaType.Trailer]: "Tilhenger",
  [SchemaType.Default]: "Annet kjøretøy",
};
