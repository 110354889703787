import { Role } from "#vehicle-contract/common/lib/model/contract/Role";
import { SignatureStatus } from "#vehicle-contract/common/lib/model/contract/SalesContract";
import { SigningParty } from "#vehicle-contract/common/lib/model/contract/SigningParty";
import { isCoOwner } from "../../admin/contract-details/SigningLinks";
import {
  ROLE_NAMES,
  useContract,
  useOtherRole,
  useRole,
} from "../ContractContext";
import {
  ContractViewModel,
  SignatureInfoViewModel,
} from "../model/ContractViewModel";

export function getNextTask(signature: SignatureInfoViewModel) {
  return signature.tasks.find(
    (x) => x.signatureStatus !== SignatureStatus.Signed,
  );
}

export function getSigningPartyRole(party: SigningParty) {
  switch (party) {
    case SigningParty.Buyer:
    case SigningParty.BuyerCoOwner:
      return Role.Buyer;
    case SigningParty.Seller:
    case SigningParty.SellerCoOwner:
      return Role.Seller;
    default:
      throw new Error(`Unrecognized SigningParty ${party}`);
  }
}

export function getMainSigningParty(role: Role) {
  switch (role) {
    case Role.Buyer:
      return SigningParty.Buyer;
    case Role.Seller:
      return SigningParty.Seller;
    default:
      throw new Error(`Unrecognized role ${role}`);
  }
}

export function getSigningPartyName(party: SigningParty) {
  const role = getSigningPartyRole(party);

  if (isCoOwner(party)) {
    return `${ROLE_NAMES[role]}s medeier`;
  }

  return ROLE_NAMES[role];
}

export function getRelativeSigningPartyName(
  relativeTo: Role,
  party: SigningParty,
) {
  const role = getSigningPartyRole(party);

  if (role === relativeTo) {
    return isCoOwner(party) ? "din medeier" : "du";
  }

  return getSigningPartyName(party);
}

export function getContactInfo(
  contract: ContractViewModel,
  party: SigningParty,
) {
  switch (party) {
    case SigningParty.Buyer:
      return contract.buyer;
    case SigningParty.BuyerCoOwner:
      return contract.buyerCoOwner;
    case SigningParty.Seller:
      return contract.seller;
    case SigningParty.SellerCoOwner:
      return contract.sellerCoOwner;
    default:
      throw new Error(`Unrecognized SigningParty ${party}`);
  }
}

export function getPhone(contract: ContractViewModel, party: SigningParty) {
  return getContactInfo(contract, party)?.phone;
}

function hasMainPartySigned(signature: SignatureInfoViewModel, role: Role) {
  return (
    signature.tasks.find((x) => x.signingParty === getMainSigningParty(role))
      ?.signatureStatus === SignatureStatus.Signed
  );
}

function haveBothPartiesSigned(signature: SignatureInfoViewModel, role: Role) {
  return (
    signature.tasks
      .filter((x) => x.role === role)
      .filter((x) => x.signatureStatus !== SignatureStatus.Signed).length === 0
  );
}

export function useSignatureStatus() {
  const contract = useContract();
  const role = useRole();
  const otherRole = useOtherRole();

  const { signature } = contract;

  if (!signature) return {};

  const isSigned = signature.signatureStatus === SignatureStatus.Signed;

  const sellersHaveSigned = haveBothPartiesSigned(signature, Role.Seller);
  const buyersHaveSigned = haveBothPartiesSigned(signature, Role.Buyer);
  const userHasSigned = hasMainPartySigned(signature, role);
  const otherHasSigned = hasMainPartySigned(signature, otherRole);

  const waitingForOtherSignature =
    !otherHasSigned && (signature.initiator !== role || userHasSigned);

  return {
    isSigned,
    sellersHaveSigned,
    buyersHaveSigned,
    userHasSigned,
    otherHasSigned,
    waitingForOtherSignature,
  };
}

export function getSignatureProgress(contract: ContractViewModel) {
  const { signature } = contract;
  if (!signature) return 0;

  const tasks = signature.tasks;

  const numbOfSigningParticipants = tasks.length;
  const numbOfSignatures = tasks.filter(
    (x) => x.signatureStatus === SignatureStatus.Signed,
  ).length;

  return numbOfSignatures / numbOfSigningParticipants;
}
