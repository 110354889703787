import { AppPages } from "../../../routing/AppPages";
import FlowLoadingPage from "../../../shared/FlowLoadingPage";
import ActivatedContractTypePage, {
  ActivatedContractBenefitList,
} from "../../contract-type/ActivatedContractTypePage";
import { PREMIUM_BENEFITS } from "../../contract-type/PremiumCard";
import {
  TrialMembershipProvider,
  useTrialMembershipContextClient,
} from "../../contract-type/trial-membership/TrialMembershipContext";
import { UpgradedContractInfoSection } from "../../contract-type/trial-membership/UpgradedContractInfoSection";
import { useRelativeUrls } from "../routing/FormFlow";
import { RelativeFormFooter } from "../routing/FormFooterLink";

export function MemberContractBenefitsList() {
  return (
    <ActivatedContractBenefitList
      benefits={PREMIUM_BENEFITS.filter((x) => !x.excludeMembers)}
    />
  );
}

export default function MemberContractPage({ page }: { page: AppPages }) {
  const relativeUrls = useRelativeUrls(page);
  const trialMembership = useTrialMembershipContextClient();

  if (trialMembership.isLoading) return <FlowLoadingPage />;

  return (
    <ActivatedContractTypePage>
      <TrialMembershipProvider result={trialMembership.result}>
        <UpgradedContractInfoSection />
      </TrialMembershipProvider>
      <RelativeFormFooter using={relativeUrls} />
    </ActivatedContractTypePage>
  );
}
