import { AccountInfo, AuthenticationResult } from "@azure/msal-browser";
import { createContext, useContext } from "react";

export const AdminLoginContext = createContext<IAdminLoginContext | null>(null);

export interface IAdminLoginContext {
  account: AccountInfo | null;
  idToken: string | undefined;
  error: string | null;
  isLoggedIn: boolean;
  isAdmin: boolean;
  openLogin(): void;
  logout(): void;
}

export function useAdminLogin() {
  const context = useContext(AdminLoginContext);

  if (!context) throw new Error("No AdminLoginContext found!");

  return context;
}
