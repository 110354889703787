import { LocationDescriptorObject } from "@naf/teamscheme";
import { useLocation } from "react-router";
import { useContractUrls } from "../routing/ContractUrls";
import { AppPages } from "../../routing/AppPages";

interface State {
  returnState?: unknown;
}

export function useSaveContractLocation() {
  const location = useLocation();
  const urls = useContractUrls();
  const search = new URLSearchParams(
    urls[AppPages.ContractSaveContract].search,
  );
  search.set("returnTo", location.pathname);
  const linkTo: LocationDescriptorObject<State> = {
    ...urls[AppPages.ContractSaveContract],
    state: {
      returnState: location.state,
    },
    search: `?${search.toString()}`,
  };
  return linkTo;
}
