import { ConsentNodeAnswer } from "./ConsentAnswers";
import { MembershipConsent } from "./useGetMembershipConsents";

function isConsentInList(
  consentList: ConsentNodeAnswer[],
  externalKey: string,
): boolean {
  if (consentList.find((consent) => consent.externalKey === externalKey)) {
    return true;
  }

  return false;
}

function hasConsented(
  consentList: ConsentNodeAnswer[],
  externalKey: string,
): boolean {
  const consent = consentList.find((x) => x.externalKey === externalKey);

  return consent?.consented ?? false;
}

export interface ConsentError {
  externalKey: string;
  message: string;
}

export function validateConsentAnswers(
  consentSchema: MembershipConsent[],
  consentValues: ConsentNodeAnswer[],
): {
  isValid: boolean;
  invalidConsents: ConsentError[];
} {
  const valididationList = consentSchema.map((membershipConsent) => {
    const validation = validateConsentAnswer(membershipConsent, consentValues);

    return { externalKey: membershipConsent.externalKey, validation };
  });
  const invalidList = valididationList.filter((x) => !x.validation.isValid);
  const invalidConsents = invalidList.map<ConsentError>((x) => ({
    externalKey: x.externalKey,
    message:
      x.validation.invalidType === "main"
        ? "Du må velge Ja eller Nei"
        : "Du må velge en kanal",
  }));

  return { isValid: invalidConsents.length === 0, invalidConsents };
}

interface ValidConsentResponse {
  isValid: boolean;
  externalKey: string;
  invalidType?: "main" | "child";
}

export function validateConsentAnswer(
  consent: MembershipConsent,
  answers: ConsentNodeAnswer[],
): ValidConsentResponse {
  const { externalKey, children } = consent;

  if (hasConsented(answers, externalKey)) {
    if (children && children.length > 0) {
      const hasChildConsented = children
        .filter((child) => isConsentInList(answers, child.externalKey))
        .some(
          (child) =>
            answers.find((x) => x.externalKey === child.externalKey)
              ?.consented ?? false,
        );

      if (!hasChildConsented) {
        return {
          externalKey,
          isValid: false,
          invalidType: "child",
        };
      }
    }
  }

  return {
    externalKey,
    isValid: true,
  };
}
