import { LocationDescriptor } from "@naf/teamscheme";
import { useContractUrls } from "../../routing/ContractUrls";
import { AppPages } from "../../../routing/AppPages";

export function useTrialMembershipLocation(): LocationDescriptor {
  const urls = useContractUrls();
  return {
    ...urls[AppPages.ContractPremiumRoot],
    pathname: urls[AppPages.ContractPremiumTrialMembership].pathname,
  };
}
