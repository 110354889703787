import type {
  LocationDescriptor,
  LocationDescriptorObject,
} from "@naf/teamscheme";

export const RETURN_PATH_PARAM = "returnTo";

export function getLocationString(location: LocationDescriptor) {
  return typeof location === "string" ? location : createLocationPath(location);
}

export function getLocationState(location: LocationDescriptor) {
  return typeof location === "string" ? null : location.state;
}

export function createLocationPath(location: LocationDescriptorObject) {
  return [location.pathname, location.search, location.hash]
    .filter((p) => p)
    .join("");
}

function replaceRouteParamsInPath(
  pathname: string,
  params: { [key: string]: string },
) {
  return Object.entries(params).reduce(
    (current, [key, value]) => current.replace(`:${key}`, value),
    pathname,
  );
}

export function addRouteParams(
  location: LocationDescriptor,
  params: { [key: string]: string },
) {
  if (typeof location === "string") {
    return replaceRouteParamsInPath(location, params);
  }

  const { pathname, ...rest } = location;

  return {
    ...rest,
    pathname: pathname && replaceRouteParamsInPath(pathname, params),
  };
}

function combineQueryParams(prev: string | undefined, params: URLSearchParams) {
  if (!prev) return params.toString();

  const combined = new URLSearchParams(prev);
  params.forEach((v, k) => combined.append(k, v));
  return combined.toString();
}

export function addLocationObjectQueryParam<T>(
  location: LocationDescriptorObject<T>,
  params: URLSearchParams,
) {
  const { search, ...rest } = location;

  return {
    ...rest,
    search: `?${combineQueryParams(search, params)}`,
  };
}

export function addQueryParam(
  location: LocationDescriptor,
  params: URLSearchParams,
) {
  if (typeof location === "string") {
    const [head, tail] = location.split("?");
    return `${head}?${combineQueryParams(tail, params)}`;
  }

  return addLocationObjectQueryParam(location, params);
}
