import { useState, useEffect, useMemo } from "react";
import { type ApiError } from "../../api/ApiClient";
import { useContractClient } from "../../api/ContractClient";
import { useLoadOncePerLocation } from "../../shared/useLoadOncePerLocation";
import { makeCancelable } from "../../utils";
import { useContract } from "../ContractContext";
import { type DateString } from "#vehicle-contract/common/lib/model/common/DateString";

export interface PublicDocumentPreviewViewModel {
  hash: string;
  previewUrl: string;
  expires: DateString;
}

export interface DocumentPreviewClient {
  loading: boolean;
  result: PublicDocumentPreviewViewModel | null;
  error: ApiError | null;
  load(): void;
}

export function useDocumentPreviewClient(): DocumentPreviewClient {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<ApiError | null>(null);
  const [result, setResult] = useState<PublicDocumentPreviewViewModel | null>(
    null,
  );
  const contract = useContract();
  const client = useContractClient();
  const { id } = contract;

  const { load, loadedLocation } = useLoadOncePerLocation();

  // TODO: subscribe to changes in document hash instead of relying on useEffect
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (!loadedLocation) return;

    setLoading(true);
    setResult(null);

    const cancelablePromise = makeCancelable(
      client.get<PublicDocumentPreviewViewModel>(
        `contract/${id}/document/preview`,
      ),
    );

    cancelablePromise.promise
      .then((document) => {
        setResult(document);
        setLoading(false);
      })
      .catch((e) => {
        const error = e as { isCanceled: true } | ApiError;
        console.log("get document preview error", error);
        if (!("isCanceled" in error && error.isCanceled)) {
          setLoading(false);
          setError(error as ApiError);
        }
      });

    return () => cancelablePromise.cancel();
  }, [id, client, contract.documentHash, loadedLocation]);

  return useMemo(
    () => ({
      loading,
      result,
      error,
      load,
    }),
    [loading, result, error, load],
  );
}
