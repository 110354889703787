import { AppPages } from "../routing/AppPages";
import { getAbsolutePath } from "../routing/AppRoutes";
import { ProductNumber } from "./ProductDescriptions";

export function createBecomeMemberFormPath(
  selectedProductNumber: ProductNumber,
  search: string,
) {
  return `${getAbsolutePath(AppPages.BecomeMemberForm, {
    ":productNumber": selectedProductNumber,
  })}${search}`;
}
