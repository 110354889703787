import { LocationDescriptorObject } from "@naf/teamscheme";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { Role } from "#vehicle-contract/common/lib/model/contract/Role";
import { SalesContract } from "#vehicle-contract/common/lib/model/contract/SalesContract";
import { AppPages } from "../../../routing/AppPages";
import {
  NavigationDirection,
  addNavigationDirection,
} from "../../../routing/NavigationDirection";
import { useContract, useRole } from "../../ContractContext";
import { useContractUrls } from "../../routing/ContractUrls";
import {
  ContractFormLocations,
  useContractFormLocations,
} from "./ContractFormRoutes";

function usePagesForRole() {
  const role = useRole();
  const pages = useFormFlowPaths();
  const pagesForRole = useMemo(
    () => pages.filter((x) => x.only === undefined || x.only.includes(role)),
    [role, pages],
  );
  return pagesForRole;
}

function getRedirectWithDirection(
  pagesForRole: FormFlowPath[],
  current: AppPages,
  direction: NavigationDirection = NavigationDirection.Forward,
) {
  const nextPage = getNextPage(pagesForRole, current);
  const prevPage = getPrevPage(pagesForRole, current);
  const targetPage =
    direction === NavigationDirection.Back ? prevPage : nextPage;

  return { direction, targetPage };
}

export interface NavigationDirectionState {
  direction?: NavigationDirection;
}

export interface RelativeUrls {
  next: LocationDescriptorObject<unknown> | null;
  prev: LocationDescriptorObject<unknown> | null;
  redirect: LocationDescriptorObject<unknown> | null;
}

export function useRelativeUrls(current: AppPages): RelativeUrls {
  const { [AppPages.Contract]: parent } = useContractUrls();
  const locations = useContractFormLocations();
  const pagesForRole = usePagesForRole();
  const location = useLocation();
  const state = location.state as NavigationDirectionState | null;
  const nextPage = getNextPage(pagesForRole, current);
  const prevPage = getPrevPage(pagesForRole, current);
  const redirect = getRedirectWithDirection(
    pagesForRole,
    current,
    state?.direction,
  );
  const nextResult = nextPage
    ? addNavigationDirection(
        locations[nextPage.path],
        NavigationDirection.Forward,
      )
    : parent;
  const prevResult = prevPage
    ? addNavigationDirection(locations[prevPage.path], NavigationDirection.Back)
    : parent;
  const redirectResult = redirect.targetPage
    ? addNavigationDirection(
        locations[redirect.targetPage.path],
        redirect.direction,
      )
    : parent;
  const result = {
    next: nextResult,
    prev: prevResult,
    redirect: redirectResult,
  };
  return result;
}

export function useRedirectUrlWithDirectionFromLocationState(
  current: AppPages,
) {
  const urls = useContractFormLocations();
  const pagesForRole = usePagesForRole();
  const location = useLocation();
  const state = location.state as NavigationDirectionState | null;
  const redirect = getRedirectWithDirection(
    pagesForRole,
    current,
    state?.direction,
  );
  const targetUrl = redirect.targetPage ? urls[redirect.targetPage.path] : null;
  const result = useMemo(
    () =>
      targetUrl ? addNavigationDirection(targetUrl, redirect.direction) : null,
    [targetUrl, redirect.direction],
  );
  return result;
}

function getNextPage(pagesForRole: FormFlowPath[], current: AppPages) {
  const currentIndex = pagesForRole.findIndex(
    (x) => x.path === current || x.also?.find((y) => y === current),
  );

  if (currentIndex < 0) throw new Error(`AppPath ${current} not found`);
  if (currentIndex >= pagesForRole.length - 1) return null;
  const nextIndex = currentIndex + 1;
  const nextPage = pagesForRole[nextIndex];
  return nextPage;
}

function getNext(
  urls: ContractFormLocations,
  pagesForRole: FormFlowPath[],
  current: AppPages,
) {
  const nextPage = getNextPage(pagesForRole, current);
  return nextPage ? urls[nextPage.path] : null;
}

function getPrevPage(pagesForRole: FormFlowPath[], current: AppPages) {
  const currentIndex = pagesForRole.findIndex(
    (x) => x.path === current || x.also?.find((y) => y === current),
  );
  if (currentIndex < 0) throw new Error(`AppPath ${current} not found`);
  if (currentIndex < 1) return null;
  const prevIndex = currentIndex - 1;
  const prevPage = pagesForRole[prevIndex];
  return prevPage;
}

function getPrev(
  urls: ContractFormLocations,
  pagesForRole: FormFlowPath[],
  current: AppPages,
) {
  const prevPage = getPrevPage(pagesForRole, current);
  return prevPage ? urls[prevPage.path] : null;
}

export function useNextLocation(current: AppPages) {
  const { [AppPages.Contract]: parent } = useContractUrls();
  const urls = useContractFormLocations();
  const pagesForRole = usePagesForRole();
  return getNext(urls, pagesForRole, current) || parent;
}

export function usePrevLocation(current: AppPages) {
  const { [AppPages.Contract]: parent } = useContractUrls();
  const urls = useContractFormLocations();
  const pagesForRole = usePagesForRole();
  return getPrev(urls, pagesForRole, current) || parent;
}

interface FormPathSection {
  section: (keyof SalesContract)[];
  path: AppPages;
}

const FORM_SECTIONS: FormPathSection[] = [
  {
    section: ["settlement", "transaction"],
    path: AppPages.ContractFormAboutPayment,
  },
  {
    section: ["buyer", "buyerCoOwner"],
    path: AppPages.ContractFormContactInfo,
  },
  {
    section: ["seller", "sellerCoOwner"],
    path: AppPages.ContractFormContactInfo,
  },
  {
    section: ["vehicleData"],
    path: AppPages.ContractFormAboutVehicle,
  },
  {
    section: ["buyerAdditionalInformation"],
    path: AppPages.ContractFormAboutVehicle,
  },
  {
    section: ["additionalInformation"],
    path: AppPages.ContractFormAboutVehicle,
  },
];

export function getFormPathForSection(section: keyof SalesContract) {
  const match = FORM_SECTIONS.filter((x) =>
    x.section.find((s) => s === section),
  );
  if (!match.length) throw new Error(`No such section: ${section}`);
  if (match.length > 1)
    throw new Error(`Matched multiple sections: ${section}`);
  return match[0].path;
}

interface FormFlowPath {
  path: AppPages;
  also?: AppPages[];
  only?: Role[];
}

export function useFormFlowPaths(): FormFlowPath[] {
  const contract = useContract();
  const { initiator } = contract;

  const pages = useMemo(
    () => [
      {
        path: AppPages.ContractFormAboutVehicle,
      },
      {
        path: AppPages.ContractFormContactInfo,
      },
      {
        path: AppPages.ContractFormAboutPayment,
        only: [initiator, Role.Buyer],
      },
      {
        path: AppPages.ContractFormAttachments,
        only: [Role.Seller],
      },
      {
        path: AppPages.ContractFormInsurance,
        only: [Role.Buyer],
      },
      {
        path: AppPages.ContractFormType,
      },
    ],
    [initiator],
  );

  return pages;
}
