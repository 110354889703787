import { type ReactNode, createContext, useContext } from "react";
import { ContractToken } from "./model/ContractViewModel";

export interface IContractTokenContext {
  token: ContractToken;
}

export const ContractTokenContext = createContext<IContractTokenContext | null>(
  null,
);

export function ContractTokenContextProvider({
  children,
  token,
}: { children: ReactNode; token: ContractToken }) {
  return (
    <ContractTokenContext.Provider value={{ token }}>
      {children}
    </ContractTokenContext.Provider>
  );
}

export function useContractTokenContext() {
  const context = useContext(ContractTokenContext);
  if (!context) throw new Error("No ContractTokenContext found!");
  return context;
}
