import { type ConsentPayloadType } from "@dtp/membership-service-types/dist/order/ConsentPayload";
import type {
  ConsentBase,
  ChildConsent,
  MembershipConsent,
} from "./useGetMembershipConsents";

// Used to track answers in the GUI
export interface ConsentNodeAnswer {
  externalKey: string;
  consented: boolean;
}

// Used to save to Consent Service
export interface ConsentAnswer {
  consentId: string;
  consented: boolean;
}

function mapToConsentAnswer(
  consent: MembershipConsent | ChildConsent,
): ConsentNodeAnswer {
  return {
    externalKey: consent.externalKey,
    consented: consent.consented,
  };
}

export function mapToAnswerNodes(
  consents: MembershipConsent[],
): ConsentNodeAnswer[] {
  const answered = consents.filter((x) => !x.undeterminedConsentStatus);

  return answered
    .map(mapToConsentAnswer)
    .concat(answered.flatMap((x) => x.children.map(mapToConsentAnswer)));
}

export function mapToConsentPayload(
  consentsSchema: MembershipConsent[],
  answers: ConsentNodeAnswer[],
): ConsentPayloadType[] {
  return filterAnsweresForUnanswerableConsents(consentsSchema, answers).map(
    (c) => ({
      consented: c.consented,
      consentNumber: c.consentId,
    }),
  );
}

function kv({ externalKey, consentId }: ConsentBase) {
  return { externalKey, consentId };
}

// Ensures we don't send answers for parent consents with id 'null'
export function filterAnsweresForUnanswerableConsents(
  consentsSchema: MembershipConsent[],
  answers: ConsentNodeAnswer[],
): ConsentAnswer[] {
  const mapping = consentsSchema.flatMap((c) => [kv(c), ...c.children.map(kv)]);
  return answers
    .map<ConsentAnswer | null>((c) => {
      const match = mapping.find((m) => m.externalKey === c.externalKey);

      if (!match?.consentId) return null;

      return {
        consented: c.consented,
        consentId: match.consentId,
      };
    })
    .filter((c): c is ConsentAnswer => c !== null);
}
