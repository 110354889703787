import {
  InfoAlert,
  Button,
  PageFooterCell,
  PageNavigationFooter,
} from "@naf/teamscheme";
import { useMemo, useState } from "react";
import { BecomeMemberFormInputs } from "../../../membership/BecomeMemberFormInputs";
import ErrorMessage from "../../../shared/error/ErrorMessage";
import {
  convertFromGenderEnum,
  convertToGenderEnum,
} from "../../../membership/GenderEnumConversion";
import { type MembershipServiceContactInfo } from "../../../membership/MembershipServiceContactInfo";
import MembershipConsentsForm from "../../../membership/consents/MembershipConsentsForm";
import { type ValidationResultViewModel } from "../../validation/ValidationResultViewModel";
import {
  OnboardingViewModel,
  usePostCustomerOnboardingClient,
} from "../NafIdOnboardingClient";
import { getRoleName } from "../../ContractContext";
import { LogInWithDifferentUserFlyout } from "../../auth/LogInWithDifferentUserFlyout";
import {
  ConsentNodeAnswer,
  mapToAnswerNodes,
  mapToConsentPayload,
} from "../../../membership/consents/ConsentAnswers";
import { filterAnsweredConsents } from "../../../membership/consents/filterAnsweredConsents";
import styled from "styled-components";
import { OnboardingFormInputs } from "./OnboardingFormInputs";

const StyledInfoAlert = styled(InfoAlert)`
  margin-bottom: ${({ theme }) => theme.spacing.space32};
`;

const Form = styled.form``;

export function OnboardingForm({
  token,
  onboarding,
  refetchContract,
}: {
  token: string;
  onboarding: OnboardingViewModel;
  refetchContract: () => void;
}) {
  const roleName = getRoleName(onboarding.role);
  const postOnboarding = usePostCustomerOnboardingClient(token);
  const unansweredConsents = filterAnsweredConsents(onboarding.consents);
  const [answers, setAnswers] = useState<ConsentNodeAnswer[]>(() =>
    mapToAnswerNodes(unansweredConsents),
  );
  const membershipServiceContactInfo = useMemo(
    () => ({
      ...onboarding.profile,
      gender: convertToGenderEnum(onboarding.profile.gender),
    }),
    [onboarding.profile],
  );
  const [contactInfo, setContactInfo] = useState<MembershipServiceContactInfo>(
    membershipServiceContactInfo,
  );
  const validationResult =
    postOnboarding.error && postOnboarding.error.status === 422
      ? (postOnboarding.error.result as ValidationResultViewModel)
      : null;

  return (
    <Form
      onSubmit={async (e) => {
        e.preventDefault();
        await postOnboarding.fetch({
          consents: mapToConsentPayload(unansweredConsents, answers),
          profile: {
            ...contactInfo,
            gender:
              typeof contactInfo.gender === "number"
                ? convertFromGenderEnum(contactInfo.gender)
                : undefined,
          },
        }).promise;
        refetchContract();
      }}
    >
      <OnboardingFormInputs
        profile={membershipServiceContactInfo}
        validationResult={validationResult}
        missingProfileFields={onboarding.missingProfileFields}
        hasMembership={onboarding.hasMembership}
        customerNumber={onboarding.customerNumber}
        contactInfo={contactInfo}
        setContactInfo={setContactInfo}
      />
      <MembershipConsentsForm
        rawConsents={unansweredConsents}
        answeredConsents={answers}
        setAnsweredConsents={setAnswers}
      />
      <StyledInfoAlert icon="info">
        Ved å lagre opplysninger låses brukeren til rollen som{" "}
        {roleName.regularCase} i kjøpekontrakten for{" "}
        {onboarding.licensePlateNumber}. Det vil ikke være mulig å endre dette i
        senere tid.
      </StyledInfoAlert>
      <PageNavigationFooter>
        {contactInfo.email ? (
          <LogInWithDifferentUserFlyout email={contactInfo.email} />
        ) : (
          <PageFooterCell />
        )}
        <Button
          type="submit"
          variant="secondary"
          isLoading={postOnboarding.loading}
        >
          Lagre opplysninger
        </Button>
      </PageNavigationFooter>
      {postOnboarding.error ? (
        <ErrorMessage>
          {postOnboarding.error.status === 422 ? (
            <span>Noen av opplysningene du oppga var feil.</span>
          ) : (
            <span>
              Det skjedde en feil da vi prøvde å lagre opplysningene! Prøv
              igjen.
            </span>
          )}
        </ErrorMessage>
      ) : null}
    </Form>
  );
}
