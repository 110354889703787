import { useEffect, useState } from "react";
import { search } from "../../shared/Filter";
import { FAQ_SECTIONS, FaqSection } from "./FaqQuestions";

export function useFilteredFaq({ query }: { query: string | null }) {
  const [matchedSections, setMatchedSections] = useState(FAQ_SECTIONS);

  useEffect(() => {
    const newSections = !query
      ? FAQ_SECTIONS
      : FAQ_SECTIONS.map((section) => {
          const items = section.items
            .map((item) => {
              const questionMatch = search(item.question, query);
              const answerMatch = search(item.answer, query);
              return {
                ...item,
                questionMatch:
                  questionMatch.weight !== undefined
                    ? questionMatch.result
                    : undefined,
                answerMatch:
                  answerMatch.weight !== undefined
                    ? answerMatch.result
                    : undefined,
              };
            })
            .filter(
              ({ questionMatch, answerMatch }) => questionMatch || answerMatch,
            )
            .map(({ ...item }) => ({
              ...item,
              question: item.questionMatch || item.question,
              answer: item.answerMatch || item.answer,
            }));

          if (!items.length) return null;

          return {
            ...section,
            items,
          };
        }).filter((s) => !!s);
    setMatchedSections(newSections as FaqSection[]);
  }, [query]);

  return { matchedSections };
}
