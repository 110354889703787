import { MenuListItem } from "@naf/teamscheme";
import { Visibility as EyeIcon } from "@styled-icons/material/Visibility";
import { Visibility as EyeIconOutline } from "@styled-icons/material-outlined/Visibility";
import { Star as StarIcon } from "@styled-icons/material/Star";
import { StarOutline as StarIconOutline } from "@styled-icons/material/StarOutline";
import { Cloud as CloudIcon } from "@styled-icons/material/Cloud";
import { Cloud as CloudIconOutline } from "@styled-icons/material-outlined/Cloud";
import { CloudDone as CloudDoneIcon } from "@styled-icons/material/CloudDone";
import { CloudDone as CloudDoneIconOutline } from "@styled-icons/material-outlined/CloudDone";
import { Help as HelpIcon } from "@styled-icons/material/Help";
import { HelpOutline as HelpIconOutline } from "@styled-icons/material/HelpOutline";
import { Info as InfoIcon } from "@styled-icons/material/Info";
import { Info as InfoIconOutline } from "@styled-icons/material-outlined/Info";
import { useCallback, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { EVENTS } from "../../Tracking";
import { useFaqFlyout } from "../../about/faq/FaqFlyout";
import SaveContractModal, {
  useIsSaved,
} from "../../contract/save-contract/SaveContractModal";
import { useAuthHeaderElements } from "../../layout/header/AuthHeaderElements";
import Header from "../../layout/header/Header";
import {
  combineMenuItems,
  createAboutLinkItem,
} from "../../layout/header/HeaderObjects";
import { HeaderVariant } from "../../layout/header/HeaderVariant";
import StaticHeader from "../../layout/header/StaticHeader";
import { AppPages } from "../../routing/AppPages";
import { createLocationPath } from "../../shared/utils/Location";
import { useContract, useRole } from "../ContractContext";
import { useContractLoginCallbackPageLocation } from "../contract-type/ContractLoginCallbackPageLocation";
import UpgradeContractModal from "../contract-type/modal/UpgradeContractModal";
import { getContractTypeName } from "../create-contract/ContractTypeName";
import { useHasUpgraded } from "../form/select-type/useHasUpgraded";
import ContractExplanationModal from "../preview/ContractExplanationModal";
import { usePreviewFlyout } from "../preview/PreviewFlyout";
import { useContractUrls } from "../routing/ContractUrls";
import { useContractHeaderContext } from "./ContractHeaderContext";
import { useThemeColorFromHeaderBackground } from "../../layout/header/HeaderBackground";

const FlowHeaderWrapper = styled.div`
  background-color: ${({ theme }) => theme.nafColor.signature.white};
  width: 100%;
  z-index: 2;
`;

export default function ContractHeader() {
  useThemeColorFromHeaderBackground();
  const contract = useContract();
  const role = useRole();
  const { showStatic } = useContractHeaderContext();
  const loginCallbackLocation = useContractLoginCallbackPageLocation();
  const authHeaderProps = useAuthHeaderElements(
    createLocationPath(loginCallbackLocation),
  );
  const wrapper = useRef<HTMLDivElement | null>(null);
  const aboutProps = createAboutLinkItem();
  const [saveContractIsOpen, setSaveContractIsOpen] = useState(false);
  const [upgradeOpen, setUpgradeOpen] = useState(false);
  const [contractExplanationOpen, setContractExplanationOpen] = useState(false);
  const toggleUpgradeModal = useCallback(() => setUpgradeOpen((x) => !x), []);
  const toggleSaveContractModal = useCallback(
    () => setSaveContractIsOpen((x) => !x),
    [],
  );
  const toggleContractExplanationModal = useCallback(
    () => setContractExplanationOpen((x) => !x),
    [],
  );
  const { open: openFaq } = useFaqFlyout();
  const { open: openPreview } = usePreviewFlyout();
  const currentContractType = contract.contractType?.current;
  const hasUpgraded = useHasUpgraded();
  const isSaved = useIsSaved();
  const contractLandingPageLocation = useContractUrls()[AppPages.Contract];

  const elements = useMemo(() => {
    const saveContractText = isSaved
      ? "Kontrakten er lagret"
      : "Lagre kontrakt";
    const saveContractProps: MenuListItem = {
      key: "SaveContract",
      text: saveContractText,
      icon: isSaved
        ? {
            icon: <CloudDoneIconOutline size={24} />,
            hoverIcon: <CloudDoneIcon size={24} />,
          }
        : {
            icon: <CloudIconOutline size={24} />,
            hoverIcon: <CloudIcon size={24} />,
          },
      trackEvent: {
        ...EVENTS.SAVE_CONTRACT_MODAL.OPEN,
        label: saveContractText,
      },
      onClick: toggleSaveContractModal,
    };

    const faqText = "Vanlige spørsmål";
    const faqProps: MenuListItem = {
      key: "FAQ",
      text: faqText,
      icon: {
        icon: <HelpIconOutline size={24} />,
        hoverIcon: <HelpIcon size={24} />,
      },
      trackEvent: { ...EVENTS.HEADER.FAQ, label: faqText },
      onClick: () => openFaq(),
    };

    const contractExplanationText = "Hva betyr feltene";
    const contractExplanationProps: MenuListItem = {
      key: "ContractExplanation",
      text: contractExplanationText,
      icon: {
        icon: <InfoIconOutline size={24} />,
        hoverIcon: <InfoIcon size={24} />,
      },
      trackEvent: {
        ...EVENTS.CONTRACT_EXPLANATION_MODAL.OPEN,
        label: contractExplanationText,
      },
      onClick: toggleContractExplanationModal,
    };

    const previewButtonText = "Forhåndsvisning";
    const previewButtonProps: MenuListItem = {
      key: "Forhåndsvisning",
      text: previewButtonText,
      trackEvent: { ...EVENTS.HEADER.PREVIEW, label: previewButtonText },
      icon: {
        icon: <EyeIconOutline size={24} />,
        hoverIcon: <EyeIcon size={24} />,
      },
      onClick: () => openPreview(),
    };

    const upgradeText =
      hasUpgraded && currentContractType
        ? getContractTypeName(currentContractType)
        : "Oppgrader kontrakten";
    const upgradeButton: MenuListItem = {
      key: "upgrade",
      text: upgradeText,
      trackEvent: { ...EVENTS.HEADER.UPGRADE_CONTRACT, label: upgradeText },
      icon: {
        icon: <StarIconOutline size={24} />,
        hoverIcon: <StarIcon size={24} />,
      },
      onClick: toggleUpgradeModal,
    };
    return combineMenuItems([
      previewButtonProps,
      upgradeButton,
      ...authHeaderProps,
      saveContractProps,
      contractExplanationProps,
      aboutProps,
      faqProps,
    ]);
  }, [
    toggleUpgradeModal,
    toggleSaveContractModal,
    openFaq,
    openPreview,
    toggleContractExplanationModal,
    currentContractType,
    authHeaderProps,
    aboutProps,
    hasUpgraded,
    isSaved,
  ]);

  return showStatic ? (
    <StaticHeader contract={contract} role={role} />
  ) : (
    <>
      <FlowHeaderWrapper ref={wrapper}>
        <Header
          variant={HeaderVariant.Flow}
          elements={elements}
          contract={contract}
          role={role}
          logoLinkLocation={contractLandingPageLocation}
        />
        <SaveContractModal
          isOpen={saveContractIsOpen}
          onClose={() => setSaveContractIsOpen(false)}
        />
        <ContractExplanationModal
          isOpen={contractExplanationOpen}
          onClose={() => setContractExplanationOpen(false)}
        />
        <UpgradeContractModal
          isOpen={upgradeOpen}
          onClose={() => setUpgradeOpen(false)}
        />
      </FlowHeaderWrapper>
    </>
  );
}
