export function leftpad(strIn: string | number, len = 2, pad = "0") {
  const str = strIn.toString();
  return (
    new Array(len - str.length)
      .fill(null)
      .map(() => pad)
      .join("") + str
  );
}

export function trimStart(str: string, char = "0") {
  const prev = str;
  let next = prev;
  do {
    next = prev.replace(new RegExp(`^${char}`), "");
  } while (prev !== next);
  return str;
}
