import { TextLink } from "@naf/teamscheme";
import { FinnNotFoundErrorMessage } from "../../contract/form/fields/finn/AddDataFromFinn";
import {
  GeneralVippsNokLimit,
  NoOwnershipTransferVippsNokLimit,
} from "../../contract/form/financial/VippsPaymentLimits";
import { AppPages } from "../../routing/AppPages";
import { getAbsolutePath } from "../../routing/AppRoutes";
import ExternalTextLink from "../../shared/ExternalTextLink";
import { NAF_NO_MITT_NAF_URL } from "../../shared/NafnoUrls";
import { CustomerServiceEmailTextLink } from "../../shared/elements/CustomerService";
import AboutTextLink from "../AboutTextLink";

export interface FaqSectionItem {
  top?: boolean;
  question: string;
  answer: JSX.Element | string;
}

export interface FaqSection {
  title: string;
  items: FaqSectionItem[];
}

export const FAQ_SECTIONS: FaqSection[] = [
  {
    title: "Opprett en kjøpekontrakt",
    items: [
      {
        question: "Kan tjenesten brukes til handel av alle typer kjøretøy?",
        answer: (
          <span>
            NAF Kjøpekontrakt tilpasser seg automatisk til kjøretøyet basert på
            registreringsnummeret, slik at partene kun trenger å forholde seg
            til opplysninger som er relevante for det aktuelle kjøretøyet.
            Tjenesten fungerer med personbil,{" "}
            <TextLink linkTo={getAbsolutePath(AppPages.LandingPageMc)}>
              MC
            </TextLink>
            , minibuss,{" "}
            <TextLink linkTo={getAbsolutePath(AppPages.LandingPageCaravan)}>
              campingvogn og bobil
            </TextLink>
            , traktor, snøscooter, lastebil, tilhenger, moped, og ATV.
          </span>
        ),
      },
      {
        question:
          "Jeg får opp: «Fant ingen kjøretøy på regnummeret. Prøv igjen.» når jeg legger inn reg.nummeret",
        answer: (
          <span>
            Sjekk at du har tastet inn riktig registreringsnummer. Hvis du
            fortsatt får feilmeldingen kan du klikke på «Finner ikke kjøretøyet»
            og deretter legge inn registreringsnummeret og understellsnummeret
            manuelt.
          </span>
        ),
      },
      {
        question: `Jeg får opp: «${FinnNotFoundErrorMessage}».`,
        answer: (
          <span>
            For å kunne bruke FINN-koden må kjøretøyet være en personbil, og
            selges av en privatperson (ikke en forhandler). Skal du kjøpe eller
            selge en annen type kjøretøy må du taste inn registreringsnummeret.
          </span>
        ),
      },
      {
        question:
          "Jeg har ikke registreringsnummer, hvordan oppretter jeg en kontrakt?",
        answer: (
          <span>
            Du kan velge «Finner ikke kjøretøyet» for å starte en kontrakt uten
            registreringsnummer. Du kan da velge å legge inn understellsnummeret
            om du har det tilgjengelig.
          </span>
        ),
      },
      {
        top: true,
        question: "Hvor finner jeg kjøpekontrakt til bil?",
        answer: (
          <span>
            Kjøpekontrakt til bruktbil oppretter du enkelt på NAF sine sider.
            Etter signering sendes den signerte kjøpekontrakten på e-post og den
            kan finnes igjen på Mitt NAF.
          </span>
        ),
      },
    ],
  },
  {
    title: "Fyll ut kontraktsopplysninger",
    items: [
      {
        question: "Hva skal jeg svare eller fylle ut i de ulike feltene?",
        answer:
          "Du kan se hjelpetekstene ved å trykke på spørsmålstegn-ikonet ved feltet du fyller ut. Er du fortsatt usikker kan du kontakte oss på chatten, så hjelper vi deg.",
      },
      {
        question: "Hvor henter dere personopplysninger fra?",
        answer: (
          <span>
            Opplysningene hentes fra profilen på Mitt NAF. Dersom du ikke har en
            profil fra før, kan du opprette dette i utfyllingen av kontrakten.
          </span>
        ),
      },
      {
        question: "Hvor henter dere omregistreringsavgiften fra?",
        answer: (
          <span>
            Vi regner ut basert på skatteetaten sin matrise for beregning av
            omregistreringsavgiften. Les mer her:{" "}
            <ExternalTextLink href="https://www.skatteetaten.no/person/avgifter/bil/eierskifte/">
              https://www.skatteetaten.no/person/avgifter/bil/eierskifte/
            </ExternalTextLink>
          </span>
        ),
      },
      {
        question: "Kan jeg endre det jeg har lagt inn?",
        answer: (
          <span>
            Ja, du kan bla tilbake til siden og feltet du ønsker å endre. Dette
            er mulig inntil dere har signert. Forhåndsvisningen oppdateres
            automatisk når du gjør endringer og motparten kan se hvilke
            endringer som er gjort.
          </span>
        ),
      },
    ],
  },
  {
    title: "Inviter motpart",
    items: [
      {
        question: "Hva gjør jeg hvis motparten ikke mottar invitasjonen?",
        answer: (
          <span>
            Sjekk at invitasjonen er sendt til riktig mobilnummer eller e-post.
            Klikk på «Inviter på nytt», tast inn mobilnummer eller
            e-postadressen og send invitasjon. Forsøk gjerne begge metodene.
            Dersom invitasjonen fortsatt ikke mottas, ta kontakt på chatten, så
            hjelper vi deg.
          </span>
        ),
      },
      {
        question:
          "Jeg har forsøkt å invitere motpart flere ganger og sendt mange invitasjoner med mobilnummeret. Nå funker det ikke lenger.",
        answer: (
          <span>
            Du kan maks sende 10 invitasjoner via SMS. Forsøk å sende invitasjon
            via e-post i stedet, eller ta kontakt på chatten så hjelper vi deg.
          </span>
        ),
      },
      {
        question:
          "Motparten har ikke smarttelefon og kan ikke åpne linker på telefonen sin. Hvordan får man fylt ut og signert?",
        answer: (
          <span>
            Du kan sende invitasjonen på e-post til mottaker i stedet. For å
            sende ny invitasjon trykker du på lenken "Inviter på nytt" og velger
            "inviter på e-post i stedet". Da kan du sende invitasjonen på nytt
            via e-post.
          </span>
        ),
      },
    ],
  },
  {
    title: "Eierskifte og omregistrering",
    items: [
      {
        question: "Er en kontrakt det samme som salgsmelding?",
        answer: (
          <span>
            Nei, kontrakten er dokumentet som viser hva partene har avtalt.
            Salgsmeldingen er et eget dokument som må sendes inn til Statens
            vegvesen og godkjennes for at eierskapet til kjøretøyet skal
            overføres til en ny eier. Dere kan velge å gjennomføre eierskiftet i
            NAF Kjøpekontrakt slik at dere slipper å gjøre dette manuelt hos
            Statens vegvesen.
          </span>
        ),
      },
      {
        question: "Hva er forskjellen på eierskifte og omregistrering?",
        answer: (
          <span>
            Eierskifte er prosessen hvor salgsmelding sendes og godkjennes, og
            kjøretøyet overføres til en ny eier. Omregistrering er hva den nye
            eieren må gjøre etter at eierskiftet er utført før kjøretøyet kan
            tas i bruk. Ny eier må forsikre kjøretøyet, betale
            omregistreringsavgift og omregistrere kjøretøyet før midlertidig
            vognkort kan lastes ned fra vegvesen.no.
          </span>
        ),
      },
      {
        question: "Hvordan fungerer eierskifte med NAF Kjøpekontrakt?",
        answer: (
          <span>
            Det fungerer ved at begge parter fyller inn fødselsnummer under
            personopplysningene og signerer eierskiftemeldingen sammen med
            kontrakten. Eierskiftet registreres ikke før oppgjøret er
            gjennomført og handelen fullføres i siste steg. Handelen må
            fullføres innen 30 dager etter at dokumentene ble signert for at
            eierskiftet skal registreres hos Statens vegvesen.
          </span>
        ),
      },
      {
        question:
          "Kan vi signere kontrakten nå, og vente med å signere eierskiftemeldingen til dagen for overtakelse?",
        answer: (
          <span>
            Eierskiftemeldingen må signeres samtidig som kjøpekontrakten for at
            eierskiftet skal gjennomføres med NAF Kjøpekontrakt. Dokumentet blir
            likevel ikke sendt til Statens vegvesen før partene fullfører
            handelen etter signering. Dere kan vente med å fullføre handelen til
            dere er klare, så lenge det skjer innen 30 dager etter signering.
            Dette er uavhengig av overtakelsesdatoen.
          </span>
        ),
      },
      {
        question:
          "Hva skjer hvis handelen ikke blir fullført før det har gått 30 dager etter signering?",
        answer: (
          <span>
            Dersom handelen ikke blir fullført før det har gått 30 dager, vil
            dere ikke kunne gjennomføre eierskiftet med NAF Kjøpekontrakt. Dere
            blir nødt til å logge dere inn hos Statens vegvesen for å sende og
            godkjenne salgsmeldingen gjennom deres selvbetjeningsløsning.
          </span>
        ),
      },
    ],
  },
  {
    title: "Signering",
    items: [
      {
        question: "Hva er InkSign?",
        answer: (
          <span>
            InkSign er den enkleste metoden for å signere dokumentene. Man
            bruker fingeren sin for å signere på smarttelefon eller musepekeren
            på datamaskin samtidig som man fyller ut fullt navn. Dette er en god
            løsning ved fysisk tilstedeværelse av begge parter hvor man i
            tillegg kan gjøre en sjekk om signaturen matcher motparten sin ID
            som bankkort/pass.
          </span>
        ),
      },
      {
        top: true,
        question:
          "Jeg venter på motparten sin signatur. Kan jeg lukke nettleseren?",
        answer: (
          <span>
            Ja, du kan lukke nettleseren. For å finne tilbake til kontrakten
            bruker du lenken du fikk tilsendt på SMS eller e-post.
          </span>
        ),
      },
      {
        question:
          "Det står at jeg venter på signatur fra motparten. Hva gjør jeg?",
        answer: (
          <span>
            Du må be motparten signere via lenken som ble sendt på SMS.
          </span>
        ),
      },
      {
        question: "Vi har signert, hvor finner jeg kontrakten?",
        answer: (
          <span>
            Kontrakten er tilgjengelig for nedlastning på oppsummeringssiden
            etter at begge har signert og fullført handelen. Du kan nå
            oppsummeringssiden via lenken du fikk på SMS. Du får også en e-post
            med kontrakten vedlagt.
          </span>
        ),
      },
      {
        question: "Kan jeg skrive ut kontrakten?",
        answer: (
          <span>
            Ja, du kan skrive ut kontrakten fra forhåndsvisningen hvis dere
            heller vil signere på papir. Høyreklikk på bildet av forhåndsvisning
            av kontrakten og lagre bildet. Dette kan skrives ut og signeres. Vær
            klar over at kontrakten må signeres digitalt for at den skal lagres
            hos NAF i to år.
          </span>
        ),
      },
      {
        top: true,
        question: "Er en digital signatur juridisk bindenede?",
        answer: (
          <span>
            Ja, signaturen er juridisk bindende og er like trygg som en vanlig
            håndskreven signatur. Les mer under:{" "}
            <AboutTextLink page={AppPages.AboutDigitalSigning}>
              Om digital signering
            </AboutTextLink>
          </span>
        ),
      },
      {
        question:
          "Vi oppdaget en feil i kontrakten etter at en av oss har signert, hva gjør vi?",
        answer: (
          <span>
            Så lenge ikke begge har signert kontrakten kan dere avbryte
            signeringen for å åpne kontrakten for endringer igjen. Signaturen
            blir da forkastet og dere kan prøve igjen. Hvis begge har signert må
            dere lage en ny kontrakt og vise til at kontrakten dere først hadde
            signert er ugyldig og at den nye kontrakten er den gyldige.
          </span>
        ),
      },
      {
        question: "Er det mulig å endre kontrakten etter den er signert?",
        answer: (
          <span>
            Nei. Når kontrakten er signert av begge parter så er den låst for
            endringer.
          </span>
        ),
      },
    ],
  },
  {
    title: "Betaling i NAF Kjøpekontrakt",
    items: [
      {
        question: "Hvordan gjør jeg opp dersom kjøretøyet har heftelser?",
        answer: (
          <>
            <p>
              Når kjøretøyet har heftelser bør selger først innhente en
              bekreftelse fra banken som dokumenterer størrelsen på gjelden
              (inkl. renter og omkostninger). Den bør også bekrefte at gjelden
              vil bli slettet ved innløsing av heftelsen.
            </p>
            <p>
              Beløpet som står dokumentert i bekreftelsen kan kjøper betale
              direkte til selgers bank der gjelden ligger. Da kan kjøper være
              sikker på at betalt beløp kun brukes til å innløse heftelsen.
            </p>
            <p>
              Skal du som kjøper tegne et billån med ny pant i kjøretøyet kan
              banken innløse heftelsen direkte med selger sin bank. Kjøper og
              selger trenger da kun å gjøre opp eventuell resterende del av
              kjøpesummen.
            </p>
          </>
        ),
      },
      {
        question: "Kan vi gjennomføre betaling gjennom NAF Kjøpekontrakt?",
        answer: (
          <span>
            Ja, med oppgradering av kjøpekontrakten til Premium er det mulig å
            overføre hele eller deler av kjøpesummen til selger gebyrfritt med
            Vipps. Kontrakten må signeres med BankID.
          </span>
        ),
      },
      {
        question: "Hvordan gjennomføres gebyrfri betaling med Vipps?",
        answer: (
          <p>
            Selger sender et betalingskrav fra kjøpekontrakten til kjøper sin
            Vipps-app med avtalt beløp. Kjøper skal godta kravet i sin
            Vipps-app. Feltet for å sende betalingskravet til kjøper
            tilgjengeliggjøres for selger dersom kjøpekontrakten er oppgradert
            til Premium, signering gjøres med BankID og beløpsgrensene ikke
            overskrides.
          </p>
        ),
      },
      {
        question:
          "Hva er beløpsgrensen for overføring med Vipps i NAF Kjøpekontrakt?",
        answer: (
          <>
            <p>
              Du slipper gebyr på 1 % hos Vipps for beløp som ikke overskrider:
            </p>
            <ul>
              <li>
                <GeneralVippsNokLimit /> når eierskiftet registreres med NAF
                Kjøpekontrakt.
              </li>
              <li>
                <NoOwnershipTransferVippsNokLimit /> uten eierskifte med NAF
                Kjøpekontrakt.
              </li>
            </ul>
            <p>
              Merk at beløpsgrensene varierer hos de ulike bankene, og at det
              kan forekomme gebyrer fra banken din. Vi anbefaler å undersøke hva
              som gjelder for din bank før gjennomførelse av betaling.
            </p>
          </>
        ),
      },
      {
        question:
          "Kan jeg Vippse mer enn 500\u00a0000\u00a0kr mot et gebyr i NAF Kjøpekontrakt?",
        answer: (
          <p>
            Nei. Beløpsgrensen er endelig, og det er kun mulig å benytte
            tjenesten én gang. Dersom beløpet overskrider beløpsgrensen, kan
            resten eller hele beløpet betales på annen måte.{" "}
          </p>
        ),
      },
      {
        question:
          "Kan vi overføre betalingen gebyrfritt med Vipps uten å gjennomføre eierskifte med NAF Kjøpekontrakt?",
        answer: (
          <span>
            Ja, uten eierskifte kan det overføres opptil{" "}
            <NoOwnershipTransferVippsNokLimit />. For høyere beløp må eierskifte
            gjennomføres med NAF Kjøpekontrakt.
          </span>
        ),
      },
      {
        question:
          "Motparten har ikke Vipps. Hvordan fullfører vi oppgjøret med NAF Kjøpekontrakt?",
        answer: (
          <span>
            Dere må da benytte en annen betalingsmetode og bekrefte at betaling
            og overlevering skjer som avtalt mellom kjøper og selger. Vi
            anbefaler at overlevering av nøkler og kjøretøy skjer samtidig som
            betaling og gjennomføring av eierskiftet hos Statens vegvesen.
          </span>
        ),
      },
      {
        question:
          "Vil betaling av kjøretøyet i NAF Kjøpekontrakt påvirke de ukentlige og årlige beløpsgrensene i Vipps?",
        answer: (
          <span>
            Nei, transaksjoner gjennom betalingsløsningen i NAF Kjøpekontrakt
            vil ikke telle mot de vanlige beløpsgrensene til Vipps.
          </span>
        ),
      },
    ],
  },
  {
    title: "Oppbevaring",
    items: [
      {
        top: true,
        question: "Hvor finner jeg den signerte kontrakten hos NAF?",
        answer: (
          <span>
            Kontrakten vedlegges en e-post som sendes etter fullført signering,
            og kan også lastes ned fra oppsummeringssiden etter fullført handel.
            Du finner tilbake til oppsummeringssiden via lenken mottatt på
            SMS/e-post, eller ved å logge inn på{" "}
            <ExternalTextLink href={NAF_NO_MITT_NAF_URL}>
              Mitt NAF
            </ExternalTextLink>
            . Dette er tilgjengelig så lenge NAF lagrer kontrakten (to år).
          </span>
        ),
      },
      {
        question:
          "Vi har signert en kontrakt, men må begynne på nytt for å legge til endringer. Hvordan sletter vi den signerte kontrakten?",
        answer: (
          <span>
            Det er ikke nødvendig at den signerte kontrakten slettes fra våre
            systemer. Dere kan likevel opprette en ny kontrakt og legge til et
            kort notat om at den første kontrakten ikke gjelder. Dette kan
            legges i feltet «Andre opplysninger». Forslag til tekst i en slik
            avtale kan være «Kjøpekontrakt for reg.nr av{" "}
            <strong>bilens reg.nr</strong> faller bort og erstattes av avtale av{" "}
            <strong>dato på ny kontrakt</strong>.».
          </span>
        ),
      },
      {
        question: "Handelen ble ikke noe av, hvordan sletter vi kontrakten?",
        answer: (
          <span>
            Dersom du ønsker at vi sletter en kontrakt før det har gått to år,
            kan du sende en e-post til <CustomerServiceEmailTextLink />. For å
            be om sletting av kontrakten må du være en av partene i kontrakten.
            Kontrakten blir sendt begge parter på e-post før den slettes fra
            våre systemer.
          </span>
        ),
      },
      {
        question: "Kan jeg laste ned kjøpekontrakten som PDF?",
        answer: (
          <span>
            Ja. Etter at begge parter har signert får dere den signerte
            kjøpekontrakten som PDF tilsendt på e-post. Kjøpekontrakten kan også
            lastes ned fra oppsummeringssiden via lenken du mottok på
            SMS/e-post.
          </span>
        ),
      },
    ],
  },
  {
    title: "Forsikring",
    items: [
      {
        question: "Jeg selger bil, må jeg si opp forsikringen min selv?",
        answer: (
          <span>
            Når salgsmeldingen er sendt inn til Statens vegvesen og godkjent av
            kjøperen, så utføres eierskiftet og selger sitt forsikringselskap
            får beskjed fra Statens vegvesen om å avslutte forsikringen.
            Kjøperen er ansvarlig for at ny forsikring ordnes.
          </span>
        ),
      },
      {
        question: "Hva er trafikkforsikringsavgift?",
        answer: (
          <span>
            1. januar 2018 ble årsavgiften erstattet med
            trafikkforsikringsavgift. Denne kreves inn av forsikringsselskapene.
            Du betaler kun for den tiden bilen er registrert med en forsikring.
            Du kan selv bestemme om du ønsker fakturaen: månedlig, kvartalsvis,
            halvår eller helår.{" "}
            <ExternalTextLink href="https://www.naf.no/bilhold/kostnader/arsavgiften-er-blitt-trafikkforsikringsavgift">
              Les mer på naf.no
            </ExternalTextLink>
          </span>
        ),
      },
      {
        question: "Når må den nye eieren ha ordnet forsikring?",
        answer: (
          <span>
            Dersom kjøretøyet skal tas i bruk og ikke avskiltes, bør forsikring
            være i orden allerede ved eierskifte. Har du ikke minst
            ansvarsforsikring innen tre dager fra og med vedtaksdatoen, vil du
            få gebyr for hver dag kjøretøyet er uforsikret.
          </span>
        ),
      },
    ],
  },
  {
    title: "Annet",
    items: [
      {
        question:
          "Kan kontrakten brukes hvis jeg selger eller kjøper fra et firma/næringsdrivende?",
        answer: (
          <span>
            Kjøpekontrakten vår er tilpasset kjøp mellom to privatpersoner. Den
            kan likevel brukes av næringsdrivende. I utfyllingen kan du krysse
            av for "Kjøretøyet selges/kjøpes på vegne av firma" og fylle inn
            organisasjonsnummer og firmanavn. Skal du også gjøre eierskifte med
            NAF Kjøpekontrakt må den som signerer ha signaturrett eller være
            prokurist for det aktuelle selskapet. Kontrakten kan ellers fylles
            ut som vanlig. Er en av partene en profesjonell bilforhandler bør
            det i stedet brukes en kontrakt som er godkjent av Norges
            Bilbransjeforbund (NBF).
          </span>
        ),
      },
      {
        question:
          "Kan NAF Kjøpekontrakt brukes ved kjøp og salg av bygg- og anleggsmaskiner?",
        answer: (
          <span>
            Ja, kjøpekontrakten kan brukes ved kjøp og salg av gravemaskin,
            minigraver, lift, truck, o.l. For å opprette kjøpekontrakt for bygg-
            og anleggsmaskiner uten registreringsnummer hos Statens vegvesen,
            kan du trykke på “Finner ikke kjøretøyet” og legge inn opplysninger
            manuelt.
          </span>
        ),
      },
    ],
  },
];
