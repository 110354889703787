import { type HTMLAttributes } from "react";
import styled from "styled-components";
import { useSchema } from "../ContractContext";
import { type ContractUpdate } from "./ContractUpdate";
import TimeSince from "./TimeSince";
import { display, enumDisplay } from "./display";

const Item = styled.div`
  border-bottom: 1px solid lightgray;
  padding-bottom: 1em;
  margin-bottom: 1.5em;

  small {
    display: block;
    font-size: 0.7em;
    color: gray;
  }
`;
export interface ContractUpdatesListProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "children"> {
  updates: ContractUpdate[];
}
export default styled(function ContractUpdatesList({
  updates,
  ...props
}: ContractUpdatesListProps) {
  const schema = useSchema();
  const dated = updates.map(({ date, ...rest }) => ({
    date: new Date(date),
    ...rest,
  }));
  const filtered = dated.reduce(
    (winners, { date, section, field, value, ...rest }) => {
      let match = winners.filter(
        (g) => g.section === section && g.field === field,
      )[0];

      if (!match) {
        match = { section, field, date, value, ...rest };

        winners.push(match);

        return winners;
      }

      if (match.date < date) {
        match.date = date;
        match.value = value;
      }

      return winners;
    },
    [] as (Omit<ContractUpdate, "date"> & { date: Date })[],
  );
  return (
    <div {...props}>
      {filtered.map(({ date, section, field, value, isEnumValue }) => (
        <Item key={`${section}-${field}-${date}`}>
          <strong>{schema.getFieldLabel(section, field)}</strong> endret til{" "}
          <strong>
            {isEnumValue
              ? enumDisplay(schema, section, field, value)
              : display(value)}
          </strong>
          <small>
            <TimeSince date={date} />
          </small>
        </Item>
      ))}
    </div>
  );
})``;
