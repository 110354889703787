import { LoadingText } from "@naf/teamscheme";
import React, { type HTMLAttributes, forwardRef } from "react";
import { DocumentPreviewZoomableScrollImage } from "./DocumentPreviewZoomableScrollImage";
import { Placeholder } from "./Placeholder";
import { DocumentPreviewContainer } from "./DocumentPreviewContainer";
import { PublicDocumentPreviewViewModel } from "./DocumentPreviewClient";

interface DocumentPreviewProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "children" | "style"> {
  isZoomed: boolean;
  setIsZoomed: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
  result?: PublicDocumentPreviewViewModel;
}

export const DocumentPreview = forwardRef<HTMLDivElement, DocumentPreviewProps>(
  function (
    { isZoomed, setIsZoomed, loading, result, className, ...props },
    ref,
  ) {
    return (
      <DocumentPreviewContainer {...props} className={className} ref={ref}>
        {loading ? (
          <Placeholder>
            <LoadingText text="Henter forhåndsvisning" />
          </Placeholder>
        ) : document && result ? (
          <DocumentPreviewZoomableScrollImage
            isZoomed={isZoomed}
            setIsZoomed={setIsZoomed}
            alt="Forhåndsvisning"
            src={result.previewUrl}
          />
        ) : null}
      </DocumentPreviewContainer>
    );
  },
);

export default DocumentPreview;
