import { LocationDescriptor } from "@naf/teamscheme";
import { LogInButton } from "../../auth/LogInButton";

export const LoginFormIngressText =
  "Logg inn for å forsette. Har du ikke allerede har en bruker hos NAF, kan du registrere en ny bruker når du logger inn.";

export function LogInForm({ linkTo }: { linkTo?: LocationDescriptor }) {
  return (
    <>
      <p>{LoginFormIngressText}</p>
      <LogInButton
        variant="primary"
        linkTo={linkTo ?? `${location.pathname}${location.search}`}
      />
    </>
  );
}
