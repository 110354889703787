import { LoadingText } from "@naf/teamscheme";
import { ButtonLink } from "@naf/teamscheme";
import { captureException } from "@sentry/core";
import { type ReactNode, useCallback, useEffect, useState } from "react";
import { type ApiError } from "../api/ApiClient";
import { useContractClient } from "../api/ContractClient";
import BottomPanel from "../layout/BottomPanel";
import { ContractContext } from "./ContractContext";
import {
  CustomUpdateCallback,
  FieldSave,
  RawFieldValue,
  UpdateContractResponse,
} from "./FieldSave";
import { useLoadContractContext } from "./LoadContractContext";
import { useContractHeaderContext } from "./header/ContractHeaderContext";
import { ContractViewModel, FieldValue } from "./model/ContractViewModel";
import { ContractValidationError } from "./validation/ContractValidationError";
import { useInputValidationContext } from "./validation/InputValidationContext";

interface Deferred {
  promise: Promise<void>;
  resolve: () => void;
  reject: (error: unknown) => void;
}

interface Save {
  deferred: Deferred;
  fields: FieldSave[];
}

export function ContractProvider({ children }: { children: ReactNode }) {
  const { contract, updateContract } = useLoadContractContext();
  const role = contract.role;
  const [saveError, setSaveError] = useState<ApiError | null>(null);
  const [saving, setSaving] = useState(false);
  const client = useContractClient();
  const {
    setIsSaving: setIsSavingInHeader,
    setHasSaved: setIsSavedInHeader,
    setSaveError: setSavedErrorInHeader,
  } = useContractHeaderContext();

  useEffect(() => {
    setIsSavingInHeader(saving);
  }, [saving, setIsSavingInHeader]);

  const [saves, setSaves] = useState<Save[]>([]);
  const [queue, setQueue] = useState<FieldSave[]>([]);

  const enqueueChanges = useCallback(function enqueueChanges(
    fields: FieldSave[],
  ) {
    setQueue((prev) => [...prev, ...fields]);
  }, []);

  const saveFields = useCallback(
    function saveFields(fields: FieldSave[]) {
      const deferred: Partial<Deferred> = {};

      deferred.promise = new Promise((resolve, reject) => {
        deferred.resolve = resolve;
        deferred.reject = reject;
      });

      setSaves((saves) => [
        ...saves.map(({ deferred, fields }) => ({
          deferred,
          fields: fields.filter(
            (a) =>
              !fields.filter(
                (b) => !(a.section === b.section && a.field === b.field),
              ).length,
          ),
        })),
        { deferred: deferred as Deferred, fields: queue.concat(fields) },
      ]);
      setQueue([]);

      return deferred.promise;
    },
    [queue],
  );

  const saveField = useCallback(
    function saveField(
      section: string,
      field: string,
      value: RawFieldValue,
      fieldValue: FieldValue | null,
      update?: CustomUpdateCallback,
    ) {
      return saveFields([{ section, field, value, fieldValue, update }]);
    },
    [saveFields],
  );

  const { setErrors: setInputValidationErrors } = useInputValidationContext();

  const contractId = contract?.data?.id;

  const onChange = useCallback(
    (data: ContractViewModel) => updateContract(data),
    [updateContract],
  );

  useEffect(() => {
    if (!saves.length) return;

    const timeout = setTimeout(async () => {
      setSaving(true);
      const fieldSaves = saves.flatMap(({ fields }) => fields);
      const deferreds = saves.map(({ deferred }) => deferred);
      const payload = {
        updates: fieldSaves
          .filter(({ section }) => !!section)
          .reduce((acc, y) => {
            const { section, field, fieldValue } = y;
            const match = acc.filter(
              (x) => x.section === section && x.field === field,
            );

            if (match.length) match[0].fieldValue = fieldValue;
            else acc.push(y);

            return acc;
          }, [] as FieldSave[])
          .map(({ section, field, fieldValue }) => ({
            name: section,
            fieldUpdate: {
              name: field,
              value: fieldValue,
            },
          })),
      };

      try {
        if (payload.updates.length) {
          const updateResult = await client.put<UpdateContractResponse>(
            `contract/${contractId}`,
            payload,
          );
          setInputValidationErrors((errors) => {
            const matchingErrors = payload.updates.map((x) => {
              const match = updateResult.validation.errors.find(
                (y) =>
                  x.name === y.section &&
                  x.fieldUpdate.name === y.field &&
                  y.role === role,
              );
              return { update: x, error: match };
            });
            const failedUpdates = matchingErrors
              .map((x) => x.error)
              .filter((x): x is ContractValidationError => !!x);
            const filtered = errors.filter((x) => {
              const match = matchingErrors.find(
                (y) =>
                  y.update.name === x.section &&
                  y.update.fieldUpdate.name === x.field &&
                  !y.error,
              );

              return !match;
            });

            return [...filtered, ...failedUpdates];
          });
          onChange(updateResult.contract);
        }

        setSaves([]);

        for (const deferred of deferreds) deferred.resolve();

        for (const x of fieldSaves) {
          const { section, field, value, update } = x;

          updateContract((prev) => {
            if (update) return update(prev);

            const next: ContractViewModel = {
              ...prev,
              [section]: {
                // biome-ignore lint/suspicious/noExplicitAny: <explanation>
                ...(prev as any)[section],
                [field]: value,
              },
            };

            return next;
          });
        }
        setSaveError(null);
        setSavedErrorInHeader(null);
        setIsSavedInHeader(true);
      } catch (e) {
        const error = e as { isCanceled: boolean } | ApiError;
        if (!("isCanceled" in error && error.isCanceled)) {
          for (const deferred of deferreds) deferred.reject(error);
          console.error("Could not save contract changes", error);
          setSaveError(error as ApiError);
          setSavedErrorInHeader(error as ApiError);
          captureException(error);
        }
      } finally {
        setSaving(false);
      }
    }, 50);

    return () => {
      clearTimeout(timeout);
    };
  }, [
    saves,
    client,
    contractId,
    role,
    setInputValidationErrors,
    onChange,
    updateContract,
    setIsSavedInHeader,
    setSavedErrorInHeader,
  ]);

  const applyContractUpdates = useCallback(
    (updates: (prev: ContractViewModel) => ContractViewModel) =>
      updateContract((contract) => updates(contract)),
    [updateContract],
  );

  return (
    <ContractContext.Provider
      value={{
        contract: contract.data,
        role: role,
        isInitiator: contract.role === contract.data.initiator,
        applyContractUpdates,
        onChange,
        saveField,
        saveFields,
        enqueueChanges,
      }}
    >
      {children}
      {saveError ? (
        <BottomPanel>
          <span>Vi klarte ikke lagre endringene.</span>
          <ButtonLink
            variant="secondary"
            onClick={() => setSaves((saves) => [...saves])}
            disabled={saving}
          >
            {saving ? <LoadingText text="Lagrer" /> : "Prøv på nytt"}
          </ButtonLink>
        </BottomPanel>
      ) : null}
      {!saveError && saving ? (
        <BottomPanel>
          <LoadingText text="Lagrer" />
        </BottomPanel>
      ) : null}
    </ContractContext.Provider>
  );
}

export default ContractContext;
