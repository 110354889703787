import { Gender } from "#vehicle-contract/common/lib/model/contract/ContractingParty";

export const genderOptions = [
  {
    key: "female",
    value: Gender.Female,
    label: "Dame",
  },
  {
    key: "male",
    value: Gender.Male,
    label: "Mann",
  },
  {
    key: "unknown",
    value: Gender.Unknown,
    label: "Ønsker ikke å oppgi kjønn",
  },
];
