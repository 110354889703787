import { DateString } from "@naf/teamscheme";
import { Role } from "#vehicle-contract/common/lib/model/contract/Role";
import { useAutoLoadingClientBase } from "../../api/ClientBase";
import { useCallback } from "react";
import { api } from "../../api/ApiClient";

export interface ContractUnauthorizedUserViewModel {
  created: DateString;
  licensePlateNumber?: string;
  role: Role;
}

export function useContractUnauthorizedUserClient(token: string | null) {
  return useAutoLoadingClientBase(
    useCallback(
      () =>
        api.get<ContractUnauthorizedUserViewModel>(
          `contractunauthorizeduser/token/${token}`,
        ),
      [token],
    ),
  );
}
