import {
  Auth0Client,
  type Auth0ClientOptions,
  type IdToken,
  type RedirectLoginOptions,
} from "@auth0/auth0-spa-js";
import { type LocationDescriptor } from "@naf/teamscheme";
import {
  type ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useTrackContext } from "../Tracking";
import { useSentryTag } from "../useSentryTag";
import { RETURN_PATH_PARAM } from "../shared/utils/Location";

export interface LoginOptions {
  targetUrl: string;
  locationState?: unknown;
  options?: Omit<RedirectLoginOptions, "appState" | "redirect_uri">;
}

export interface IAuthContext {
  isLoading: boolean;
  isInitialized: boolean;
  isAuthenticated: boolean;
  email: string | null;
  isAdmin: boolean;
}

export const AuthContext = createContext<IAuthContext | null>(null);

export function useAuth() {
  const auth0Context = useContext(AuthContext);
  if (!auth0Context) throw new Error("No Auth0Context found!");
  return auth0Context;
}

export function SsrAuthProvider({ children }: { children: ReactNode }) {
  return (
    <AuthContext.Provider
      value={{
        isLoading: false,
        isInitialized: false,
        email: null,
        isAdmin: false,
        isAuthenticated: false,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

function createClient(): Auth0Client {
  if (typeof window === "undefined") {
    return new Auth0Client({ clientId: "", domain: "" });
  }

  const location = window.location;
  const options: Auth0ClientOptions =
    location.host === "kjopekontrakt.naf.no"
      ? {
          domain: import.meta.env.VITE_AUTH0_PROD_DOMAIN,
          clientId: import.meta.env.VITE_AUTH0_PROD_CLIENT_ID,
          authorizationParams: {
            audience: import.meta.env.VITE_AUTH0_PROD_AUDIENCE,
          },
        }
      : {
          domain: import.meta.env.VITE_AUTH0_STAGE_DOMAIN,
          clientId: import.meta.env.VITE_AUTH0_STAGE_CLIENT_ID,
          authorizationParams: {
            audience: import.meta.env.VITE_AUTH0_STAGE_AUDIENCE,
          },
        };
  const initOptions: Auth0ClientOptions = {
    ...options,
    authorizationParams: {
      ...options.authorizationParams,
      redirect_uri: `${location.origin}/login/callback`,
      scope: "email",
    },
    useCookiesForTransactions: true,
    cacheLocation: "localstorage",
    useRefreshTokensFallback: true,
    useRefreshTokens: true,
  };
  const client = new Auth0Client({
    ...initOptions,
  });

  return client;
}

export const auth0Client = createClient();

export const initAuthResult =
  typeof window === "undefined"
    ? Promise.resolve()
    : auth0Client.checkSession();

export function loginWithRedirect({
  targetUrl,
  locationState,
  options,
}: LoginOptions) {
  return auth0Client.loginWithRedirect({
    ...options,
    authorizationParams: {
      redirect_uri: `${document.location.origin}/login/callback`,
      ...options?.authorizationParams,
    },
    appState: {
      locationState,
      targetUrl,
    },
  });
}

function getRedirectCallback(
  appState?: { targetUrl: string } | { locationState: unknown },
) {
  const next: LocationDescriptor = {
    pathname: "/",
    state: null,
    search: undefined,
  };

  if (appState && typeof appState === "object") {
    if ("targetUrl" in appState) {
      const [path, search] = appState.targetUrl.split("?");
      next.pathname = path;
      next.search = search ? `?${search}` : undefined;
    }
    if ("locationState" in appState) {
      next.state = appState.locationState;
    }
  }

  return next;
}

type LoginCallback = () => void;
type Cleanup = () => void;
let subscribers: (() => void)[] = [];
function onLogin(callback: LoginCallback): Cleanup {
  subscribers.push(callback);

  return () => {
    subscribers = subscribers.filter((cb) => cb !== callback);
  };
}

export async function handleRedirectCallback() {
  const { appState } = await auth0Client.handleRedirectCallback();

  for (const sub of subscribers) sub();

  return { redirect: getRedirectCallback(appState) };
}

export function Auth0AuthProvider({ children }: { children: ReactNode }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [idTokenClaims, setIdTokenClaims] = useState<IdToken | undefined>();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const init = useCallback(async () => {
    setIsLoading(true);
    const isAuthenticated = await auth0Client.isAuthenticated();
    const idTokenClaims = await auth0Client.getIdTokenClaims();
    console.log({ isAuthenticated, idTokenClaims });
    setIsAuthenticated(isAuthenticated);
    setIdTokenClaims(idTokenClaims);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    async function waitForInitAuth() {
      setIsLoading(true);
      await initAuthResult;
      await init();
      setIsInitialized(true);
    }

    waitForInitAuth();
  }, [init]);

  useEffect(() => onLogin(init), [init]);

  useSentryTag("isAuthenticated", isAuthenticated);
  useTrackContext("is_authenticated", isAuthenticated);

  const context: IAuthContext = {
    isLoading,
    isInitialized,
    email: idTokenClaims?.email ?? null,
    isAdmin: getIsAdmin(idTokenClaims),
    isAuthenticated,
  };

  return (
    <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
  );
}

export function logout() {
  const { origin, pathname, search } = document.location;
  const query = new URLSearchParams({
    [RETURN_PATH_PARAM]: `${pathname}${search}`,
  });
  const returnTo = `${origin}/logout/callback?${query.toString()}`;
  return auth0Client.logout({ logoutParams: { returnTo } });
}

function getIsAdmin(idTokenClaims: IdToken | undefined) {
  if (!idTokenClaims) return false;

  const roles: string[] | undefined = idTokenClaims["https://id.naf.no/roles"];
  if (!roles) return false;

  const adminRole = "VehicleContractAdmin";
  return roles.includes(adminRole);
}
