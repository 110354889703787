import { type LocationDescriptorObject } from "@naf/teamscheme";
import React from "react";
import { type ReactNode, useContext, useMemo } from "react";
import { AppPages } from "../../routing/AppPages";
import { AppRoutes, getAbsolutePath } from "../../routing/AppRoutes";
import { type LocationCollection } from "../../routing/ChildRoutes";
import { useContractToken } from "../ContractParams";

export interface IContractUrlsContext {
  urls: ContractUrls;
}

export const ContractUrlsContext =
  React.createContext<IContractUrlsContext | null>(null);

export type ContractUrls = LocationCollection<AppPages>;

export function getContractLocation(
  key: AppPages,
  token: string,
): LocationDescriptorObject {
  const query = `?token=${token}`;
  const absolute = getAbsolutePath(key);
  return { pathname: absolute, search: query };
}

export function getUrls(token: string) {
  const paths = Object.keys(AppRoutes) as AppPages[];
  const result = paths.reduce(
    (acc, key) => {
      acc[key as AppPages] = getContractLocation(key, token);
      return acc;
    },
    {} as { [key in AppPages]: LocationDescriptorObject },
  );
  return result;
}

export function ContractUrlsProvider({ children }: { children: ReactNode }) {
  const token = useContractToken();

  const urls = useMemo(() => getUrls(token), [token]);

  return (
    <ContractUrlsContext.Provider
      value={{
        urls,
      }}
    >
      {children}
    </ContractUrlsContext.Provider>
  );
}

export function useContractUrls(): ContractUrls {
  const context = useContext(ContractUrlsContext);

  if (!context) throw new Error("No ContractUrlsContext found!");

  const { urls } = context;

  return urls;
}
