import {
  SchemaType,
  SchemaTypeName,
  SCHEMA_TYPES,
  SCHEMA_TYPE_NAMES,
} from "./model/contract/SchemaType";
import fieldConfiguration from "./field-configuration.json";
import text from "./text.json";
import { Field, FieldOption } from "./model/contract/Field";
export { fieldConfiguration, text };

type EnableSource = { [key in SchemaTypeName]: boolean };

declare interface EnableSourceWithDefault extends EnableSource {
  Default: boolean;
}

declare interface SectionType {
  label: string;
  fields: { [key: string]: Field };
}

declare interface Text {
  sectionTypes: { [key: string]: SectionType };
  sections: { [key: string]: { type: string } };
  subtitle: string;
}

declare interface Validation {
  sectionTypes: {
    [typeName: string]: {
      [fieldName: string]: {
        required?: boolean;
      };
    };
  };
}

export interface ConditionQuestion {
  id: string;
  type: "boolean" | "int" | "string";
  required: boolean;
  hidden?: boolean;
  excludeElectricVehicles?: boolean;
}

function includes<T>(array: T[], element: T) {
  for (const e of array) if (element === e) return true;
  return false;
}

function values<T>(object: { [key: string]: T }): T[] {
  return Object.keys(object).map((key) => object[key]);
}

export class Schema {
  type: SchemaType;
  typeName: SchemaTypeName;
  text: Text;
  validation: Validation;
  conditionQuestions: ConditionQuestion[];
  _enableFields: { [key: string]: { [key: string]: EnableSourceWithDefault } };

  constructor(schemaType: SchemaType) {
    if (!includes(values(SCHEMA_TYPES), schemaType)) {
      throw new Error(
        `Unrecognized schemaType "${schemaType}". Valid schema types are: ${(<
          SchemaTypeName[]
        >Object.keys(SCHEMA_TYPES))
          .map((key) => ({ name: key, value: SCHEMA_TYPES[key] }))
          .map(({ name, value }) => `${value} (${name})`)
          .join(", ")}`
      );
    }
    this.type = schemaType;
    this.text = getLabels(this.type);
    this.typeName = SCHEMA_TYPE_NAMES[this.type];
    this.conditionQuestions = (fieldConfiguration.conditionQuestions[
      this.typeName
    ] || fieldConfiguration.conditionQuestions.Default) as any;
    this.validation = fieldConfiguration.validation;
    this._enableFields = fieldConfiguration.enableFields;
  }

  isEnabled(sectionName: string, fieldName: string): boolean {
    const section = this._enableFields[sectionName];

    if (!section) return false;

    const field = section[fieldName];

    if (!field) return false;

    return typeof field[this.typeName] === "boolean"
      ? field[this.typeName]
      : field.Default;
  }

  getSectionType(sectionName: string) {
    const { sections, sectionTypes } = this.text;
    const section = sections[sectionName];

    if (!section) return null;

    const typeName = section.type;
    const type = sectionTypes[typeName];

    return type;
  }

  getSectionLabel(sectionName: string) {
    const section = this.getSectionType(sectionName);
    if (!section) return null;
    return section.label;
  }

  getFieldType(sectionName: string, fieldName: string) {
    const section = this.getSectionType(sectionName);
    if (!section) return null;
    return section.fields[fieldName] || null;
  }

  getFieldLabel(sectionName: string, fieldName: string) {
    const type = this.getFieldType(sectionName, fieldName);
    if (!type) return null;
    return type.label;
  }

  getFieldPlaceholder(sectionName: string, fieldName: string) {
    const type = this.getFieldType(sectionName, fieldName);
    if (!type) return null;
    return type.placeholder;
  }

  getFieldDescription(sectionName: string, fieldName: string) {
    const type = this.getFieldType(sectionName, fieldName);
    if (!type) return null;
    return type.description;
  }

  getFieldValidation(sectionName: string, fieldName: string) {
    const { sections } = this.text;
    const { sectionTypes } = this.validation;
    const section = sections[sectionName];
    const typeName = section.type;

    if (!(typeName in sectionTypes)) return {};

    const sectionValidation = sectionTypes[typeName];

    if (!(fieldName in sectionValidation)) return {};

    return sectionValidation[fieldName];
  }
}

type RawSchemaTypeStringBase = { [key in SchemaTypeName]?: string };

interface RawSchemaTypeString extends RawSchemaTypeStringBase {
  [SchemaTypeName.Default]: string;
}

type RawSchemaTypeOptionsBase = { [key in SchemaTypeName]?: FieldOption[] };

interface RawSchemaTypeOptions extends RawSchemaTypeOptionsBase {
  [SchemaTypeName.Default]: FieldOption[];
}

function getFieldValue(
  source: RawSchemaTypeString,
  schemaType: SchemaType
): string {
  return source[SCHEMA_TYPE_NAMES[schemaType]] || source.Default;
}

function getFieldOptions(
  source: RawSchemaTypeOptions,
  schemaType: SchemaType
): FieldOption[] {
  return source[SCHEMA_TYPE_NAMES[schemaType]] || source.Default;
}

function getField(source: RawSectionTypeField, schemaType: SchemaType): Field {
  const { label, description, placeholder, options } = source;
  return {
    label: getFieldValue(label, schemaType),
    options: options ? getFieldOptions(options, schemaType) : undefined,
    description: description
      ? getFieldValue(description, schemaType)
      : undefined,
    placeholder: placeholder
      ? getFieldValue(placeholder, schemaType)
      : undefined,
  };
}

interface RawSectionTypeField {
  label: RawSchemaTypeString;
  placeholder?: RawSchemaTypeString;
  description?: RawSchemaTypeString;
  options?: RawSchemaTypeOptions;
}

interface RawSectionType {
  label: RawSchemaTypeString;
  fields: { [key: string]: RawSectionTypeField };
}

function getLabels(schemaType: SchemaType): Text {
  const { sectionTypes, sections, subtitle, ...x } = text;

  return {
    ...x,
    sectionTypes: Object.keys(sectionTypes)
      .map((key) => ({
        key,
        sectionType: (sectionTypes as any)[key] as RawSectionType,
      }))
      .map(({ key, sectionType: { label, fields, ...sectionType } }) => ({
        key,
        sectionType: {
          ...sectionType,
          label: getFieldValue(label, schemaType),
          fields: Object.keys(fields)
            .map((key) => ({
              key,
              field: fields[key],
            }))
            .map(({ key, field }) => ({
              key,
              field: getField(field, schemaType),
            }))
            .reduce<{ [key: string]: Field }>((acc, { key, field }) => {
              acc[key] = field;
              return acc;
            }, {}),
        },
      }))
      .reduce<{ [key: string]: SectionType }>((acc, { key, sectionType }) => {
        acc[key] = sectionType;
        return acc;
      }, {}),
    sections,
    subtitle: getFieldValue(subtitle, schemaType),
  };
}
