import React, { type HTMLAttributes } from "react";
import styled from "styled-components";
import { defaultAspectRatio } from "./ZoomableImage";

export const Placeholder = styled(function ({
  children,
  ...props
}: HTMLAttributes<HTMLDivElement>) {
  return (
    <div {...props}>
      <section>{children}</section>
    </div>
  );
})`
  background: ${({ theme }) => theme.nafColor.signature.white};
  flex: 1 0 auto;
  width: 100%;
  padding-top: ${Math.round((1 / defaultAspectRatio) * 1000) / 10}%;
  position: relative;

  & > section {
    padding: ${({ theme }) => theme.spacing.space24};
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

`;
