import { captureException } from "@sentry/core";
import React, {
  useState,
  useEffect,
  useCallback,
  createContext,
  ReactNode,
  useMemo,
} from "react";
import { AttachmentFileViewModel, TemporaryUrl } from "./AttachmentInfoClient";
import { LoadAttachmentParameters } from "./LoadAttachmentParameters";

interface IAttachmentUrlCacheContext {
  urls: { [id: string]: Promise<string> };
  load(parameters: LoadAttachmentParameters): void;
}

export const AttachmentUrlCacheContext =
  createContext<IAttachmentUrlCacheContext | null>(null);

export function AttachmentUrlCacheProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [cache, setCache] = useState<{ [id: string]: Promise<TemporaryUrl> }>(
    {},
  );
  const [queue, setQueue] = useState<LoadAttachmentParameters[]>([]);

  const doLoad = useCallback(async function load(
    cache: { [id: string]: Promise<TemporaryUrl> },
    {
      id,
      client,
    }: {
      id: string;
      client: { get: (id: string) => Promise<AttachmentFileViewModel> };
    },
  ) {
    const cachePromise = cache[id];

    if (cachePromise) {
      try {
        const cached = await cachePromise;
        if (new Date(cached.expires) > new Date()) return;
      } finally {
      }
    }

    try {
      const promise = client.get(id).then((x) => x.url);
      setCache((c) => ({
        ...c,
        [id]: promise,
      }));
    } catch (error) {
      captureException(error);
    } finally {
    }
  }, []);

  const loadItem = useCallback(
    (cfg: {
      id: string;
      client: { get: (id: string) => Promise<AttachmentFileViewModel> };
    }) => doLoad(cache, cfg),
    [cache, doLoad],
  );

  const process = useCallback(
    async (queue: LoadAttachmentParameters[]) => {
      if (!queue.length) return;
      const next = queue[0];
      setQueue((q) => q.filter(({ id }) => id !== next.id));
      await loadItem(next);
    },
    [loadItem],
  );

  useEffect(() => {
    process(queue);
  }, [process, queue]);

  const load = useCallback(
    ({ id, client }: LoadAttachmentParameters) =>
      setQueue((q) => [...q, { id, client }]),
    [],
  );

  const urls = Object.keys(cache).reduce(
    (x, key) => {
      x[key] = cache[key].then(({ href }) => href);
      return x;
    },
    {} as {
      [id: string]: Promise<string>;
    },
  );

  return (
    <AttachmentUrlCacheContext.Provider
      value={{
        urls,
        load,
      }}
    >
      {children}
    </AttachmentUrlCacheContext.Provider>
  );
}
