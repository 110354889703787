import { captureException } from "@sentry/core";
import {
  type ReactNode,
  type SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Role } from "#vehicle-contract/common/lib/model/contract/Role";
import { SCHEMA_TYPE_NAMES } from "#vehicle-contract/common/lib/model/contract/SchemaType";
import { useTrackContext, useTracking } from "../Tracking";
import { roleName } from "../admin/shared/roleName";
import { api, ApiError } from "../api/ApiClient";
import { TITLE_POSTFIX, useDocumentTitle } from "../shared/DocumentTitle";
import { useSentryTag } from "../useSentryTag";
import { type ContractViewModel } from "./model/ContractViewModel";
import {
  LoaderFunction,
  useLoaderData,
  useNavigate,
  useRevalidator,
} from "react-router-dom";
import { StaticLoadingScreen } from "../shared/LoadingScreen";
import { NotFoundScreen } from "../shared/error/NotFoundScreen";
import { getContractTypeKeySoft } from "./create-contract/ContractTypeName";
import { LoadContractContext } from "./LoadContractContext";
import { UnauthorizedScreen } from "./UnauthorizedScreen";
import { type UnauthorizedContractErrorResult } from "./UnauthorizedContractErrorResult";
import { useContractReducerContext } from "./ContractReducer";
import { useContractTokenContext } from "./ContractTokenContext";

function getContractTitle(contract: ContractViewModel | null) {
  if (!contract) return null;

  if (contract.licensePlateNumber) return contract.licensePlateNumber;
  if (contract.vehicleData) {
    const { manufactureName, modelName } = contract.vehicleData;
    return [manufactureName, modelName].filter((p) => !!p?.length).join(" ");
  }
  return null;
}

interface ConflictResult {
  archived: boolean;
  quarantined: boolean;
}

interface LoadedContract {
  contract: ContractViewModel;
  role: Role;
}

type ContractLoaderResult =
  | { conflict: ConflictResult }
  | { loaded: LoadedContract }
  | { unauthorized: UnauthorizedContractErrorResult };

export const contractLoader: LoaderFunction = async ({
  request,
}): Promise<null | ContractLoaderResult> => {
  if (typeof window === "undefined") return null;
  const token = new URL(request.url).searchParams.get("token");
  if (!token) return null;

  try {
    const contract = await api.get<ContractViewModel>(
      `contract/token/${token}`,
    );
    const role =
      contract && "buyerToken" in contract ? Role.Buyer : Role.Seller;
    return { loaded: { contract, role } };
  } catch (error: unknown) {
    console.log({ error });
    const apiError = error as ApiError;
    if (apiError.status === 409) {
      const conflictResult = apiError.result as ConflictResult;
      return { conflict: conflictResult };
    }
    if (apiError.status === 401) {
      const unauthorizedResult =
        apiError.result as UnauthorizedContractErrorResult;
      return { unauthorized: unauthorizedResult };
    }
    captureException(apiError);
    throw new Error("Failed to fetch contract");
  }
};

contractLoader.hydrate = false;

export function LoadContract({
  children,
}: {
  children: ReactNode;
}) {
  const { token } = useContractTokenContext();
  const { state, dispatch } = useContractReducerContext();
  const updateContract = useCallback(
    (action: SetStateAction<ContractViewModel>) =>
      dispatch({ type: "update", value: action }),
    [dispatch],
  );
  const [redirect, setRedirect] = useState<string | null>(null);
  const tracking = useTracking();
  const onLoad = useCallback(
    function onLoad({ contract, role }: LoadedContract) {
      tracking.contractLoad(contract, role);
    },
    [tracking],
  );

  const contractData = state?.contract?.data;

  useSentryTag("contractId", contractData ? contractData.id : null);
  useSentryTag("role", state ? roleName(state.contract.role) : null);
  useTrackContext("role", state ? roleName(state.contract.role) : null);
  const currentType = contractData?.contractType?.current;
  useSentryTag("contractType", getContractTypeKeySoft(currentType));
  useTrackContext("contractType", getContractTypeKeySoft(currentType));
  useTrackContext(
    "contractSchemaType",
    contractData?.schemaType !== undefined
      ? SCHEMA_TYPE_NAMES[contractData.schemaType]
      : null,
  );
  const documentTitle = useMemo(() => {
    if (!state) return null;
    return getContractTitle(state.contract.data);
  }, [state]);

  useDocumentTitle(
    documentTitle ? `${documentTitle} ${TITLE_POSTFIX}` : undefined,
  );

  const loaderResult = useLoaderData() as ContractLoaderResult;

  const unauthorizedContractErrorResult =
    "unauthorized" in loaderResult ? loaderResult.unauthorized : null;

  useEffect(() => {
    if ("loaded" in loaderResult) {
      const { contract, role } = loaderResult.loaded;
      dispatch({ type: "loaded", value: { data: contract, role } });
      if (contract) onLoad({ contract, role });
    }

    if ("conflict" in loaderResult) {
      const conflictResult = loaderResult.conflict;
      if (conflictResult.archived) {
        setRedirect(`/arkiv?token=${token}`);
      } else if (conflictResult.quarantined) {
        setRedirect(`/karantene?token=${token}`);
      }
    }
  }, [loaderResult, onLoad, dispatch, token]);

  const revalidator = useRevalidator();

  const navigate = useNavigate();

  useEffect(() => {
    if (redirect) navigate(redirect, { replace: true });
  }, [redirect, navigate]);

  if (unauthorizedContractErrorResult) {
    return (
      <UnauthorizedScreen
        token={token}
        result={unauthorizedContractErrorResult}
        refetchContract={revalidator.revalidate}
      />
    );
  }

  if (redirect) {
    return <StaticLoadingScreen text="Videresender" />;
  }

  if (typeof window === "undefined" || !state) {
    return <StaticLoadingScreen text="Henter kjøpekontrakt" />;
  }

  if (!contractData) {
    return <NotFoundScreen />;
  }

  return (
    <LoadContractContext.Provider
      value={{
        contract: state.contract,
        updated: state.updated,
        updateContract,
        loaded: state.loaded,
      }}
    >
      {children}
    </LoadContractContext.Provider>
  );
}
