import { type ButtonHTMLAttributes } from "react";
import styled from "styled-components";
import { OverlayBackground, Zoom } from "./DocumentOverlay";

const ContractPrevieWrapperBody = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  overflow-y: hidden;
`;
export const ContractPreviewWrapper = styled(function ({
  children,
  ...props
}: Omit<ButtonHTMLAttributes<HTMLButtonElement>, "type">) {
  return (
    <button type="button" {...props}>
      <ContractPrevieWrapperBody>{children}</ContractPrevieWrapperBody>
    </button>
  );
})`
  cursor: zoom-in;
  position: relative;
  display: block;
  margin: 0 auto;
  padding: 0;
  border: 0;
  border-radius: 2px;
  width: 100%;
  max-width: 720px;
  padding-top: ${((8 * 31) / 720) * 100}%;
  transition: box-shadow 0.25s ease;

  &:hover {
    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.1);
  }

  &:hover,
  &:active {
    ${OverlayBackground} {
      opacity: 0.05;
    }

    ${Zoom} {
      box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
    }
  }
`;
