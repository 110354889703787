import { Button } from "@naf/teamscheme";
import { ReactNode } from "react";
import { TITLE_POSTFIX } from "../DocumentTitle";
import { LoadErrorPage } from "./LoadErrorPage";

export interface ErrorPageProps {
  message?: ReactNode;
}

export default function ErrorPage({
  message = "Vi klarte dessverre ikke hente kontrakten.",
}: ErrorPageProps) {
  return (
    <LoadErrorPage title={`Feil ${TITLE_POSTFIX}`}>
      <p>{message}</p>
      <p>
        <Button variant="primary" onClick={() => window.location.reload()}>
          Last på nytt
        </Button>
      </p>
    </LoadErrorPage>
  );
}
