import { Accordion, AccordionRow } from "@naf/teamscheme";
import { Fragment, useEffect } from "react";
import styled from "styled-components";
import { EVENTS } from "../../Tracking";
import { FAQ_SECTIONS } from "./FaqQuestions";

const items = FAQ_SECTIONS.flatMap(({ items }) => items.filter((i) => i.top));

export const TopFaq = styled(function TopFaq({
  className,
}: {
  className?: string;
}) {
  return (
    <Accordion className={className}>
      <section>
        {items.map((item) => (
          <Fragment key={item.question}>
            <AccordionRow
              title={item.question}
              openEvent={{
                ...EVENTS.LANDING_PAGE.OPEN_QUESTION,
                label: item.question,
              }}
            >
              <p>{item.answer}</p>
            </AccordionRow>
          </Fragment>
        ))}
      </section>
    </Accordion>
  );
})`
  margin-bottom: ${({ theme }) => theme.spacing.space32};
`;
