import styled from "styled-components";
import { useBecomeMemberPath } from "../../membership/MembershipPaymentParameters";
import { type CustomerViewModel } from "../../shared/models/CustomerViewModel";
import { typeCardNonMemberPremiumPrice } from "./type-card-price/TypeCardPriceArray";
import { useHasMembershipPath } from "./useHasMembershipPath";
import TypeCard, {
  TypeCardBenefitList,
  TypeCardButton,
  TypeCardHeader,
  TypeCardImage,
  TypeCardProps,
  TypeCardTitle,
} from "./TypeCard";
import {
  Button,
  ExternalButtonLink,
  LocationDescriptor,
} from "@naf/teamscheme";
import {
  TypeCardPrice,
  TypeCardPriceElement,
} from "./type-card-price/TypeCardPrice";
import NafLogo from "../../shared/logos/graphics/naf-logo_56x56.svg";
import { MEMBERSHIP_BENEFITS } from "../../membership/MembershipBenefits";
import { PREMIUM_BENEFITS } from "./PremiumCard";
import { getContractTypeKey } from "../create-contract/ContractTypeName";
import { ContractType } from "#vehicle-contract/common/lib/model/contract/SalesContract";

export const MemberPackageBenefits = () => (
  <>
    <p>
      Som NAF-medlem får du automatisk tilgang til Premium-kontrakt og andre
      tjenester som er nyttige ved bruktbilhandel. Våre juridiske og biltekniske
      rådgivere har spesialisert seg på bilrelaterte saker og kan hjelpe deg med
      å løse eventuelle problemer i etterkant av handelen.
    </p>
    <p>
      Du får også tilgang til en rekke rabatterte tjenester hos NAF-senterne og
      fra våre samarbeidspartnere. Få bilen grundig sjekket av våre eksperter,
      eller bruk medlemsrabatten på alt fra forsikring, til dekk og vedlikehold.
    </p>
    <p>
      Med et NAF-medlemskap i lomma, kan du handle bruktbil med trygghet. Du vet
      at du har en partner som hjelper deg med å unngå fellene og står ved din
      side hvis noe skulle gå galt.
    </p>
    <p>
      <ExternalButtonLink variant="secondary" href="https://naf.no/medlemskap">
        Les mer om NAF-medlemskapet
      </ExternalButtonLink>
    </p>
  </>
);

export interface MemberCardProps extends Omit<TypeCardProps, "label"> {
  linkTo?: LocationDescriptor<unknown>;
  priceArray?: TypeCardPriceElement[];
}

export const MemberCard = styled(function ({
  linkTo,
  priceArray,
  ...props
}: MemberCardProps) {
  return (
    <TypeCard {...props}>
      <TypeCardHeader>
        <TypeCardImage src={NafLogo} alt="NAF" width="56" height="56" />
        <TypeCardTitle>NAF-medlem</TypeCardTitle>
      </TypeCardHeader>
      <TypeCardBenefitList
        benefits={[
          ...PREMIUM_BENEFITS,
          ...MEMBERSHIP_BENEFITS.filter((x) => x.contractRelated).map(
            (x) => x.label,
          ),
        ]}
      />
      {priceArray ? <TypeCardPrice elements={priceArray} /> : null}
      {linkTo ? (
        <TypeCardButton
          action={{ linkTo }}
          typeKey={getContractTypeKey(ContractType.Member)}
          variant="primary"
        >
          Bli medlem
        </TypeCardButton>
      ) : null}
    </TypeCard>
  );
})``;

export const MemberUpgradeCard = styled(function ({
  customer,
  returnPath,
  className,
}: {
  customer: CustomerViewModel | null;
  returnPath?: string;
  className?: string;
}) {
  const becomeMemberLocation = useBecomeMemberPath(returnPath);
  const hasMembershipPath = useHasMembershipPath(returnPath);
  const baseProps: MemberCardProps = {
    className,
    linkTo: customer?.hasMembership ? hasMembershipPath : becomeMemberLocation,
    priceArray: [typeCardNonMemberPremiumPrice],
  };

  return <MemberCard {...baseProps} />;
})``;
