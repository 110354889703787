import { HTMLAttributes } from "react";
import styled from "styled-components";

export const FaqSectionWrapper = styled(function ({
  title,
  children,
  ...props
}: {
  title: string;
} & HTMLAttributes<HTMLDivElement>) {
  return (
    <section {...props}>
      <h3>{title}</h3>
      {children}
    </section>
  );
})`
  margin-bottom: ${({ theme }) => theme.spacing.space48};
`;
