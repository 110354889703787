import { ITheme } from "@naf/teamscheme-core";
import { HTMLAttributes } from "react";
import styled, { css } from "styled-components";

export interface InputComponentProps extends HTMLAttributes<HTMLDivElement> {
  hasError?: boolean;
}

export const InputComponent = styled(function ({
  hasError: _,
  ...props
}: InputComponentProps) {
  return <div {...props} />;
})`
  textarea,
  input[type="text"],
  input[type="search"],
  input[type="email"],
  input[type="url"],
  input[type="tel"],
  input[type="date"],
  input[type="time"],
  input[type="number"],
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    box-shadow: none;
    width: 100%;
    box-sizing: border-box;
    padding: ${({ theme }) => theme.spacing.space8};
    border: 1px solid ${({ theme }) => theme.themeLight.border.heavy};
    transition: box-shadow 0.1s ease;
    font-size: 1em;
    line-height: normal;
    border-radius: ${({ theme }) => theme.radius.s};
    min-height: ${({ theme }) => theme.spacing.space48};
    ${({ theme }) => theme.fontStyle.article.articleText};
  }

  textarea:focus-visible,
  input[type="text"]:focus-visible,
  input[type="email"]:focus-visible,
  input[type="search"]:focus-visible,
  input[type="url"]:focus-visible,
  input[type="tel"]:focus-visible,
  input[type="date"]:focus-visible,
  input[type="number"]:focus-visible,
  input[type="checkbox"]:focus-visible,
  select:focus-visible {
    outline: 0;
    border: 2px solid ${({ theme }) => theme.themeLight.typography.defaultText};
    padding: calc(${({ theme }) => theme.spacing.space8} - 1px);
  }

  textarea:disabled,
  input[type="text"]:disabled,
  input[type="email"]:disabled,
  input[type="search"]:disabled,
  input[type="url"]:disabled,
  input[type="tel"]:disabled,
  input[type="date"]:disabled,
  input[type="number"]:disabled,
  input[type="checkbox"]:disabled,
  select:disabled {
    cursor: not-allowed;
  }

  ${({ hasError, theme }: { hasError?: boolean; theme: ITheme }) =>
    hasError &&
    css`
      textarea,
      input[type="text"],
      input[type="email"],
      input[type="search"],
      input[type="url"],
      input[type="tel"],
      input[type="date"],
      input[type="number"],
      select {
        border: 2px solid ${theme.themeLight.componentColors.alert.error};
        padding: calc(${theme.spacing.space8} - 1px);
      }
    `}

  select:focus::-moz-focus-inner,
  input[type="checkbox"]:focus::-moz-focus-inner {
    border: none;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 6"><g><polygon points="14,0 26,0 20,6" fill="black" /></g></svg>');
    background-repeat: no-repeat;
    background-position: center right;
    background-size: auto 15%;
  }

  select::-ms-expand {
    display: none;
  }
`;
