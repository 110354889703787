import { addBreadcrumb } from "@sentry/browser";
import { auth0Client, initAuthResult } from "../auth/AuthProvider";
import { weakGuid } from "../shared/utils/weakGuid";

const API_URL = "/api";

export const SESSION_ID_HEADER = "X-Session-Id";

export type HttpVerb = "GET" | "POST" | "PUT" | "DELETE" | "PATCH";

export class ApiError extends Error {
  constructor(
    message: string,
    public result: unknown,
    public status: number,
  ) {
    super(message);
  }
}

export const sessionId = weakGuid();

export type ApiClientFunction = (
  method: HttpVerb,
  uri: string,
  content?: unknown,
  headers?: { [key: string]: string },
) => Promise<unknown>;

export interface IRestClient {
  get<T>(uri: string): Promise<T>;
  post<T>(uri: string, content?: unknown): Promise<T>;
  put<T>(uri: string, content?: unknown): Promise<T>;
  patch<T>(uri: string, content?: unknown): Promise<T>;
  delete<T>(uri: string): Promise<T>;
}

export function createRestClient(send: ApiClientFunction): IRestClient {
  return {
    get<T>(uri: string) {
      return send("GET", uri) as Promise<T>;
    },
    post<T>(uri: string, content?: unknown) {
      return send("POST", uri, content) as Promise<T>;
    },
    put<T>(uri: string, content?: unknown) {
      return send("PUT", uri, content) as Promise<T>;
    },
    patch<T>(uri: string, content?: unknown) {
      return send("PATCH", uri, content) as Promise<T>;
    },
    delete<T>(uri: string) {
      return send("DELETE", uri) as Promise<T>;
    },
  };
}

export const sendJson: ApiClientFunction = async (
  method,
  uri,
  content,
  headers,
) => {
  const initBase =
    content !== undefined
      ? {
          headers: {
            "content-type": "application/json",
            ...headers,
          },
          body: JSON.stringify(content),
        }
      : { headers: headers };

  const init: RequestInit = {
    ...initBase,
    method,
  };

  addBreadcrumb({
    category: "FetchData",
    data: {
      uri,
      init,
    },
  });

  const response = await fetch(uri, init);

  if (response.status === 404 && method === "GET") return null;

  const contentType = response.headers.get("content-type");

  const result =
    contentType && contentType.indexOf("application/json") !== -1
      ? await response.json()
      : await response.text();

  const statusString = response.status.toString();

  if (!statusString.startsWith("2")) {
    const error = new ApiError(
      `Status code is not OK: ${response.statusText}. ${result.error || ""}`,
      result,
      response.status,
    );

    throw error;
  }

  return result;
};

export type Middleware = (base: ApiClientFunction) => ApiClientFunction;

export function withBaseUri(baseUrl: string): Middleware {
  return (base) => (method, uri, options, headers) =>
    base(method, `${baseUrl}/${uri}`, options, headers);
}

export const withAuth: Middleware =
  (base) => async (method, uri, options, headers) => {
    let jwt: string | null = null;

    await initAuthResult;

    try {
      jwt = await auth0Client.getTokenSilently();
    } catch (error: unknown) {
      if ((error as { error?: string }).error !== "login_required") {
        throw error;
      }
    }

    const authHeaders = jwt ? { Authorization: `Bearer ${jwt}` } : null;

    return base(method, uri, options, { ...headers, ...authHeaders });
  };

export function withHeader(key: string, value: string): Middleware {
  return (base) => (method, uri, options, headers) =>
    base(method, uri, options, { ...headers, [key]: value });
}

export const withSessionId = withHeader(SESSION_ID_HEADER, sessionId);

export function composeClient(
  send: ApiClientFunction,
  ...middlewares: Middleware[]
) {
  return middlewares.reduce((acc, m) => m(acc), send);
}

export const apiClientFunction = composeClient(
  sendJson,
  withBaseUri(API_URL),
  withAuth,
  withSessionId,
);

export const api = createRestClient(apiClientFunction);
