import {
  ButtonLinkAnchorElementProps,
  ExternalButtonLink as TeamSchemeExternalButtonLink,
} from "@naf/teamscheme";
import { OpenInNew } from "@styled-icons/material/OpenInNew";
import styled from "styled-components";
import { EVENTS } from "../Tracking";
import { normalizeComponentTree } from "./utils/normalizeComponentTree";

export interface ExternalButtonLinkProps extends ButtonLinkAnchorElementProps {}

const ExternalButtonLink = styled(
  ({ href, children, trackEvent, ...props }: ExternalButtonLinkProps) => {
    return (
      <TeamSchemeExternalButtonLink
        {...props}
        href={href}
        trackEvent={
          trackEvent || {
            ...EVENTS.COMMON.OPEN_EXTERNAL_LINK,
            label: normalizeComponentTree(children),
            value: href,
          }
        }
      >
        {children}
      </TeamSchemeExternalButtonLink>
    );
  },
)``;

export default ExternalButtonLink;

export const ExternalButtonLinkWithIcon = styled(
  function ExternalButtonLinkWithIcon({
    className,
    ...props
  }: ExternalButtonLinkProps) {
    return (
      <div className={className}>
        <ExternalButtonLink {...props} />
        <OpenInNew width={24} />
      </div>
    );
  },
)`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.space8};
`;
