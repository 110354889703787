import { useLocation, useParams } from "react-router-dom";
import {
  ContractTypeKey,
  getContractType,
} from "../../create-contract/ContractTypeName";
import { RETURN_PATH_PARAM } from "../../../shared/utils/Location";

export const CONTRACT_TYPE_RATH_PARAM = "contractType";
export const BACK_PATH_PARAM = "backTo";

export function useReturnPath(): string | null {
  const location = useLocation();
  const path = new URLSearchParams(location.search).get(RETURN_PATH_PARAM);
  return path;
}
export function useBackPath(): string | null {
  const location = useLocation();
  const path = new URLSearchParams(location.search).get(BACK_PATH_PARAM);
  return path;
}

export function useContractType() {
  const { [CONTRACT_TYPE_RATH_PARAM]: contractType } = useParams();

  if (!contractType) throw new Error("Missing param 'contractType'!");

  const result = getContractType(contractType as ContractTypeKey);

  if (!result) throw new Error(`Unrecognized contract type ${contractType}`);

  return result;
}
