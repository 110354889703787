import StaticHeader from "../../layout/header/StaticHeader";
import ErrorPage, { ErrorPageProps } from "./ErrorPage";

export default function StaticErrorScreen({
  message = "Vi klarte dessverre ikke hente kontrakten.",
  ...props
}: ErrorPageProps) {
  return (
    <>
      <StaticHeader />
      <ErrorPage message={message} {...props} />
    </>
  );
}
