import { InfoAlert } from "@naf/teamscheme";
import { NokPrice } from "@naf/teamscheme";

export const GeneralVippsNokLimit = () => <NokPrice value={500000} />;
export const NoOwnershipTransferVippsNokLimit = () => (
  <NokPrice value={95000} />
);
export const DnbVippsNokLimit = () => <NokPrice value={90000} />;

export const BankTransferLimitInfoAlert = () => (
  <InfoAlert icon="info">
    <strong>Viktig å sjekke før betaling med Vipps</strong>
    <br />
    Beløpsgrensene for straksbetaling varierer mellom bankene. Skal kjøretøyet
    betales med Vipps må kjøper først sjekke beløpsgrensen hos sin bank.
  </InfoAlert>
);
