import { onDesktop } from "@naf/teamscheme-core";
import { HTMLAttributes, ReactNode } from "react";
import styled, { css } from "styled-components";
import ReturnToLandingPage, {
  ContractBreadcrumb,
} from "../contract/contract-landing/ReturnToLandingPage";
import { EditableStatusMessage } from "../contract/status/ContractStatus";

export interface CardSectionProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "children" | "title"> {
  title?: string;
  ingress?: ReactNode;
  ingressClassName?: string;
  children?: ReactNode;
}

export const CardSectionTitle = styled.h1``;

export const CardSectionHeader = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.space48};

  div {
    text-align: left;
  }
`;

const CardSection = styled(function ({
  title,
  ingress,
  ingressClassName,
  children,
  ...props
}: CardSectionProps) {
  return (
    <CardSectionWrapper {...props}>
      {title || ingress ? (
        <CardSectionHeader>
          <CardTitle>{title}</CardTitle>
          {ingress && <div className={ingressClassName}>{ingress}</div>}
        </CardSectionHeader>
      ) : null}
      {children}
    </CardSectionWrapper>
  );
})``;

export const CardSectionWrapper = styled.section`
  ${CardSectionHeader} ${CardSectionTitle}:last-child {
    margin-bottom: 0;
  }

  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.space24};

    ${onDesktop`
      margin-bottom: ${theme.spacing.space32};
    `}
  `};
`;

export const CardTitle = styled(CardSectionTitle)`
  ${({ theme }) => theme.mobileFontStyle.headers.header1};
  ${({ theme }) => onDesktop`
    ${theme.fontStyle.headers.header1}
  `}
`;

export const TitleSection = styled(CardSection)``;

export const ContractTitleSection = styled(function (props: CardSectionProps) {
  return (
    <>
      <ReturnToLandingPage />
      <TitleSection {...props} />
    </>
  );
})``;

export const ContractFormTitleSection = styled(function (
  props: CardSectionProps,
) {
  return (
    <>
      <ContractBreadcrumb title={props.title} />
      <EditableStatusMessage />
      <TitleSection {...props} />
    </>
  );
})``;

export default CardSection;
