import { FlyoutModal } from "@naf/teamscheme";
import {
  Accordion,
  AccordionRow,
  normalizedAccordionId,
} from "@naf/teamscheme";
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useState,
} from "react";
import { EVENTS } from "../../Tracking";
import FormGroup from "../../forms/FormGroup";
import { useDebouncedQuery } from "../../shared/Filter";
import { FilterField } from "../../shared/FilterField";
import NoResultsPlaceholder from "../../shared/NoResultsPlaceholder";
import { normalizeComponentTree } from "../../shared/utils/normalizeComponentTree";
import { FaqSectionWrapper } from "./FaqSectionWrapper";
import { useFilteredFaq } from "./FilteredFaq";

export interface IFaqFlyoutContext {
  open(query?: string): void;
}

export function useFaqFlyout() {
  const context = useContext(FaqFlyoutContext);

  if (!context) throw new Error("No FlowFaqContext found!");

  return context;
}

export const FaqFlyoutContext = createContext<IFaqFlyoutContext | null>(null);

export function FaqFlyoutProvider({ children }: { children: ReactNode }) {
  const [isOpen, setIsOpen] = useState(false);
  const [queryString, setQueryString] = useState<string>("");

  const { query, clear, inputProps } = useDebouncedQuery({
    queryEvent: EVENTS.FAQ_MODAL.FILTER,
    queryString,
  });
  const { matchedSections } = useFilteredFaq({ query });

  const open = useCallback(function (query?: string) {
    setQueryString(query || "");
    setIsOpen(true);
  }, []);

  return (
    <FaqFlyoutContext.Provider value={{ open }}>
      {children}
      <FlyoutModal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <h1>Vanlige spørsmål</h1>
        <FormGroup>
          <FilterField label="Hva lurer du på?" inputProps={inputProps} />
        </FormGroup>
        <Accordion>
          {matchedSections.map((section) => {
            const id = normalizedAccordionId(
              normalizeComponentTree(section.title),
            );
            return (
              <FaqSectionWrapper key={`section-${id}`} title={section.title}>
                {section.items.map((item) => {
                  const id = normalizedAccordionId(
                    normalizeComponentTree(item.question),
                  );
                  return (
                    <AccordionRow
                      key={id}
                      id={id}
                      title={item.question}
                      open={!!query}
                    >
                      {item.answer}
                    </AccordionRow>
                  );
                })}
              </FaqSectionWrapper>
            );
          })}
        </Accordion>
        {query && !matchedSections.length ? (
          <NoResultsPlaceholder query={query} clear={clear} />
        ) : null}
      </FlyoutModal>
    </FaqFlyoutContext.Provider>
  );
}
