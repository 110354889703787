import { getContractTypeKey } from "../../create-contract/ContractTypeName";
import { ContractUrls } from "../../routing/ContractUrls";
import {
  RETURN_PATH_PARAM,
  addQueryParam,
  addRouteParams,
  getLocationString,
} from "../../../shared/utils/Location";
import { AppPages } from "../../../routing/AppPages";
import { LocationDescriptor } from "@naf/teamscheme";
import { BACK_PATH_PARAM, CONTRACT_TYPE_RATH_PARAM } from "./locationParams";
import { ContractType } from "#vehicle-contract/common/lib/model/contract/SalesContract";

export function getContractUpgradeCreatePaymentLocation(
  urls: ContractUrls,
  contractType: ContractType,
  returnTo: LocationDescriptor,
  backTo?: LocationDescriptor,
) {
  const params = new URLSearchParams({
    [RETURN_PATH_PARAM]: getLocationString(returnTo),
  });
  if (backTo) params.set(BACK_PATH_PARAM, getLocationString(backTo));
  return addQueryParam(
    addRouteParams(urls[AppPages.ContractUpgradeProductRoot], {
      [CONTRACT_TYPE_RATH_PARAM]: getContractTypeKey(contractType),
    }),
    params,
  );
}
