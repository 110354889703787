import {
  type CtaClickProps,
  type ITrackingContext,
  type TrackEventProps,
  TrackingContext as TeamschemeTrackingContext,
  type TrackingValue,
} from "@naf/teamscheme-core";
import { type ReactNode, createContext, useContext, useEffect } from "react";
import { type Role } from "#vehicle-contract/common/lib/model/contract/Role";
import { roleName } from "./admin/shared/roleName";
import { type ContractViewModel } from "./contract/model/ContractViewModel";
import { normalizeComponentTree } from "./shared/utils/normalizeComponentTree";

interface TrackEventEventParams {
  contract_context: string;
  contract_action: string;
  contract_detail?: string;
  contract_value?: TrackingValue;
  contract_additional_props?: {
    [key: string]: TrackingValue;
  };
}

export function useTrackContext(
  attribute: string,
  value: TrackingValue | null | undefined,
) {
  const tracking = useTracking();
  useEffect(() => {
    if (value !== null && value !== undefined) {
      tracking.context({ [attribute]: value });
    }
  }, [attribute, tracking, value]);
}

export interface IAppTrackingContext {
  ctaClick(props: CtaClickProps): void;
  event(props: TrackEventProps): void;
  pageView({ title, path }: { title: string; path: string }): void;
  contractLoad(contract: ContractViewModel, role: Role): void;
  experiment(experimentName: string): void;
  appLoad(): void;
  context(contextObject: { [attribute: string]: TrackingValue }): void;
}

const AppTrackingContext = createContext<IAppTrackingContext | null>(null);

export function SsrTrackingProvider({ children }: { children: ReactNode }) {
  return (
    <TeamschemeTrackingContext.Provider
      value={{
        trackCtaClick() {},
        trackEvent() {},
      }}
    >
      <AppTrackingContext.Provider
        value={{
          appLoad() {},
          context() {},
          contractLoad() {},
          ctaClick() {},
          event() {},
          experiment() {},
          pageView() {},
        }}
      >
        {children}
      </AppTrackingContext.Provider>
    </TeamschemeTrackingContext.Provider>
  );
}

export function AppTrackingProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  function trackCtaClick({ navigate, label }: CtaClickProps) {
    const location = window.location;

    let destination: string | undefined = undefined;
    if (typeof navigate === "string") {
      destination = navigate;
    } else if (navigate) {
      destination = navigate.pathname;
    }

    let hostname: string | undefined = undefined;
    let absoluteDestinationPath: string | undefined = undefined;
    if (destination) {
      if (destination.startsWith("http")) {
        hostname = new URL(destination).hostname;
        absoluteDestinationPath = destination;
      } else if (!destination.startsWith("/")) {
        /*
          If destination does not start with the http-protocol we must assume we
          are on our own domain, so hostname of target must be origin of where we are.
        */
        hostname = location.origin;
        absoluteDestinationPath = `${hostname}/${destination}`;
      } else {
        hostname = location.origin;
        absoluteDestinationPath = hostname + destination;
      }
    }
    const eventName = "cta_click";
    const eventParams = {
      cta_location: location.pathname,
      cta_text: normalizeComponentTree(label),
      cta_hostname: location.origin,
      cta_url: absoluteDestinationPath,
    };

    window.dataLayer.push({
      event: eventName,
      event_params: eventParams,
    });

    console.log(eventName, {
      event_params: eventParams,
    });
  }

  function trackEvent({
    category: contract_context,
    action: contract_action,
    label: contract_detail,
    value: contract_value,
    additionalProps: contract_additional_props,
  }: TrackEventProps) {
    const eventName = "vehicle_contract";
    const eventParams: TrackEventEventParams = {
      contract_context,
      contract_action,
      contract_detail,
      contract_value,
      contract_additional_props,
    };

    window.dataLayer.push({
      event: eventName,
      event_params: eventParams,
    });

    console.log(eventName, {
      event_params: eventParams,
    });
  }

  function addTrackingContext(contextObject: {
    [attribute: string]: TrackingValue;
  }) {
    for (const [attribute, value] of Object.entries(contextObject)) {
      window.dataLayer.push({ [attribute]: value });
    }
  }

  function trackContractLoad(contract: ContractViewModel, role: Role) {
    const params = {
      id: contract.id,
      role: roleName(role),
    };

    window.dataLayer.push({
      event: "contract",
      ...params,
    });

    console.log("contract", params);
  }

  function trackExperiment(experimentName: string) {
    window.dataLayer.push({
      event: "experiment",
      experimentName,
    });

    console.log("experiment", experimentName);
  }

  function trackAppLoad() {
    window.dataLayer.push({
      event: "load",
      appVersion: import.meta.env.PUBLIC_ENV__REACT_APP_VERSION,
    });
  }

  function trackPageView({ title, path }: { title: string; path: string }) {
    window.dataLayer.push({
      event: "virtual_page_view",
      virtualPageURL: path,
      virtualPageTitle: title,
    });

    console.log("pageview", path, title);
  }

  const trackingContext: ITrackingContext = { trackEvent, trackCtaClick };
  const appTrackingContext: IAppTrackingContext = {
    ctaClick: trackCtaClick,
    event: trackEvent,
    pageView: trackPageView,
    appLoad: trackAppLoad,
    contractLoad: trackContractLoad,
    experiment: trackExperiment,
    context: addTrackingContext,
  };

  return (
    <TeamschemeTrackingContext.Provider value={trackingContext}>
      <AppTrackingContext.Provider value={appTrackingContext}>
        {children}
      </AppTrackingContext.Provider>
    </TeamschemeTrackingContext.Provider>
  );
}

export function useTracking() {
  const context = useContext(AppTrackingContext);
  if (!context) {
    throw new Error("No AppTrackingContext found!");
  }
  return context;
}

export function useAbTracking(abTestName: string, variant: string) {
  const tracking = useTracking();

  useEffect(() => {
    tracking.experiment(`AB-Test/${abTestName}/${variant}`);
  }, [tracking, abTestName, variant]);
}

export function externalLinkEvent(href: string) {
  return {
    ...EVENTS.COMMON.OPEN_EXTERNAL_LINK,
    label: href,
  };
}

const CATEGORIES = {
  ABOUT_PAGE_BUTTONS: "About Page Buttons",
  ABOUT_PAYMENT_PAGE: "About Payment Page",
  ADD_FROM_FINN: "Add from Finn",
  ATTACHMENTS_PAGE: "Attachments Page",
  BECOME_MEMBER_PAGE: "Become Member Page",
  BREADCRUMBS: "Breadcrumbs",
  CAR_INSURANCE: "Car Insurance",
  CAR_LOAN_CONFIRMATION_PAGE: "Confirmation/Car Loan",
  CAR_LOAN: "Car Loan",
  COMMON: "Common",
  CONFIRMATION_PAGE_TRIAL_MEMBERSHIP: "Confirmation/Trial Membership",
  CONFIRMATION_PAGE: "Confirmation",
  CONTRACT_EXPLANATION_MODAL: "Contract Explanation Modal",
  CONTRACT_TYPES: "Contract Types",
  CONTRACT_UPDATES_MODAL: "Contract Updates Modal",
  CORONA_BANNER: "Corona Banner",
  FAQ_MODAL: "FAQ Modal",
  FAQ_PAGE: "FAQ Page",
  FOOTER: "Footer",
  GLOBAL_ALERT: "Global Alert",
  HEADER: "Header",
  INVITATION: "Invitation",
  LANDING_PAGE: "Landing Page",
  PAYMENT: "Payment",
  PREVIEW: "Preview",
  SAVE_CONTRACTS_MODAL: "Save Contracts Modal",
  SETTLEMENT_PAGE: "Settlement Page",
  SIGNING: "Signing",
  STATIC_CONTRACTS: "Static Contracts",
  SUMMARY_PAGE: "Summary Page",
  TRIAL_MEMBERSHIP_ALERT: "Trial Membership Alert",
  TRIAL_MEMBERSHIP: "Trial Membership",
};

export const EVENTS = {
  ABOUT_PAGE_BUTTONS: {
    OPEN_ABOUT_PAGE: {
      category: CATEGORIES.ABOUT_PAGE_BUTTONS,
      action: "Open About Page",
    },
  },
  ABOUT_PAYMENT_PAGE: {
    OPEN_LIENS_LINK: {
      category: CATEGORIES.ABOUT_PAYMENT_PAGE,
      action: "Open Liens Link",
    },
  },
  ADD_FROM_FINN: {
    FETCH_DATA: {
      category: CATEGORIES.ADD_FROM_FINN,
      action: "Fetch data",
    },
    RETRY_FETCH_DATA: {
      category: CATEGORIES.ADD_FROM_FINN,
      action: "Retry Fetch data",
    },
  },
  ATTACHMENTS: {
    REMOVE: {
      category: CATEGORIES.ATTACHMENTS_PAGE,
      action: "Remove",
    },
    DROP: {
      category: CATEGORIES.ATTACHMENTS_PAGE,
      action: "Drag-and-Drop to Upload",
    },
    UPLOAD: {
      category: CATEGORIES.ATTACHMENTS_PAGE,
      action: "Click to Upload",
    },
    DOWNLOAD_ZIP: {
      category: CATEGORIES.ATTACHMENTS_PAGE,
      action: "Download Zip",
    },
  },
  BECOME_MEMBER_PAGE: {
    GO_TO_PAYMENT: {
      category: CATEGORIES.BECOME_MEMBER_PAGE,
      action: "Go to Payment",
    },
    GO_TO_FORM: {
      category: CATEGORIES.BECOME_MEMBER_PAGE,
      action: "Go to Become Member Form",
    },
    LOG_IN: {
      category: CATEGORIES.BECOME_MEMBER_PAGE,
      action: "Log In",
    },
    CREATE_USER: {
      category: CATEGORIES.BECOME_MEMBER_PAGE,
      action: "Create User",
    },
  },
  BREADCRUMBS: {
    BACK_ONEUP: {
      category: CATEGORIES.BREADCRUMBS,
      action: "Back Oneup",
    },
    BACK_SUBPAGE: {
      category: CATEGORIES.BREADCRUMBS,
      action: "Back Subpage",
    },
  },
  CAR_INSURANCE: {
    CONFIRM: {
      category: CATEGORIES.CAR_INSURANCE,
      action: "Confirm",
    },
    NEXT: {
      category: CATEGORIES.CAR_INSURANCE,
      action: "Next",
    },
    REMOVE: {
      category: CATEGORIES.CAR_INSURANCE,
      action: "Remove",
    },
    DECLINE: {
      category: CATEGORIES.CAR_INSURANCE,
      action: "Decline",
    },
    CALCULATE: {
      category: CATEGORIES.CAR_INSURANCE,
      action: "Calculate",
    },
    CHECK_PRICE: {
      category: CATEGORIES.CAR_INSURANCE,
      action: "Check Price",
    },
  },
  CAR_LOAN_CONFIRMATION_PAGE: {
    APPLY: {
      category: CATEGORIES.CAR_LOAN_CONFIRMATION_PAGE,
      action: "Apply",
    },
    CALCULATE: {
      category: CATEGORIES.CAR_LOAN_CONFIRMATION_PAGE,
      action: "Calculate",
    },
  },
  CAR_LOAN: {
    SHOW_CALCULATOR: {
      category: CATEGORIES.CAR_LOAN,
      action: "Show calculator",
    },
    HIDE_CALCULATOR: {
      category: CATEGORIES.CAR_LOAN,
      action: "Hide calculator",
    },
    APPLY: {
      category: CATEGORIES.CAR_LOAN,
      action: "Apply",
    },
    CALCULATE: {
      category: CATEGORIES.CAR_LOAN,
      action: "Calculate",
    },
  },
  COMMON: {
    OPEN_EXTERNAL_LINK: {
      category: CATEGORIES.COMMON,
      action: "Open External Link",
    },
    LOG_OUT: {
      category: CATEGORIES.COMMON,
      action: "Log Out",
    },
    LOG_IN: {
      category: CATEGORIES.COMMON,
      action: "Log In",
    },
    CREATE_USER: {
      category: CATEGORIES.COMMON,
      action: "Create User",
    },
    DISMISS_ALERT: {
      category: CATEGORIES.COMMON,
      action: "Dismiss Alert",
    },
  },
  CONFIRMATION_PAGE_TRIAL_MEMBERSHIP: {
    ACTIVATE_TRIAL_MEMBERSHIP: {
      category: CATEGORIES.CONFIRMATION_PAGE_TRIAL_MEMBERSHIP,
      action: "Activate",
    },
  },
  CONFIRMATION_PAGE: {
    DOWNLOAD_PDF: {
      category: CATEGORIES.CONFIRMATION_PAGE,
      action: "Download PDF",
    },
    OPEN_EXTERNAL_LINK: {
      category: CATEGORIES.CONFIRMATION_PAGE,
      action: "Open External Link",
    },
    ADD_CUSTOMER_REFERENCE: {
      category: CATEGORIES.CONFIRMATION_PAGE,
      action: "Add Customer Reference",
    },
    ADD_CUSTOMER_REFERENCE_ERROR: {
      category: CATEGORIES.CONFIRMATION_PAGE,
      action: "Add Customer Reference Error",
    },
  },
  CONTRACT_EXPLANATION_MODAL: {
    OPEN: {
      category: CATEGORIES.CONTRACT_EXPLANATION_MODAL,
      action: "Open Contract Explanation",
    },
    FILTER_FIELD_DESCRIPTIONS: {
      category: CATEGORIES.CONTRACT_EXPLANATION_MODAL,
      action: "Filter field descriptions",
    },
    OPEN_CATEGORY: {
      category: CATEGORIES.CONTRACT_EXPLANATION_MODAL,
    },
  },
  CONTRACT_TYPES: {
    SELECT_OPTION: {
      category: CATEGORIES.CONTRACT_TYPES,
      action: "Select Option",
    },
    CONTINUE: {
      category: CATEGORIES.CONTRACT_TYPES,
      action: "Continue",
    },
    READ_MORE: {
      category: CATEGORIES.CONTRACT_TYPES,
      action: "Read More",
    },
    OPEN_LINK_SERVICE: {
      category: CATEGORIES.CONTRACT_TYPES,
      action: "Open Link Service",
    },
    LOG_IN: {
      category: CATEGORIES.CONTRACT_TYPES,
      action: "Log In",
    },
    ACTIVATE_TRIAL_MEMBERSHIP: {
      category: CATEGORIES.CONTRACT_TYPES,
      action: "Activate Trial Membership",
    },
    UPDATE_CONSENTS: {
      category: CATEGORIES.CONTRACT_TYPES,
      action: "Update Consents",
    },
    BECOME_MEMBER: {
      category: CATEGORIES.CONTRACT_TYPES,
      action: "Become Member",
    },
    GET_PREMIUM_ACCESS: {
      category: CATEGORIES.CONTRACT_TYPES,
      action: "Get Premium Access",
    },
  },
  CONTRACT_UPDATES_MODAL: {
    OPEN: {
      category: CATEGORIES.CONTRACT_UPDATES_MODAL,
      action: "Open",
    },
    OK: {
      category: CATEGORIES.CONTRACT_UPDATES_MODAL,
      action: "Ok",
    },
    DISMISS: {
      category: CATEGORIES.CONTRACT_UPDATES_MODAL,
      action: "Dismiss",
    },
  },
  CORONA_BANNER: {
    CLOSE: {
      category: CATEGORIES.CORONA_BANNER,
      action: "Close",
    },
  },
  FAQ_PAGE: {
    FILTER: {
      category: CATEGORIES.FAQ_PAGE,
      action: "Filter",
    },
    OPEN_QUESTION: {
      category: CATEGORIES.FAQ_PAGE,
      action: "Open Question",
    },
  },
  FAQ_MODAL: {
    FILTER: {
      category: CATEGORIES.FAQ_MODAL,
      action: "Filter",
    },
    OPEN_QUESTION: {
      category: CATEGORIES.FAQ_MODAL,
      action: "Open Question",
    },
  },
  FOOTER: {
    OPEN_OLD_CONTRACT: {
      category: CATEGORIES.FOOTER,
      action: "Open Old Contract",
    },
    EMAIL_CUSTOMER_SERVICE: {
      category: CATEGORIES.FOOTER,
      action: "Email Customer Service",
    },
  },
  GLOBAL_ALERT: {
    HIDE: {
      category: CATEGORIES.GLOBAL_ALERT,
      action: "Hide",
    },
  },
  HEADER: {
    BECOME_MEMBER: {
      category: CATEGORIES.HEADER,
      action: "Become Member",
    },
    LOG_IN: {
      category: CATEGORIES.HEADER,
      action: "Log In",
    },
    LOG_OUT: {
      category: CATEGORIES.HEADER,
      action: "Log Out",
    },
    PREVIEW: {
      category: CATEGORIES.HEADER,
      action: "Open Preview",
    },
    FAQ: {
      category: CATEGORIES.HEADER,
      action: "Open FAQ",
    },
    UPGRADE_CONTRACT: {
      category: CATEGORIES.HEADER,
      action: "Upgrade Contract",
    },
  },
  INVITATION: {
    SEND: {
      category: CATEGORIES.INVITATION,
      action: "Send",
    },
    CHANGE_RECIPIENT: {
      category: CATEGORIES.INVITATION,

      action: "Change Recipient",
    },
    INVITE_BY_EMAIL: {
      category: CATEGORIES.INVITATION,
      action: "Invite by Email",
    },
    INVITE_BY_SMS: {
      category: CATEGORIES.INVITATION,
      action: "Invite by SMS",
    },
  },
  LANDING_PAGE: {
    OPEN_OLD_CONTRACT: {
      category: CATEGORIES.LANDING_PAGE,
      action: "Open Old Contract",
    },
    CREATE_CONTRACT: {
      category: CATEGORIES.LANDING_PAGE,
      action: "Create Contract",
    },
    OPEN_SAVED_CONTRACT: {
      category: CATEGORIES.LANDING_PAGE,
      action: "Open Saved Contract",
    },
    START_NEW: {
      category: CATEGORIES.LANDING_PAGE,
      action: "Start New",
    },
    OPEN_PREVIEW: {
      category: CATEGORIES.LANDING_PAGE,
      action: "Open Preview",
    },
    OPEN_QUESTION: {
      category: CATEGORIES.LANDING_PAGE,
      action: "Open Question",
    },
  },
  PAYMENT: {
    INITIATE_VIPPS_PAYMENT: {
      category: CATEGORIES.PAYMENT,
      action: "Initiate Vipps Payment",
    },
    INITIATE_CHECKOUT: {
      category: CATEGORIES.PAYMENT,
      action: "Initiate Checkout",
    },
    CREATE_CARD_PAYMENT: {
      category: CATEGORIES.PAYMENT,
      action: "Create Card Payment",
    },
    CARD_PAYMENT_DECLINED: {
      category: CATEGORIES.PAYMENT,
      action: "Card Payment Declined",
    },
    CARD_PAYMENT_COMPLETED: {
      category: CATEGORIES.PAYMENT,
      action: "Card Payment Completed",
    },
  },
  PREVIEW: {
    DOWNLOAD_PDF: {
      category: CATEGORIES.PREVIEW,
      action: "Download PDF",
    },
    RESEND_SIGNATURE_INVITATION: {
      category: CATEGORIES.PREVIEW,
      action: "Open Resend Signature Invitation Modal",
    },
  },
  SAVE_CONTRACT_MODAL: {
    OPEN: {
      category: CATEGORIES.SAVE_CONTRACTS_MODAL,
      action: "Open Modal",
    },
    SEND_LINK: {
      category: CATEGORIES.SAVE_CONTRACTS_MODAL,
      action: "Send Link",
    },
    COPY_LINK: {
      category: CATEGORIES.SAVE_CONTRACTS_MODAL,
      action: "Copy Link",
    },
    REMOVE_SAVED: {
      category: CATEGORIES.SAVE_CONTRACTS_MODAL,
      action: "Remove Contract",
    },
    OPEN_SAVED_CONTRACT: {
      category: CATEGORIES.SAVE_CONTRACTS_MODAL,
      action: "Open Saved Contract",
    },
  },
  SETTLEMENT_PAGE: {
    HOW_TO_SAFE_SETTLEMENT: {
      category: CATEGORIES.SETTLEMENT_PAGE,
      action: "Open How To Safe Settlement",
    },
    CONFIRM_SETTLEMENT: {
      category: CATEGORIES.SETTLEMENT_PAGE,
      action: "Confirm Settlement",
    },
    CONFIRM_OWNERSHIP_TRANSFER: {
      category: CATEGORIES.SETTLEMENT_PAGE,
      action: "Confirm Ownership Transfer",
    },
    SEND_PAYMENT_REQUEST: {
      category: CATEGORIES.SETTLEMENT_PAGE,
      action: "Send Payment Request",
    },
    CANCEL_PAYMENT_REQUEST: {
      category: CATEGORIES.SETTLEMENT_PAGE,
      action: "Cancel Payment Request",
    },
    USE_VIPPS: {
      category: CATEGORIES.SETTLEMENT_PAGE,
      action: "Use Vipps",
    },
    USE_MANUAL_SETTLEMENT: {
      category: CATEGORIES.SETTLEMENT_PAGE,
      action: "Use Manual Settlement",
    },
  },
  SIGNING: {
    CANCEL: {
      category: CATEGORIES.SIGNING,
      action: "Cancel",
    },
    SELECT_PAYMENT_METHOD: {
      category: CATEGORIES.SIGNING,
      action: "Select Payment Method",
    },
    SELECT_SIGNING_METHOD: {
      category: CATEGORIES.SIGNING,
      action: "Select Signing Method",
    },
    GO_TO_BANK_ID_SIGNING: {
      category: CATEGORIES.SIGNING,
      action: "Go To BankID Signing",
    },
    SELECT_OTHER_SIGNING_METHOD: {
      category: CATEGORIES.SIGNING,
      action: "Select Other Signign Method",
    },
    CANCEL_BANK_ID: {
      category: CATEGORIES.SIGNING,
      action: "Cancel BankID",
    },
    COMPLETED: {
      category: CATEGORIES.SIGNING,
      action: "Completed",
    },
  },
  STATIC_CONTRACTS: {
    DOWNLOAD: {
      category: CATEGORIES.STATIC_CONTRACTS,
      action: "Download",
    },
  },
  SUMMARY_PAGE: {
    CANCEL_PAYMENT_REQUEST: {
      category: CATEGORIES.SUMMARY_PAGE,
      action: "Cancel Payment Request",
    },
  },
  TRIAL_MEMBERSHIP_ALERT: {
    REJECT: {
      category: CATEGORIES.TRIAL_MEMBERSHIP_ALERT,
      action: "Reject",
    },
    TO_ACTIVATION: {
      category: CATEGORIES.TRIAL_MEMBERSHIP_ALERT,
      action: "To Activation",
    },
  },
  TRIAL_MEMBERSHIP: {
    CHOOSE: {
      category: CATEGORIES.TRIAL_MEMBERSHIP,
      action: "Choose",
    },
    NEXT: {
      category: CATEGORIES.TRIAL_MEMBERSHIP,
      action: "Next",
    },
    ACTIVATE_NOW: {
      category: CATEGORIES.TRIAL_MEMBERSHIP,
      action: "Activate Now",
    },
    ELIGIBILITY_CHECKED: {
      category: CATEGORIES.TRIAL_MEMBERSHIP,
      action: "Eligibility Checked",
    },
  },
};
