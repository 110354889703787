import {
  Button,
  ErrorAlert,
  ExternalTextLink,
  InfoAlert,
  PageFooterCell,
  PageNavigationFooter,
} from "@naf/teamscheme";
import { useCustomer } from "../../auth/CustomerProvider";
import { TitleSection } from "../../formatting/CardSection";
import ContractPageCard from "../../formatting/ContractPageCard";
import { FlowPage } from "../../formatting/Page";
import Banner from "../../shared/elements/Banner";
import styled from "styled-components";
import { onDesktop } from "@naf/teamscheme-core";
import { HTMLAttributes } from "react";
import { TextList, TextListItem } from "@naf/text-list";
import { NAF_NO_MITT_NAF_URL } from "../../shared/NafnoUrls";
import { ArrowForward } from "../form/routing/FormFooterLink";
import { getRoleName } from "../ContractContext";
import { useDocumentTitle } from "../../shared/DocumentTitle";
import { CustomerViewModel } from "../../shared/models/CustomerViewModel";
import FlowLoadingPage from "../../shared/FlowLoadingPage";
import FlowErrorPage from "../../shared/FlowErrorPage";
import { CustomerReference } from "#vehicle-contract/common/lib/model/contract/CustomerReference";
import { Role } from "#vehicle-contract/common/lib/model/contract/Role";
import { LogInWithDifferentUserFlyout } from "./LogInWithDifferentUserFlyout";

const AuthenticatedBannerHeadline = styled.h2`
  margin-bottom: ${({ theme }) => theme.spacing.space8};
  ${({ theme }) => theme.fontStyle.article.articleTextHeader};
`;

const AuthenticatedUserBannerContentWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-row: auto;
  grid-column-gap: ${({ theme }) => theme.spacing.space20};
  grid-row-gap: ${({ theme }) => theme.spacing.space20};

  ${({ theme }) => onDesktop`
    grid-template-columns: 1fr 1fr;
    grid-row: auto;
    grid-column-gap: ${theme.spacing.space20};
    grid-row-gap: ${theme.spacing.space20};
  `};
`;

const AuthenticatedUserBannerInfoRow = styled(function ({
  label,
  children,
  ...props
}: {
  label: string;
} & Omit<HTMLAttributes<HTMLDivElement>, "label">) {
  return (
    <div {...props}>
      <span>{label}</span>
      <p>{children}</p>
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
  word-break: break-all;
  gap: ${({ theme }) => theme.spacing.space4};

  span {
    font-weight: bold;
  }
`;

export function AuthenticatedUserBanner({
  email,
  firstName,
  lastName,
  customerNumber,
  hasMembership,
}: {
  email?: string;
  firstName?: string;
  lastName?: string;
  customerNumber?: string;
  hasMembership: boolean;
}) {
  return (
    <>
      <AuthenticatedBannerHeadline>
        Du er logget inn med:
      </AuthenticatedBannerHeadline>
      <Banner variant="green">
        <AuthenticatedUserBannerContentWrapper>
          <AuthenticatedUserBannerInfoRow label="E-post">
            {email ? email : "-"}
          </AuthenticatedUserBannerInfoRow>
          <AuthenticatedUserBannerInfoRow label="Navn">
            {firstName || lastName
              ? `${firstName ?? "-"}${" "}${lastName ?? "-"}`
              : "-"}
          </AuthenticatedUserBannerInfoRow>
          <AuthenticatedUserBannerInfoRow label="Kundenummer">
            {customerNumber ? customerNumber : "-"}
          </AuthenticatedUserBannerInfoRow>
          <AuthenticatedUserBannerInfoRow label="Brukerstatus">
            {hasMembership ? "Medlem" : "Ikke-medlem"}
          </AuthenticatedUserBannerInfoRow>
        </AuthenticatedUserBannerContentWrapper>
      </Banner>
    </>
  );
}

const LoginReasonsHeader = styled.h4`
  margin-bottom: ${({ theme }) => theme.spacing.space4};
  ${({ theme }) => theme.fontStyle.article.articleTextLargeHeader}
`;

function ReasonsToConfirmUserList() {
  return (
    <TextList variant="bullet">
      <TextListItem>
        En kontrakt er et bindende dokument, det er derfor viktig å være sikker
        på at riktig bruker (tilhørende riktig person) blir knyttet til
        kontrakten. Både for selger og for kjøper.
      </TextListItem>
      <TextListItem>
        Vi lagrer kjøpekontrakten trygt på din profil i{" "}
        <ExternalTextLink href={NAF_NO_MITT_NAF_URL}>Mitt NAF</ExternalTextLink>{" "}
        slik at du enkelt kan finne den igjen når du trenger det.
      </TextListItem>
    </TextList>
  );
}

const ConfirmUserReasons = styled(function (
  props: HTMLAttributes<HTMLDivElement>,
) {
  return (
    <div {...props}>
      <LoginReasonsHeader>Hvorfor må jeg logge inn?</LoginReasonsHeader>
      <ReasonsToConfirmUserList />
    </div>
  );
})`
  margin-bottom: ${({ theme }) => theme.spacing.space40};
`;

const StyledErrorAlert = styled(ErrorAlert)`
  margin-bottom: ${({ theme }) => theme.spacing.space40};
  word-break: break-word;
`;
const StyledInfoAlert = styled(InfoAlert)`
  margin-bottom: ${({ theme }) => theme.spacing.space40};
  word-break: break-word;
`;

export function ConfirmAuthenticatedUserPage({
  role,
  customerReferences,
  licensePlateNumber,
  onClickConfirm,
}: {
  role: Role;
  customerReferences: CustomerReference[];
  licensePlateNumber?: string;
  onClickConfirm(): void;
}) {
  const roleString = getRoleName(role);
  const customer = useCustomer();

  const title = "Bekreft innlogget bruker";
  useDocumentTitle(title);

  if (customer.isLoading) {
    return <FlowLoadingPage text="Henter bruker" />;
  }
  if (customer.error) {
    return <FlowErrorPage>Kunne ikke hente bruker</FlowErrorPage>;
  }

  if (customer.result) {
    const { customerId, email } = customer.result;
    const overlappingCustomerReference = customerReferences?.find(
      (customerReference) => customerReference.customerGuid === customerId,
    );

    const infoAlertText = `Ved å bekrefte bruker låses den til rollen som
    ${roleString.regularCase} i kjøpekontrakten${
      licensePlateNumber
        ? ` for
      ${licensePlateNumber}`
        : ""
    }. Det vil ikke være mulig å endre dette i
    senere tid.`;

    return (
      <>
        <FlowPage>
          <ContractPageCard>
            <TitleSection title={title}>
              <AuthenticatedUserBanner
                email={customer.result.email}
                firstName={customer.result.firstName}
                lastName={customer.result.lastName}
                customerNumber={customer.result.customerNumber}
                hasMembership={customer.result.hasMembership}
              />
              {overlappingCustomerReference ? (
                <StyledErrorAlert>
                  {infoAlertText}
                  <br />
                  <br />
                  Merk: <b>{email}</b> brukes allerede av{" "}
                  <b>
                    {getRoleName(overlappingCustomerReference.role).regularCase}
                  </b>
                </StyledErrorAlert>
              ) : (
                <StyledInfoAlert icon="info">{infoAlertText}</StyledInfoAlert>
              )}
              {customer.result.email ? (
                <LogInWithDifferentUserFlyout email={customer.result.email} />
              ) : null}
              <ConfirmUserReasons />
            </TitleSection>
            <PageNavigationFooter>
              <PageFooterCell />
              <Button
                variant="secondary"
                onClick={async () => onClickConfirm()}
              >
                Bekreft
                <ArrowForward />
              </Button>
            </PageNavigationFooter>
          </ContractPageCard>
        </FlowPage>
      </>
    );
  }

  return null;
}
