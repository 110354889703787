import { TextLink } from "@naf/teamscheme";
import { ButtonLinkModalProps, FlyoutModal } from "@naf/teamscheme";
import { useState } from "react";

export interface TextLinkModalProps
  extends Omit<ButtonLinkModalProps, "variant"> {}

export default function TextLinkModal({
  title,
  children,
  onClick,
  onClose,
  ...props
}: TextLinkModalProps) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <TextLink
        onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          e.preventDefault();
          setIsOpen(true);
        }}
        {...props}
      >
        {title}
      </TextLink>
      <FlyoutModal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        {children}
      </FlyoutModal>
    </>
  );
}
