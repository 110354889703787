import styled from "styled-components";
import { LabeledInputComponent } from "../LabeledInputComponent";
import { InputRadioGroupProps, RadioGroup } from "../RadioGroup";
import { ReactNode } from "react";

const StyledLabeledInputComponent = styled(LabeledInputComponent)`
  label input {
    display: inline-block;
    margin-right: ${({ theme }) => theme.spacing.space16};
  }
`;

export interface LabeledRadioProps<T> extends InputRadioGroupProps<T> {
  className?: string;
  label: string;
  description?: ReactNode;
}

export default function RadioField<T>({
  className,
  name,
  label,
  description,
  value,
  options,
  onChange,
  inputProps,
  inputRef,
}: LabeledRadioProps<T>) {
  return (
    <StyledLabeledInputComponent
      id={name}
      className={className}
      label={label}
      description={description}
    >
      <RadioGroup<T>
        name={name}
        value={value}
        options={options}
        onChange={onChange}
        inputProps={inputProps}
        inputRef={inputRef}
      />
    </StyledLabeledInputComponent>
  );
}

export interface LabeledBooleanRadioProps
  extends Omit<LabeledRadioProps<boolean | null>, "options"> {
  emptyOption?: string;
}

export function BooleanRadioField({
  name,
  label,
  description,
  value,
  onChange,
  inputProps,
  emptyOption,
  inputRef,
}: LabeledBooleanRadioProps) {
  const options = [
    { value: true, key: "Yes", label: "Ja" },
    { value: false, key: "No", label: "Nei" },
    ...(emptyOption ? [{ value: null, key: "null", label: emptyOption }] : []),
  ];

  return (
    <RadioField
      name={name}
      label={label}
      description={description}
      value={value}
      onChange={onChange}
      inputProps={inputProps}
      options={options}
      inputRef={inputRef}
    />
  );
}
