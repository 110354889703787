import { Location } from "@remix-run/router";
import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

export function useLoadOncePerLocation() {
  const location = useLocation();
  const prev = useRef<Location | null>();
  const [loadedLocation, setLoadedLocation] = useState<Location | null>(null);
  const [loadRequested, setLoadRequested] = useState<Location | null>(null);

  useEffect(() => {
    setLoadedLocation(loadRequested);
  }, [loadRequested]);

  useEffect(() => {
    if (location) setLoadedLocation(null);
  }, [location]);

  const load = useCallback(() => {
    if (prev.current !== location) {
      setLoadRequested(location);
      prev.current = location;
    }
  }, [location]);

  return {
    load,
    loadedLocation,
  };
}
