import { NokPrice } from "@naf/teamscheme";
import { ButtonLinkModal } from "@naf/teamscheme";
import TextLinkModal from "../../../shared/elements/TextLinkModal";

export function TrialMembershipDetails() {
  return (
    <>
      <p>
        Med Premium kjøpekontrakt får du et prøvemedlemskap med veihjelp (gyldig
        i 30 dager fra aktivering).
      </p>
      <h3>Hva får jeg?</h3>
      <p>
        Som prøvemedlem får du tilgang til medlemsfordeler som veihjelp, rabatt
        på tjenester hos våre NAF sentre, juridisk og bilteknisk rådgivning,
        samt mange gode medlemstilbud.
      </p>
      <p>
        Prøvemedlemskapet inkluderer ikke veibok, medlemsmagasinet Motor, og
        gyldig stemme og/eller mulighet for å delta i organisasjonsarbeidet til
        NAF.
      </p>
      <h3>Må jeg forbli medlem etter prøveperioden?</h3>
      <p>
        Du velger selv om du ønsker å beholde NAF-medlemskapet etter
        prøveperioden (30 dager). I løpet av prøveperioden får du en faktura for{" "}
        Medlemskap med veihjelp (
        <NokPrice value={1440} /> for 12 måneder). Hvis du ikke ønsker å beholde
        NAF-medlemskapet etter prøveperioden, kan du bare se bort fra denne
        fakturaen.
      </p>
    </>
  );
}

export default function TrialMembershipInfoModalTextLink() {
  return (
    <TextLinkModal title="Mer om prøvemedlemskap">
      <h1>Om prøvemedlemskap</h1>
      <TrialMembershipDetails />
    </TextLinkModal>
  );
}
