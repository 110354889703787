import { useEffect } from "react";
import { themeLight } from "@naf/theme";

export type HeaderBackground = "white" | "green";
export function useThemeColorFromHeaderBackground(
  background: HeaderBackground = "white",
) {
  useEffect(() => {
    const tag = document.head.querySelector('meta[name="theme-color"]');
    if (!tag) return;

    const themeColor =
      background === "green"
        ? themeLight.background.brand
        : themeLight.background.default;

    const current = tag.getAttribute("content");

    tag.setAttribute("content", themeColor);

    return () => {
      if (current) tag.setAttribute("content", current);
    };
  }, [background]);
}
