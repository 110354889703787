import { HeaderAlerts } from "./HeaderAlert";
import { HeaderVariant } from "./HeaderVariant";
import { RoadAssistance } from "./RoadAssistance";
import { OuterHeader } from "./OuterHeader";
import { HeaderWrapper } from "./HeaderWrapper";
import { LogoHeaderSection } from "./LogoHeaderSection";
import { HeaderLogo } from "./HeaderLogo";

export function StandaloneHeader() {
  return (
    <>
      <OuterHeader>
        <HeaderWrapper variant={HeaderVariant.Static}>
          <LogoHeaderSection>
            <HeaderLogo linkLocation="/" />
            <RoadAssistance />
          </LogoHeaderSection>
        </HeaderWrapper>
      </OuterHeader>
      <HeaderAlerts variant={HeaderVariant.Static} />
    </>
  );
}
