import { onDesktop } from "@naf/teamscheme-core";
import { ChevronRight } from "@styled-icons/material/ChevronRight";
import type { StyledIconProps } from "@styled-icons/styled-icon";
import { type HTMLAttributes, ReactNode, type SVGAttributes } from "react";
import styled from "styled-components";
import { EVENTS } from "../../Tracking";
import { normalizeComponentTree } from "../utils/normalizeComponentTree";
import Link, { LinkAction, LinkProps } from "./Link";

export interface BreadcrumbItem {
  action: LinkAction;
  label: ReactNode;
}

export interface BreadcrumbItemProps {
  items: BreadcrumbItem[];
  currentPage?: Omit<BreadcrumbItem, "action">;
}

export interface BreadcrumbsProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "children">,
    BreadcrumbItemProps {}

export function createBreadcrumbItem(
  path: string,
  title: string,
): BreadcrumbItem {
  return {
    action: { linkTo: path },
    label: title,
  };
}

type BackArrowProps = Omit<SVGAttributes<SVGElement>, "children"> & {
  title: string;
};

const BackArrow = styled(function (props: BackArrowProps) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="20"
      viewBox="0 0 16 20"
    >
      <title>{props.title}</title>
      <g>
        <path
          d="M10,6,6,10,10,14"
          fill="none"
          stroke="currentColor"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1"
        />
      </g>
      <line
        opacity="0"
        x1="2"
        x2="10"
        y1="10"
        y2="10"
        fill="none"
        stroke="currentColor"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1"
      />
    </svg>
  );
})``;

const OneUp = styled(function ({ children, ...props }: LinkProps) {
  return (
    <Link {...props}>
      <BackArrow title="Opp" />
      {children}
    </Link>
  );
})`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  color: ${({ theme }) => theme.themeLight.typography.subtleText};

  svg {
    font-size: ${({ theme }) => theme.spacing.space16};
    height: auto;
    margin-right: ${({ theme }) => theme.spacing.space4};
  }

  svg line {
    transition: opacity 250ms ease 0ms;
  }

  svg path {
    transition: transform 250ms ease;
  }

  &:hover {
    color: ${({ theme }) => theme.themeLight.typography.textLink};

    svg line {
      opacity: 1;
      transition-delay: 50ms;
    }

    svg path {
      transform: ${({ theme }) => `translateX(-${theme.spacing.space4})`};
    }
  }
`;

const Current = styled.div``;
const Parent = styled(Link)`
  text-decoration: underline;
`;
const StyledChevron = styled(function (props: StyledIconProps) {
  return <ChevronRight {...props} />;
})``;
const Item = styled.div``;
const Items = styled.div``;

const Breadcrumbs = styled(function ({
  items,
  currentPage,
  ...props
}: BreadcrumbsProps) {
  if (!items.length) return null;

  const isOneUp = items.length === 1 && !currentPage;
  const oneUp = items.length > 1 ? items[items.length - 2] : items[0];

  const trackEvent = {
    ...EVENTS.BREADCRUMBS.BACK_SUBPAGE,
    label: normalizeComponentTree(oneUp.label),
    value: typeof window !== "undefined" ? window.location.pathname : undefined,
  };

  if (isOneUp) {
    return (
      <div {...props}>
        <OneUp action={oneUp.action} trackEvent={trackEvent}>
          {oneUp.label}
        </OneUp>
      </div>
    );
  }

  return (
    <div {...props}>
      <Items>
        {items.map(({ action, label }) => {
          return (
            <Item key={`parent-${label}`}>
              <Parent action={action} trackEvent={trackEvent}>
                {label}
              </Parent>
              <StyledChevron size={16} />
            </Item>
          );
        })}
        {currentPage ? (
          <Item key="last">
            <Current>{currentPage.label}</Current>
          </Item>
        ) : null}
      </Items>
    </div>
  );
})`
  ${({ theme }) => theme.fontStyle.bodyText.small}

  line-height: 1;

  ${Parent} {
    ${({ theme }) => theme.fontStyle.bodyText.small}
  }

  ${Current} {
    color: ${({ theme }) => theme.themeLight.typography.subtleText};
    cursor: default;
    text-decoration-line: none;
  }

  ${StyledChevron} {
    color: ${({ theme }) => theme.themeLight.typography.defaultText};
    font-size: ${({ theme }) => theme.spacing.space16};
    padding: 0 ${({ theme }) => theme.spacing.space8};
    width: auto;
  }

  ${Parent} {
    color: ${({ theme }) => theme.themeLight.typography.defaultText};
    &:hover {
      color: ${({ theme }) => theme.themeLight.typography.textLink};
      text-decoration-line: none;
    }
  }

  ${Item} {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  ${Items} {
    display: none;
    flex-direction: row;
    align-items: center;
  }

  ${onDesktop`
    ${Items} {
      display: flex;
    }
  `}
`;

export default Breadcrumbs;
