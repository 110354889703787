import { captureException } from "@sentry/browser";
import { useState } from "react";
import { ContractType } from "#vehicle-contract/common/lib/model/contract/SalesContract";
import { ApiError } from "../../api/ApiClient";
import { useContractClient } from "../../api/ContractClient";
import { useContract, useContractContext } from "../ContractContext";
import { ContractViewModel } from "../model/ContractViewModel";

export function useMembershipContractClient() {
  const contract = useContract();
  const { put } = useContractClient();
  const { onChange } = useContractContext();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<ApiError | null>(null);

  async function activate() {
    setIsLoading(true);
    try {
      const updated = await put<ContractViewModel>(
        `contract/${contract.id}/contractType/current/member`,
      );
      onChange(updated);
      return true;
    } catch (e) {
      console.log("error upgrading membership contract", e);
      captureException(e);
      setError(e as ApiError);
      setIsLoading(false);
      return false;
    }
  }

  return {
    isActive: contract.contractType?.current === ContractType.Member,
    isLoading,
    error,
    activate,
  };
}
