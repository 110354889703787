import { type Schema } from "#vehicle-contract/common/lib/common";
import { type FieldValue } from "../model/ContractViewModel";

export function display(value: FieldValue) {
  if (!value) return "-";
  if (value.stringValue !== null && value.stringValue !== undefined)
    return value.stringValue;
  if (value.dateTimeValue !== null && value.dateTimeValue !== undefined)
    return new Date(value.dateTimeValue).toLocaleDateString();
  if (value.intValue !== null && value.intValue !== undefined)
    return value.intValue.toString();
  if (value.decimalValue !== null && value.decimalValue !== undefined)
    return value.decimalValue.toString();
  if (value.boolValue !== null && value.boolValue !== undefined)
    return value.boolValue ? "Ja" : "Nei";
  return "-";
}

export function enumDisplay(
  schema: Schema,
  section: string,
  field: string,
  value: FieldValue,
) {
  const definition = schema.getFieldType(section, field);

  if (
    !definition ||
    !definition.options ||
    typeof definition.options === "string"
  ) {
    return display(value);
  }

  const match = definition.options.find((x) => x.value === value.intValue);

  if (!match) return display(value);

  return match.label;
}
