import { captureException } from "@sentry/core";
import {
  type ReactNode,
  useEffect,
  useContext,
  useState,
  createContext,
} from "react";
import { useTrackContext } from "../Tracking";
import { api, type ApiError } from "../api/ApiClient";
import { type CustomerViewModel } from "../shared/models/CustomerViewModel";
import { useSentryTag } from "../useSentryTag";
import { useAuth } from "./AuthProvider";

export interface ICustomerContext {
  isLoggedIn: boolean;
  isLoading: boolean;
  result: CustomerViewModel | null;
  error: ApiError | null;
}

export const CustomerContext = createContext<ICustomerContext | null>(null);

export function useCustomer() {
  const context = useContext(CustomerContext);

  if (!context) throw new Error("No CustomerContext found!");

  return context;
}

export default function CustomerProvider({
  children,
}: {
  children: ReactNode;
}) {
  const { isAuthenticated } = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<ApiError | null>(null);
  const [result, setResult] = useState<CustomerViewModel | null>(null);

  useEffect(() => {
    async function getCustomer() {
      setIsLoading(true);

      try {
        const customer = await api.get<CustomerViewModel>("customer");

        setResult(customer);
      } catch (error) {
        captureException(error);
        setError(error as ApiError);
      } finally {
        setIsLoading(false);
      }
    }

    if (!isAuthenticated) return;

    getCustomer();
  }, [isAuthenticated]);

  useTrackContext("customer_number", result?.customerNumber);
  useTrackContext("customer_id", result?.customerId);
  useSentryTag("hasMembership", result?.hasMembership);
  useTrackContext("has_membership", result?.hasMembership);

  const context = {
    isLoggedIn: isAuthenticated,
    isLoading,
    result,
    error,
  };

  return (
    <CustomerContext.Provider value={context}>
      {children}
    </CustomerContext.Provider>
  );
}
