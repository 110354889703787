import React from "react";
import styled from "styled-components";
import MagnifyingGlassSvg from "./illustrations/magnifying-glass-plus.svg";

export function DocumentOverlay() {
  return (
    <>
      <OverlayBackground />
      <Overlay>
        <Zoom>
          <img alt="Zoom" src={MagnifyingGlassSvg} />
          Forhåndsvis
        </Zoom>
      </Overlay>
    </>
  );
}
const Overlay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
`;
export const OverlayBackground = styled(Overlay)`
  background: ${({ theme }) => theme.nafColor.signature.black};
  opacity: 0.1;
  transition: opacity 0.25s ease;
`;
export const Zoom = styled.div`
  padding: ${({ theme }) => theme.spacing.space24}
    ${({ theme }) => theme.spacing.space32};
  border-radius: ${({ theme }) => theme.radius.s};
  background: white;
  display: flex;
  align-items: center;
  transition: box-shadow 0.25s ease;
  ${({ theme }) => theme.fontStyle.componentText.button};

  img {
    width: 1.5em;
    height: 1.5em;
    margin-right: 1em;
  }
`;
