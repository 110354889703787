import {
  ExternalTextLink as TeamSchemeExternalTextLink,
  TextLinkAnchorElementProps,
} from "@naf/teamscheme";
import styled from "styled-components";
import { EVENTS } from "../Tracking";
import { normalizeComponentTree } from "./utils/normalizeComponentTree";

export interface ExternalLinkProps extends TextLinkAnchorElementProps {}

const ExternalTextLink = styled(
  ({ href, children, trackEvent, ...props }: ExternalLinkProps) => {
    return (
      <TeamSchemeExternalTextLink
        {...props}
        href={href}
        trackEvent={
          trackEvent || {
            ...EVENTS.COMMON.OPEN_EXTERNAL_LINK,
            label: normalizeComponentTree(children),
            value: href,
          }
        }
      >
        {children}
      </TeamSchemeExternalTextLink>
    );
  },
)``;

export default ExternalTextLink;
