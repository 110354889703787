import { Role } from "#vehicle-contract/common/lib/model/contract/Role";
import { SalesContract } from "#vehicle-contract/common/lib/model/contract/SalesContract";
import { SigningParty } from "#vehicle-contract/common/lib/model/contract/SigningParty";
import { CompactMutation } from "../../api/Mutation";
import Copyable from "../../shared/Copyable";
import { roleName } from "../../shared/roleName";

function signingPartyString(signingParty: SigningParty) {
  switch (signingParty) {
    case SigningParty.Buyer:
      return "Buyer";
    case SigningParty.BuyerCoOwner:
      return "BuyerCoOwner";
    case SigningParty.Seller:
      return "Seller";
    case SigningParty.SellerCoOwner:
      return "SellerCoOwner";
    default:
      throw new Error(`Unrecognized signing party ${signingParty}`);
  }
}

function CopyableExternalUrl({ url }: { url: string }) {
  return (
    <Copyable>
      <a href={url} target="_blank" rel="noopener noreferrer">
        {url}
      </a>
    </Copyable>
  );
}

export function LinkToSigningTaskButton({
  contract,
  party,
}: {
  contract: SalesContract;
  party: SigningParty;
}) {
  const partyString = signingPartyString(party);

  return (
    <CompactMutation<string>
      title="Fetch URL"
      icon="eye-open"
      method="GET"
      path={`admin/contract/${contract.id}/signature/signingTask/${partyString}/link`}
    >
      {({ data: url }) => <CopyableExternalUrl url={url} />}
    </CompactMutation>
  );
}

export function LinkToContractButton({
  contract,
  role,
}: {
  contract: SalesContract;
  role: Role;
}) {
  const roleString = roleName(role);

  const hasParty =
    ("sellerToken" in contract
      ? contract.sellerToken && role === Role.Seller
      : false) ||
    ("buyerToken" in contract
      ? contract.buyerToken && role === Role.Buyer
      : false);

  return hasParty ? (
    <CompactMutation<string>
      title="Fetch URL"
      method="GET"
      icon="eye-open"
      path={`admin/contract/${contract.id}/${roleString}/link`}
    >
      {({ data: url }) => <CopyableExternalUrl url={url} />}
    </CompactMutation>
  ) : (
    <span>{roleString} is not invited</span>
  );
}
