import { onDesktop } from "@naf/teamscheme-core";
import React, { forwardRef, HTMLAttributes } from "react";
import styled from "styled-components";
import { PageFooter } from "./PageFooter";

export interface PageProps {
  children: React.ReactNode;
  footer?: boolean;
  className?: string;
}

export const StyledPage = styled(
  forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
    function (props, ref) {
      return <main ref={ref} {...props} />;
    },
  ),
)`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ theme }) => onDesktop`
    padding: ${theme.spacing.space16};
  `}
`;

export type PageRef = HTMLDivElement;

const Page = forwardRef<PageRef | null, PageProps>(function (
  { className, children, footer },
  ref,
) {
  return (
    <>
      <StyledPage ref={ref} className={className}>
        {children}
      </StyledPage>
      {footer ? <PageFooter /> : null}
    </>
  );
});

export const FlowPage = styled(Page)``;

export default Page;
