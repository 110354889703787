import { Gender } from "#vehicle-contract/common/lib/model/contract/ContractingParty";

export function convertToGenderEnum(gender?: string | null) {
  switch (gender) {
    case "female":
      return Gender.Female;
    case "male":
      return Gender.Male;
    case "unknown":
    case "":
      return Gender.Unknown;
    default:
      return Gender.None;
  }
}

export function convertFromGenderEnum(gender: Gender) {
  switch (gender) {
    case Gender.Female:
      return "female";
    case Gender.Male:
      return "male";
    case Gender.Unknown:
      return "";
    default:
      return undefined;
  }
}
