import { FlyoutModal } from "@naf/teamscheme";
import { onDesktop } from "@naf/teamscheme-core";
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useState,
} from "react";
import styled, { css } from "styled-components";
import DocumentPreview from "./DocumentPreview";
import { DocumentPreviewPlaceholder } from "./DocumentPreviewContainer";
import { DocumentPreviewZoomableScrollImage } from "./DocumentPreviewZoomableScrollImage";
import { useDocumentPreview } from "./DocumentPreviewContext";
import { ContractExplanationCard } from "./ContractExplanationCard";

export interface PreviewModalProps {
  isOpen: boolean;
  onClose(): void;
}

const PreviewMenuModal = styled(FlyoutModal)`
  width: 100%;
`;

const ModalDocumentPreview = styled(DocumentPreview)`
  ${({ theme }) => css`
    width: auto;
    max-width: initial;
    margin: 0 -${theme.spacing.space24};

    ${onDesktop`
      margin: 0 -${theme.spacing.space48};
    `}

    ${DocumentPreviewZoomableScrollImage} {
      box-shadow: none;
      border: 1px solid ${theme.nafColor.neutral.neutral2};
    }
  `}
`;

const ModalDocumentPreviewWrapper = styled.div`
  display: block;
  margin: 0 auto;
  padding: 0;
  border: 0;
  border-radius: 2px;
  width: 100%;
  position: relative;
  transition: box-shadow 0.25s ease;
`;

function LoadDocumentPreview() {
  const documentPreview = useDocumentPreview();
  const [isZoomed, setIsZoomed] = useState(false);

  return (
    <ModalDocumentPreviewWrapper onClick={() => setIsZoomed(!isZoomed)}>
      <ModalDocumentPreview
        isZoomed={isZoomed}
        setIsZoomed={setIsZoomed}
        loading={documentPreview.loading}
        result={documentPreview.result ?? undefined}
      />
    </ModalDocumentPreviewWrapper>
  );
}

function PreviewModal({ isOpen, onClose }: PreviewModalProps) {
  return (
    <PreviewMenuModal isOpen={isOpen} onClose={onClose}>
      <h1>Forhåndsvisning</h1>
      <p>
        Dette er kun en forhåndsvisning og regnes ikke som gyldig dokument.
        Gyldig signert dokument sendes på e-post etter signering.
      </p>
      {isOpen ? <LoadDocumentPreview /> : <DocumentPreviewPlaceholder />}
      <ContractExplanationCard />
    </PreviewMenuModal>
  );
}

export interface IPreviewFlyoutContext {
  open: () => void;
}

export function usePreviewFlyout() {
  const context = useContext(PreviewFlyoutContext);

  if (!context) throw new Error("No PreviewContext found!");

  return context;
}

export const PreviewFlyoutContext = createContext<IPreviewFlyoutContext | null>(
  null,
);

export function PreviewFlyoutProvider({ children }: { children: ReactNode }) {
  const [isOpen, setIsOpen] = useState(false);

  const open = useCallback(() => setIsOpen(true), []);

  return (
    <PreviewFlyoutContext.Provider value={{ open }}>
      {children}
      <PreviewModal isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </PreviewFlyoutContext.Provider>
  );
}
