import type { HTMLAttributes } from "react";
import { normalizeComponentTree } from "../../shared/utils/normalizeComponentTree";
import { Button } from "@blueprintjs/core";
import { AdminToaster } from "../AdminToaster";
import styled from "styled-components";

function Copyable({
  children,
  copy,
  ...props
}: {
  children: string | JSX.Element;
  copy?: string | JSX.Element;
} & Omit<HTMLAttributes<HTMLSpanElement>, "children">) {
  return (
    <span {...props}>
      {children}
      <Button
        type="button"
        icon="duplicate"
        minimal
        onClick={async () => {
          navigator.clipboard.writeText(
            normalizeComponentTree(copy || children),
          );
          AdminToaster.then((t) =>
            t.show({
              message: "Copied to clipboard!",
              intent: "success",
            }),
          );
        }}
        title="Copy to clipboard"
      />
    </span>
  );
}

export default styled(Copyable)`
  white-space: nowrap;
`;
