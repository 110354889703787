import { onDesktop } from "@naf/teamscheme-core";
import { HTMLAttributes, ReactNode } from "react";
import styled, { DefaultTheme, css } from "styled-components";

type BannerVariant = "green" | "yellow" | "yellow20" | "blue";

export interface BannerBaseProps extends HTMLAttributes<HTMLDivElement> {
  variant: BannerVariant;
  label?: ReactNode;
}

interface FigureProps
  extends Omit<HTMLAttributes<HTMLImageElement>, "width" | "src" | "alt"> {
  width: number;
  widthMobile: number;
  aspectRatio: number;
  src: string;
  alt: string;
}

const Label = styled.h3`
  ${({ theme }) => theme.fontStyle.componentText.tag};
`;

function getBackground(theme: DefaultTheme, variant: BannerVariant) {
  return css`
  ${variant === "green" && css`background: ${theme.nafColor.primary.dew};`}
  ${
    variant === "yellow" &&
    css`background: ${theme.nafColor.signature.yellow10};`
  }
  ${
    variant === "yellow20" &&
    css`background: ${theme.nafColor.signature.yellow20};`
  }
  ${variant === "blue" && css`background: ${theme.nafColor.secondary.frost};`}
  `;
}

const BannerWithFigure = styled(function ({
  figure,
  variant,
  label,
  children,
  ...props
}: {
  figure: FigureProps;
} & BannerBaseProps) {
  const { aspectRatio, widthMobile, ...restFigure } = figure;
  return (
    <div {...props}>
      <aside>
        <img
          {...restFigure}
          src={figure.src}
          width={figure.width}
          height={aspectRatio * figure.width}
          alt={figure.alt}
        />
      </aside>
      <section>
        {label ? <Label>{label}</Label> : null}
        {children}
      </section>
    </div>
  );
})`
  ${({ theme, figure: { width, widthMobile, aspectRatio }, variant }) => css`
    ${getBackground(theme, variant)}
    padding: ${theme.spacing.space16};
    margin-bottom: ${theme.spacing.space40};
    width: 100%;
    flex: 1;

    img {
      width: ${widthMobile}px;
      height: ${aspectRatio * widthMobile}px;
    }

    ${onDesktop`
      display: flex;
      padding: ${theme.spacing.space32};
      gap: ${theme.spacing.space48};
      aside {
        margin-left: auto;
        margin-right: 0;
        align-self: flex-start;
        order: 1;
      }
      img {
        width: ${width}px;
        height: ${aspectRatio * width}px;
      }
    `}
  `}
`;

const BannerWithoutFigure = styled(function ({
  variant,
  label,
  children,
  ...props
}: BannerBaseProps) {
  return (
    <div {...props}>
      {label ? <Label>{label}</Label> : null}
      {children}
    </div>
  );
})`${({ theme, variant }) => css`
    ${getBackground(theme, variant)}
    padding: ${theme.spacing.space16};
    margin-bottom: ${theme.spacing.space40};
    width: 100%;
    flex: 1;
  `}
`;

function Banner({
  figure,
  ...props
}: { figure?: FigureProps } & BannerBaseProps) {
  if (figure) {
    return <BannerWithFigure figure={figure} {...props} />;
  }

  return <BannerWithoutFigure {...props} />;
}

export default Banner;
