import React from "react";
import LoadingOverlay from "../components/LoadingOverlay";
import { type QueryResult } from "./QueryResult";
import { Button } from "@blueprintjs/core";
import { AdminErrorMessage } from "../shared/AdminErrorMessage";

export function QueryResultView<T>({
  queryPage: { error, loading, data, refresh, updated },
  children,
  showOverlay = true,
}: {
  queryPage: QueryResult<T>;
  children: () => React.ReactNode;
  showOverlay?: boolean;
}) {
  if (error) {
    return (
      <div>
        <AdminErrorMessage>
          {error.message || error.toString()}
        </AdminErrorMessage>
        <Button
          icon="refresh"
          type="button"
          onClick={(e) => {
            e.preventDefault();
            refresh();
          }}
        >
          Retry
        </Button>
      </div>
    );
  }

  if (loading && !data) {
    return <LoadingOverlay loading={loading} />;
  }

  if (!data) {
    return (
      <div>
        <span>Ingen data!</span>
      </div>
    );
  }

  return (
    <>
      {children()}
      <div>
        <Button icon="refresh" type="button" onClick={refresh}>
          Refresh
        </Button>
        {
          <small style={{ margin: "0 .5em" }}>
            Updated: {updated?.toLocaleTimeString()}
          </small>
        }
        {loading && showOverlay ? <LoadingOverlay loading={loading} /> : null}
      </div>
    </>
  );
}
