export enum AppPages {
  LandingPage = "LandingPage", // StaticPath.Landing
  LandingPageMc = "LandingPageMc", // StaticPath.McLanding
  LandingPageCaravan = "LandingPageCaravan", // StaticPath.CaravanLanding
  AboutLandingPage = "aboutLandingPage", // AboutPath.LandingPage
  AboutDigitalSigning = "aboutDigitalSigning", // AboutPath.DigitalSigning
  AboutFaq = "aboutFaq", // AboutPath.Faq
  AboutHowItWorks = "aboutHowItWorks", // AboutPath.HowItWorks
  AboutOwnershipTransfer = "aboutOwnershipTransfer", // AboutPath.OwnershipTransfer
  AboutVipps = "aboutVipps", // AboutPath.Vipps
  AboutInstructions = "aboutInstructions", // AboutPath.Instructions
  AboutPrivacy = "aboutPrivacy", // AboutPath.Privacy
  AboutStaticContracts = "aboutStaticContracts", // AboutPath.StaticContracts
  AboutTerms = "aboutTerms", // AboutPath.Terms
  AboutUpgrade = "aboutUpgrade", // AboutPath.Upgrade
  BecomeMember = "becomeMember", // StaticPath.BecomeMember
  BecomeMemberWelcome = "becomeMemberWelcome", // BecomeMemberPath.Welcome
  BecomeMemberForm = "becomeMemberForm", // BecomeMemberPath.Form,
  Invitation = "invitation", // New invitationPath
  ContractInvitation = "contractInvitation", // should be deleted at some point
  Create = "create",
  Contract = "contract", // StaticPath.Contract og ContractPath.Root
  ContractForm = "contractForm", // ContractPath.Form
  ContractFormAboutVehicle = "contractFormAboutVehicle", // ContractFormPath.AboutVehicle
  ContractFormContactInfo = "contractFormContactInfo", // ContractFormPath.ContactInfo
  ContractFormAboutPayment = "contractFormAboutPayment", // ContractFormPath.AboutPayment
  ContractFormAttachments = "contractFormAttachments", // ContractFormPath.Attachments
  ContractFormInsurance = "contractFormInsurance", // ContractFormPath.Insurance
  ContractFormType = "contractFormType", // ContractFormPath.Insurance
  ContractMembershipUpgrade = "contractMembershipUpgrade", // ContractPath.MembershipUpgrade
  ContractLoginCallback = "contractLoginCallback", // ContractPath.LoginCallback
  ContractPreview = "contractPreview", // ContractPath.Preview
  ContractSaveContract = "contractSaveContract", // ContractPath.Save
  ContractSign = "contractSign", // ContractPath.SignCallback
  ContractSignCallback = "contractSignCallback", // ContractPath.SignCallback
  ContractSigningMethodUpgradeContract = "contractSigningMethodUpgradeContract", // ContractPath.SigningMethodUpgradeContract
  ContractStatus = "contractStatus", // ContractPath.Status
  ContractUpgradeRoot = "contractUpgradeRoot",
  ContractPremiumRoot = "contractPremiumRoot",
  ContractPremiumTrialMembership = "contractTrialMembership",
  ContractPremiumTrialMembershipOrchestration = "contractTrialMembershipOrchestration",
  ContractUpgradeProductRoot = "contractUpgradeProductRoot",
  ContractConfirmationLanding = "contractConfirmationLanding", // ContractPath.ConfirmationLanding
  ContractConfirmationWaitingForPdf = "contractConfirmationWaitingForPdf", // ConfirmationPath.WaitingForPdf
  ContractConfirmationSettlement = "contractConfirmationSettlement", // ConfirmationPath.Settlement
  ContractConfirmationSummary = "contractConfirmationSummary", // ConfirmationPath.Summary
  ContractConfirmationInsurance = "contractConfirmationInsurance", // ConfirmationPath.Insurance
  ContractConfirmationFinancial = "contractConfirmationFinancial", // ConfirmationPath.Financial
  ContractConfirmationTrialMembership = "contractConfirmationTrialMembership", // ConfirmationPath.TrialMembership
  ContractConfirmationTrialMembershipOrchestration = "contractConfirmationTrialMembershipOrchestration",
}
