import { useCallback } from "react";
import { api, ApiError } from "../../api/ApiClient";
import { useClientBase } from "../../api/ClientBase";
import { ContractViewModel } from "../model/ContractViewModel";
import { Cancelable } from "../../utils";

export interface LinkCustomerToContractClient {
  link(): Cancelable<ContractViewModel>;
  result: ContractViewModel | null;
  error: ApiError | null;
  isLoading: boolean;
}

export function useLinkCustomerToContractClient(
  token: string | null,
): LinkCustomerToContractClient {
  const { error, fetch, isLoading, result } = useClientBase<
    void,
    ContractViewModel
  >(
    useCallback(
      () => api.post(`customerOnboarding/token/${token}/link`, null),
      [token],
    ),
  );
  return {
    link: fetch,
    isLoading,
    result,
    error,
  };
}
