import { type TrialMembershipOrchestrationHistoryItem } from "#vehicle-contract/common/lib/model/contract/TrialMembership";
import { SuccessAlert } from "@naf/teamscheme";
import { useCallback, useMemo } from "react";
import { apiClientFunction, createRestClient } from "../../../api/ApiClient";
import { useAutoLoadingClientBase } from "../../../api/ClientBase";
import { withContractToken } from "../../../api/ContractClient";
import { type ContractToken } from "../../model/ContractViewModel";

function useTrialMembershipOrchestrationByContractToken(
  contractId: string,
  contractToken: ContractToken,
  orchestrationId: string,
) {
  const contractTokenClient = useMemo(
    () => createRestClient(withContractToken(contractToken)(apiClientFunction)),
    [contractToken],
  );
  return useAutoLoadingClientBase(
    useCallback(
      () =>
        contractTokenClient.get<TrialMembershipOrchestrationHistoryItem>(
          `contract/${contractId}/trialMembership/orchestration/${orchestrationId}`,
        ),
      [contractTokenClient, contractId, orchestrationId],
    ),
  );
}

export function TrialMembershipOrchestrationView({
  trial,
}: {
  trial?: TrialMembershipOrchestrationHistoryItem;
}) {
  const customerNumber = trial?.customerNumber;
  return (
    <SuccessAlert>
      {customerNumber ? (
        <span>
          Prøvemedlemskapet ditt er aktivert med medlemsnummer{" "}
          <strong>{customerNumber}</strong>.{" "}
        </span>
      ) : (
        <span>Vi aktiverer ditt prøvemedlemskap. </span>
      )}
      <span>Vi sender deg mer informasjon på e-post.</span>
    </SuccessAlert>
  );
}

export function TrialMembershipOrchestration({
  contractId,
  contractToken,
  offerId: orchestrationId,
}: {
  offerId: string;
  contractToken: ContractToken;
  contractId: string;
}) {
  const trial = useTrialMembershipOrchestrationByContractToken(
    contractId,
    contractToken,
    orchestrationId,
  );
  return <TrialMembershipOrchestrationView trial={trial.result ?? undefined} />;
}
