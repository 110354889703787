import { CONTRACT_TYPE_RATH_PARAM } from "../contract/contract-type/contract-upgrade/locationParams";
import { AppPages } from "./AppPages";

export interface AppPathDescription {
  absolute: string;
  relative: string;
  params?: string[];
  postfix?: string;
  path: string;
}

export type AppPathRoutes<T extends string> = {
  [path in T]: AppPathDescription;
};

export const buildAppRoute = (
  relative: string,
  postfix?: string,
  params?: string[],
): AppPathDescription => ({
  absolute: `${postfix ?? ""}${relative}`,
  relative,
  params,
  postfix,
  path: relative,
});

export const AppRoutes: AppPathRoutes<AppPages> = {
  [AppPages.LandingPage]: buildAppRoute("/"),
  [AppPages.LandingPageMc]: buildAppRoute("mc", "/"),
  [AppPages.LandingPageCaravan]: buildAppRoute("campingvogn-og-bobil", "/"),
  [AppPages.AboutLandingPage]: buildAppRoute("om", "/"),
  [AppPages.AboutDigitalSigning]: buildAppRoute("digital-signering", "/om/"),
  [AppPages.AboutFaq]: buildAppRoute("sporsmal-og-svar", "/om/"),
  [AppPages.AboutHowItWorks]: buildAppRoute("slik-fungerer-det", "/om/"),
  [AppPages.AboutOwnershipTransfer]: buildAppRoute("eierskifte", "/om/"),
  [AppPages.AboutVipps]: buildAppRoute("vipps", "/om/"),
  [AppPages.AboutInstructions]: buildAppRoute("utfylling-av-kontrakt", "/om/"),
  [AppPages.AboutPrivacy]: buildAppRoute("cookies-og-personvern", "/om/"),
  [AppPages.AboutStaticContracts]: buildAppRoute("last-ned-kontrakt", "/om/"),
  [AppPages.AboutTerms]: buildAppRoute("vilkar", "/om/"),
  [AppPages.AboutUpgrade]: buildAppRoute("oppgradering", "/om/"),
  [AppPages.Create]: buildAppRoute("opprett/:contractType", "/", [
    ":contractType",
  ]),
  [AppPages.BecomeMember]: buildAppRoute("bli-medlem", "/"),
  [AppPages.BecomeMemberWelcome]: buildAppRoute("velkommen", "/bli-medlem/"),
  [AppPages.BecomeMemberForm]: buildAppRoute(":productNumber", "/bli-medlem/", [
    ":productNumber",
  ]),
  [AppPages.Invitation]: buildAppRoute("invitasjon", "/"),
  [AppPages.Contract]: buildAppRoute("kontrakt", "/"),
  [AppPages.ContractInvitation]: buildAppRoute("invitasjon", "/kontrakt/"),
  [AppPages.ContractForm]: buildAppRoute("utfylling", "/kontrakt/"),
  [AppPages.ContractFormAboutVehicle]: buildAppRoute(
    "om-kjoretoyet",
    "/kontrakt/utfylling/",
  ),
  [AppPages.ContractFormContactInfo]: buildAppRoute(
    "kontaktinfo",
    "/kontrakt/utfylling/",
  ),
  [AppPages.ContractFormAboutPayment]: buildAppRoute(
    "om-betaling",
    "/kontrakt/utfylling/",
  ),
  [AppPages.ContractFormAttachments]: buildAppRoute(
    "vedlegg",
    "/kontrakt/utfylling/",
  ),
  [AppPages.ContractFormInsurance]: buildAppRoute(
    "forsikring",
    "/kontrakt/utfylling/",
  ),
  [AppPages.ContractFormType]: buildAppRoute(
    "velg-type",
    "/kontrakt/utfylling/",
  ),
  [AppPages.ContractMembershipUpgrade]: buildAppRoute(
    "oppgrader/medlem",
    "/kontrakt/",
  ),
  [AppPages.ContractLoginCallback]: buildAppRoute("innlogging", "/kontrakt/"),
  [AppPages.ContractPreview]: buildAppRoute("forhandsvisning", "/kontrakt/"),
  [AppPages.ContractSaveContract]: buildAppRoute("lagre", "/kontrakt/"),
  [AppPages.ContractSignCallback]: buildAppRoute(
    "callback",
    "/kontrakt/signer/:hash/",
    [":hash"],
  ),
  [AppPages.ContractSigningMethodUpgradeContract]: buildAppRoute(
    "bankid/oppgrader-kontrakt",
    "/kontrakt/",
  ),
  [AppPages.ContractSign]: buildAppRoute(":hash", "/kontrakt/signer/", [
    ":hash",
  ]),
  [AppPages.ContractStatus]: buildAppRoute("status", "/kontrakt/"),
  [AppPages.ContractUpgradeRoot]: buildAppRoute("oppgradering", "/kontrakt/"),
  [AppPages.ContractUpgradeProductRoot]: buildAppRoute(
    `oppgradering/:${CONTRACT_TYPE_RATH_PARAM}`,
    "/kontrakt/",
    [`:${CONTRACT_TYPE_RATH_PARAM}`],
  ),
  [AppPages.ContractPremiumRoot]: buildAppRoute("premium", "/kontrakt/"),
  [AppPages.ContractPremiumTrialMembership]: buildAppRoute(
    "provemedlemskap",
    "/kontrakt/premium/",
  ),
  [AppPages.ContractPremiumTrialMembershipOrchestration]: buildAppRoute(
    ":orchestrationId",
    "/kontrakt/premium/provemedlemskap/",
    [":orchestrationId"],
  ),
  [AppPages.ContractConfirmationLanding]: buildAppRoute(
    "bekreftelse",
    "/kontrakt/",
  ),
  [AppPages.ContractConfirmationWaitingForPdf]: buildAppRoute(
    "pdf",
    "/kontrakt/bekreftelse/",
  ),
  [AppPages.ContractConfirmationSettlement]: buildAppRoute(
    "oppgjor",
    "/kontrakt/bekreftelse/",
  ),
  [AppPages.ContractConfirmationSummary]: buildAppRoute(
    "oppsummering",
    "/kontrakt/bekreftelse/",
  ),
  [AppPages.ContractConfirmationInsurance]: buildAppRoute(
    "forsikring",
    "/kontrakt/bekreftelse/",
  ),
  [AppPages.ContractConfirmationFinancial]: buildAppRoute(
    "betaling",
    "/kontrakt/bekreftelse/",
  ),
  [AppPages.ContractConfirmationTrialMembership]: buildAppRoute(
    "provemedlemskap",
    "/kontrakt/bekreftelse/",
  ),
  [AppPages.ContractConfirmationTrialMembershipOrchestration]: buildAppRoute(
    ":orchestrationId",
    "/kontrakt/bekreftelse/provemedlemskap/",
    [":orchestrationId"],
  ),
};

export function getAbsolutePath(
  page: AppPages,
  paramValues?: { [key: string]: string },
) {
  const currentPage = AppRoutes[page];
  if (!AppRoutes[page]) {
    throw Error(`Could not find ${page}`);
  }
  const { params = [], absolute } = currentPage;

  const absolutePath = params.reduce((ap, paramKey) => {
    if (paramValues?.[paramKey]) {
      return ap.replace(paramKey, paramValues[paramKey]);
    }
    return ap;
  }, absolute);

  return absolutePath;
}

export function getRelativePath(page: AppPages) {
  return AppRoutes[page].relative;
}

export function getChildrenPaths(
  path: string,
): Partial<AppPathRoutes<AppPages>> {
  const objectKeys = Object.keys(AppRoutes);

  return objectKeys.reduce(
    (op, pathName) => {
      const appPage = AppRoutes[pathName as AppPages];
      if (appPage.absolute.startsWith(path) && appPage.absolute !== path) {
        op[pathName as AppPages] = appPage;
      }
      return op;
    },
    {} as Record<AppPages, AppPathDescription>,
  );
}
