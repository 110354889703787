import { TextLink } from "@naf/teamscheme";
import { HTMLAttributes } from "react";
import styled from "styled-components";
import Illustration from "./no-results_129x114.svg";

const NoResultsPlaceholder = styled(function ({
  query,
  clear,
  ...props
}: {
  query: string;
  clear: () => void;
} & Omit<HTMLAttributes<HTMLDivElement>, "children">) {
  return (
    <div {...props}>
      <img src={Illustration} alt="" width="129" height="114" />
      <p>
        <strong>Fant ingen treff på "{query}".</strong>
      </p>
      <p>
        Prøv å søke på noe annet eller{" "}
        <TextLink onClick={clear}>nullstill søket</TextLink>.
      </p>
    </div>
  );
})`
  text-align: center;
  img {
    margin-bottom: ${({ theme }) => theme.spacing.space32};
  }
`;

export default NoResultsPlaceholder;
