import { type LocationDescriptor } from "@naf/teamscheme";
import { useLocation } from "react-router";
import styled from "styled-components";
import { ContractType } from "#vehicle-contract/common/lib/model/contract/SalesContract";
import { type CustomerViewModel } from "../../shared/models/CustomerViewModel";
import { getContractTypeKey } from "../create-contract/ContractTypeName";
import {
  GeneralVippsNokLimit,
  NoOwnershipTransferVippsNokLimit,
} from "../form/financial/VippsPaymentLimits";
import {
  ContractTypeBenefit,
  bankIdBenefit,
  insuranceBenefit,
  ownershipTransferBenefit,
  vehicleContractBenefit,
  vippsBenefit,
} from "./ContractTypeBenefit";
import TypeCard, {
  TypeCardImage,
  TypeCardTitle,
  TypeCardBenefitList,
  TypeCardHeader,
  type TypeCardProps,
  TypeCardButton,
} from "./TypeCard";
import PremiumIllustration from "./illustrations/full_91x124.svg";
import {
  TypeCardPrice,
  TypeCardPriceElement,
} from "./type-card-price/TypeCardPrice";
import { typeCardNonMemberPremiumPrice } from "./type-card-price/TypeCardPriceArray";
import { useContractUrls } from "../routing/ContractUrls";
import { getContractUpgradeCreatePaymentLocation } from "./contract-upgrade/getContractUpgradeCreatePaymentLocation";
import { useHasMembershipPath } from "./useHasMembershipPath";

export const PREMIUM_BENEFITS: ContractTypeBenefit[] = [
  vehicleContractBenefit,
  bankIdBenefit,
  vippsBenefit,
  ownershipTransferBenefit,
  insuranceBenefit,
];

export const PremiumPackageDetails = () => (
  <>
    <p>
      Premium kontrakt inkluderer bl.a. registrering av eierskifte hos Statens
      vegvesen, gebyrfri betaling med Vipps, og identifisering og signering med
      BankID.
    </p>
    <p>
      Med Vipps og eierskifte med NAF Kjøpekontrakt kan en rettferdig og
      bekymringsfri overlevering av kjøretøyet sikres i det øyeblikket det er
      utfordrende å vite hvilken del av oppgjøret som skal skje først. Vi sørger
      for at det skjer samtidig, slik at ingen går hjem tomhendte. Det betyr at
      kjøretøyet registreres på ny eier hos Statens vegvesen i samme øyeblikk
      som betalingen Vippses til selger. Kjøper kan da være sikker på at
      kjøretøyet er registrert på sitt navn, og selger kan trygt gi fra seg
      nøkler med betalingen trygt på sin konto. Vær oppmerksom på at signering
      må gjøres med BankID for å benytte eierskifte hos Statens vegvesen og
      gebyrfri betaling av kjøpesummen med Vipps.
    </p>
    <p>Du slipper gebyr på 1 % hos Vipps for beløp som ikke overskrider: </p>
    <ul>
      <li>
        <GeneralVippsNokLimit /> når eierskiftet registreres med NAF
        Kjøpekontrakt.
      </li>
      <li>
        <NoOwnershipTransferVippsNokLimit /> uten eierskifte med NAF
        Kjøpekontrakt.
      </li>
    </ul>
    <p>
      Merk at beløpsgrensene varierer hos de ulike bankene, og at det kan
      forekomme gebyrer fra banken din. Vi anbefaler å undersøke hva som gjelder
      for din bank før gjennomførelse av betaling.
    </p>
  </>
);

export interface PremiumCardProps extends Omit<TypeCardProps, "label"> {
  linkTo?: LocationDescriptor<unknown>;
  priceArray?: TypeCardPriceElement[];
}

export const PremiumCard = styled(function ({
  linkTo,
  priceArray,
  ...props
}: PremiumCardProps) {
  return (
    <TypeCard {...props}>
      <TypeCardHeader>
        <TypeCardImage
          src={PremiumIllustration}
          alt="Premium"
          width="91"
          height="124"
        />
        <TypeCardTitle>Premium</TypeCardTitle>
      </TypeCardHeader>
      <TypeCardBenefitList benefits={PREMIUM_BENEFITS} />
      {priceArray ? <TypeCardPrice elements={priceArray} /> : null}
      {linkTo ? <PremiumCardActionButton linkTo={linkTo} /> : null}
    </TypeCard>
  );
})``;

export default PremiumCard;

function PremiumCardActionButton({
  linkTo,
}: {
  linkTo: LocationDescriptor<unknown>;
}) {
  return (
    <TypeCardButton
      action={{ linkTo }}
      typeKey={getContractTypeKey(ContractType.Premium)}
      variant="dark"
    >
      Velg Premium
    </TypeCardButton>
  );
}

export const PremiumUpgradeCard = styled(function ({
  customer,
  returnPath,
  onClick,
  className,
}: {
  customer: CustomerViewModel | null;
  returnPath?: string;
  className?: string;
  onClick?(): void;
}) {
  const urls = useContractUrls();
  const location = useLocation();
  const next = getContractUpgradeCreatePaymentLocation(
    urls,
    ContractType.Premium,
    returnPath ?? location.pathname,
    location.pathname,
  );
  const hasMembershipPath = useHasMembershipPath(returnPath);
  const baseProps: PremiumCardProps = {
    className,
    linkTo: customer?.hasMembership ? hasMembershipPath : next,
    priceArray: [typeCardNonMemberPremiumPrice],
    onClick,
  };

  return <PremiumCard {...baseProps} />;
})``;
