import { DateString } from "../common/DateString";

export interface Seller extends ContractingParty {
  accountNumber?: string;
}

export enum AutoFillSource {
  None = 0,
  ExternalCustomerSearch = 1,
  Crm = 2,
  ProfileForm = 4,
}

export interface ContractingParty extends ContactInfo {
  accountNumber?: string;
  /**
   * @deprecated Prefer autoFillSource
   */
  autoFilled: boolean;
  autoFillSource?: AutoFillSource;
  isOrganization?: boolean;
  organizationNumber?: string;
  organizationName?: string;
  nationalIdentityNumber?: string;
  updated?: DateString;
}

export interface ContactInfo {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  address?: string;
  city?: string;
  postalCode?: string;
  gender?: Gender;
  dateOfBirth?: DateString;
}

export enum Gender {
  None = 0,
  Male = 1,
  Female = 2,
  Unknown = 4,
}
