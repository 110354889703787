import { onDesktop } from "@naf/teamscheme-core";
import styled from "styled-components";

export const NotFoundWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${onDesktop`
    flex-direction: row;
    justify-content: flex-start;
  `}
`;
