import { AppPages } from "../../../routing/AppPages";
import { getAbsolutePath } from "../../../routing/AppRoutes";
import { ContractType } from "#vehicle-contract/common/lib/model/contract/SalesContract";
import { getContractTypeKey } from "../../create-contract/ContractTypeName";
import { createBecomeMemberFormPath } from "../../../membership/createBecomeMemberFormPath";
import { MEMBERSHIP_WITH_ROADASSISTANCE_PRODUCT_NUMBER } from "../../../membership/membershipService/useMembershipService";

export const staticUpgradeLinks = {
  [ContractType.Standard]: {
    pathname: getAbsolutePath(AppPages.Create, {
      ":contractType": getContractTypeKey(ContractType.Standard) ?? "",
    }),
  },
  [ContractType.Simple]: {
    pathname: getAbsolutePath(AppPages.Create, {
      ":contractType": getContractTypeKey(ContractType.Simple) ?? "",
    }),
  },
  [ContractType.Premium]: {
    pathname: getAbsolutePath(AppPages.Create, {
      ":contractType": getContractTypeKey(ContractType.Premium) ?? "",
    }),
  },
  [ContractType.Member]: {
    pathname: createBecomeMemberFormPath(
      MEMBERSHIP_WITH_ROADASSISTANCE_PRODUCT_NUMBER,
      "",
    ),
  },
};
