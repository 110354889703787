import { Button, ButtonButtonElementProps } from "@naf/teamscheme";
import { ITheme, onDesktop } from "@naf/teamscheme-core";
import {
  HTMLAttributes,
  InputHTMLAttributes,
  ReactNode,
  Ref,
  TextareaHTMLAttributes,
} from "react";
import styled, { css } from "styled-components";
import ErrorMessage from "../shared/error/ErrorMessage";
import { LabeledInputComponent } from "./LabeledInputComponent";

const CompactButton = styled(Button)`
  height: auto;
  white-space: nowrap;
`;

const LabeledInputComponentWrapper = styled(
  ({
    disabled: _,
    ...props
  }: HTMLAttributes<HTMLDivElement> & { disabled?: boolean }) => (
    <div {...props} />
  ),
)`
  ${(props) =>
    props.disabled
      ? css`
           {
            background: ${({ theme }) => theme.nafColor.neutral.neutral3};
          }
        `
      : ""}

  flex: 1;
  ${({ theme }: { theme: ITheme }) => onDesktop`
    margin-right: ${theme.spacing.space8};
  `}
`;

const InputButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${onDesktop`
    flex-direction: row;
  `}
`;

const StyledLabeledInputComponent = styled(LabeledInputComponent)``;

interface LabeledInputProps extends HTMLAttributes<HTMLDivElement> {
  id: string;
  name: string;
  label: string;
  description?: ReactNode;
  inputProps: InputHTMLAttributes<HTMLInputElement> | null;
  inputRef?: Ref<HTMLInputElement>;
  hasError?: boolean;
  error?: string | null;
}

export const LabeledInputWithButton = ({
  id,
  name,
  label,
  description,
  inputProps,
  inputRef = null,
  error = null,
  hasError = false,
  buttonProps,
  ...props
}: LabeledInputProps & {
  buttonProps: ButtonButtonElementProps;
}) => (
  <StyledLabeledInputComponent
    {...props}
    id={id}
    label={label}
    description={description}
    hasError={!!error || hasError}
    className={[
      props.className,
      inputProps && inputProps.type === "checkbox" ? "Checkbox" : null,
    ]
      .filter((c) => !!c)
      .join(" ")}
  >
    <InputButtonWrapper>
      <LabeledInputComponentWrapper disabled={inputProps?.disabled || false}>
        <input {...inputProps} ref={inputRef} id={id} name={name} />
      </LabeledInputComponentWrapper>
      <CompactButton {...buttonProps} variant="secondary" />
    </InputButtonWrapper>
    {error ? <ErrorMessage>{error}</ErrorMessage> : null}
  </StyledLabeledInputComponent>
);

export default function LabeledInput({
  id,
  name,
  label,
  description,
  inputProps = null,
  inputRef = null,
  error = null,
  hasError = false,
  ...props
}: LabeledInputProps) {
  return (
    <LabeledInputComponent
      {...props}
      id={id}
      label={label}
      description={description}
      hasError={!!error || hasError}
      className={[
        props.className,
        inputProps && inputProps.type === "checkbox" ? "Checkbox" : null,
      ]
        .filter((c) => !!c)
        .join(" ")}
    >
      <input {...inputProps} ref={inputRef} id={id} name={name} />
      {error ? <ErrorMessage>{error}</ErrorMessage> : null}
    </LabeledInputComponent>
  );
}

export const LabeledTextArea = ({
  name,
  label,
  description,
  inputProps,
  ...props
}: {
  name: string;
  label: string;
  description?: ReactNode;
  inputProps: TextareaHTMLAttributes<HTMLTextAreaElement> | null;
}) => (
  <LabeledInputComponent
    {...props}
    label={label}
    id={name}
    description={description}
  >
    <textarea {...inputProps} name={name} />
  </LabeledInputComponent>
);
