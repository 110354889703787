import { MarkEmailRead } from "@styled-icons/material-outlined/MarkEmailRead";
import { HTMLAttributes, ReactNode } from "react";
import styled from "styled-components";
import FormGroup from "../../forms/FormGroup";
import { Verified } from "@styled-icons/material-outlined/Verified";
import { Build } from "@styled-icons/material-outlined/Build";
import { Mail } from "@styled-icons/material-outlined/Mail";
import { onDesktop } from "@naf/teamscheme-core";
import carImage from "./volvo-car_1683x999.png";

const HighlightedConsent = styled.div`
    margin-bottom: ${({ theme }) => theme.spacing.space32};

    ${FormGroup} {
        margin-bottom: 0;
    }
`;
const Content = styled.div`
  background: white;
  border: 1px solid ${({ theme }) => theme.nafColor.neutral.neutral2};
  border-bottom-right-radius: ${({ theme }) => theme.radius.m};
  border-bottom-left-radius: ${({ theme }) => theme.radius.m};
  padding: ${({ theme }) => `${theme.spacing.space16}`};
  ${onDesktop`
    padding: ${({
      theme: {
        spacing: { space24, space32 },
      },
    }) => `${space24} ${space32}`};
  `}
`;
const TitleIcon = styled(MarkEmailRead)``;
const Title = styled.div`
  background: ${({ theme }) => theme.nafColor.primary.dew};

  border-left: 1px solid ${({ theme }) => theme.nafColor.primary.moss};
  border-right: 1px solid ${({ theme }) => theme.nafColor.primary.moss};
  border-top: 1px solid ${({ theme }) => theme.nafColor.primary.moss};
  border-top-right-radius: ${({ theme }) => theme.radius.m};
  border-top-left-radius: ${({ theme }) => theme.radius.m};

  padding: ${({ theme }) => `${theme.spacing.space16}`};
  ${onDesktop`
    padding: ${({
      theme: {
        spacing: { space24, space32 },
      },
    }) => `${space24} ${space32}`};
  `}
  h4 {
    margin: 0;
    font-weight: normal;
  }

  display: flex;
  gap: ${({ theme }) => theme.spacing.space16};
  align-items: center;
  ${TitleIcon} {
    flex: 0 0 24px;
  }
`;

const Banner = styled.div`
    padding: ${({ theme }) => theme.spacing.space56} ${({ theme }) =>
      theme.spacing.space24};
    position: relative;
`;
const Img = styled.img`
  width: 100%;
  height: auto;
  max-width: 240px;
  height: auto;
  display: block;
  margin: 0 auto ${({ theme }) => theme.spacing.space16} auto;

  /* Slightly off-center to fit with dialog bubbles */
  transform: translateX(-30px);
`;

export function HightlightedConsentBox({
  children,
  checked,
}: { children: ReactNode; checked: boolean }) {
  return (
    <HighlightedConsent>
      <Title>
        <TitleIcon size={24} />
        <h4>Motta nyttig informasjon om å eie, kjøpe og selge bil</h4>
      </Title>
      <Content>
        <Banner>
          <Img src={carImage} width={1683} height={999} alt="" />
          <Bubbles>
            <BubblesContent>
              <Bubble
                highlight={checked}
                icon={<Verified size={20} />}
                label="Rabatt til bilen"
              />
              <Bubble
                highlight={checked}
                icon={<Build size={20} />}
                label="Tips om verksted"
              />
              <Bubble
                highlight={checked}
                icon={<Mail size={20} />}
                label="Nyttige råd"
              />
            </BubblesContent>
          </Bubbles>
        </Banner>
        {children}
      </Content>
    </HighlightedConsent>
  );
}

const BubbleIcon = styled.div`
    svg {
        width: ${({ theme }) => theme.fontStyle.bodyText.small["font-size"]};
    }

    @media screen and (min-width: 400px) {
        svg {
            width: unset;
        }
    }
`;
const BubbleLabel = styled.div`
    ${({ theme }) => theme.fontStyle.bodyText.small}

    @media screen and (min-width: 400px) {
        ${({ theme }) => theme.fontStyle.bodyText.bodyText}
    }
`;
const Bubble = styled(function ({
  icon,
  label,
  highlight: _,
  ...props
}: {
  icon: ReactNode;
  label: ReactNode;
  highlight: boolean;
} & Omit<HTMLAttributes<HTMLDivElement>, "children">) {
  return (
    <div {...props}>
      <BubbleIcon>{icon}</BubbleIcon>
      <BubbleLabel>{label}</BubbleLabel>
    </div>
  );
})`
    transition: background-color 0.2s ease;
    background: ${({ theme: { nafColor }, highlight }) =>
      highlight ? nafColor.primary.dew : nafColor.neutral.neutral1};
    display: inline-block;
    padding: ${({ theme }) => theme.spacing.space12} ${({ theme }) =>
      theme.spacing.space16};
    border-radius: ${({ theme }) => theme.radius.l};

    position: absolute;

    &:after {
        display: block;
        border: solid transparent;
        content: "";
        height: 0;
        width: 0;
        position: absolute;
        border-width: 14px;
        border-color: ${({ theme: { nafColor }, highlight }) =>
          highlight ? nafColor.primary.dew : nafColor.neutral.neutral1};
    }
`;

const BubblesContent = styled.div`
    position: relative;
    max-width: 400px;
    margin: 0 auto;
    width: 100%;
    height: 100%;

    ${Bubble}:nth-child(1) {
        top: 0;
        left: 0;


        &:after {
            right: 4px;
            bottom: -26px;
            border-left-color: transparent;
            border-right-color: transparent;
            border-bottom-color: transparent;
        }
    }

    ${Bubble}:nth-child(2) {
        top: 20px;
        right: 0;

        &:after {
            left: 4px;
            bottom: -26px;
            border-left-color: transparent;
            border-right-color: transparent;
            border-bottom-color: transparent;
        }
    }

    ${Bubble}:nth-child(3) {
        bottom: 0;
        left: 20px;

        &:after {
            right: 4px;
            top: -26px;
            border-left-color: transparent;
            border-right-color: transparent;
            border-top-color: transparent;
        }
    }
`;
const Bubbles = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    margin-bottom: ${({ theme }) => theme.spacing.space16};
`;
