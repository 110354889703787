import { Button } from "@naf/teamscheme";
import ErrorMessage from "../../../shared/error/ErrorMessage";
import { CustomerViewModel } from "../../../shared/models/CustomerViewModel";
import { MemberContractBenefitsList } from "../../form/select-type/MemberContractPage";
import { useMembershipContractClient } from "../MembershipContractClient";
import { ModalContentProps } from "./ModalContentProps";
import { SignatureExistErrorMessage } from "../SignatureExistErrorMessage";

function MembershipUpgradeContent({ closeModal }: ModalContentProps) {
  const membershipUpgrade = useMembershipContractClient();

  return (
    <>
      <MemberContractBenefitsList />
      <Button
        variant="primary"
        isLoading={membershipUpgrade.isLoading}
        onClick={async () => {
          if (!membershipUpgrade.isActive) {
            const wasUpgraded = await membershipUpgrade.activate();
            if (wasUpgraded) {
              closeModal();
            }
          }
        }}
      >
        Oppgrader kontrakten med medlemskap
      </Button>
      {membershipUpgrade.error ? (
        membershipUpgrade.error.status === 409 &&
        "signatureExist" in (membershipUpgrade.error.result as object) ? (
          <SignatureExistErrorMessage />
        ) : (
          <ErrorMessage>En feil har opptsått! Prøv igjen.</ErrorMessage>
        )
      ) : null}
    </>
  );
}

export function CustomerMembershipUpgrade({
  closeModal,
  customer,
}: ModalContentProps & { customer: CustomerViewModel }) {
  return (
    <div>
      <p>
        Du er logget inn med medlemsnummer {customer.customerNumber}. Nå har du
        tilgang til:
      </p>
      <MembershipUpgradeContent closeModal={closeModal} />
    </div>
  );
}

export function CompletedMembershipUpgrade({
  customerId,
}: {
  customerId?: string;
}) {
  return (
    <div>
      <p>
        Kontrakten er oppgradert{" "}
        {customerId
          ? `med medlemsnummer ${customerId}`
          : "med et NAF-medlemskap"}
        . Nå har du tilgang til:
      </p>
      <MemberContractBenefitsList />
    </div>
  );
}
