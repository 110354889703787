import type { Location, LocationDescriptor } from "@naf/teamscheme";
import { useContext } from "react";
import { useLocation } from "react-router";
import { ContractContext } from "../contract/ContractContext";
import { type SalesContractReferenceViewModel } from "../payments/PaymentViewModel";
import { AppPages } from "../routing/AppPages";
import { getAbsolutePath } from "../routing/AppRoutes";
import { RETURN_PATH_PARAM } from "../shared/utils/Location";
import { BACK_PATH_PARAM } from "../contract/contract-type/contract-upgrade/locationParams";
import { type ContractToken } from "../contract/model/ContractViewModel";
import { getContractToken } from "../contract/ContractParams";

export function getBecomeMemberSearchParams(
  returnTo?: string,
  contractToken?: ContractToken,
  backTo?: string,
) {
  const params = new URLSearchParams();
  if (returnTo) params.set(RETURN_PATH_PARAM, returnTo);
  if (backTo) params.set(BACK_PATH_PARAM, backTo);
  if (contractToken) params.set("token", contractToken);
  return params;
}

export function createBecomeMemberPath(
  returnTo?: string,
  reportToken?: ContractToken,
  backTo?: string,
): LocationDescriptor<void> {
  const params = getBecomeMemberSearchParams(returnTo, reportToken, backTo);
  return `${getAbsolutePath(AppPages.BecomeMember)}?${params.toString()}`;
}

export function createBecomeMemberFormPath(
  productNumber: string,
  returnTo?: string,
  contractToken?: ContractToken,
  backTo?: string,
): LocationDescriptor<void> {
  const params = getBecomeMemberSearchParams(returnTo, contractToken, backTo);
  return `${getAbsolutePath(AppPages.BecomeMemberForm, {
    ":productNumber": productNumber,
  })}?${params.toString()}`;
}

export function useBecomeMemberFormPath(
  productNumber: string,
  returnTo?: string,
  backTo?: string,
): LocationDescriptor<void> {
  const location = useLocation();
  const contractContext = useContext(ContractContext);
  const token = contractContext
    ? getContractToken(contractContext.contract)
    : undefined;
  return createBecomeMemberFormPath(
    productNumber,
    returnTo || location.pathname,
    token || undefined,
    backTo,
  );
}
export function useBecomeMemberPath(
  returnTo?: string,
): LocationDescriptor<void> {
  const location = useLocation();
  const contractContext = useContext(ContractContext);
  const token = contractContext
    ? getContractToken(contractContext.contract)
    : undefined;
  return createBecomeMemberPath(
    returnTo || location.pathname,
    token || undefined,
  );
}

export interface ReturnPathParameters {
  returnPath: string;
  contractToken?: ContractToken;
}

export function createReturnLocation(
  parameters: ReturnPathParameters | SalesContractReferenceViewModel,
) {
  const tokenValue =
    "token" in parameters
      ? parameters.token
      : parameters.contractToken
        ? parameters.contractToken
        : null;
  const returnPath = parameters.returnPath;
  return tokenValue ? `${returnPath}?token=${tokenValue}` : returnPath;
}

export function useBecomeMemberSearchParams<T>(location: Location<T>) {
  const search = new URLSearchParams(location.search);
  const returnPath = search.get(RETURN_PATH_PARAM);
  const backPath = search.get("backTo");
  const contractToken = search.get("token") as ContractToken | undefined;
  return {
    backPath,
    returnPath,
    contractToken,
  };
}
