import { type ReactNode } from "react";
import { type QueryResult } from "./QueryResult";
import LoadingOverlay from "../components/LoadingOverlay";
import { Button } from "@blueprintjs/core";
import { AdminErrorMessage } from "../shared/AdminErrorMessage";

export function CompactQueryResultView<T>({
  queryPage: { error, loading, loaded, data, refresh },
  children,
}: {
  queryPage: QueryResult<T>;
  children: () => ReactNode;
}): JSX.Element | null {
  if (error) {
    return (
      <span>
        <AdminErrorMessage>
          {error.message || error.toString()}
        </AdminErrorMessage>
        <Button type="button" onClick={refresh}>
          Retry
        </Button>
      </span>
    );
  }

  if (loading && !data) {
    return <LoadingOverlay loading={loading} />;
  }

  if (!loaded) return null;

  if (!data) {
    return <span>Ingen data!</span>;
  }

  const result = children();

  return result ? (result as JSX.Element) : null;
}
