import { ContractType } from "#vehicle-contract/common/lib/model/contract/SalesContract";
import { PACKAGE_NAMES } from "./ComparisonTable";
import { Button, NokPrice, type LocationDescriptor } from "@naf/teamscheme";
import Banner from "../../../shared/elements/Banner";
import styled from "styled-components";
import { EVENTS } from "../../../Tracking";
import TrialMembershipInfoModalTextLink from "../trial-membership/TrialMembershipInfoModalTextLink";
import { PackageType } from "./PackageType";
import { PRICES } from "./PRICES";

const SelectButton = styled(Button)`
  margin-top: ${({ theme }) => theme.spacing.space8};
`;
const ProductBanner = styled(Banner).attrs({ variant: "yellow" })`
  p {
    margin: ${({ theme }) => theme.spacing.space8} 0;
  }
`;
const LargePrice = styled(NokPrice)`
  ${({ theme }) => theme.fontStyle.headers.header1}
`;
export function SelectedContractTypeBanner({
  contractType,
  links,
}: {
  contractType: PackageType;
  links: {
    [key in PackageType]: LocationDescriptor<unknown>;
  };
}) {
  return (
    <ProductBanner variant="yellow">
      <div>
        <p>Du har valgt {PACKAGE_NAMES[contractType]}.</p>
        <p>
          <LargePrice value={PRICES[contractType].amount} />
        </p>
        {contractType === ContractType.Premium && (
          <p>
            Inkl. Prøvemedlemskap med veihjelp i 30 dager.{" "}
            <TrialMembershipInfoModalTextLink />
          </p>
        )}
        {contractType === ContractType.Simple && (
          <p>NAF-medlemmer får Premium uten ekstra kostnad.</p>
        )}
        {contractType === ContractType.Member && (
          <p>Medlemskap i 12 måneder, og gir tilgang til Premium. </p>
        )}
        <SelectButton
          type="submit"
          variant="primary"
          linkTo={links[contractType]}
          trackEvent={{
            ...EVENTS.CONTRACT_TYPES.SELECT_OPTION,
            label: `Velg ${PACKAGE_NAMES[contractType]}`,
            value: PACKAGE_NAMES[contractType],
          }}
        >
          Velg {PACKAGE_NAMES[contractType]}
        </SelectButton>
      </div>
    </ProductBanner>
  );
}
