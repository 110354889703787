import { useCallback, useEffect, useState } from "react";
import { Cancelable, makeCancelable } from "../utils";
import { ApiError } from "./ApiClient";

export interface ClientBase {
  isLoading: boolean;
  error: ApiError | null;
}

export interface ResultClient<T> extends ClientBase {
  result: T | null;
}

export interface ActionClient<TRequest, TResult> extends ResultClient<TResult> {
  fetch(request: TRequest): Cancelable<TResult>;
}

export function useClientBase<TRequest, TResult>(
  load: (request: TRequest) => Promise<TResult>,
  initialLoadingState = false,
): ActionClient<TRequest, TResult> {
  const [isLoading, setIsLoading] = useState(initialLoadingState);
  const [error, setError] = useState<ApiError | null>(null);
  const [result, setResult] = useState<TResult | null>(null);
  const fetch = useCallback(
    function (request: TRequest) {
      async function fetchInner(cancelable: Cancelable<TResult>) {
        setIsLoading(true);
        try {
          const result = await cancelable.promise;
          setResult(result);
          setError(null);
        } catch (error) {
          if (!cancelable.isCanceled) {
            setError(error as ApiError);
          }
        } finally {
          if (!cancelable.isCanceled) {
            setIsLoading(false);
          }
        }
      }
      const promise = load(request);
      const cancelable = makeCancelable(promise);
      fetchInner(cancelable);
      return cancelable;
    },
    [load],
  );
  return {
    isLoading,
    error,
    result,
    fetch,
  };
}

export function useAutoLoadingClientBase<T>(
  load: () => Promise<T>,
): ActionClient<void, T> {
  const base = useClientBase<void, T>(load, true);

  const { fetch } = base;

  useEffect(() => {
    const cancelable = fetch();

    return () => cancelable?.cancel();
  }, [fetch]);

  return base;
}
