import { Accordion, AccordionRow } from "@naf/teamscheme";
import { useEffect, useMemo, useState } from "react";
import { Schema } from "#vehicle-contract/common/lib/common";
import { EVENTS } from "../../Tracking";
import CardSection from "../../formatting/CardSection";
import PageCard from "../../formatting/PageCard";
import FormGroup from "../../forms/FormGroup";
import { searchHTML, useDebouncedQuery } from "../../shared/Filter";
import { FilterField } from "../../shared/FilterField";
import NoResultsPlaceholder from "../../shared/NoResultsPlaceholder";
import { useSchema } from "../ContractContext";

const BLACKLIST = [
  { sectionType: "transactionInfo", field: "method" }, // Deprecated
  { sectionType: "contractingParty", field: "nationalIdentityNumber" }, // Not in PDF
  { sectionType: "coOwner", field: "nationalIdentityNumber" }, // Not in PDF
];
function getExplainedSections(schema: Schema) {
  const { sectionTypes } = schema.text;
  const explainedSections = Object.keys(sectionTypes)
    .map((name) => ({ name, type: sectionTypes[name] }))
    .map((sectionType) => {
      const type = sectionType.type;

      const fields = Object.keys(type.fields)
        .map((key) => ({
          key,
          ...type.fields[key],
        }))
        .filter(
          ({ key }) =>
            !BLACKLIST.find(
              (x) => x.sectionType === sectionType.name && x.field === key,
            ),
        )
        .filter(({ description }) => description);

      return { name: sectionType.name, label: type.label, fields };
    })
    .filter(({ fields }) => fields.length);

  return explainedSections;
}
export function ContractExplanationCard() {
  const { clear, query, inputProps } = useDebouncedQuery({
    queryEvent: EVENTS.CONTRACT_EXPLANATION_MODAL.FILTER_FIELD_DESCRIPTIONS,
  });

  const schema = useSchema();

  const explainedSections = useMemo(
    () => getExplainedSections(schema),
    [schema],
  );

  const [displaySections, setDisplaySections] = useState(explainedSections);

  useEffect(() => {
    setDisplaySections(
      !query
        ? explainedSections
        : explainedSections
            .map(({ fields, ...section }) => {
              return {
                ...section,
                fields: fields
                  .filter(({ description }) => description)
                  .map(({ description, label, ...field }) => ({
                    ...field,
                    description,
                    label,
                    descriptionMatch:
                      description && searchHTML(description, query),
                    labelMatch: searchHTML(label, query),
                  }))
                  .filter(
                    ({ descriptionMatch, labelMatch }) =>
                      descriptionMatch || labelMatch,
                  )
                  .map(
                    ({
                      descriptionMatch,
                      labelMatch,
                      description,
                      label,
                      ...field
                    }) => ({
                      ...field,
                      description: descriptionMatch || description,
                      label: labelMatch || label,
                    }),
                  ),
              };
            })
            .filter(({ fields }) => fields.length),
    );
  }, [query, explainedSections]);

  return (
    <PageCard>
      <CardSection title="Hva betyr feltene i kontrakten?">
        <FormGroup>
          <FilterField label="Søk i feltene" inputProps={inputProps} />
        </FormGroup>
        <Accordion>
          {displaySections.map(({ name, label, fields }) => (
            <AccordionRow
              id={name}
              title={label}
              key={name}
              open={!!query}
              openEvent={{
                ...EVENTS.CONTRACT_EXPLANATION_MODAL.OPEN_CATEGORY,
                action: `Open ${label}`,
              }}
            >
              {fields.map(({ key, label, description }) => (
                <div key={key}>
                  {/* biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation> */}
                  <h4 dangerouslySetInnerHTML={{ __html: label }} />
                  {description ? (
                    // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
                    <div dangerouslySetInnerHTML={{ __html: description }} />
                  ) : null}
                </div>
              ))}
            </AccordionRow>
          ))}
          {query && !displaySections.length ? (
            <NoResultsPlaceholder query={query} clear={clear} />
          ) : null}
        </Accordion>
      </CardSection>
    </PageCard>
  );
}
