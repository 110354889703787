import { onDesktop } from "@naf/teamscheme-core";
import { HTMLAttributes } from "react";
import styled from "styled-components";
import BankIdLogo from "../shared/logos/BankIdLogo";
import { GjensidigeLogo } from "../shared/logos/GjensidigeLogo";
import SvvLogo from "../shared/logos/SvvLogo";
import VippsLogo from "../shared/logos/VippsLogo";

const LogoWrapper = styled.div``;

const Logos = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.space32};

  flex-wrap: wrap;
  ${onDesktop`
    flex-wrap: nowrap;
  `}

  img {
    ${onDesktop`
      width: ${8 * 18}px;
    `};
    display: block;
    width: ${8 * 12}px;
    height: auto;
  }
`;

const PremiumLogos = styled(function PremiumLogos(
  props: HTMLAttributes<HTMLDivElement>,
) {
  return (
    <Logos {...props}>
      <LogoWrapper>
        <BankIdLogo />
      </LogoWrapper>
      <LogoWrapper>
        <GjensidigeLogo />
      </LogoWrapper>
      <LogoWrapper>
        <VippsLogo />
      </LogoWrapper>
      <LogoWrapper>
        <SvvLogo />
      </LogoWrapper>
    </Logos>
  );
})`
  margin-bottom: ${({ theme }) => theme.spacing.space16};
`;

export const PartnerSection = styled(function (
  props: Omit<HTMLAttributes<HTMLDivElement>, "children">,
) {
  return (
    <div {...props}>
      <h2>Vi samarbeider med</h2>
      <PremiumLogos />
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.space32};
`;
