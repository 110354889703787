import { Navigate } from "react-router-dom";
import { addLocationObjectQueryParam } from "../shared/utils/Location";
import { type UnauthorizedContractErrorResult } from "./UnauthorizedContractErrorResult";
import { NotFoundScreen } from "../shared/error/NotFoundScreen";
import MissingAuthenticatedUserPage from "./auth/MissingAuthenticatedUserPage";
import WrongAuthenticatedUserPage from "./auth/WrongAuthenticatedUserPage";
import NafIdOnboardingPage from "./nafid-onboarding/NafIdOnboardingPage";
import { ContractToken } from "./model/ContractViewModel";

export function UnauthorizedScreen({
  token,
  result,
  refetchContract,
}: {
  result: UnauthorizedContractErrorResult;
  token: ContractToken;
  refetchContract: () => void;
}) {
  if (result.unacceptedInvitation) {
    const pageRedirect = addLocationObjectQueryParam(
      { pathname: "/invitasjon" },
      new URLSearchParams({
        token: token,
      }),
    );
    return <Navigate to={pageRedirect} />;
  }
  if (result.wrongAuthenticatedUser) {
    return <WrongAuthenticatedUserPage token={token} />;
  }
  if (result.missingAuthenticatedUser) {
    return <MissingAuthenticatedUserPage token={token} />;
  }
  if (result.noReferencedUser) {
    return (
      <NafIdOnboardingPage token={token} refetchContract={refetchContract} />
    );
  }

  return <NotFoundScreen />;
}
