import { LoadingText } from "@naf/teamscheme";
import React, { type HTMLAttributes, forwardRef } from "react";
import styled from "styled-components";
import { Placeholder } from "./Placeholder";

export const DocumentPreviewContainer = styled(
  forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
    function (props, ref) {
      return <div ref={ref} {...props} />;
    },
  ),
)`
  position: relative;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: ${({ theme }) => theme.nafColor.signature.white};
`;

export function DocumentPreviewPlaceholder() {
  return (
    <DocumentPreviewContainer>
      <Placeholder>
        <LoadingText text="Henter forhåndsvisning" />
      </Placeholder>
    </DocumentPreviewContainer>
  );
}
