import { getContractLocation } from "../routing/ContractUrls";
import { type TargetState } from "../routing/ReturnLocation";
import type { Location, LocationDescriptorObject } from "@naf/teamscheme";
import {
  RETURN_PATH_PARAM,
  addLocationObjectQueryParam,
} from "../../shared/utils/Location";
import { AppPages } from "../../routing/AppPages";
import { type ContractToken } from "../model/ContractViewModel";

export function getContractLoginCallbackPageLocation<T>(
  location: Location<T>,
  token: ContractToken,
): LocationDescriptorObject<TargetState<T>> {
  const { pathname: returnPath, state: targetState } = location;
  const state: TargetState<T> = { targetState };
  const loginCallback = getContractLocation(
    AppPages.ContractLoginCallback,
    token,
  );

  const withReturnPath = addLocationObjectQueryParam(
    loginCallback,
    new URLSearchParams({ [RETURN_PATH_PARAM]: returnPath }),
  );

  const withTargetState = { ...withReturnPath, state };

  return withTargetState;
}
