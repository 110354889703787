import { type HTMLAttributes } from "react";
import { type CustomerViewModel } from "../../../shared/models/CustomerViewModel";
import styled from "styled-components";
import { convertToGenderEnum } from "../../../membership/GenderEnumConversion";
import { genderOptions } from "../../../membership/genderOptions";
import { formatDate } from "../../../formatting/date";

const ProfileField = styled.div`margin-bottom: ${({ theme }) =>
  theme.spacing.space16};`;
const ProfileFieldLabel = styled.span`display: block; font-weight: bold;`;
const ProfileFieldValue = styled.span`display: block;`;

const profileFields = [
  { key: "email", label: "E-postadresse" },
  { key: "firstName", label: "Fornavn" },
  { key: "lastName", label: "Etternavn" },
  {
    key: "dateOfBirth",
    label: "Fødselsdato",
    format: (dateString: string) => formatDate(dateString),
  },
  { key: "phone", label: "Telefonnummer" },
  { key: "address", label: "Adresse" },
  {
    key: "gender",
    label: "Kjønn",
    format: (gender: string) =>
      genderOptions.find((g) => g.value === convertToGenderEnum(gender))?.label,
  },
] as const;

type ProfileFieldType = (typeof profileFields)[number]["key"];
export type ProfileViewModel = Pick<CustomerViewModel, ProfileFieldType>;

export const Profile = styled(function ({
  customer,
  ...props
}: {
  customer: ProfileViewModel;
} & Omit<HTMLAttributes<HTMLDivElement>, "children">) {
  return (
    <div {...props}>
      {profileFields
        .map(({ key, ...rest }) => ({ key, value: customer[key], ...rest }))
        .filter(({ value }) => value)
        .map((field) => {
          const { key, label, value } = field;
          const formatted =
            value && "format" in field ? field.format(value) : value;
          return (
            <ProfileField key={key} data-key={key}>
              <ProfileFieldLabel>{label}</ProfileFieldLabel>
              <ProfileFieldValue>{formatted}</ProfileFieldValue>
            </ProfileField>
          );
        })}
    </div>
  );
})`
  margin-bottom: ${({ theme }) => theme.spacing.space32};
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  ${ProfileField}[data-key=email] {
    grid-column: 1 / 3;
  }
`;
