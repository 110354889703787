import { LocationDescriptorObject } from "@naf/teamscheme";
import { PathDescription, PathRoutes } from "./PathRoutes";

export function getChildRoutes<TPath extends string>(
  base: LocationDescriptorObject,
  pathRoutes: PathRoutes<TPath>,
) {
  const locations = getChildLocations(base, pathRoutes);
  return getPathRouteEntries(pathRoutes).map(([key, { path, ...rest }]) => ({
    ...rest,
    path: locations[key].pathname,
  }));
}

export function getPathRouteEntries<TPath extends string>(
  pathRoutes: PathRoutes<TPath>,
) {
  const entries = Object.entries(pathRoutes) as [TPath, PathDescription][];
  return entries;
}

export type LocationCollection<TPath extends string> = {
  [key in TPath]: LocationDescriptorObject;
};

export function getChildLocations<TPath extends string>(
  base: LocationDescriptorObject,
  pathRoutes: PathRoutes<TPath>,
): LocationCollection<TPath> {
  const result = getPathRouteEntries(pathRoutes).reduce(
    (acc, [key, { path }]) => {
      const location: LocationDescriptorObject = {
        ...base,
        pathname: `${base.pathname}/${path}`,
      };
      acc[key] = location;
      return acc;
    },
    {} as {
      [key in TPath]: LocationDescriptorObject;
    },
  );
  return result;
}
