import { ReactNode, SelectHTMLAttributes } from "react";
import { LabeledInputComponent } from "./LabeledInputComponent";

export interface LabeledSelectProps {
  onChange(value: string | null): void;
  value: string | null;
  label: string;
  id: string;
  description?: ReactNode;
  options: LabeledSelectOption[];
  topOptions?: LabeledSelectOption[];
  placeholder?: string;
  inputProps?: SelectHTMLAttributes<HTMLSelectElement>;
}

export interface LabeledSelectOption {
  value: string;
  label?: string;
}

export function LabeledSelect({
  onChange,
  value,
  label,
  id,
  description,
  options,
  topOptions,
  placeholder,
  inputProps,
}: LabeledSelectProps) {
  return (
    <LabeledInputComponent id={id} label={label} description={description}>
      <select
        onChange={(event) =>
          onChange(event.target.value === "" ? null : event.target.value)
        }
        value={value === null ? "" : value}
        {...inputProps}
      >
        {placeholder ? <option value="">{placeholder}</option> : null}
        {topOptions ? (
          <>
            <optgroup label="Mest brukt">
              {generateOptions(topOptions)}
            </optgroup>
            <optgroup label="Alle">{generateOptions(options)}</optgroup>
          </>
        ) : (
          generateOptions(options)
        )}
      </select>
    </LabeledInputComponent>
  );
}

function generateOptions(options: LabeledSelectOption[]) {
  return options.map(({ value, label }) => (
    <option key={value} value={value}>
      {label || value}
    </option>
  ));
}
