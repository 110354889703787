import { type ReactNode } from "react";
import { ContractValidationContext } from "./IContractValidationContext";
import { useContractValidationContextClient } from "./useContractValidationContextClient";

export function ContractValidationProvider({
  children,
}: {
  children: ReactNode;
}) {
  const context = useContractValidationContextClient();

  return (
    <ContractValidationContext.Provider value={context}>
      {children}
    </ContractValidationContext.Provider>
  );
}
