import { ContractType } from "#vehicle-contract/common/lib/model/contract/SalesContract";
import { useContract } from "../../ContractContext";

export function useHasUpgraded() {
  const contract = useContract();
  const contractType = contract.contractType?.current;
  const hasUpgrade =
    !!contractType &&
    [ContractType.Premium, ContractType.Member].includes(contractType);

  return hasUpgrade;
}
