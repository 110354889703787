import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { MembershipServiceContactInfo } from "../../../../membership/MembershipServiceContactInfo";
import { usePostalCityClient } from "../../../../membership/PostalCityClient";
import FormGroup from "../../../../forms/FormGroup";
import LabeledBooleanCheckbox from "../../../../forms/LabeledBooleanCheckbox";
import { OnboardingFormTextInput } from "./OnboardingFormTextInput";

export function OnboardingFormAdressFields({
  contactInfo,
  setContactInfo,
  profile,
}: {
  contactInfo: MembershipServiceContactInfo;
  setContactInfo: Dispatch<SetStateAction<MembershipServiceContactInfo>>;
  profile: MembershipServiceContactInfo;
}) {
  const { fetch, isLoading: postalCityLoading } = usePostalCityClient();
  const [hasForeignAdress, setHasForeignAdress] = useState<boolean>();
  const [postalCity, setPostalCity] = useState<string>();
  const [lastSearch, setLastSearch] = useState<string>();

  // Makes sure fetch is triggered when postalCode is _exactly_ 4 digits,
  // and not if it is the same as the previous search
  useEffect(() => {
    async function fetchData(postalCode: string) {
      try {
        const result = await fetch(postalCode).promise;
        if (result.postalCity) {
          setPostalCity(result.postalCity);
        }
      } catch (error) {
        console.log({ error });
      }
    }

    if (contactInfo.postalCode && contactInfo.postalCode !== lastSearch) {
      if (!hasForeignAdress && contactInfo.postalCode.length === 4) {
        fetchData(contactInfo.postalCode);
        setLastSearch(contactInfo.postalCode);
      }
    }
  }, [contactInfo, fetch, hasForeignAdress, lastSearch]);

  //updates the value of postalCity if it changes
  useEffect(() => {
    setContactInfo((x) => ({
      ...x,
      city: postalCity,
    }));
  }, [postalCity, setContactInfo]);

  // restes both city and postalCode if hasForeignAddress is toggled
  useEffect(() => {
    if (hasForeignAdress !== undefined) {
      setContactInfo((x) => ({
        ...x,
        city: undefined,
        postalCode: undefined,
      }));
    }
  }, [hasForeignAdress, setContactInfo]);

  // resets city and last search if postalcode field is empty and you have searched before
  useEffect(() => {
    if (!!lastSearch && !contactInfo.postalCode) {
      setPostalCity(undefined);
      setLastSearch(undefined);
    }
  }, [contactInfo.postalCode, lastSearch]);

  return (
    <>
      {!profile.postalCode ? (
        <FormGroup className="ContactInfoField-wrapper">
          <LabeledBooleanCheckbox
            name="hasForeignAdress"
            value={hasForeignAdress || false}
            onChange={setHasForeignAdress}
            label={<span>Jeg har adresse utenfor Norge</span>}
          />
        </FormGroup>
      ) : null}
      <OnboardingFormTextInput
        name="address"
        label="Adresse"
        placeholder="Din adresse"
        contactInfo={contactInfo}
        setContactInfo={setContactInfo}
        readOnly={!!profile.address}
      />
      <OnboardingFormTextInput
        name="postalCode"
        label="Postnummer"
        placeholder="4 siffer"
        contactInfo={contactInfo}
        setContactInfo={setContactInfo}
        readOnly={!!profile.postalCode}
        disabled={postalCityLoading}
        errorMessage={
          !hasForeignAdress &&
          !(!contactInfo.postalCode || contactInfo.postalCode?.length === 4)
            ? "Postnummer må være 4 siffer"
            : undefined
        }
        pattern={!hasForeignAdress ? "[0-9]{4}" : undefined}
        customType="intString"
        maxLength={!hasForeignAdress ? 4 : undefined}
      />
      <OnboardingFormTextInput
        name="city"
        label="Poststed"
        placeholder="Ditt poststed"
        contactInfo={contactInfo}
        setContactInfo={setContactInfo}
        readOnly={!hasForeignAdress}
      />
    </>
  );
}
