import { Intent, Overlay2, Spinner, SpinnerSize } from "@blueprintjs/core";
import { INTENT_SUCCESS } from "@blueprintjs/core/lib/esm/common/classes";
import { useRef } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

function LoadingOverlay({ loading }: { loading: boolean }) {
  const spinnerRef = useRef<HTMLElement | null>(null);
  return (
    <Overlay2 isOpen={loading} childRef={spinnerRef}>
      <Wrapper
        ref={(ref) => {
          spinnerRef.current = ref;
          if (spinnerRef) spinnerRef.current = ref;
        }}
      >
        <Spinner
          aria-label="Loading..."
          size={SpinnerSize.STANDARD}
          intent={Intent.PRIMARY}
        />
      </Wrapper>
    </Overlay2>
  );
}

export default LoadingOverlay;
