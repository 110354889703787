import { type DateString } from "#vehicle-contract/common/lib/model/common/DateString";
import {
  SalesContract,
  SignatureStatus,
} from "#vehicle-contract/common/lib/model/contract/SalesContract";
import { SigningParty } from "#vehicle-contract/common/lib/model/contract/SigningParty";
import { Icon, Tag } from "@blueprintjs/core";
import DataTable from "../shared/DataTable";
import { LinkToSigningTaskButton } from "./details/LinkToContractButton";
import DateTimeCells from "../shared/DateTimeCells";

function signingPartyName(party: SigningParty) {
  switch (party) {
    case SigningParty.Buyer:
      return "Buyer";
    case SigningParty.BuyerCoOwner:
      return "Buyer Co Owner";
    case SigningParty.Seller:
      return "Seller";
    case SigningParty.SellerCoOwner:
      return "Seller Co Owner";
    default:
      throw new Error(`Unrecognized SigningParty ${party}`);
  }
}

export function isCoOwner(party: SigningParty) {
  switch (party) {
    case SigningParty.Buyer:
    case SigningParty.Seller:
      return false;
    case SigningParty.BuyerCoOwner:
    case SigningParty.SellerCoOwner:
      return true;
    default:
      throw new Error(`Unrecognized SigningParty ${party}`);
  }
}

export function getSigningLinks(contract: SalesContract) {
  if (!contract.signature) return [];
  if (!contract.signature.tasks.length) return [];

  return contract.signature.tasks.reduce(
    (acc, task) => {
      const isSigned = task.signatureStatus === SignatureStatus.Signed;
      const isCurrent = !acc.hasPassed && !isSigned;

      acc.hasPassed = acc.hasPassed || isCurrent;
      acc.rows.push({
        signed: isSigned ? task.updated : undefined,
        isNext: isCurrent,
        party: task.signingParty,
        token: task.token,
      });

      return acc;
    },
    {
      rows: [] as {
        signed?: DateString;
        isNext: boolean;
        party: SigningParty;
        token: string;
      }[],
      hasPassed: false,
    },
  ).rows;
}

export function SigningLinkRows({ contract }: { contract: SalesContract }) {
  return (
    <>
      {getSigningLinks(contract).map((y) => (
        <tr key={y.party}>
          <th>{signingPartyName(y.party)} signature</th>
          {y.signed ? (
            <DateTimeCells value={y.signed} />
          ) : !y.isNext ? (
            <td colSpan={2}>Waiting for previous parties to sign</td>
          ) : (
            <td colSpan={2}>
              <LinkToSigningTaskButton contract={contract} party={y.party} />
            </td>
          )}
        </tr>
      ))}
    </>
  );
}
