import { useLocation } from "react-router-dom";
import { getRoleName } from "../ContractContext";
import { ActionButtonWrapper } from "../../shared/elements/ActionButtonWrapper";
import { Button } from "@naf/teamscheme";
import { loginWithRedirect } from "../../auth/AuthProvider";
import { createLocationPath } from "../../shared/utils/Location";
import { getAbsolutePath } from "../../routing/AppRoutes";
import { TITLE_POSTFIX, useDocumentTitle } from "../../shared/DocumentTitle";
import { useContractUnauthorizedUserClient } from "./ContractUnauthorizedUserClient";
import EmptyHeader from "../../layout/header/EmptyHeader";
import ContractPageCard from "../../formatting/ContractPageCard";
import { TitleSection } from "../../formatting/CardSection";
import { FlowPage } from "../../formatting/Page";
import { AppPages } from "../../routing/AppPages";
import { StaticLoadingScreen } from "../../shared/LoadingScreen";
import StaticErrorScreen from "../../shared/error/StaticErrorScreen";
import { type ContractToken } from "../model/ContractViewModel";

export default function MissingAuthenticatedUserPage({
  token,
}: { token: ContractToken }) {
  const location = useLocation();
  const contractUnauthorizedUserClient =
    useContractUnauthorizedUserClient(token);

  const title = "Logg inn";
  useDocumentTitle(`${title} ${TITLE_POSTFIX}`);

  if (contractUnauthorizedUserClient.isLoading) {
    return <StaticLoadingScreen />;
  }
  if (!token) {
    return <StaticErrorScreen />;
  }

  if (
    !contractUnauthorizedUserClient.result ||
    contractUnauthorizedUserClient.error
  ) {
    return <StaticErrorScreen />;
  }

  const { licensePlateNumber, role } = contractUnauthorizedUserClient.result;

  return (
    <>
      <EmptyHeader
        role={role}
        token={token}
        licensePlateNumber={licensePlateNumber}
      />
      <FlowPage>
        <ContractPageCard>
          <TitleSection title={title}>
            <p>
              {getRoleName(role).TitleCase} i denne kjøpekontrakten
              {licensePlateNumber
                ? `${" "}for ${licensePlateNumber}${" "}`
                : " "}
              er registrert på en bruker i NAF-ID.
              <br />
              <br />
              Vennligst logg inn med riktig bruker for å få tilgang til
              kontrakten eller gå ut av kontrakten.
            </p>
            <ActionButtonWrapper>
              <Button
                variant="outline"
                linkTo={getAbsolutePath(AppPages.LandingPage)}
              >
                Gå ut
              </Button>
              <Button
                variant="secondary"
                onClick={() =>
                  loginWithRedirect({
                    targetUrl: createLocationPath(location),
                  })
                }
              >
                Logg inn
              </Button>
            </ActionButtonWrapper>
          </TitleSection>
        </ContractPageCard>
      </FlowPage>
    </>
  );
}
