import { HTMLAttributes, forwardRef } from "react";
import styled from "styled-components";

export type CardProps = HTMLAttributes<HTMLDivElement>;

const Card = styled(
  forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
    function (props, ref) {
      return <div ref={ref} {...props} />;
    },
  ),
)`
  background-color: ${({ theme }) => theme.nafColor.signature.white};
  box-sizing: border-box;
  margin-bottom: 1em;
  padding: ${({ theme }) => theme.spacing.space16};
  border-radius: ${({ theme }) => theme.radius.s};
`;

export default Card;
