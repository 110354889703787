import { FlyoutModal, ModalProps } from "@naf/teamscheme";
import { ExternalTextLink } from "@naf/teamscheme";
import { ButtonLink } from "@naf/teamscheme";
import { useLocation } from "react-router";
import { Role } from "#vehicle-contract/common/lib/model/contract/Role";
import { EVENTS } from "../../Tracking";
import { loginWithRedirect, useAuth } from "../../auth/AuthProvider";
import { NAF_NO_MITT_NAF_URL } from "../../shared/NafnoUrls";
import { createLocationPath } from "../../shared/utils/Location";
import { useContract, useRole } from "../ContractContext";
import { useSaveContractLocation } from "./SaveContractLocation";
import { LogInButton } from "../auth/LogInButton";
import { LoginFormIngressText } from "../contract-type/contract-upgrade/LogInForm";

export function useIsSaved() {
  const contract = useContract();
  const role = useRole();
  const isSaved = !!contract.customerReferences.find((x) => x.role === role);
  return isSaved;
}

export function SavedContractMessage() {
  const auth = useAuth();
  const location = useLocation();

  if (auth.email) {
    return (
      <p>
        Logg inn som {auth.email} for å finne den igjen på{" "}
        <ExternalTextLink href={NAF_NO_MITT_NAF_URL}>Mitt NAF</ExternalTextLink>
        .
      </p>
    );
  }

  return (
    <>
      <p>Logg inn for å finne den igjen på Mitt NAF.</p>
      <ButtonLink
        variant="secondary"
        trackEvent={EVENTS.COMMON.LOG_IN}
        onClick={() =>
          loginWithRedirect({
            targetUrl: createLocationPath(location),
            locationState: location.state,
          })
        }
      >
        Logg inn
      </ButtonLink>
    </>
  );
}

export function SaveContractIngress() {
  return (
    <p>
      {LoginFormIngressText} Da finner du den igjen ved å logge inn på{" "}
      <ExternalTextLink href={NAF_NO_MITT_NAF_URL}>Mitt NAF</ExternalTextLink>.
    </p>
  );
}

export interface SaveContractModalProps extends Omit<ModalProps, "children"> {}

export default function SaveContractModal({
  isOpen,
  ...props
}: SaveContractModalProps) {
  const isSaved = useIsSaved();
  const role = useRole();
  const contract = useContract();
  const section = role === Role.Buyer ? contract.buyer : contract.seller;
  const email = section?.email;
  const linkTo = useSaveContractLocation();
  return (
    <FlyoutModal isOpen={isOpen} {...props}>
      {isSaved ? (
        <div>
          <h1>Kontrakten er lagret</h1>
          <SavedContractMessage />
        </div>
      ) : (
        <div>
          <h1>Lagre min kontrakt</h1>
          <SaveContractIngress />
          <LogInButton variant="primary" linkTo={linkTo} email={email} />
        </div>
      )}
    </FlyoutModal>
  );
}
