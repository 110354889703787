import { useCallback } from "react";
import { type ConsentPayloadType } from "@dtp/membership-service-types/dist/order/ConsentPayload";
import { api, type ApiError } from "../../api/ApiClient";
import { useAutoLoadingClientBase, useClientBase } from "../../api/ClientBase";
import { type ContractViewModel } from "../model/ContractViewModel";
import { type ConsentViewModel } from "../../shared/models/CustomerViewModel";
import { type MembershipConsent } from "../../membership/consents/useGetMembershipConsents";
import { type DateString } from "@naf/teamscheme";
import { type CustomerReference } from "#vehicle-contract/common/lib/model/contract/CustomerReference";
import { type Role } from "#vehicle-contract/common/lib/model/contract/Role";
import { type Cancelable } from "../../utils";
import { type StringProfileFieldName } from "../../membership/StringProfileFieldName";

export interface OnboardingProfileModel {
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  email?: string;
  gender?: string;
  dateOfBirth?: DateString;
  address?: string;
  postalCode?: string;
  city?: string;
}

export interface OnboardingViewModel {
  profile: OnboardingProfileModel;
  missingProfileFields: StringProfileFieldName[];
  consents: MembershipConsent[];
  missingConsentNumbers: ConsentViewModel[];
  role: Role;
  hasMembership: boolean;
  customerNumber?: string;
  licensePlateNumber?: string;
  customerReferences: CustomerReference[];
}
export interface PostCustomerOnboardingClient {
  fetch(request: OnboardingRequestModel): Cancelable<ContractViewModel>;
  result: ContractViewModel | null;
  error: ApiError | null;
  loading: boolean;
}

export interface OnboardingRequestModel {
  consents: ConsentPayloadType[];
  profile: OnboardingProfileModel;
}

export function useGetCustomerOnboardingClient(token: string | null) {
  return useAutoLoadingClientBase<OnboardingViewModel>(
    useCallback(() => api.get(`customerOnboarding/token/${token}`), [token]),
  );
}

export function usePostCustomerOnboardingClient(
  token: string | null,
): PostCustomerOnboardingClient {
  const { error, isLoading, result, fetch } = useClientBase<
    OnboardingRequestModel,
    ContractViewModel
  >(
    useCallback(
      (request) => api.post(`customerOnboarding/token/${token}`, request),
      [token],
    ),
  );

  return {
    loading: isLoading,
    error,
    result,
    fetch,
  };
}
