import { ReactNode, createContext, useContext } from "react";
import { useAbTracking } from "../../Tracking";
import {
  SelectTypePageVariant,
  contractExperiments,
} from "../../shared/ab-testing/AbTestingSetup";
import { useContract } from "../ContractContext";

type ContractExperimentStringType = keyof typeof contractExperiments;

export interface IAbTestingContext {
  selectTypePage: SelectTypePageVariant;
}

const AbTestingContext = createContext<IAbTestingContext | null>(null);

export function useAbTesting() {
  const context = useContext(AbTestingContext);
  if (!context) {
    throw new Error("No AbTestingContext found!");
  }
  return context;
}

function getContractExperimentVariant(
  experimentSeed: number,
  experimentName: ContractExperimentStringType,
) {
  switch (experimentName) {
    case "SelectTypePage":
      return (
        contractExperiments[experimentName].variants.find(
          (x) => x.range.from <= experimentSeed && experimentSeed < x.range.to,
        ) ?? contractExperiments[experimentName].variants[0]
      );
    default:
      throw new Error(`Cannot find experimentname: ${experimentName}`);
  }
}

function useAbTestSetup(experimentName: keyof typeof contractExperiments) {
  const { experimentSeed } = useContract();
  const experimentVariant = getContractExperimentVariant(
    experimentSeed,
    experimentName,
  );

  useAbTracking(experimentName, experimentVariant.name);

  return experimentVariant;
}

export function AbTestingProvider({ children }: { children: ReactNode }) {
  //SelectTypePage AB-Test
  const selectTypePageVariant = useAbTestSetup("SelectTypePage");

  //New AB-Test here:

  const value = {
    selectTypePage: selectTypePageVariant.name,
  };

  return (
    <AbTestingContext.Provider value={value}>
      {children}
    </AbTestingContext.Provider>
  );
}
