import { onDesktop } from "@naf/teamscheme-core";
import styled from "styled-components";

export const ActionButtonWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing.space16};
  ${onDesktop`
    flex-direction: row;
    align-items: center;
  `};
`;
