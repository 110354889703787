import {
  membershipPrice,
  typeCardNonMemberPremiumPrice,
  typeCardNonMemberSimplePrice,
} from "../type-card-price/TypeCardPriceArray";
import { ContractType } from "#vehicle-contract/common/lib/model/contract/SalesContract";
import { PackageType } from "./PackageType";

export const PRICES: {
  [key in PackageType]: { amount: number };
} = {
  [ContractType.Simple]: { amount: typeCardNonMemberSimplePrice.price },
  [ContractType.Premium]: { amount: typeCardNonMemberPremiumPrice.price },
  [ContractType.Member]: { amount: membershipPrice.price },
};
