import { RouteObject } from "react-router-dom";
import { AdminLoadingPage } from "./AdminLoadingPage";

export const adminRoutes: RouteObject = {
  path: "admin",
  lazy: async () => {
    const { AdminWrapper } = await import("./AdminWrapper");
    return { Component: AdminWrapper };
  },
  HydrateFallback: AdminLoadingPage,
  children: [
    {
      path: "login",
      lazy: async () => {
        const { AdminLogin } = await import("./auth/AdminLogin");
        return { Component: AdminLogin };
      },
    },
    {
      lazy: async () => {
        const { AdminProtectedRoutes } = await import("./AdminProtectedRoutes");
        return { Component: AdminProtectedRoutes };
      },
      children: [
        {
          index: true,
          lazy: async () => {
            const { AdminLandingPage } = await import(
              "./pages/AdminLandingPage"
            );
            return { Component: AdminLandingPage };
          },
        },
        {
          path: "tools",
          lazy: async () => {
            const { ToolsPage } = await import("./pages/tools/ToolsPage");
            return { Component: ToolsPage };
          },
          children: [
            {
              path: ":tabId",
              lazy: async () => {
                const { ToolsPage } = await import("./pages/tools/ToolsPage");
                return { Component: ToolsPage };
              },
            },
          ],
        },
        {
          path: "lookup/token/:token",
          lazy: async () => {
            const { LookupContractByToken } = await import(
              "./pages/contracts/LookupContractByToken"
            );
            return { Component: LookupContractByToken };
          },
        },
        {
          path: "query",
          children: [
            {
              path: "byCustomerReference",
              lazy: async () => {
                const { CustomerReferenceSearchPage } = await import(
                  "./pages/contracts/CustomerReferenceSearchPage"
                );
                return { Component: CustomerReferenceSearchPage };
              },
            },
            {
              path: "byLicensePlateNumber",
              lazy: async () => {
                const { LicensePlateNumberSearchPage } = await import(
                  "./pages/contracts/LicensePlateNumberSearchPage"
                );
                return { Component: LicensePlateNumberSearchPage };
              },
            },
            {
              path: "details/:id",
              lazy: async () => {
                const { ContractsDetailsPageWrapper } = await import(
                  "./contract-details/ContractsDetailsPageWrapper"
                );
                return { Component: ContractsDetailsPageWrapper };
              },
              children: [
                {
                  path: "open/:role",
                  lazy: async () => {
                    const { OpenContract } = await import(
                      "./contract-details/OpenContract"
                    );
                    return { Component: OpenContract };
                  },
                },
                {
                  path: ":tabId",
                  lazy: async () => {
                    const { ContractDetailsPage } = await import(
                      "./contract-details/ContractDetailsPage"
                    );
                    return { Component: ContractDetailsPage };
                  },
                },
                {
                  index: true,
                  lazy: async () => {
                    const { ContractDetailsPage } = await import(
                      "./contract-details/ContractDetailsPage"
                    );
                    return { Component: ContractDetailsPage };
                  },
                },
              ],
            },
            {
              index: true,
              lazy: async () => {
                const { default: ContractListPage } = await import(
                  "./pages/contracts/ContractListPage"
                );
                return { Component: ContractListPage };
              },
            },
          ],
        },
        {
          path: "details/:id",
          lazy: async () => {
            const { ContractsDetailsPageWrapper } = await import(
              "./contract-details/ContractsDetailsPageWrapper"
            );
            return { Component: ContractsDetailsPageWrapper };
          },
          children: [
            {
              path: "open/:role",
              lazy: async () => {
                const { OpenContract } = await import(
                  "./contract-details/OpenContract"
                );
                return { Component: OpenContract };
              },
            },
            {
              path: ":tabId",
              lazy: async () => {
                const { ContractDetailsPage } = await import(
                  "./contract-details/ContractDetailsPage"
                );
                return { Component: ContractDetailsPage };
              },
            },
            {
              index: true,
              lazy: async () => {
                const { ContractDetailsPage } = await import(
                  "./contract-details/ContractDetailsPage"
                );
                return { Component: ContractDetailsPage };
              },
            },
          ],
        },
        {
          path: "payment",
          children: [
            {
              index: true,
              lazy: async () => {
                const { default: PaymentListPage } = await import(
                  "./pages/payments/PaymentListPage"
                );
                return { Component: PaymentListPage };
              },
            },
            {
              path: "query",
              lazy: async () => {
                const { default: PaymentListPage } = await import(
                  "./pages/payments/PaymentListPage"
                );
                return { Component: PaymentListPage };
              },
            },
            {
              path: "recurring/query",
              lazy: async () => {
                const { default: RecurringPaymentListPage } = await import(
                  "./pages/RecurringPaymentListPage"
                );
                return { Component: RecurringPaymentListPage };
              },
            },
            {
              path: ":paymentId",
              lazy: async () => {
                const { default: PaymentPage } = await import(
                  "./pages/payments/PaymentPage"
                );
                return { Component: PaymentPage };
              },
            },
          ],
        },
        {
          path: "buyerCarInsurance",
          lazy: async () => {
            const { default: BuyerCarInsuranceListPage } = await import(
              "./pages/BuyerCarInsuranceListPage"
            );
            return { Component: BuyerCarInsuranceListPage };
          },
        },
        {
          path: "aggregate",
          children: [
            {
              index: true,
              lazy: async () => {
                const { AggregatePage } = await import(
                  "./aggregate/AggregatePage"
                );
                return { Component: AggregatePage };
              },
            },
            {
              path: ":tabId",
              lazy: async () => {
                const { AggregatePage } = await import(
                  "./aggregate/AggregatePage"
                );
                return { Component: AggregatePage };
              },
            },
            {
              path: "counter/:counter",
              lazy: async () => {
                const { AggregateRangePage } = await import(
                  "./aggregate/AggregateRangePage"
                );
                return { Component: AggregateRangePage };
              },
            },
          ],
        },
        {
          path: "messages",
          lazy: async () => {
            const { default: QueryMessagesPage } = await import(
              "./contract-details/QueryMessagesPage"
            );
            return { Component: QueryMessagesPage };
          },
        },
        {
          path: "nudges",
          lazy: async () => {
            const { QueryNudgesPage } = await import("./pages/QueryNudgesPage");
            return { Component: QueryNudgesPage };
          },
        },
        {
          path: "audit",
          lazy: async () => {
            const { default: AuditLogPage } = await import(
              "./pages/AuditLogPage"
            );
            return { Component: AuditLogPage };
          },
        },
        {
          path: "export",
          lazy: async () => {
            const { default: ExportPage } = await import("./pages/ExportPage");
            return { Component: ExportPage };
          },
        },
        {
          path: "externalTests",
          lazy: async () => {
            const { default: ExternalTestsListPage } = await import(
              "./pages/external-tests/ExternalTestsListPage"
            );
            return { Component: ExternalTestsListPage };
          },
        },
        {
          path: "ownershipTransfer",
          lazy: async () => {
            const { default: OwnershipTransferListPage } = await import(
              "./pages/OwnershipTransferListPage"
            );
            return { Component: OwnershipTransferListPage };
          },
        },
        {
          path: "archive",
          lazy: async () => {
            const { default: ArchivePage } = await import(
              "./pages/ArchivePage"
            );
            return { Component: ArchivePage };
          },
        },
        {
          path: "alerts",
          lazy: async () => {
            const { default: AdminAlertsPage } = await import(
              "./pages/AdminAlertsPage"
            );
            return { Component: AdminAlertsPage };
          },
        },
        {
          path: "pollingJobs",
          children: [
            {
              index: true,
              lazy: async () => {
                const { default: PollingJobsListPage } = await import(
                  "./pages/polling-jobs/PollingJobsListPage"
                );
                return { Component: PollingJobsListPage };
              },
            },
            {
              path: ":id",
              lazy: async () => {
                const { default: PollingJobDetailsPage } = await import(
                  "./pages/polling-jobs/PollingJobDetailsPage"
                );
                return { Component: PollingJobDetailsPage };
              },
            },
          ],
        },
        {
          path: "payment-request",
          children: [
            {
              index: true,
              lazy: async () => {
                const { PaymentRequestListPage } = await import(
                  "./pages/payment-request/PaymentRequestListPage"
                );
                return { Component: PaymentRequestListPage };
              },
            },
            {
              path: ":id",
              lazy: async () => {
                const { default: PaymentRequestDetailsPage } = await import(
                  "./pages/payment-request/PaymentRequestDetailsPage"
                );
                return { Component: PaymentRequestDetailsPage };
              },
            },
          ],
        },
        {
          path: "blocklist",
          children: [
            {
              path: "quarantine",
              lazy: async () => {
                const { default: QuarantineListPage } = await import(
                  "./pages/blocklist/QuarantineListPage"
                );
                return { Component: QuarantineListPage };
              },
            },
            {
              path: "emails",
              lazy: async () => {
                const { default: AdminEmailBlocklistPage } = await import(
                  "./pages/blocklist/AdminEmailBlocklistPage"
                );
                return { Component: AdminEmailBlocklistPage };
              },
            },
            {
              path: "phoneNumbers",
              lazy: async () => {
                const { default: AdminPhoneNumberBlocklistPage } = await import(
                  "./pages/blocklist/AdminPhoneNumberBlocklistPage"
                );
                return { Component: AdminPhoneNumberBlocklistPage };
              },
            },
            {
              index: true,
              lazy: async () => {
                const { default: AdminBlocklistPage } = await import(
                  "./pages/blocklist/AdminBlocklistPage"
                );
                return { Component: AdminBlocklistPage };
              },
            },
          ],
        },
        {
          path: "trial-membership",
          children: [
            {
              index: true,
              lazy: async () => {
                const { default: TrialMembershipPage } = await import(
                  "./pages/trial-membership/TrialMembershipPage"
                );
                return { Component: TrialMembershipPage };
              },
            },
            {
              path: ":tabId",
              lazy: async () => {
                const { default: TrialMembershipPage } = await import(
                  "./pages/trial-membership/TrialMembershipPage"
                );
                return { Component: TrialMembershipPage };
              },
            },
          ],
        },
        {
          path: "*",
          lazy: async () => {
            const { NotFoundAdminPage } = await import("./pages/NotFound");
            return { Component: NotFoundAdminPage };
          },
        },
      ],
    },
  ],
};
