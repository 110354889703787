import { InfoButton, type InfoButtonProps } from "@naf/teamscheme";
import styled from "styled-components";

const StyledInfoButton = styled(function (props: InfoButtonProps) {
  return <InfoButton {...props} />;
})`
  display: inline-block;
  vertical-align: sub;
`;

export default StyledInfoButton;
