import { onDesktop } from "@naf/teamscheme-core";
import { type HTMLAttributes } from "react";
import styled from "styled-components";
import { HeaderSection } from "./HeaderSection";

export const LogoHeaderSection = styled(function (
  props: HTMLAttributes<HTMLDivElement>,
) {
  return <HeaderSection {...props} />;
})`
  justify-content: flex-start;
  flex: auto;
  ${onDesktop`
    width: 40%;
  `}
`;
