import { type HTMLAttributes, useMemo } from "react";
import styled from "styled-components";
import Header from "./Header";
import { combineMenuItems } from "./HeaderObjects";
import { useAuthHeaderElements } from "./AuthHeaderElements";
import { useLocation } from "react-router";
import { getContractLoginCallbackPageLocation } from "../../contract/contract-type/getContractLoginCallbackPageLocation";
import { type Role } from "#vehicle-contract/common/lib/model/contract/Role";
import { HeaderVariant } from "./HeaderVariant";
import { type ContractToken } from "../../contract/model/ContractViewModel";

const EmptyHeaderWrapper = styled.div`
  background-color: ${({ theme }) => theme.nafColor.signature.white};
  width: 100%;
  z-index: 2;
`;

export interface EmptyHeaderProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "role"> {
  token?: ContractToken;
  role?: Role;
  licensePlateNumber?: string;
}

export default function EmptyHeader({
  token,
  role,
  licensePlateNumber,
  ...props
}: EmptyHeaderProps) {
  const location = useLocation();
  const loginLocation = useMemo(() => {
    if (!token) return;
    return getContractLoginCallbackPageLocation(location, token);
  }, [location, token]);
  const authHeaderProps = useAuthHeaderElements(loginLocation);

  const elements = useMemo(() => {
    return combineMenuItems([...authHeaderProps]);
  }, [authHeaderProps]);

  return (
    <EmptyHeaderWrapper {...props}>
      <Header
        variant={HeaderVariant.Static}
        elements={elements}
        role={role}
        licensePlateNumber={licensePlateNumber}
      />
    </EmptyHeaderWrapper>
  );
}
