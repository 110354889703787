import { type HTMLAttributes, useMemo } from "react";
import { useLocation } from "react-router";
import styled from "styled-components";
import { type Role } from "#vehicle-contract/common/lib/model/contract/Role";
import { getContractLoginCallbackPageLocation } from "../../contract/contract-type/ContractLoginCallbackPageLocation";
import type { ContractViewModel } from "../../contract/model/ContractViewModel";
import { useAuthHeaderElements } from "./AuthHeaderElements";
import Header from "./Header";
import {
  combineMenuItems,
  useAboutLinkItem,
  useFaqLinkItem,
} from "./HeaderObjects";
import { HeaderVariant } from "./HeaderVariant";
import {
  type HeaderBackground,
  useThemeColorFromHeaderBackground,
} from "./HeaderBackground";

type HeaderBackgroundProps = {
  background?: HeaderBackground;
};

const StaticHeaderWrapper = styled.div
  .attrs<HeaderBackgroundProps>({})
  .withConfig({
    shouldForwardProp(prop) {
      return prop !== "background";
    },
  })`
  background-color: ${({ theme, background }) =>
    background === "green"
      ? theme.nafColor.primary.dew
      : theme.nafColor.signature.white};
  width: 100%;
  z-index: 2;
`;

export interface StaticHeaderProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "role">,
    HeaderBackgroundProps {
  contract?: ContractViewModel;
  role?: Role;
}

export default function StaticHeader({
  contract,
  role,
  ...props
}: StaticHeaderProps) {
  const location = useLocation();
  const loginLocation = useMemo(() => {
    if (!contract) return;
    const token =
      "sellerToken" in contract ? contract.sellerToken : contract.buyerToken;
    return getContractLoginCallbackPageLocation(location, token);
  }, [location, contract]);
  const authHeaderProps = useAuthHeaderElements(loginLocation);
  const faqProps = useFaqLinkItem();
  const aboutProps = useAboutLinkItem();

  const elements = useMemo(() => {
    return combineMenuItems([...authHeaderProps, aboutProps, faqProps]);
  }, [authHeaderProps, faqProps, aboutProps]);

  const { background } = props;

  useThemeColorFromHeaderBackground(background);

  return (
    <StaticHeaderWrapper {...props}>
      <Header
        variant={HeaderVariant.Static}
        elements={elements}
        contract={contract}
        role={role}
        logoLinkLocation="/"
      />
    </StaticHeaderWrapper>
  );
}
