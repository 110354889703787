import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { Role } from "#vehicle-contract/common/lib/model/contract/Role";
import { useRole } from "../ContractContext";

export interface IInputValidationContext {
  errors: InputValidationError[];
  setErrors: Dispatch<SetStateAction<InputValidationError[]>>;
  focus: InputValidationError | null;
  setFocus: Dispatch<SetStateAction<InputValidationError | null>>;
  validateField(
    role: Role,
    section: string,
    field: ValidationFieldSpecification,
    value: string,
  ): void;
}
const InputValidationContext = createContext<IInputValidationContext | null>(
  null,
);

function chunkString(str: string, length: number) {
  const match = str.match(new RegExp(`.{1,${length}}`, "g"));
  if (!match) return "";
  return match.toString();
}

function countLines(str: string, maxCharsPerLine?: number) {
  let lines = str.split("\n").filter((line) => !!line);

  if (maxCharsPerLine) {
    lines = lines.flatMap((line) => chunkString(line, maxCharsPerLine));
  }

  return lines.length;
}

export interface InputValidationErrorResult {
  isRequired?: boolean;
  maxLines?: number;
  patternError?: string;
  callbackError?: string;
}

export interface InputValidationError extends InputValidationErrorResult {
  section: string;
  field: string;
  role: Role;
}

export interface ValidationFieldSpecification extends ValidationParameters {
  name: string;
}

export interface PatternValidationParameters {
  match: string;
  error: string;
}

export interface ValidationParameters {
  required?: boolean;
  maxLines?: {
    maxLineLength: number;
    num: number;
  };
  pattern?: PatternValidationParameters;
  callback?: (value: string | null) => InputValidationErrorResult | null;
}

export function InputValidationProvider({ children }: { children: ReactNode }) {
  const [errors, setErrors] = useState<InputValidationError[]>([]);
  const [focus, setFocus] = useState<InputValidationError | null>(null);

  function setFieldError(
    role: Role,
    section: string,
    field: ValidationFieldSpecification,
    error: InputValidationErrorResult | null,
  ) {
    setErrors((errors) => {
      const add = error ? [{ section, field: field.name, role, ...error }] : [];

      return [
        ...errors.filter(
          (e) => e.section !== section || e.field !== field.name,
        ),
        ...add,
      ];
    });
  }

  function getError(
    { required, maxLines, pattern, callback }: ValidationParameters,
    value: string | null,
  ): InputValidationErrorResult | null {
    if (required && !value) {
      return { isRequired: true };
    }

    if (
      maxLines &&
      countLines(value || "", maxLines.maxLineLength) > maxLines.num
    ) {
      return { maxLines: maxLines.num };
    }

    if (pattern && value && !new RegExp(pattern.match).test(value)) {
      return { patternError: pattern.error };
    }

    if (callback) {
      return callback(value);
    }

    return null;
  }

  function validateField(
    role: Role,
    section: string,
    field: ValidationFieldSpecification,
    value: string,
  ) {
    const error = getError(field, value);

    setFieldError(role, section, field, error);
  }

  return (
    <InputValidationContext.Provider
      value={{
        errors,
        setErrors,
        focus,
        setFocus,
        validateField,
      }}
    >
      {children}
    </InputValidationContext.Provider>
  );
}

export function useInputValidation(
  section: string,
  field: string,
  { required, maxLines, pattern, callback }: ValidationParameters = {},
) {
  const role = useRole();
  const { errors, validateField } = useInputValidationContext();

  const error = errors.filter(
    (e) => e.role === role && e.section === section && e.field === field,
  )[0];

  const validate = useCallback(
    (value: string) =>
      validateField(
        role,
        section,
        { name: field, required, maxLines, pattern, callback },
        value,
      ),
    [
      validateField,
      role,
      section,
      field,
      required,
      maxLines,
      pattern,
      callback,
    ],
  );

  return useMemo(
    () => ({
      error,
      validate,
    }),
    [error, validate],
  );
}

export interface InputFocus {
  consume(): void;
}

export function useInputValidationContext() {
  const inputValidationContext = useContext(InputValidationContext);
  if (!inputValidationContext) {
    throw new Error("No InputValidationContext found!");
  }
  return inputValidationContext;
}

export function useInputFocus(
  section: string,
  field: string,
): InputFocus | null {
  const { focus, setFocus } = useInputValidationContext();

  return focus && focus.section === section && focus.field === field
    ? {
        consume: () => setFocus(null),
      }
    : null;
}

export default InputValidationContext;
