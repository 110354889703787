import { ActivatedContractBenefitList } from "../ActivatedContractTypePage";
import { PREMIUM_BENEFITS } from "../PremiumCard";

export const UPGRADED_TO_COMPLETE_TITLE =
  "Kontrakten er oppgradert til Premium";

export function UpgradedToCompleteIngress() {
  return (
    <>
      <p>I kontrakten har du tilgang til:</p>
      <ActivatedContractBenefitList benefits={PREMIUM_BENEFITS} />
    </>
  );
}
