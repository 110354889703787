import type { HTMLAttributes, ReactNode } from "react";
import CheckList, { CheckListItem } from "./CheckList";
import { normalizeComponentTree } from "../utils/normalizeComponentTree";

export interface Benefit {
  label: ReactNode;
  positive: boolean;
}

export interface BenefitListProps extends HTMLAttributes<HTMLUListElement> {
  benefits: (ReactNode | Benefit)[];
}

function isBenefit(x: ReactNode | Benefit): x is Benefit {
  if (!x) return false;
  if (typeof x === "string") return false;
  if (typeof x === "number") return false;
  if (typeof x === "boolean") return false;
  if ("label" in x) return true;
  return false;
}

export default function BenefitList({ benefits, ...props }: BenefitListProps) {
  return (
    <CheckList {...props}>
      {benefits.map((item) => {
        const benefit = isBenefit(item)
          ? item
          : { label: item, positive: true };
        return (
          <CheckListItem
            key={normalizeComponentTree(benefit.label)}
            checked={benefit.positive}
          >
            {benefit.label}
          </CheckListItem>
        );
      })}
    </CheckList>
  );
}
