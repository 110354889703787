import { Location } from "@naf/teamscheme";
import { useCallback, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useForcedLoginForMembershipApp } from "../../landing/LandingPage";
import { AppPages } from "../../routing/AppPages";
import { useContract, useRole } from "../ContractContext";
import { useContractToken } from "../ContractParams";
import { useContractFormLocations } from "../form/routing/ContractFormRoutes";
import { useSignatureStatus } from "../signing/SignatureStatus";
import { useContractUrls } from "./ContractUrls";

export function useContractStateRedirects() {
  const navigate = useNavigate();
  const location = useLocation();

  const contract = useContract();
  const role = useRole();
  const urls = useContractUrls();
  const formLocations = useContractFormLocations();
  const formPaths = useMemo(
    () => Object.values(formLocations).map((x) => x.pathname),
    [formLocations],
  );
  const beforeSigningPaths = useMemo(
    () => [
      ...formPaths,
      urls[AppPages.Contract].pathname,
      urls[AppPages.ContractSign].pathname,
      urls[AppPages.ContractSigningMethodUpgradeContract].pathname,
      urls[AppPages.ContractStatus].pathname,
      urls[AppPages.ContractPreview].pathname,
    ],
    [
      formPaths,
      urls[AppPages.Contract],
      urls[AppPages.ContractSign],
      urls[AppPages.ContractSigningMethodUpgradeContract],
      urls[AppPages.ContractStatus],
      urls[AppPages.ContractPreview],
    ],
  );
  const token = useContractToken();
  const { otherHasSigned, isSigned, userHasSigned } = useSignatureStatus();
  const sourceForRole =
    role === contract.initiator ? contract.source : undefined;

  useForcedLoginForMembershipApp(
    sourceForRole,
    `${location.pathname}${location.search}`,
  );

  // TODO: fix this using subscription instead of use effect

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const processContractState = useCallback(
    function processContractState(location: Location<unknown>) {
      if (!token) return;

      if (beforeSigningPaths.includes(location.pathname) && isSigned) {
        console.log(
          `navigate(${AppPages.ContractConfirmationLanding}, { replace: true });`,
        );

        navigate(urls[AppPages.ContractConfirmationLanding], { replace: true });

        return;
      }

      if (
        location.pathname !== urls[AppPages.ContractStatus].pathname &&
        !isSigned &&
        userHasSigned &&
        !otherHasSigned
      ) {
        console.log(`navigate(${AppPages.ContractStatus}, { replace: true });`);
        navigate(urls[AppPages.ContractStatus], { replace: true });

        return;
      }
    },
    [
      token,
      beforeSigningPaths,
      isSigned,
      urls,
      userHasSigned,
      otherHasSigned,
      contract.invitation,
      contract.initiator,
      role,
      navigate,
    ],
  );

  useEffect(() => {
    processContractState(location);
  }, [processContractState, location]);
}
