import { MemberPackageBenefits } from "./MemberUpgradeCard";
import { PremiumPackageDetails } from "./PremiumCard";
import { SimplePackageDetails } from "./contract-upgrade/SimpleCard";
import { TrialMembershipDetails } from "./trial-membership/TrialMembershipInfoModalTextLink";

export function AboutContractTypes() {
  return (
    <>
      <h2>Om Enkel</h2>
      <SimplePackageDetails />
      <h2>Om Premium</h2>
      <PremiumPackageDetails />
      <h2>Om Prøvemedlemskap</h2>
      <TrialMembershipDetails />
      <h2>Om NAF-medlemskap</h2>
      <MemberPackageBenefits />
    </>
  );
}
