import { useLocation } from "react-router-dom";
import { loginWithRedirect, useAuth } from "../../auth/AuthProvider";
import { Button, ButtonLinkModal } from "@naf/teamscheme";
import { createLocationPath } from "../../shared/utils/Location";
import styled from "styled-components";
import { type HTMLAttributes } from "react";

function LogInWithDifferentUserFlyoutContent({
  title,
  email,
}: { title: string; email: string }) {
  const location = useLocation();
  const auth = useAuth();
  return (
    <>
      <h1>{title}</h1>
      <p>
        <b>E-post:</b> {email}
      </p>
      <p>
        Når du fortsetter til kontrakten blir den bundet til brukerkontoen med
        e-post {email}. Ønsker du å bruke en annen e-postadresse kan du logge ut
        og logge inn igjen.
      </p>
      <div>
        <Button
          variant="secondary"
          onClick={() =>
            loginWithRedirect({
              targetUrl: createLocationPath(location),
              options: { authorizationParams: { prompt: "login" } },
            })
          }
        >
          Logg inn med annen bruker
        </Button>
      </div>
    </>
  );
}

export const LogInWithDifferentUserFlyout = styled(function ({
  email,
  ...props
}: { email: string } & Omit<HTMLAttributes<HTMLDivElement>, "children">) {
  const title = "Feil bruker?";
  return (
    <div {...props}>
      <ButtonLinkModal variant="primary" type="button" title={title}>
        <LogInWithDifferentUserFlyoutContent title={title} email={email} />
      </ButtonLinkModal>
    </div>
  );
})`
  margin-bottom: ${({ theme }) => theme.spacing.space24};
`;
