import { ReactNode, ReactText } from "react";
import { DateString } from "#vehicle-contract/common/lib/model/common/DateString";
import { ContractType } from "#vehicle-contract/common/lib/model/contract/SalesContract";
import { ContractViewModel } from "./contract/model/ContractViewModel";

export class CancellationToken {
  private _isCanceled = false;

  get isCanceled() {
    return this._isCanceled;
  }

  cancel() {
    this._isCanceled = true;
  }
}

export interface ICancellationToken {
  isCanceled: boolean;
}

export interface Cancelable<T> extends ICancellationToken {
  promise: Promise<T>;
  cancel: () => void;
}

export interface Cancellation {
  isCanceled: true;
}

export function makeCancelable<T>(promise: Promise<T>): Cancelable<T> {
  const context: Partial<Cancelable<T>> = {
    isCanceled: false,
  };

  const wrappedPromise = new Promise<T>((resolve, reject) => {
    promise.then(
      (val) =>
        context.isCanceled ? reject({ isCanceled: true }) : resolve(val),
      (error) =>
        context.isCanceled ? reject({ isCanceled: true }) : reject(error),
    );
  });

  context.promise = wrappedPromise;
  context.cancel = () => {
    context.isCanceled = true;
  };

  return context as Cancelable<T>;
}

export const capitalizeFirst = (str: string) =>
  str.substring(0, 1).toUpperCase() + str.substring(1);

export const lowerFirst = (value: string) =>
  value.charAt(0).toLowerCase() + value.slice(1);

export function startOfDay(d: DateString | Date) {
  const d2 = new Date(d);
  const result = new Date(
    d2.getFullYear(),
    d2.getMonth(),
    d2.getDate(),
    0,
    0,
    0,
  );
  return result;
}

export function addDays(d: DateString | Date, n: number) {
  const result = new Date(d);
  result.setDate(result.getDate() + n);
  return result;
}

export function getRelativeUrlPart(url: URL) {
  return url.pathname + url.search;
}

export function isFutureFromTomorrow(d: DateString | Date) {
  const date = new Date(d);
  const tommorow = addDays(new Date(), 1);
  return date > startOfDay(tommorow);
}

export function isPremiumContract(contract: ContractViewModel) {
  const currentType = contract.contractType?.current;

  return (
    typeof currentType === "number" &&
    [ContractType.Member, ContractType.Premium].includes(currentType)
  );
}

export function isSimpleContract(contract: ContractViewModel) {
  const currentType = contract.contractType?.current;

  return (
    typeof currentType === "number" &&
    [ContractType.None, ContractType.Simple].includes(currentType)
  );
}

function deepEqual<T extends object>(object1: T, object2: T) {
  const keys1 = Object.keys(object1) as (keyof T)[];
  const keys2 = Object.keys(object2) as (keyof T)[];

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      (areObjects && !deepEqual(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false;
    }
  }

  return true;
}

function isObject(o: unknown): o is object {
  return o != null && typeof o === "object";
}

export function compareArrays<T>(a: Array<T>, b: Array<T>) {
  if (a.length !== b.length) return false;

  for (const i in a) {
    if (!deepEqual(a, b)) {
      return false;
    }
  }

  return true;
}

export function isReactText(input: ReactNode): input is ReactText {
  return typeof input === "string" || typeof input === "number";
}

export function choice(array: string[]) {
  return array[~~(array.length * Math.random())];
}
