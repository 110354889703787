import styled from "styled-components";
import { ContractType } from "#vehicle-contract/common/lib/model/contract/SalesContract";
import { getContractTypeKey } from "../../create-contract/ContractTypeName";
import {
  ContractTypeBenefit,
  inkSignBenefit,
  vehicleContractBenefit,
} from "../ContractTypeBenefit";
import TypeCard, {
  TypeCardImage,
  TypeCardTitle,
  TypeCardBenefitList,
  TypeCardHeader,
  TypeCardButton,
  TypeCardProps,
} from "../TypeCard";
import SimpleCardIllustration from "../illustrations/simple_92x82.svg";
import {
  TypeCardPrice,
  TypeCardPriceElement,
} from "../type-card-price/TypeCardPrice";
import { LocationDescriptor, LocationDescriptorObject } from "@naf/teamscheme";
import { useContractUrls } from "../../routing/ContractUrls";
import { useLocation } from "react-router-dom";
import { getContractUpgradeCreatePaymentLocation } from "./getContractUpgradeCreatePaymentLocation";
import { typeCardNonMemberSimplePrice } from "../type-card-price/TypeCardPriceArray";

export const SIMPLE_BENEFITS: ContractTypeBenefit[] = [
  vehicleContractBenefit,
  inkSignBenefit,
];

export const SimplePackageDetails = () => (
  <p>
    Du trenger ikke være NAF-medlem for å benytte deg av NAF Kjøpekontrakt.
    Enkel kjøpekontrakt kan kun signeres med InkSign fingersignatur. Kjøper og
    selger er selv ansvarlige for at eierskiftet gjennomføres hos Statens
    vegvesen.
  </p>
);

export interface SimpleCardProps extends Omit<TypeCardProps, "label"> {
  linkTo?: LocationDescriptor<unknown>;
  priceArray?: TypeCardPriceElement[];
}

export const SimpleCard = styled(function ({
  linkTo,
  priceArray,
  ...props
}: SimpleCardProps) {
  return (
    <TypeCard {...props}>
      <TypeCardHeader>
        <TypeCardImage
          src={SimpleCardIllustration}
          alt="Enkel"
          width="92"
          height="82"
        />
        <TypeCardTitle>Enkel</TypeCardTitle>
      </TypeCardHeader>
      <TypeCardBenefitList benefits={SIMPLE_BENEFITS} />
      {priceArray ? <TypeCardPrice elements={priceArray} /> : null}
      {linkTo ? (
        <TypeCardButton
          variant="outline"
          typeKey={getContractTypeKey(ContractType.Simple)}
          action={{
            linkTo: linkTo,
          }}
        >
          Velg Enkel
        </TypeCardButton>
      ) : null}
    </TypeCard>
  );
})``;

export function SimpleUpgradeCard({
  onClick,
  nextLocation,
}: {
  onClick?(): void;
  nextLocation?: LocationDescriptorObject<unknown> | null;
}) {
  const urls = useContractUrls();
  const location = useLocation();
  const next = getContractUpgradeCreatePaymentLocation(
    urls,
    ContractType.Simple,
    nextLocation?.pathname ?? location.pathname,
    location.pathname,
  );

  return (
    <SimpleCard
      onClick={onClick}
      linkTo={next}
      priceArray={[typeCardNonMemberSimplePrice]}
    />
  );
}
