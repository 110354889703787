import {
  type ReactNode,
  type SetStateAction,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { sessionId } from "../../api/ApiClient";
import {
  type InvitationViewModel,
  type ContractViewModel,
} from "../model/ContractViewModel";
import {
  type ContractUpdateSubscriber,
  ContractUpdatesContext,
} from "./ContractUpdates";
import { UpdateNotification } from "./UpdateNotification";
import UpdateType from "./UpdateType";
import { useWebSocketClient } from "../../api/WebSocketClient";
import { StaticLoadingScreen } from "../../shared/LoadingScreen";
import { useContractReducerContext } from "../ContractReducer";
import { useContractTokenContext } from "../ContractTokenContext";

export default function ContractUpdatesProvider({
  children,
}: {
  children: ReactNode;
}) {
  const { token } = useContractTokenContext();
  const { dispatch } = useContractReducerContext();
  const { onMessage, onOpen, send } = useWebSocketClient();

  const updateContract = useCallback(
    function doUpdateFields(updated: ContractViewModel) {
      dispatch({
        type: "update",
        value: (prev) =>
          prev.documentHash !== updated.documentHash ? updated : prev,
      });
    },
    [dispatch],
  );

  const onChange = useCallback(
    (contract: ContractViewModel) =>
      dispatch({ type: "update", value: contract }),
    [dispatch],
  );

  const applyContractUpdates = useCallback(
    (updates: SetStateAction<ContractViewModel>) =>
      dispatch({ type: "update", value: updates }),
    [dispatch],
  );

  const [updateSubscribers, setUpdateSubscribers] = useState<
    ContractUpdateSubscriber[]
  >([]);

  useEffect(
    () =>
      onMessage((event) => {
        const update = JSON.parse(event.data) as UpdateNotification;

        if (!update.contract) return;

        const contractToken =
          "sellerToken" in update.contract
            ? update.contract.sellerToken
            : update.contract.buyerToken;

        if (contractToken !== token) return;

        const { contract, updateType } = update;

        if (contract) {
          switch (updateType) {
            case UpdateType.Fields:
              updateContract(contract);
              break;
            case UpdateType.Signature:
            case UpdateType.BankIdPayment:
            case UpdateType.ContractType:
            case UpdateType.OwnershipTransfer:
            case UpdateType.OwnershipTransferValidation:
              onChange(contract);
              break;
            case UpdateType.Settlement:
              onChange(contract);
              break;
            case UpdateType.InvitationAccepted:
              applyContractUpdates((contract) => ({
                ...contract,
                invitation: {
                  ...(contract.invitation as InvitationViewModel),
                  accepted: true,
                },
              }));
              break;
            case UpdateType.RoleSpecificInfo:
              onChange(contract);
              break;
            default:
              break;
          }

          for (const subscriber of updateSubscribers.filter(
            (x) => x.updateType === updateType,
          )) {
            subscriber.callback(contract);
          }
        }
      }),
    [
      applyContractUpdates,
      updateContract,
      onChange,
      updateSubscribers,
      onMessage,
      token,
    ],
  );

  const subscribeData = useMemo(() => {
    if (!token) return null;

    return {
      token,
      sessionId,
    };
  }, [token]);

  const [isSubscribed, setIsSubscribed] = useState(false);
  useEffect(() => {
    if (!subscribeData) return;

    const unsubscribe = onOpen(async () => {
      try {
        await send({
          action: "Subscribe",
          data: subscribeData,
        });
      } finally {
        setIsSubscribed(true);
      }
    });

    return unsubscribe;
  }, [onOpen, send, subscribeData]);

  const onUpdate = useCallback(function (subscriber: ContractUpdateSubscriber) {
    setUpdateSubscribers((x) => [...x, subscriber]);

    return () => {
      setUpdateSubscribers((x) => x.filter((sub) => sub !== subscriber));
    };
  }, []);

  if (!isSubscribed) return <StaticLoadingScreen text="Kobler til" />;

  return (
    <ContractUpdatesContext.Provider
      value={{
        onUpdate,
      }}
    >
      {children}
    </ContractUpdatesContext.Provider>
  );
}
