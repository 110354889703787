import { VehicleData } from "#vehicle-contract/common/lib/model/contract/VehicleData";
import { capitalizeFirst } from "../../../utils";
import { AutoTechVehicleViewModel } from "../../model/ContractViewModel";

function formatVehicleModel(autoTechData: AutoTechVehicleViewModel) {
  const { manufactureName, modelName } = autoTechData;

  return `${manufactureName} ${modelName}`;
}

export function VehicleModel({
  autoTechData,
}: {
  autoTechData: AutoTechVehicleViewModel;
}) {
  return <span>{formatVehicleModel(autoTechData)}</span>;
}

export function ManuallyEnteredVehicleModel({
  vehicleData,
}: {
  vehicleData: VehicleData;
}) {
  const { manufactureName, modelName } = vehicleData;

  const vehicleNameList: string[] = [];

  if (manufactureName) {
    vehicleNameList.push(capitalizeFirst(manufactureName));
  }
  if (modelName) {
    vehicleNameList.push(capitalizeFirst(modelName));
  }

  if (vehicleNameList.length > 0) {
    return <span>{vehicleNameList.toString().replaceAll(",", " ")}</span>;
  }

  return null;
}
