import { Button } from "@naf/teamscheme";
import { ReactNode } from "react";
import { TitleSection } from "../formatting/CardSection";
import { FlowPage } from "../formatting/Page";
import PageCard from "../formatting/PageCard";
import { TITLE_POSTFIX } from "./DocumentTitle";
import ErrorMessage, { ErrorMessageProps } from "./error/ErrorMessage";

export default function FlowErrorPage({
  children = "Vi kunne ikke hente denne siden akkurat nå. Last siden på nytt for å prøve igjen.",
  ...props
}: Omit<ErrorMessageProps, "children"> & { children?: ReactNode }) {
  return (
    <FlowPage>
      <PageCard>
        <TitleSection title={`Feil ${TITLE_POSTFIX}`}>
          <ErrorMessage {...props}>{children}</ErrorMessage>
          <Button
            block
            variant="primary"
            onClick={() => window.location.reload()}
          >
            Last på nytt
          </Button>
        </TitleSection>
      </PageCard>
    </FlowPage>
  );
}
