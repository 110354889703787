import {
  FooterLink,
  FormNavigationButtonGroup,
  LinkFooterLinkProps,
  NavigationDirection,
  PageFooterCell,
  PageNavigationFooter,
} from "@naf/teamscheme";
import { ButtonLink } from "@naf/teamscheme";
import { ArrowBack as ArrowBackIcon } from "@styled-icons/material/ArrowBack";
import { ArrowForward as ArrowForwardIcon } from "@styled-icons/material/ArrowForward";
import type { StyledIconProps } from "@styled-icons/styled-icon";
import styled from "styled-components";
import { AppPages } from "../../../routing/AppPages";
import { useContractUrls } from "../../routing/ContractUrls";
import { RelativeUrls, useRelativeUrls } from "./FormFlow";

export interface BaseProps
  extends Omit<LinkFooterLinkProps, "linkTo" | "next" | "back" | "direction"> {
  direction: NavigationDirection;
}
export interface FromProps extends BaseProps {
  from: AppPages;
}

export interface RelativeProps extends BaseProps {
  using: RelativeUrls;
}

type RouterFooterLinkProps = FromProps | RelativeProps;

function FromLink({ from, direction, ...props }: FromProps) {
  const pages = useRelativeUrls(from);
  const nextUrl = pages.next;
  const prevUrl = pages.prev;
  const next = direction === "next";
  const back = direction === "back";
  const to = next ? nextUrl : back ? prevUrl : null;

  if (!to) throw new Error("Either 'next' or 'back' is required!");

  if (!to) return <PageFooterCell />;

  return <FooterLink linkTo={to} next={next} back={back} {...props} />;
}

function RelativeLink({ using, direction, ...props }: RelativeProps) {
  const next = direction === "next";
  const back = direction === "back";
  const to = next ? using.next : back ? using.prev : null;

  if (!to) return <PageFooterCell />;

  return (
    <FooterLink
      linkTo={to}
      next={next}
      back={back}
      state={to.state}
      {...props}
    />
  );
}

export default function FormFooterLink(props: RouterFooterLinkProps) {
  if ("using" in props) return <RelativeLink {...props} />;
  if ("from" in props) return <FromLink {...props} />;
  throw new Error("Unrecognized props");
}

type FormFooterProps = {
  disableNext?: boolean;
  disablePrevious?: boolean;
};

interface UsingRelativeFormFooterProps extends FormFooterProps {
  using: RelativeUrls;
}

interface FromRelativeFormFooterProps extends FormFooterProps {
  from: AppPages;
}

export function RelativeFormFooter({
  disableNext,
  disablePrevious,
  ...props
}: UsingRelativeFormFooterProps | FromRelativeFormFooterProps) {
  const urls = useContractUrls();

  return (
    <PageNavigationFooter>
      <ButtonLink variant="primary" linkTo={urls[AppPages.Contract]}>
        Din oversikt
      </ButtonLink>
      <FormNavigationButtonGroup>
        <FormFooterLink
          direction="back"
          disabled={disablePrevious}
          {...props}
        />
        <FormFooterLink direction="next" disabled={disableNext} {...props} />
      </FormNavigationButtonGroup>
    </PageNavigationFooter>
  );
}

export const ArrowBack = styled(function (props: StyledIconProps) {
  return <ArrowBackIcon {...props} />;
})`
  margin-right: ${({ theme }) => theme.spacing.space8};
`;

export const ArrowForward = styled(function (props: StyledIconProps) {
  return <ArrowForwardIcon {...props} />;
})`
  margin-left: ${({ theme }) => theme.spacing.space8};
`;
