import { type TrackEventProps } from "@naf/teamscheme-core";
import { type ChangeEvent, cloneElement, type HTMLAttributes } from "react";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { useTracking } from "../Tracking";
import { normalizeComponentTree } from "./utils/normalizeComponentTree";

export function useDebouncedQuery({
  queryEvent,
  queryString: initialQueryString = "",
}: {
  queryEvent: TrackEventProps;
  queryString?: string;
}) {
  const [queryString, setQueryString] = useState(initialQueryString);

  useEffect(() => {
    setQueryString(initialQueryString);
  }, [initialQueryString]);

  const [query, setQuery] = useState<string | null>(null);
  const tracking = useTracking();

  useEffect(() => {
    const timeout = setTimeout(() => {
      const newQuery = queryString.length >= 3 ? queryString : null;
      setQuery(newQuery);
    }, 250);

    return () => clearTimeout(timeout);
  }, [queryString]);

  useEffect(() => {
    if (query) {
      const timeout = setTimeout(() => {
        tracking.event({
          ...queryEvent,
          label: query,
        });
      }, 1000);

      return () => clearTimeout(timeout);
    }
  }, [queryEvent, tracking, query]);

  function clear() {
    setQuery(null);
    setQueryString("");
  }

  return {
    query,
    clear,
    inputProps: {
      value: queryString,
      onChange: (event: ChangeEvent<HTMLInputElement>) =>
        setQueryString(event.target.value || ""),
    },
  };
}

function escapeRegExp(string: string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

export const Match = styled(function (props: HTMLAttributes<HTMLSpanElement>) {
  return <span {...props} />;
})`
  background-color: yellow;
`;

interface IMatch {
  isMatch?: boolean;
  startIndex: number;
  endIndex: number;
}

export function splitMatches(text: string, query: string): IMatch[] {
  if (!query) return [{ startIndex: 0, endIndex: text.length }];

  const re = new RegExp(escapeRegExp(query), "gi");

  const matches = [];

  let result: RegExpExecArray | null;

  let prevIndex = re.lastIndex;
  // biome-ignore lint/suspicious/noAssignInExpressions: <explanation>
  while ((result = re.exec(text)) !== null) {
    if (result.index > prevIndex) {
      matches.push({ startIndex: prevIndex, endIndex: result.index });
    }
    matches.push({
      isMatch: true,
      startIndex: result.index,
      endIndex: re.lastIndex,
    });
    prevIndex = re.lastIndex;
  }

  if (matches.length) {
    matches.push({ startIndex: prevIndex, endIndex: text.length });
  }

  return matches;
}

function createMatchResult(text: string, match: IMatch, key: string | number) {
  const { isMatch, startIndex, endIndex } = match;
  const part = text.substring(startIndex, endIndex);
  return isMatch ? (
    <Match key={`${key}::match::${startIndex}-${endIndex}`}>{part}</Match>
  ) : (
    <span key={`${key}::part::${startIndex}-${endIndex}`}>{part}</span>
  );
}

export function searchString(text: string, query: string) {
  return splitMatches(text, query).map((match, i) =>
    createMatchResult(text, match, i),
  );
}

function applyMatchesToHTML(root: HTMLElement, matches: IMatch[], i = 0) {
  if (!matches.length) return null;

  const str = (x: string) => ({
    result: matches
      .map(({ startIndex, endIndex, ...match }) => ({
        ...match,
        startIndex: startIndex - i,
        endIndex: endIndex - i,
      }))
      .filter((match) => match.endIndex >= 0 && match.startIndex <= x.length)
      .map((match) => {
        const { isMatch, startIndex, endIndex } = match;
        const part = x.substring(startIndex, endIndex);
        if (isMatch) {
          const span = document.createElement("span");
          span.innerText = part;
          span.className = "Match";
          return span;
        }
        return document.createTextNode(part);
      })
      .reduce((wrapper, child) => {
        wrapper.appendChild(child);
        return wrapper;
      }, document.createElement("span")),
    endIndex: i + x.length,
  });

  if (root.nodeType === Node.TEXT_NODE && "textContent" in root)
    return str(root.textContent ?? "");

  const result = root.cloneNode() as Element;

  let nextIndex = i;
  for (const child of Array.from(root.childNodes)) {
    const next = applyMatchesToHTML(child as HTMLElement, matches, nextIndex);

    if (!next) continue;

    const { result: newChild, endIndex } = next;

    nextIndex = endIndex;

    result.appendChild(newChild);
  }

  return {
    result,
    endIndex: nextIndex,
  };
}

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
function isIterable(input: any) {
  if (input === null || input === undefined) {
    return false;
  }

  return typeof input[Symbol.iterator] === "function";
}

function applyMatchesToComponents(
  root: JSX.Element | string,
  matches: IMatch[],
  i = 0,
) {
  if (!matches.length) return null;

  const str = (x: string) => ({
    result: matches
      .map(({ startIndex, endIndex, ...match }) => ({
        ...match,
        startIndex: startIndex - i,
        endIndex: endIndex - i,
      }))
      .filter((match) => match.endIndex >= 0 && match.startIndex <= x.length)
      .map((match, j) => createMatchResult(x, match, `${j}/${i}`)),
    endIndex: i + x.length,
  });

  if (typeof root === "string") return str(root);

  if (!root.props.children) {
    return { result: root, endIndex: i };
  }

  let nextIndex = i;
  let newChildren: JSX.Element[];

  if (typeof root.props.children === "string") {
    const { result, endIndex } = str(root.props.children);
    newChildren = result;
    nextIndex = endIndex;
  } else {
    newChildren = [];

    if (isIterable(root.props.children)) {
      for (const child of root.props.children) {
        const next = applyMatchesToComponents(child, matches, nextIndex);

        if (!next) continue;

        const { result, endIndex } = next;

        nextIndex = endIndex;

        if (Array.isArray(result)) {
          newChildren.push(...result);
        } else {
          newChildren.push(result);
        }
      }
    }
  }

  const element = cloneElement(root, {
    ...root.props,
    children: newChildren,
    key: i,
  });

  return {
    result: element,
    endIndex: nextIndex,
  };
}

export function searchComponents(
  root: JSX.Element | string,
  query: string,
): { result: JSX.Element; weight: number | undefined } {
  const text = normalizeComponentTree(root);

  const matches = splitMatches(text, query);

  if (!matches.length) return { weight: undefined, result: <></> };

  const applied = applyMatchesToComponents(root, matches);

  if (!applied) return { weight: undefined, result: <></> };

  return { result: <>{applied.result}</>, weight: matches.length };
}

export function searchHTML(html: string, query: string): string | null {
  const wrapper = document.createElement("div");
  wrapper.innerHTML = html;
  const text = wrapper.innerText;

  const matches = splitMatches(text, query);

  if (!matches.length) return null;

  const node = applyMatchesToHTML(wrapper, matches)?.result;

  if (!node) return null;

  if ("innerHTML" in node) {
    return node.innerHTML;
  }

  return null;
}

export function search(text: JSX.Element | string, query: string) {
  if (typeof text === "string") {
    const result = searchString(text, query);

    return {
      weight: result.length && query.length ? query.length : undefined,
      result: <span>{result}</span>,
    };
  }

  const result = searchComponents(text, query);

  return result;
}
