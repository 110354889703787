import {
  type ReactNode,
  useState,
  useEffect,
  createContext,
  useContext,
} from "react";
import {
  DocumentPreviewClient,
  useDocumentPreviewClient,
} from "./DocumentPreviewClient";

const DocumentPreviewContext = createContext<DocumentPreviewClient | null>(
  null,
);

export function DocumentPreviewProvider({ children }: { children: ReactNode }) {
  const context = useDocumentPreviewClient();

  return (
    <DocumentPreviewContext.Provider value={context}>
      {children}
    </DocumentPreviewContext.Provider>
  );
}

export function useDocumentPreview() {
  const context = useContext(DocumentPreviewContext);
  const [loading, setLoading] = useState(true);

  if (!context) throw new Error("No DocumentPreviewContext found!");

  const { load } = context;

  useEffect(() => {
    load();
  }, [load]);

  useEffect(() => {
    setLoading(context.loading);
  }, [context.loading]);

  return { ...context, loading: loading || context.loading };
}
