import React, { useContext } from "react";

export interface ILayoutContext {
  setPaddingBottom(padding: number): void;
}

const LayoutContext = React.createContext<ILayoutContext | null>(null);

export function useLayoutContext() {
  const context = useContext(LayoutContext);
  if (!context) throw new Error("No LayoutContext found!");
  return context;
}

export default LayoutContext;
