import { createContext } from "react";
import { type ApiError } from "../../api/ApiClient";
import { type ContractValidationResult } from "./ContractValidationResult";

export interface IContractValidationContext {
  load(): void;
  retry(): void;
  result: ContractValidationResult | null;
  error: ApiError | null;
  loading: boolean;
}
export const ContractValidationContext =
  createContext<IContractValidationContext | null>(null);
