import { type ReactNode } from "react";
import { TitleSection } from "../../formatting/CardSection";
import { FlowPage } from "../../formatting/Page";
import PageCard from "../../formatting/PageCard";
import ErrorPage from "../../shared/error/ErrorPage";
import { useGetCustomerOnboardingClient } from "./NafIdOnboardingClient";
import { useDocumentTitle } from "../../shared/DocumentTitle";
import EmptyHeader from "../../layout/header/EmptyHeader";
import StaticHeader from "../../layout/header/StaticHeader";
import { StaticLoadingScreen } from "../../shared/LoadingScreen";
import { OnboardingForm } from "./form/OnboardingForm";
import { LinkCustomer } from "./LinkCustomer";
import { type ContractToken } from "../model/ContractViewModel";

function OnboardingFormWrapper({ children }: { children: ReactNode }) {
  const title = "Bekreft brukeropplysninger";
  useDocumentTitle(title);

  return (
    <FlowPage>
      <PageCard>
        <TitleSection title={title}>{children}</TitleSection>
      </PageCard>
    </FlowPage>
  );
}

export default function NafIdOnboardingPage({
  token,
  refetchContract,
}: { token: ContractToken; refetchContract: () => void }) {
  const getOnboarding = useGetCustomerOnboardingClient(token);

  if (getOnboarding.isLoading) {
    return <StaticLoadingScreen />;
  }
  if (getOnboarding.error || !getOnboarding.result) {
    return (
      <>
        <StaticHeader />
        <ErrorPage />
      </>
    );
  }

  const hasMissingProfileFields =
    getOnboarding.result.missingProfileFields.length > 0;
  const hasMissingConsents =
    getOnboarding.result.missingConsentNumbers.length > 0;

  if (!hasMissingConsents && !hasMissingProfileFields) {
    return (
      <>
        <EmptyHeader
          licensePlateNumber={getOnboarding.result.licensePlateNumber}
          role={getOnboarding.result.role}
          token={token}
        />
        <LinkCustomer
          refetchContract={refetchContract}
          token={token}
          role={getOnboarding.result.role}
          licensePlateNumber={getOnboarding.result.licensePlateNumber}
          customerReferences={getOnboarding.result.customerReferences}
        />
      </>
    );
  }

  return (
    <>
      <EmptyHeader
        licensePlateNumber={getOnboarding.result.licensePlateNumber}
        role={getOnboarding.result.role}
        token={token}
      />
      <OnboardingFormWrapper>
        <p>
          Disse opplysningene lagres til brukerprofilen din på Mitt NAF og vil
          kunne brukes til utfylling av kjøpekontrakten.
        </p>
        <OnboardingForm
          onboarding={getOnboarding.result}
          token={token}
          refetchContract={refetchContract}
        />
      </OnboardingFormWrapper>
    </>
  );
}
