import LoadingBox from "../../../shared/LoadingBox";
import { LoadingWrapper } from "./LoadingWrapper";

export const Loading = () => (
  <>
    <LoadingWrapper>
      <LoadingBox />
    </LoadingWrapper>
  </>
);
