import styled from "styled-components";
import { LogoProps } from "./LogoProps";
import Logo from "./graphics/svv_360x190.svg";

const SvvLogo = styled(function ({ width = 180, ...props }: LogoProps) {
  return (
    <img
      {...props}
      src={Logo}
      alt="SVV"
      width={width}
      height={(190 / 360) * width}
    />
  );
})``;

export default SvvLogo;
