import { useCallback, useEffect, useMemo } from "react";
import { ResultClient, useClientBase } from "../../../api/ClientBase";
import { useContractClient } from "../../../api/ContractClient";
import { useAuth } from "../../../auth/AuthProvider";
import { useContract } from "../../ContractContext";
import { EVENTS, useTracking } from "../../../Tracking";
import { TrialMembershipOffer } from "#vehicle-contract/common/lib/model/contract/TrialMembership";

export type TrialMembershipProductClient = ResultClient<TrialMembershipProduct>;

export interface TrialMembershipProduct {
  offerId: string;
}

export function useTrialMembershipProductClient(): TrialMembershipProductClient {
  const { get } = useContractClient();
  const { id, trialMembershipOrchestration: existingOrchestration } =
    useContract();
  const client = useClientBase<void, TrialMembershipProduct>(
    useCallback(
      () =>
        get<TrialMembershipProduct>(`contract/${id}/trialMembership/product`),
      [get, id],
    ),
  );
  const { isAuthenticated } = useAuth();
  const hasOrchestration = !!existingOrchestration;

  const { fetch } = client;
  useEffect(() => {
    if (isAuthenticated && !hasOrchestration) {
      const cancelable = fetch();
      return () => cancelable.cancel();
    }
  }, [isAuthenticated, hasOrchestration, fetch]);
  const ineligibilityError =
    client.error?.status === 400 ? (client.error.result as string) : null;

  useEffect(() => {
    if (ineligibilityError) {
      console.log(
        "Ineligible for trial membership because",
        ineligibilityError,
      );
    }
  }, [ineligibilityError]);

  const tracking = useTracking();

  useEffect(() => {
    if (client.isLoading) return;

    // Debounced to avoid double send
    const to = setTimeout(() => {
      const value =
        client.result !== null && ineligibilityError === null
          ? "eligible"
          : "ineligible";

      tracking.event({
        ...EVENTS.TRIAL_MEMBERSHIP.ELIGIBILITY_CHECKED,
        value,
        // `label` is mapped to `detail`
        label: ineligibilityError ?? undefined,
      });
    });

    return () => clearTimeout(to);
  }, [client.result, client.isLoading, ineligibilityError, tracking.event]);

  return useMemo(() => {
    const { error, ...other } = client;
    return { ...other, error: error?.status === 400 ? null : error };
  }, [client]);
}

export interface CreateTrialMembershipRequestModel {
  cookies: string;
}

export function useCreateTrialMembershipClient(
  offerId: string,
  requestContent: CreateTrialMembershipRequestModel,
) {
  const { post } = useContractClient();
  const { id } = useContract();
  const { isAuthenticated } = useAuth();
  if (!isAuthenticated) throw new Error("Not authenticated");
  const client = useClientBase<void, TrialMembershipOffer>(
    useCallback(
      () =>
        post<TrialMembershipOffer>(
          `contract/${id}/trialMembership/offer/${offerId}/orchestrate`,
          requestContent,
        ),
      [post, id, offerId, requestContent],
    ),
  );

  return client;
}
