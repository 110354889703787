import { OverlayToaster, Toaster } from "@blueprintjs/core";
import { createRoot } from "react-dom/client";
import { createGlobalStyle } from "styled-components";

export const AdminToasterStyle = createGlobalStyle`
    .bp5-toast-container.bp5-toast-container-inline.admin-toaster {
        position: fixed;
    }
`;

const MockToaster: Promise<Toaster> = Promise.resolve({
  clear() {},
  dismiss() {},
  getToasts() {
    return [];
  },
  show() {
    return "";
  },
});

export const AdminToaster =
  typeof document === "undefined"
    ? MockToaster
    : OverlayToaster.createAsync(
        {
          usePortal: true,
          className: "admin-toaster",
        },
        {
          // Use createRoot() instead of ReactDOM.render(). This can be deleted after
          // a future Blueprint version uses createRoot() for Toasters by default.
          domRenderer: (toaster, containerElement) =>
            createRoot(containerElement).render(toaster),
        },
      );
