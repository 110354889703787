import { useTrialMembershipContextClient } from "./TrialMembershipContext";
import { useTrialMembershipLocation } from "./useTrialMembershipLocation";
import { type ContractViewModel } from "../../model/ContractViewModel";
import { TrialMembershipOrchestrationView } from "./TrialMembershipOrchestration";
import { ActivateTrialMembershipAlert } from "./ActivateTrialMembershipAlert";

export function TrialMembershipOnUpgrade({
  contract,
}: {
  contract: ContractViewModel;
}) {
  const trialMembership = useTrialMembershipContextClient();
  const trialMembershipLocation = useTrialMembershipLocation();

  if (contract.trialMembershipOrchestration) {
    return (
      <TrialMembershipOrchestrationView
        trial={contract.trialMembershipOrchestration}
      />
    );
  }

  if (!trialMembership.result) return null;

  if (trialMembership.result.trialMembershipProduct) {
    return (
      <ActivateTrialMembershipAlert
        activateTrialMembershipLocation={trialMembershipLocation}
        offerId={trialMembership.result.trialMembershipProduct.offerId}
      />
    );
  }
  return null;
}
