import ErrorMessage from "../../shared/error/ErrorMessage";

export function SignatureExistErrorMessage() {
  return (
    <ErrorMessage>
      En kontrakt med påbegynt signatur kan ikke oppgraderes. For å kunne
      oppgradere må signeringen avbrytes.
    </ErrorMessage>
  );
}
