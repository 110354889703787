import { type DateString } from "@naf/teamscheme";

const SECOND = 1000;
const MINUTE = 60 * SECOND;
const HALF_MINUTE = MINUTE / 2;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;
const YEAR = 365 * DAY;
function pluralize(n: number, format: string) {
  let result = format.replace(/#/g, n.toString());

  result =
    n > 1
      ? result.replace(/\{>1:([^}]+)\}/g, "$1")
      : result.replace(/\{>1:([^}]+)\}/g, "");

  result =
    n === 1
      ? result.replace(/\{1:([^}]+)\}/g, "$1")
      : result.replace(/\{1:([^}]+)\}/g, "");

  result =
    n === 0
      ? result.replace(/\{0:([^}]+)\}/g, "$1")
      : result.replace(/\{0:([^}]+)\}/g, "");

  return result;
}

export function timeSinceText(
  inDate: Date | DateString,
  inNow: Date | DateString,
) {
  const date = inDate instanceof Date ? inDate : new Date(inDate);
  const now = inNow instanceof Date ? inNow : new Date(inNow);

  const diff = +now - +date;

  if (diff < HALF_MINUTE) return "Noen sekunder siden";
  if (diff < MINUTE) return "Under et minutt siden";
  if (diff < HOUR) return pluralize(~~(diff / MINUTE), "# minutt{>1:er} siden");
  if (diff < DAY) return pluralize(~~(diff / HOUR), "# time{>1:r} siden");
  if (diff < YEAR) return pluralize(~~(diff / DAY), "# dag{>1:er} siden");

  return `${~~(diff / YEAR)} år siden`;
}
