import { createContext, useContext } from "react";
import { ValidationResultViewModel } from "../../validation/ValidationResultViewModel";

export const OnboardingFormValidationContext = createContext<{
  validationResult: ValidationResultViewModel | null;
} | null>(null);

export function useValidationContextMessage(name: string) {
  const context = useContext(OnboardingFormValidationContext);
  if (!context) throw new Error("No ValidationContext found!");
  if (!context.validationResult) return null;
  const match = context.validationResult.errors.find((x) => x.field === name);
  if (!match) return null;
  return match.message;
}
