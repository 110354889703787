import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { MembershipServiceContactInfo } from "../../../membership/MembershipServiceContactInfo";
import { ValidationResultViewModel } from "../../validation/ValidationResultViewModel";
import {
  Country,
  PhoneInput,
  countries,
  defaultTopCountries,
} from "@naf/teamscheme";
import styled from "styled-components";
import { onDesktop } from "@naf/teamscheme-core";
import { StringProfileFieldName } from "../../../membership/StringProfileFieldName";
import {
  Profile,
  ProfileViewModel,
} from "../../contract-type/contract-upgrade/Profile";
import { convertFromGenderEnum } from "../../../membership/GenderEnumConversion";
import { OnboardingFormValidationContext } from "./OnboardingFormValidationContext";
import { OnboardingFormCustomInputWrapper } from "./input-components/OnboardingFormCustomInputWrapper";
import { OnboardingFormTextInput } from "./input-components/OnboardingFormTextInput";
import {
  NorwegianPhoneNumberRegex,
  selectedPhoneCountryIsNorway,
} from "../../../formatting/PhoneNumber";
import { OnboardingFormAdressFields } from "./input-components/OnboardingFormAdressFields";

type ProfileFieldName = keyof MembershipServiceContactInfo;

export function onboardingFormCreateClassName(fieldName: ProfileFieldName) {
  return `ContactInfoField-${fieldName}`;
}

const ContactInfoFields = styled.fieldset`
  border: none;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1em;
  padding: 0;

  .FormGroup {
    width: 100%;
    padding: 0 1em;
    box-sizing: border-box;
  }

  .FormGroup.ContactInfoField-city,
  .FormGroup.ContactInfoField-postalCode {
    width: 50%;
  }

  ${onDesktop`
      .FormGroup.ContactInfoField-firstName,
      .FormGroup.ContactInfoField-lastName {
          width: 50%;
      }
    `}
`;

export function OnboardingFormInputs({
  profile,
  missingProfileFields,
  hasMembership,
  customerNumber,
  contactInfo,
  setContactInfo,
  validationResult,
}: {
  profile: MembershipServiceContactInfo;
  missingProfileFields: StringProfileFieldName[];
  hasMembership: boolean;
  customerNumber?: string;
  contactInfo: MembershipServiceContactInfo;
  setContactInfo: Dispatch<SetStateAction<MembershipServiceContactInfo>>;
  validationResult: ValidationResultViewModel | null;
}) {
  const [selectedPhoneCountry, setSelectedPhoneCountry] = useState<Country>();

  function onChange<T extends ProfileFieldName>(
    fieldName: T,
    value: MembershipServiceContactInfo[T] | undefined,
  ) {
    setContactInfo((x) => ({
      ...x,
      [fieldName]: value || undefined,
    }));
  }

  const inputs = useRef<{ [name: string]: HTMLInputElement }>({});

  useEffect(() => {
    if (!validationResult) return;
    const errorInputs = validationResult.errors
      .map((x) => inputs.current[x.field])
      .filter((x) => x);
    const first = errorInputs[0];
    if (!first) return;
    first.focus();
  }, [validationResult]);

  const answeredFields: AnsweredField[] = [];
  const missingFields: JSX.Element[] = [];

  if (customerNumber) {
    answeredFields.push({
      label: hasMembership ? "Medlemsnummer" : "Kundenummer",
      value: customerNumber,
    });
  }

  if (
    missingProfileFields.includes("firstName") ||
    missingProfileFields.includes("lastName")
  ) {
    missingFields.push(
      <OnboardingFormTextInput
        key="missingFields.firstName"
        name="firstName"
        label="Fornavn"
        placeholder="Ditt fornavn"
        contactInfo={contactInfo}
        setContactInfo={setContactInfo}
        readOnly={!!profile.firstName}
      />,
      <OnboardingFormTextInput
        key="missingFields.lastName"
        name="lastName"
        label="Etternavn"
        placeholder="Ditt etternavn"
        contactInfo={contactInfo}
        setContactInfo={setContactInfo}
        readOnly={!!profile.lastName}
      />,
    );
  } else {
    answeredFields.push({
      label: "Navn",
      value: `${contactInfo.firstName} ${contactInfo.lastName}`,
    });
  }

  if (missingProfileFields.includes("email")) {
    missingFields.push(
      <OnboardingFormTextInput
        key="missingFields.email"
        name="email"
        label="E-postadresse"
        placeholder="Din e-postadresse"
        contactInfo={contactInfo}
        setContactInfo={setContactInfo}
        readOnly={!!profile.email}
      />,
    );
  } else {
    answeredFields.push({
      label: "E-postadresse",
      value: contactInfo.email,
    });
  }

  if (missingProfileFields.includes("phoneNumber")) {
    missingFields.push(
      <OnboardingFormCustomInputWrapper
        key="missingFields.phoneNumber"
        name="phoneNumber"
        label="Telefonnummer"
        className={onboardingFormCreateClassName("phoneNumber")}
      >
        <PhoneInput
          options={countries}
          highlightedOptions={{
            options: defaultTopCountries,
          }}
          getSelectedCountry={setSelectedPhoneCountry}
          value={contactInfo.phoneNumber || ""}
          onChange={(value) => onChange("phoneNumber", value)}
          inputProps={{
            placeholder: selectedPhoneCountryIsNorway(selectedPhoneCountry)
              ? "8 siffer"
              : "Ditt telefonnummer",
            id: "membership.phoneNumber",
            required: true,
            pattern: selectedPhoneCountryIsNorway(selectedPhoneCountry)
              ? NorwegianPhoneNumberRegex
              : undefined,
          }}
        />
      </OnboardingFormCustomInputWrapper>,
    );
  } else {
    answeredFields.push({
      label: "Mobilnummer",
      value: contactInfo.phoneNumber,
    });
  }

  if (
    missingProfileFields.includes("address") ||
    missingProfileFields.includes("postalCode") ||
    missingProfileFields.includes("city")
  ) {
    missingFields.push(
      <OnboardingFormAdressFields
        key="missingFields.address"
        contactInfo={contactInfo}
        setContactInfo={setContactInfo}
        profile={profile}
      />,
    );
  } else {
    answeredFields.push({
      label: "Adresse",
      value: `${contactInfo.address}, ${contactInfo.postalCode} ${contactInfo.city}`,
    });
  }

  if (Object.values(profile).every((field) => field)) {
    const { dateOfBirth, gender, phoneNumber, ...rest } = profile;
    const customer: ProfileViewModel = {
      ...rest,
      dateOfBirth,
      phone: phoneNumber,
      gender: gender ? convertFromGenderEnum(gender) : undefined,
    };
    return <Profile customer={customer} />;
  }

  return (
    <OnboardingFormValidationContext.Provider value={{ validationResult }}>
      {answeredFields.length > 0 && (
        <AnsweredFields answeredFields={answeredFields} />
      )}
      {missingFields.length > 0 && (
        <>
          <h3>Dette er informasjon vi trenger om deg</h3>
          <ContactInfoFields>{missingFields}</ContactInfoFields>
        </>
      )}
    </OnboardingFormValidationContext.Provider>
  );
}

interface AnsweredField {
  label: string;
  value?: string;
}

function AnsweredFields({
  answeredFields,
}: {
  answeredFields: AnsweredField[];
}) {
  return (
    <>
      <StyledHeader>Dine opplysninger</StyledHeader>
      <FullWidthTable>
        <tbody>
          {answeredFields.map(({ label, value }) => (
            <ResponsiveTableRow key={label}>
              <th>{label}:</th>
              <td>{value}</td>
            </ResponsiveTableRow>
          ))}
        </tbody>
      </FullWidthTable>
    </>
  );
}

const StyledHeader = styled.h3`
  margin-bottom: 0;

  ${({ theme }) => onDesktop`
    margin-bottom: ${theme.spacing.space24};
  `}
`;

const FullWidthTable = styled.table`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing.space24};
`;

const ResponsiveTableRow = styled.tr`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.spacing.space8};

  th {
    vertical-align: top;
    font-weight: normal;
  }

  td {
    margin-top: -${({ theme }) => theme.spacing.space4};
    word-break: break-word;
    font-weight: bold;
  }

  ${({ theme }) => onDesktop`
    display: table-row;
    flex-direction: unset;
    margin-top: 0;

    th {
      padding-right: ${theme.spacing.space16};
      width: 0;
    }

    td {
      margin-top: 0;
      text-align: left;
    }
  `}
`;
