import { onDesktop } from "@naf/teamscheme-core";
import { HTMLAttributes, ReactNode, TableHTMLAttributes } from "react";
import styled from "styled-components";
import { ContractType } from "#vehicle-contract/common/lib/model/contract/SalesContract";
import { CheckIconCircle } from "@naf/teamscheme";
import { INSURANCE_MAX_DISCOUNT_PERCENT_MEMBER } from "../../form/insurance/InsuranceDiscount";

enum TableRow {
  Contract = 1,
  BankId = 2,
  Vipps = 3,
  OwnershipTransfer = 4,
  InsuranceDiscount = 5,
  LegalAdvice = 6,
  TechnicalAdvice = 7,
  RoadHelpAndDiscounts = 8,
}
const LABELS: {
  [row in TableRow]: ReactNode;
} = {
  [TableRow.Contract]: "Kjøpekontrakt med digital signatur",
  [TableRow.BankId]: "Trygg signering med BankID",
  [TableRow.Vipps]: "Gebyrfri betaling med Vipps",
  [TableRow.OwnershipTransfer]: "Eier\u00ADskifte hos Statens vegvesen",
  [TableRow.InsuranceDiscount]: `Inntil ${INSURANCE_MAX_DISCOUNT_PERCENT_MEMBER}\u00a0% rabatt på forsikring`,
  [TableRow.LegalAdvice]: "Juridisk bistand etter handelen",
  [TableRow.TechnicalAdvice]: "Tekniske eksperter på telefon og e-post",
  [TableRow.RoadHelpAndDiscounts]: "Veihjelp og rabatter til bilholdet",
};
export const PACKAGE_NAMES: {
  [contractType in ContractType]: string;
} = {
  [ContractType.None]: "",
  [ContractType.Simple]: "Enkel",
  [ContractType.Standard]: "Standard",
  [ContractType.Premium]: "Premium",
  [ContractType.Member]: "NAF-medlemskap",
};
type TableValue = boolean | string;
const TABLE_VALUES: {
  [type in PackageType]: {
    [row in TableRow]: TableValue;
  };
} = {
  [ContractType.Simple]: {
    [TableRow.Contract]: true,
    [TableRow.BankId]: false,
    [TableRow.Vipps]: false,
    [TableRow.OwnershipTransfer]: false,
    [TableRow.InsuranceDiscount]: false,
    [TableRow.LegalAdvice]: false,
    [TableRow.TechnicalAdvice]: false,
    [TableRow.RoadHelpAndDiscounts]: false,
  },
  [ContractType.Premium]: {
    [TableRow.Contract]: true,
    [TableRow.BankId]: true,
    [TableRow.Vipps]: true,
    [TableRow.OwnershipTransfer]: true,
    [TableRow.InsuranceDiscount]: true,
    [TableRow.LegalAdvice]: "30 dager",
    [TableRow.TechnicalAdvice]: "30 dager",
    [TableRow.RoadHelpAndDiscounts]: "30 dager",
  },
  [ContractType.Member]: {
    [TableRow.Contract]: true,
    [TableRow.BankId]: true,
    [TableRow.Vipps]: true,
    [TableRow.OwnershipTransfer]: true,
    [TableRow.InsuranceDiscount]: true,
    [TableRow.LegalAdvice]: true,
    [TableRow.TechnicalAdvice]: true,
    [TableRow.RoadHelpAndDiscounts]: true,
  },
};
const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const packages = [
  ContractType.Simple,
  ContractType.Premium,
  ContractType.Member,
] as const;
type PackageType = (typeof packages)[number];
type ComparisonListProps = {
  contractType: PackageType;
} & Omit<TableHTMLAttributes<HTMLTableElement>, "children">;

export const ComparisonList = styled(function ({
  contractType,
  ...props
}: ComparisonListProps) {
  return (
    <table {...props}>
      <tbody>
        {Object.keys(LABELS)
          .map((row) => Number(row) as TableRow)
          .map((row) => {
            const value = TABLE_VALUES[contractType]?.[row];
            const display =
              typeof value === "boolean" ? (
                <IconWrapper>
                  {value ? (
                    <CheckIconCircle aria-label="Inkludert" />
                  ) : (
                    <CheckIconCircle
                      aria-label="Ikke inkludert"
                      variant="inactive"
                    />
                  )}
                </IconWrapper>
              ) : (
                value
              );
            return (
              <tr key={`row-${row}`}>
                <td>{display}</td>
                <td>{LABELS[row]}</td>
              </tr>
            );
          })}
      </tbody>
      <tfoot>
        <tr>
          {/* Ghost element to stretch column when not visible */}
          <td>30 dager</td>
        </tr>
      </tfoot>
    </table>
  );
})`
  background: #FAFAFA;
  border: 1px solid ${({ theme }) => theme.nafColor.neutral.neutral2};
  border-radius: ${({ theme }) => theme.radius.m};

  tr td:first-child {
    text-align: center;
  }

  tbody tr:not(:last-child) td {
    border-bottom: 1px solid ${({ theme }) => theme.nafColor.neutral.neutral2};
  }

  tr td:last-child {
    padding: ${({ theme: { spacing } }) =>
      `${spacing.space8} ${spacing.space16} ${spacing.space8} ${spacing.space8}`};
  }

  tfoot,
  tbody {
    tr {
      td:first-child {
        white-space: nowrap;
      }
    }
  }

  tfoot tr {
    visibility: collapse;
  }
`;

export const ComparisonTable = styled(function ComparisonTable(
  props: Omit<HTMLAttributes<HTMLDivElement>, "children">,
) {
  return (
    <div {...props}>
      <table>
        <thead>
          <tr>
            <th />
            {packages.map((key) => (
              <th key={`header-${key}`}>{PACKAGE_NAMES[key]}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Object.keys(LABELS)
            .map((row) => Number(row) as TableRow)
            .map((row) => (
              <tr key={`row-${row}`}>
                <th>{LABELS[row]}</th>
                {packages.map((key) => {
                  const value = TABLE_VALUES[key]?.[row];
                  const display =
                    typeof value === "boolean" ? (
                      <IconWrapper>
                        {value ? (
                          <CheckIconCircle aria-label="Inkludert" />
                        ) : (
                          <CheckIconCircle
                            aria-label="Ikke inkludert"
                            variant="inactive"
                          />
                        )}
                      </IconWrapper>
                    ) : (
                      value
                    );
                  return <td key={`cell-${row}-${key}`}>{display}</td>;
                })}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
})`
  width: 100%;
  display: none;

  ${onDesktop`
    display: block;
  `}

  table {
    width: 100%;
    margin-bottom: ${({ theme }) => theme.spacing.space16};
    border-raidus: ${({ theme }) => theme.radius.m};
    border-spacing: 0;
  }

  table thead tr th {
    vertical-align: bottom;
  }

  table tbody {
    /* TODO: need lighter color */
    //background: ${({ theme }) => theme.nafColor.neutral.neutral1};
    background: #fbfbfb;
  }

  table tbody tr th,
  table tbody tr td {
    border-bottom: 1px solid ${({ theme }) => theme.nafColor.neutral.neutral2};
  }

  table tbody tr:first-child th,
  table tbody tr:first-child td {
    border-top: 1px solid ${({ theme }) => theme.nafColor.neutral.neutral2};
  }

  table tbody tr>*:first-child {
    border-left: 1px solid ${({ theme }) => theme.nafColor.neutral.neutral2};
  }

  table tbody tr>*:last-child {
    border-right: 1px solid ${({ theme }) => theme.nafColor.neutral.neutral2};
  }


  table tbody>tr:first-child>*:first-child {
    border-top-left-radius: ${({ theme }) => theme.radius.m};
  }

  table tbody>tr:first-child>*:last-child {
    border-top-right-radius: ${({ theme }) => theme.radius.m};
  }

  table tbody>tr:last-child>*:first-child {
    border-bottom-left-radius: ${({ theme }) => theme.radius.m};
  }

  table tbody>tr:last-child>*:last-child {
    border-bottom-right-radius: ${({ theme }) => theme.radius.m};
  }

  tbody th {
    font-weight: normal;
    padding-left: ${({ theme }) => theme.spacing.space16};
  }

  th,
  td {
    padding: ${({ theme }) => theme.spacing.space16} 2px;
  }

  thead th,
  td {
    text-align: center;
  }

  td {
    width: ${~~(50 / 2.7)}%;
  }
`;
