export class Range {
  constructor(
    public from: number,
    public to: number,
  ) {
    if (from >= to) throw new Error(`From ${from} must be lower than to ${to}`);
  }

  toString() {
    return `[${this.from}, ${this.to})`;
  }
}

type Variant<T> = { range: Range; name: T };

export class Experiment<T> {
  constructor(public variants: Variant<T>[]) {
    const ranges = variants
      .map(({ range }) => range)
      .sort((a, b) => a.from - b.from);
    if (ranges.length < 2) {
      throw new Error("Minimum 2 variants");
    }
    const first = ranges[0];
    const last = ranges[ranges.length - 1];
    if (first.from !== 0) {
      throw new Error("Ranges must start at 0");
    }
    if (last.to !== 1) {
      throw new Error("Ranges must end at 1");
    }
    const pairs = ranges.slice(1).map((range, i) => [ranges[i], range]);
    const overlap = pairs.find(([a, b]) => b.from < a.to);
    if (overlap) {
      const [a, b] = overlap;
      throw new Error(`Ranges cannot overlap: ${a} overlaps ${b}`);
    }
    const gap = pairs.find(([a, b]) => b.from > a.to);
    if (gap) {
      const [a, b] = gap;
      throw new Error(`Cannot have gap between ranges: from ${a} to ${b}`);
    }
  }
}

// Namingscheme for AB-tests are heroes from Marvel.
// Inspiration: https://comicvine.gamespot.com/profile/arthurcbps/lists/top-200-heroes-of-marvel/14088/
// Latest used hero: Hulk

export enum SelectTypePageVariant {
  Wolverine = "wolverine",
  Spiderman = "spiderman",
}

export enum SafeSaleProductPriceVariant {
  Thor = "thor",
  Ironman = "ironman",
  Hulk = "hulk",
}

export const contractExperiments = {
  SelectTypePage: new Experiment<SelectTypePageVariant>([
    { range: new Range(0, 0.5), name: SelectTypePageVariant.Wolverine },
    { range: new Range(0.5, 1), name: SelectTypePageVariant.Spiderman },
  ]),
};

export const staticExperiments = {
  SafeSaleProduct: new Experiment<SafeSaleProductPriceVariant>([
    { range: new Range(0, 0.33), name: SafeSaleProductPriceVariant.Thor },
    { range: new Range(0.33, 0.66), name: SafeSaleProductPriceVariant.Ironman },
    { range: new Range(0.66, 1), name: SafeSaleProductPriceVariant.Hulk },
  ]),
};
