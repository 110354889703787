import { Percent } from "@naf/teamscheme";
import { ReactNode } from "react";

export interface ContractTypeBenefit {
  label: ReactNode;
  positive: boolean;
  excludeMembers?: boolean;
}

export const vehicleContractBenefit: ContractTypeBenefit = {
  label: "Kjøpekontrakt",
  positive: true,
};
export const inkSignBenefit: ContractTypeBenefit = {
  label: "InkSign fingersignatur",
  positive: true,
};

export const bankIdBenefit: ContractTypeBenefit = {
  label: "Signatur med BankID",
  positive: true,
};
export const vippsBenefit: ContractTypeBenefit = {
  label: "Gebyrfri betaling med Vipps",
  positive: true,
};
export const ownershipTransferBenefit: ContractTypeBenefit = {
  label: "Eierskifte hos Statens vegvesen",
  positive: true,
};
export const insuranceBenefit: ContractTypeBenefit = {
  label: (
    <>
      Inntil <Percent value={26} /> rabatt på bilforsikring hos Gjensidige
    </>
  ),
  positive: true,
};
