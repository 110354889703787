import { InputHTMLAttributes } from "react";
import LabeledInput from "../forms/LabeledInput";

export function FilterField({
  inputProps,
  label = "Søk i feltene",
  placeholder = "Let etter nøkkelord",
}: {
  inputProps?: Omit<
    InputHTMLAttributes<HTMLInputElement>,
    "type" | "placeholder"
  >;
  label?: string;
  placeholder?: string;
}) {
  return (
    <LabeledInput
      id="search"
      name="search"
      label={label}
      inputProps={{
        type: "search",
        placeholder,
        ...inputProps,
      }}
    />
  );
}
