import * as Sentry from "@sentry/browser";
import { Component, type ErrorInfo, type ReactNode } from "react";
import ErrorPage from "./shared/error/ErrorPage";
import { StandaloneHeader } from "./layout/header/StandaloneHeader";

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

export class ErrorBoundary extends Component<Props, State> {
  state: State = {
    hasError: false,
  };

  static getDerivedStateFromError(_: Error): State {
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    console.log({ error, info });
    Sentry.withScope((scope) => {
      scope.setExtras({ info: info });
      Sentry.captureException(error);
    });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <>
          <StandaloneHeader />
          <ErrorPage message="Det ser ut som noe gikk galt!" />
        </>
      );
    }

    return this.props.children;
  }
}
