import { createContext, useContext, useEffect } from "react";
import styled from "styled-components";

export function useProgress(progress: number) {
  const { setProgress } = useProgressContext();
  useEffect(() => {
    setProgress(progress);

    return () => setProgress(0);
  }, [progress, setProgress]);
}

export interface IProgressContext {
  progress: number;
  setProgress(value: number): void;
}

export const ProgressContext = createContext<IProgressContext | null>(null);

function useProgressContext() {
  const context = useContext(ProgressContext);
  if (!context) throw new Error("No ProgressContext found!");
  return context;
}

export const ProgressBar = styled.header`
  transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  width: 100%;
  transform-origin: 0 50%;

  background-color: ${(props) => props.theme.nafColor.primary.kart};
`;

export const ProgressWrapper = styled.div`
  transform-origin: 50% 0;
  transition: transform 0.15s linear;
  overflow-x: hidden;
  background-color: ${(props) => props.theme.nafColor.primary.dew};
  width: 100%;
  height: ${(props) => props.theme.spacing.space8};

  ${ProgressBar} {
    height: ${(props) => props.theme.spacing.space8};
  }
`;
