import { useCallback } from "react";
import { api } from "../api/ApiClient";
import { useClientBase } from "../api/ClientBase";

interface PostalCity {
  postalCity: string;
}

export function usePostalCityClient() {
  const clientBase = useClientBase<string, PostalCity>(
    useCallback(
      (postalCode: string) =>
        api.get<PostalCity>(`membership/postalAddress/${postalCode}`),
      [],
    ),
  );

  return clientBase;
}
