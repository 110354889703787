import React, { ReactNode } from "react";
import styled from "styled-components";
import { TitleSection } from "../../formatting/CardSection";
import Page from "../../formatting/Page";
import PageCard, { PageCardContent } from "../../formatting/PageCard";

export const LoadErrorPage = styled(function ({
  children,
  title,
}: {
  children: ReactNode;
  title?: string;
}) {
  return (
    <Page footer>
      <PageCard>
        <TitleSection title={title ?? "NAF Kjøpekontrakt"}>
          {children}
        </TitleSection>
      </PageCard>
    </Page>
  );
})`
  flex: 1 0 auto;

  ${PageCard} {
    max-width: 40em;
    margin: auto;
  }

  ${PageCard} ${PageCardContent} {
    padding: 1em;
  }

  p:last-child {
    margin: 0;
  }
`;
