import { ImgHTMLAttributes } from "react";
import styled from "styled-components";
import Illustration from "./graphics/404_294x268.svg";

export const NotFoundIllustration = styled(function ({
  width = 268,
  ...props
}: Omit<
  ImgHTMLAttributes<HTMLImageElement>,
  "width" | "height" | "alt" | "src"
> & { width?: number }) {
  return (
    <img
      {...props}
      src={Illustration}
      alt="404"
      width={width}
      height={(268 / 294) * width}
    />
  );
})``;
