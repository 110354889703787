import { ReactNode } from "react";
import { useValidationContextMessage } from "../OnboardingFormValidationContext";
import { LabeledInputComponent } from "../../../../forms/LabeledInputComponent";
import FormGroup from "../../../../forms/FormGroup";
import ErrorMessage from "../../../../shared/error/ErrorMessage";

export function OnboardingFormCustomInputWrapper({
  children,
  name,
  label,
  className,
}: {
  children: ReactNode;
  name: string;
  label: string;
  className: string;
}) {
  const validationMessage = useValidationContextMessage(name);
  return (
    <FormGroup className={className}>
      <LabeledInputComponent
        id={`membership.${name}`}
        label={label}
        hasError={!!validationMessage}
      >
        {children}
        {validationMessage ? (
          <ErrorMessage>{validationMessage}</ErrorMessage>
        ) : null}
      </LabeledInputComponent>
    </FormGroup>
  );
}
