import { type ReactNode, createContext, useContext, useMemo } from "react";
import { useLoadContractContext } from "../contract/LoadContractContext";
import {
  type ApiError,
  type IRestClient,
  type Middleware,
  apiClientFunction,
  createRestClient,
} from "./ApiClient";
import type { ContractToken } from "../contract/model/ContractViewModel";
import { getContractToken } from "../contract/ContractParams";

const TOKEN_HEADER = "X-Token";

export const ContractClientContext = createContext<IRestClient | null>(null);

export function useContractClient() {
  const context = useContext(ContractClientContext);

  if (!context) throw new Error("No ContractClientContext found!");

  return context;
}

export function withContractToken(contractToken: ContractToken): Middleware {
  return (base) => (method, uri, content, headers) =>
    base(method, uri, content, {
      ...headers,
      [TOKEN_HEADER]: contractToken,
    });
}

function createContractClient(token: ContractToken): IRestClient {
  const middleware = token ? withContractToken(token) : null;
  const send = middleware ? middleware(apiClientFunction) : apiClientFunction;
  return createRestClient(send);
}

export function ContractClientProvider({ children }: { children: ReactNode }) {
  const { contract } = useLoadContractContext();
  const contractToken = getContractToken(contract.data);
  const client = useMemo(
    () => createContractClient(contractToken),
    [contractToken],
  );

  return (
    <ContractClientContext.Provider value={client}>
      {children}
    </ContractClientContext.Provider>
  );
}

export interface ImmutableClient<T> {
  isLoading: boolean;
  result: T | null;
  error: ApiError | null;
  refetch(): Promise<T | null>;
}
