import { FlowPage } from "../formatting/Page";
import LoadingBox, { LoadingBoxProps } from "./LoadingBox";

export default function FlowLoadingPage({
  footer,
  ...props
}: LoadingBoxProps & { footer?: boolean }) {
  return (
    <FlowPage footer={footer}>
      <LoadingBox {...props} />
    </FlowPage>
  );
}
