import { ExternalTextLink, TextLink } from "@naf/teamscheme";
import { LocationDescriptor } from "@naf/teamscheme";
import { Dispatch, HTMLAttributes, SetStateAction } from "react";
import styled from "styled-components";
import LabeledBooleanCheckbox from "../../forms/LabeledBooleanCheckbox";

export interface TermsBase {
  name: string;
}
export interface ExternalTerms extends TermsBase {
  href: string;
}

export interface LocalTerms extends TermsBase {
  linkTo: LocationDescriptor;
}

export type Terms = ExternalTerms | LocalTerms;
interface TermsCheckboxProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "children"> {
  accepted: boolean;
  setAccepted: Dispatch<SetStateAction<boolean>>;
  terms: Terms;
}

export default styled(function TermsCheckbox({
  terms,
  accepted,
  setAccepted,
  ...props
}: TermsCheckboxProps) {
  const label = <>vilkårene for {terms.name}</>;
  return (
    <div {...props}>
      <LabeledBooleanCheckbox
        name="terms"
        value={accepted}
        onChange={setAccepted}
        required
        label={
          <>
            Jeg aksepterer{" "}
            {"href" in terms ? (
              <ExternalTextLink href={terms.href}>{label}</ExternalTextLink>
            ) : (
              <TextLink target="_blank" linkTo={terms.linkTo}>
                {label}
              </TextLink>
            )}
            .
          </>
        }
      />
    </div>
  );
})`
  margin-bottom: ${({ theme }) => theme.spacing.space16};
`;
