export const MEMBERSHIP_BENEFITS = [
  { label: "Juridisk og bilteknisk rådgivning" },
  { label: "Veihjelp i Norge og Europa", requiresRoadHelp: true },
  { label: "Rabatt hos våre NAF-sentre" },
  { label: "Egenandelsforsikring for leiebil", requiresRoadHelp: true },
  { label: "Nøkkelforsikring", requiresRoadHelp: true },
  { label: "Rabatter og gode medlemstilbud" },
  { label: "Rabatt på bilforsikring hos Gjensidige", contractRelated: true },
  { label: "Drivstoffrabatt hos Circle K", contractRelated: true },
];
