import { LocationDescriptor } from "@naf/teamscheme";
import { TitleSection } from "../../../formatting/CardSection";
import { getRoleName, useContract, useRole } from "../../ContractContext";
import { ActivatedContractBenefitList } from "../ActivatedContractTypePage";
import { PREMIUM_BENEFITS } from "../PremiumCard";
import { TrialMembershipOnUpgrade } from "./ToActivateButton";
import { useUpgradedContractInfo } from "./UpgradedContractInfo";

export interface UpgradedContractInfoSectionProps {
  declineLocation?: LocationDescriptor;
}

export function UpgradedContractInfoSection({
  declineLocation,
}: UpgradedContractInfoSectionProps) {
  const role = useRole();
  const info = useUpgradedContractInfo();
  const UpgradedBy =
    typeof info.upgradedBy === "number"
      ? info.upgradedBy === role
        ? "Du"
        : getRoleName(info.upgradedBy).TitleCase
      : null;
  const contract = useContract();
  return (
    <TitleSection title="Kontrakten er oppgradert til Premium!">
      <p>
        {UpgradedBy ? (
          <span>{UpgradedBy} har oppgradert kjøpekontrakten</span>
        ) : (
          <span>Kjøpekontrakten er oppgradert</span>
        )}{" "}
        til Premium og nå har dere tilgang til:
      </p>
      <ActivatedContractBenefitList benefits={PREMIUM_BENEFITS} />
      <TrialMembershipOnUpgrade contract={contract} />
    </TitleSection>
  );
}
