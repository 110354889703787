import { useMemo } from "react";
import { useContract } from "./ContractContext";
import type {
  ContractToken,
  ContractViewModel,
} from "./model/ContractViewModel";

export function getContractToken(contract: ContractViewModel): ContractToken {
  if ("buyerToken" in contract) return contract.buyerToken;
  if ("sellerToken" in contract) return contract.sellerToken;
  throw new Error("No token found in contract");
}

export function useContractToken(): ContractToken {
  const contract = useContract();

  return useMemo(() => getContractToken(contract), [contract]);
}
