import { captureException } from "@sentry/core";
import { useCallback, useEffect, useState } from "react";
import { type ApiError } from "../../api/ApiClient";
import { useContractClient } from "../../api/ContractClient";
import { useLoadOncePerLocation } from "../../shared/useLoadOncePerLocation";
import { Cancelable, makeCancelable } from "../../utils";
import { useContract } from "../ContractContext";
import { type ContractValidationResult } from "./ContractValidationResult";
import { useContractValidationResult } from "./ContractValidationResultContext";
import { type IContractValidationContext } from "./IContractValidationContext";

export function useContractValidationContextClient(): IContractValidationContext {
  const contract = useContract();
  const client = useContractClient();
  const [loading, setValidationLoading] = useState(false);
  const [error, setValidationError] = useState<ApiError | null>(null);
  const [retries, setRetries] = useState(0);
  const { result, setResult } = useContractValidationResult();

  function retry() {
    setRetries((retries) => retries + 1);
  }

  const validate = useCallback(
    async function validate(cancelable: Cancelable<ContractValidationResult>) {
      setValidationLoading(true);

      try {
        const validationResult = await cancelable.promise;
        setResult(validationResult);
        setValidationLoading(false);
        setValidationError(null);
      } catch (e) {
        const error = e as { isCanceled: true } | ApiError;
        if (!("isCanceled" in error && error.isCanceled)) {
          const apiError = error as ApiError;
          if (apiError.status === 400) {
            const validationResult =
              apiError.result as ContractValidationResult;
            setResult(validationResult);
          } else {
            setValidationError(apiError);
            captureException(error);
            console.log("validationError", error);
          }
          setValidationLoading(false);
        }
      }
    },
    [setResult],
  );

  const { loadedLocation, load } = useLoadOncePerLocation();

  const onValidate = useCallback(() => {
    if (!loadedLocation) return;
    if (contract.signature) return;

    const cancelablePromise = makeCancelable(
      client.post<ContractValidationResult>(`contract/${contract.id}/validate`),
    );

    validate(cancelablePromise);

    return () => cancelablePromise.cancel();
  }, [contract.id, contract.signature, validate, client, loadedLocation]);

  // TODO: replace with a subscription to "contract.documentHash" and "retries" changing
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    onValidate();
  }, [onValidate, contract.documentHash, retries]);

  return {
    loading,
    error,
    result: contract.signature ? VALID : result,
    retry,
    load,
  };
}
const VALID: ContractValidationResult = {
  isValid: true,
  errors: [],
  hasBuyerInviterError: false,
  hasSellerInviterError: false,
  hasSellerResponsibleError: false,
  hasBuyerResponsibleError: false,
  hasBuyerError: false,
  hasSellerError: false,
  hasConditionError: false,
  hasVehicleDataError: false,
  hasSettlementError: false,
};
