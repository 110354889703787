import { useCallback, useEffect, useState } from "react";
import { HttpMethod } from "../../shared/models/HttpMethod";
import { useAdminClient } from "./AdminClient";
import { CompactQueryResultView } from "./CompactQueryResultView";
import { QueryResult } from "./QueryResult";
import { QueryResultView } from "./QueryResultView";
import { QuerySuccessResult } from "./QuerySuccessResult";
import styled from "styled-components";
import { AdminPre } from "../shared/AdminPre";
import { Button, Card, IconName } from "@blueprintjs/core";

export type MutationParams = {
  path: string;
  method?: HttpMethod;
  body?: unknown;
};

export function useMutation<TResult>({ path, method, body }: MutationParams): {
  result: QueryResult<TResult>;
  fetch: () => Promise<TResult | undefined>;
  clear: () => void;
} {
  const [updated] = useState<Date | undefined>();
  const { data, loading, loaded, error, fetch, clear } =
    useAdminClient<TResult>();

  const doFetch = useCallback(
    () => fetch({ path, method, body }),
    [fetch, path, method, body],
  );

  return {
    fetch: doFetch,
    clear,
    result: {
      data: data as TResult,
      loading,
      loaded,
      error,
      refresh: doFetch,
      updated,
    },
  };
}

export type MutationProps<TResult> = MutationParams & {
  title?: string;
  icon?: IconName;
  disabled?: boolean;
  children?: (queryPage: QuerySuccessResult<TResult>) => JSX.Element;
};

const MutationTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  h3 {
    margin: 0;
  }

  button {
    margin-left: 1em;
  }
`;

export function CompactMutation<TResult>({
  path,
  method,
  body,
  title,
  icon,
  children,
  disabled,
}: MutationProps<TResult>) {
  const [sent, setSent] = useState<Date | undefined>();
  const { result, fetch } = useMutation<TResult>({ path, method, body });

  useEffect(() => {
    if (sent) fetch();
  }, [sent, fetch]);

  const renderSuccess = () =>
    children ? (
      children(result as QuerySuccessResult<TResult>)
    ) : (
      <AdminPre>
        <code>{JSON.stringify(result.data, null, "  ")}</code>
      </AdminPre>
    );

  return (
    <div>
      {sent ? (
        <CompactQueryResultView<TResult> queryPage={result}>
          {renderSuccess}
        </CompactQueryResultView>
      ) : (
        <Button
          disabled={disabled}
          type="button"
          icon={icon}
          onClick={() => setSent(new Date())}
        >
          {title}
        </Button>
      )}
    </div>
  );
}

export default function Mutation<TResult>({
  path,
  method,
  body,
  title,
  disabled,
  children,
}: MutationProps<TResult>) {
  const [sent, setSent] = useState<Date | undefined>();
  const { result, fetch } = useMutation<TResult>({ path, method, body });

  return (
    <Card>
      <MutationTitle>
        <h3>{title}</h3>
        <Button
          type="button"
          disabled={disabled}
          onClick={() => {
            setSent(new Date());
            return fetch();
          }}
        >
          Send
        </Button>
      </MutationTitle>
      {sent ? (
        <QueryResultView<TResult> queryPage={result}>
          {() =>
            children ? (
              children(result as QuerySuccessResult<TResult>)
            ) : (
              <AdminPre>
                <code>{JSON.stringify(result.data, null, "  ")}</code>
              </AdminPre>
            )
          }
        </QueryResultView>
      ) : null}
    </Card>
  );
}
