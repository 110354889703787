export enum ContractSource {
  None = 0,
  Web = 1,
  NafNoForm = 2,
  MembershipApp = 4,
}

export enum ContractSourceName {
  None = "None",
  Web = "Web",
  NafNoForm = "NafNoForm",
  MembershipApp = "MembershipApp",
}
