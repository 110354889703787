import { useCallback, useEffect, useMemo, useState } from "react";
import { ApiError } from "../../api/ApiClient";
import { HttpMethod } from "../../shared/models/HttpMethod";
import { useAdminLogin } from "../auth/AdminLoginContext";

const API_URL = "/api";

export type AdminFetchParams = {
  path: string;
  method?: HttpMethod;
  body?: unknown;
};

export function useAdminFetch() {
  const loginContext = useAdminLogin();

  const doFetch = useCallback(
    async function doFetch({
      path,
      method = "GET",
      body = null,
    }: AdminFetchParams): Promise<unknown> {
      const uri = `${API_URL}/${path}`;
      const headers: Record<string, string> = {
        authorization: `Bearer ${loginContext.idToken}`,
      };
      if (body) headers["content-type"] = "application/json";
      const response = await fetch(uri, {
        method,
        headers,
        body: body ? JSON.stringify(body) : null,
      });

      if (!response.ok) {
        throw new Error(
          `Response status is not OK: ${
            response.status
          }. ${await response.text()}`,
        );
      }

      const contentTypeHeader = response.headers.get("content-type");

      const data =
        contentTypeHeader &&
        contentTypeHeader.indexOf("application/json") !== -1
          ? await response.json()
          : await response.text();

      return data;
    },
    [loginContext.idToken],
  );

  return useMemo(
    () => ({
      fetch: doFetch,
    }),
    [doFetch],
  );
}

export function useAdminClient<T>() {
  const { fetch } = useAdminFetch();
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<ApiError | null>(null);

  const doFetch = useCallback(
    async function doFetch({
      path,
      method = "GET",
      body = null,
    }: AdminFetchParams) {
      setLoading(true);
      setError(null);

      try {
        const data = (await fetch({ path, method, body })) as T;

        setData(data);

        return data;
      } catch (error) {
        setError(error as ApiError);
      } finally {
        setLoading(false);
        setLoaded(true);
      }
    },
    [fetch],
  );

  const clear = useCallback(() => {
    setError(null);
    setData(null);
    setLoading(false);
  }, []);

  return useMemo(
    () => ({
      loading,
      loaded,
      data,
      error,
      fetch: doFetch,
      clear,
    }),
    [data, doFetch, loading, loaded, error, clear],
  );
}
export interface QueryRequest<TRequest> {
  method?: HttpMethod;
  path: string;
  body?: TRequest;
}

export function useAdminQueries<TRequest, TResponse>(
  requests: QueryRequest<TRequest>[],
) {
  const { fetch } = useAdminFetch();

  const [error, setError] = useState<ApiError | null>(null);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState<{ path: string; data: TResponse }[] | null>(
    null,
  );

  const clear = useCallback(() => {
    setError(null);
    setData(null);
    setLoading(false);
  }, []);

  const fetchPaths = useCallback(async () => {
    if (!requests.filter((p) => !!p).length) {
      clear();
      return;
    }

    setError(null);
    setLoading(true);

    try {
      const results = await Promise.all(
        requests.map(({ path, method, body }) =>
          fetch({ path, method: method || "GET", body: body }).then((data) => ({
            path,
            data: data as TResponse,
          })),
        ),
      );

      setData(results);
    } catch (error) {
      setError(error as ApiError);
    } finally {
      setLoading(false);
      setLoaded(true);
    }
  }, [requests, fetch, clear]);

  useEffect(() => {
    fetchPaths();
  }, [fetchPaths]);

  return useMemo(
    () => ({
      loading,
      loaded,
      error,
      data,
      reload: fetchPaths,
    }),
    [error, loading, loaded, data, fetchPaths],
  );
}

export function useAdminQuery<T>(path: string | null) {
  const client = useAdminClient();

  const { fetch, clear } = client;

  useEffect(() => {
    if (path) fetch({ path });
    else clear();
  }, [path, fetch, clear]);

  return useMemo(
    () => ({
      ...client,
      data: client.data ? (client.data as T) : null,
      loading: client.loading === null ? true : client.loading,
    }),
    [client],
  );
}
