import {
  type ReactNode,
  type Dispatch,
  type SetStateAction,
  type Reducer,
  createContext,
  useReducer,
  useContext,
} from "react";
import { type ContractResult } from "./LoadContractContext";
import { type ContractViewModel } from "./model/ContractViewModel";

type ContractState = {
  updated: Date;
  loaded: Date;
  contract: ContractResult;
} | null;

type ContractAction =
  | { type: "loaded"; value: ContractResult }
  | { type: "update"; value: SetStateAction<ContractViewModel> }
  | { type: "reset" };

interface IContractReducer {
  state: ContractState;
  dispatch: Dispatch<ContractAction>;
}

export const ContractReducerContext = createContext<IContractReducer | null>(
  null,
);

export function useContractReducerContext() {
  const context = useContext(ContractReducerContext);
  if (!context) throw new Error("No ContractReducerContext found!");
  return context;
}

const reducerFunction: Reducer<ContractState, ContractAction> = (
  prev,
  action,
) => {
  switch (action.type) {
    case "loaded":
      return {
        updated: new Date(),
        loaded: new Date(),
        contract: action.value,
      };
    case "update":
      if (!prev) {
        throw new Error("Cannot update contract before it is loaded!");
      }
      return {
        ...prev,
        updated: new Date(),
        contract: {
          ...prev.contract,
          data:
            typeof action.value === "function"
              ? action.value(prev.contract.data)
              : action.value,
        },
      };
    case "reset":
      return null;
  }
};

export function ContractReducerProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(reducerFunction, null);
  return (
    <ContractReducerContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </ContractReducerContext.Provider>
  );
}
