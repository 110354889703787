// https://vike.dev/onRenderClient
export { onRenderClient };

import { Dedupe as DedupeIntegration } from "@sentry/integrations";
import ReactDOM from "react-dom/client";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import type { OnRenderClientAsync } from "vike/types";
import { AppTrackingProvider } from "../Tracking";
import { routes } from "../routing/AppRouter";
import { ErrorBoundary } from "../ErrorBoundary";
import { captureException } from "@sentry/browser";

let root: ReactDOM.Root;
const onRenderClient: OnRenderClientAsync = async (pageContext) => {
  if (!document.location.hostname.includes("localhost")) {
    import("@sentry/browser").then((Sentry) =>
      Sentry.init({
        dsn: "https://baf6bcb4e6ac4efeb30632b56345ac6b@sentry.io/2035715",
        environment: document.location.hostname,
        release: `naf-kjopekontrakt@${
          import.meta.env.PUBLIC_ENV__REACT_APP_VERSION
        }`,
        debug: false,
        integrations: [new DedupeIntegration()],
        ignoreErrors: ["Non-Error promise rejection captured"],
      }),
    );
  }

  const container = document.getElementById("page-view");
  if (!container) throw new Error("No root found");
  const router = createBrowserRouter(routes, {
    future: {
      v7_partialHydration: true,
    },
  });
  const page = (
    <ErrorBoundary>
      <AppTrackingProvider>
        <RouterProvider router={router} />
      </AppTrackingProvider>
    </ErrorBoundary>
  );
  if (pageContext.isHydration) {
    const title = pageContext.config.documentProps?.title;
    if (title) {
      document.title = title;
    }
    root = ReactDOM.hydrateRoot(container, page, {
      onRecoverableError(err, info) {
        captureException(err, { extra: { info } });
        console.log({ err, info });
      },
    });
  } else {
    if (!root) {
      root = ReactDOM.createRoot(container);
    }
    root.render(page);
  }
};
