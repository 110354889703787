import { ITheme, onDesktop, onMinWidthXtraLarge } from "@naf/teamscheme-core";
import { HTMLAttributes } from "react";
import styled, { useTheme } from "styled-components";
import CreateContractCard, {
  CreateContractCardProps,
} from "../contract/create-contract/CreateContractCard";

interface BaseLandingPageHeroSizes {
  paddingTopHeroDesktop: string;
  paddingTopHeroLargeDesktop: string;
  heightH1: string;
  paddingCreateReportCard: string;
  maxWidthCreateReportCard: string;
}
interface LandingPageHeroSizes extends BaseLandingPageHeroSizes {
  maxWidthIllustration: string;
  maxHeightIllustration: string;
}

function baseLandingPageHeroSizes(theme: ITheme): BaseLandingPageHeroSizes {
  return {
    paddingTopHeroDesktop: theme.spacing.space20,
    paddingTopHeroLargeDesktop: theme.spacing.space80,
    heightH1: theme.fontStyle.headers.header1["font-size"],
    paddingCreateReportCard: theme.spacing.space48,
    maxWidthCreateReportCard: `${650}px`,
  };
}

function useLandingPageHeroSizes(
  width: number,
  height: number,
  maxWidth: number,
): LandingPageHeroSizes {
  const theme = useTheme();
  const viewportIllustration = {
    width,
    height,
  };
  const aspectRatioIllustration =
    viewportIllustration.height / viewportIllustration.width;
  const maxWidthIllustration = maxWidth;

  return {
    ...baseLandingPageHeroSizes(theme),
    maxWidthIllustration: `${maxWidthIllustration}px`,
    maxHeightIllustration: `${
      maxWidthIllustration * aspectRatioIllustration
    }px`,
  };
}

interface HeroProps extends HTMLAttributes<HTMLDivElement> {
  landingPageHeroSizes: LandingPageHeroSizes;
}
const Hero = styled(function ({
  landingPageHeroSizes: _,
  ...props
}: HeroProps) {
  return <div {...props} />;
})`
  width: 100%;
  position: relative;
  padding: 0 ${({ theme }) => theme.spacing.space8};

  ${({ theme, landingPageHeroSizes }) => onDesktop`
      padding: ${landingPageHeroSizes.paddingTopHeroDesktop} ${theme.spacing.space24} 0;
    `}
  ${({ landingPageHeroSizes }) => onMinWidthXtraLarge`
      padding-top: ${landingPageHeroSizes.paddingTopHeroLargeDesktop};
    `}
`;

const HeroContent = styled.div`
  margin: auto;
  max-width: ${({ theme }) => theme.customBreakpoints.maxHeroWidth}px;
  display: flex;
  flex-direction: column-reverse;
  position: relative;
  align-items: center;
  z-index: 1;

  ${onMinWidthXtraLarge`
      flex-direction: row;
      align-items: flex-start;
      justify-content: center;
    `}
`;

interface StyledCreateContractProps extends CreateContractCardProps {
  landingPageHeroSizes: LandingPageHeroSizes;
}

const StyledCreateContract = styled(function (
  props: StyledCreateContractProps,
) {
  const { landingPageHeroSizes, ...createCardProps } = props;
  return <CreateContractCard {...createCardProps} />;
})`
  width: 100%;
  padding: ${({ theme }) => theme.spacing.space24};

  ${({ landingPageHeroSizes }) => onDesktop`
    max-width: ${landingPageHeroSizes.maxWidthCreateReportCard};
    padding: ${landingPageHeroSizes.paddingCreateReportCard};
  `};
`;

const ImageWrapper = styled(function ({
  landingPageHeroSizes: _,
  ...props
}: HTMLAttributes<HTMLDivElement> & {
  landingPageHeroSizes: LandingPageHeroSizes;
}) {
  return <div {...props} />;
})`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 0 ${({ theme }) => theme.spacing.space64};

  img {
    width: 100%;
    height: auto;
    max-width: ${({ landingPageHeroSizes }) =>
      landingPageHeroSizes.maxWidthIllustration};
  }

  ${({ theme }) => onDesktop`
      padding: 0 ${theme.spacing.space8};
    `};
  ${onMinWidthXtraLarge`
      padding: 0;
    `};
`;

const HeroBackground = styled(function ({
  landingPageHeroSizes: _,
  ...props
}: HTMLAttributes<HTMLDivElement> & {
  landingPageHeroSizes: LandingPageHeroSizes;
}) {
  return <div {...props} />;
})`
  position: absolute;
  top: 0;
  background: ${({ theme }) => theme.nafColor.primary.dew};
  height: 50%;
  max-height: 50vh;
  width: 100%;
  margin: 0 -${({ theme }) => theme.spacing.space8};

  ${({ landingPageHeroSizes, theme }) => {
    const {
      paddingTopHeroDesktop,
      maxHeightIllustration: maxHeightCarryingIllustration,
      heightH1,
      paddingCreateReportCard,
    } = landingPageHeroSizes;
    return onDesktop`
        height: calc(
          ${paddingTopHeroDesktop} +
          ${maxHeightCarryingIllustration} +
          ${heightH1} +
          ${paddingCreateReportCard}
        );
        margin: 0 -${theme.spacing.space24};
      `;
  }}

  ${({ landingPageHeroSizes }) => {
    const {
      paddingTopHeroLargeDesktop,
      maxHeightIllustration: maxHeightCarryingIllustration,
    } = landingPageHeroSizes;
    return onMinWidthXtraLarge`
        height: calc(
          ${paddingTopHeroLargeDesktop} +
          ${maxHeightCarryingIllustration}
        );
      `;
  }}
`;

export interface LandingHeroIllustration {
  src: string;
  width: number;
  height: number;
  maxWidth: number;
}

export interface LandingHeroProps extends CreateContractCardProps {
  illustration: LandingHeroIllustration;
}

export function LandingHero(props: LandingHeroProps) {
  const { illustration, ...createCardProps } = props;
  const landingPageHeroSizes = useLandingPageHeroSizes(
    illustration.width,
    illustration.height,
    illustration.maxWidth,
  );

  return (
    <Hero landingPageHeroSizes={landingPageHeroSizes}>
      <HeroContent>
        <StyledCreateContract
          landingPageHeroSizes={landingPageHeroSizes}
          {...createCardProps}
        />
        <ImageWrapper landingPageHeroSizes={landingPageHeroSizes}>
          <img
            src={illustration.src}
            alt={props.title}
            width={illustration.width}
            height={illustration.height}
          />
        </ImageWrapper>
      </HeroContent>
      <HeroBackground landingPageHeroSizes={landingPageHeroSizes} />
    </Hero>
  );
}
