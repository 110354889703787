import styled from "styled-components";

const Main = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  background-position: top right;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: 90%;
`;

export default Main;
