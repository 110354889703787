import { Role } from "#vehicle-contract/common/lib/model/contract/Role";
import ROLES from "../../contract/roles";

export function roleName(role: Role) {
  switch (role) {
    case ROLES.SELLER:
      return "Seller";
    case ROLES.BUYER:
      return "Buyer";
    default:
      throw new Error(`Unrecognized role ${role}`);
  }
}
