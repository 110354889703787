import { Button, type ButtonBaseProps, ButtonVariant } from "@naf/teamscheme";
import {
  ButtonLink,
  type ButtonLinkBaseProps,
  ButtonLinkVariant,
} from "@naf/teamscheme";
import { onDesktop } from "@naf/teamscheme-core";
import type { HTMLAttributes, ReactNode } from "react";
import { type ImgHTMLAttributes } from "react";
import styled from "styled-components";
import { EVENTS } from "../../Tracking";
import Card, { type CardProps } from "../../formatting/Card";
import BenefitList from "../../shared/elements/BenefitList";
import {
  ButtonAction,
  ButtonLinkAction,
} from "../../shared/utils/ButtonAction";

const Label = styled.div`
  position: absolute;
  background: ${({ theme }) => theme.nafColor.signature.yellow};
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  right: ${({ theme }) => theme.spacing.space32};
  top: -${({ theme }) => theme.spacing.space12};
  padding: 0 ${({ theme }) => theme.spacing.space8};
`;

export interface TypeCardProps extends CardProps {
  label?: string;
}

const TypeCard = styled(function ({
  label,
  children,
  ...props
}: TypeCardProps) {
  return (
    <Card {...props}>
      {label ? <Label>{label}</Label> : null}
      {children}
    </Card>
  );
})`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  font-size: 0.9em;
  max-width: ${8 * 60}px;
  border: 1px solid ${({ theme }) => theme.nafColor.neutral.neutral2};
`;

export default TypeCard;

export const TypeCardImage = styled(function ({
  className,
  alt,
  ...props
}: ImgHTMLAttributes<HTMLImageElement>) {
  return (
    <div className={className}>
      {/* biome-ignore lint/a11y/useAltText: maintained through component */}
      <img alt={alt} {...props} />
    </div>
  );
})`
  height: ${8 * 10}px;
  display: flex;
  align-items: flex-end;

  img {
    display: block;
    width: ${8 * 8}px;
    height: auto;
    max-height: 100%;
  }
`;
export const TypeCardHeader = styled.div`
  margin: ${({ theme }) => `0 0 ${theme.spacing.space16} 0`};

  ${TypeCardImage} {
    flex: 0 1 auto;
    margin: auto 1em auto 0;
  }

  ${onDesktop`
    flex-direction: column;

    ${TypeCardImage} {
      margin-bottom: 1em;
    }
  `}
`;
export const TypeCardTitle = styled.h3`
  margin: ${({ theme }) => `0 0 ${theme.spacing.space8} 0`};
  ${({ theme }) => theme.fontStyle.headers.header3};
`;

export const TypeCardBenefitList = styled(BenefitList)`
  flex: 1;
`;

function useButtonTrackEvent({ type }: { type?: string }) {
  return type
    ? { ...EVENTS.CONTRACT_TYPES.SELECT_OPTION, label: type }
    : undefined;
}

interface TypeCardButtonProps {
  className?: string;
  typeKey?: string;
  children: ReactNode;
  action: ButtonAction;
  variant?: ButtonVariant;
}

export const TypeCardButton = styled(function ({
  typeKey,
  children,
  action: props,
  variant,
  className,
}: TypeCardButtonProps) {
  const trackEvent = useButtonTrackEvent({ type: typeKey });
  variant = variant || "secondary";
  const base: ButtonBaseProps & HTMLAttributes<HTMLElement> = {
    variant,
    trackEvent,
    children,
    className,
  };
  if ("linkTo" in props) {
    const { linkTo, ...rest } = props;
    return <Button {...rest} {...base} linkTo={linkTo} />;
  }
  if ("href" in props) {
    const { href, ...rest } = props;
    return <Button {...rest} {...base} href={href} />;
  }
  if ("onClick" in props && !!props.onClick) {
    const { onClick, ...rest } = props;
    return <Button {...rest} {...base} onClick={onClick} />;
  }
  if ("type" in props && !!props.type && !(props.type === "reset")) {
    const { type, ...rest } = props;
    return <Button {...rest} {...base} type={type} />;
  }
  throw new Error("type, href, to or onClick is required!");
})<TypeCardButtonProps>`
  flex: 0 0;
  display: flex;
`;

interface TypeCardButtonLinkProps
  extends Omit<TypeCardButtonProps, "action" | "variant"> {
  action: ButtonLinkAction;
  variant?: ButtonLinkVariant;
}

export const TypeCardButtonLink = styled(function ({
  typeKey,
  children,
  action: props,
  variant,
  className,
}: TypeCardButtonLinkProps) {
  const trackEvent = useButtonTrackEvent({ type: typeKey });
  variant = variant || "secondary";
  const base: ButtonLinkBaseProps & HTMLAttributes<HTMLElement> = {
    variant,
    trackEvent,
    children,
    className,
  };
  if ("linkTo" in props) {
    const { linkTo, ...rest } = props;
    return <ButtonLink {...rest} {...base} linkTo={linkTo} />;
  }
  if ("href" in props) {
    const { href, ...rest } = props;
    return <ButtonLink {...rest} {...base} href={href} />;
  }
  if ("onClick" in props && !!props.onClick) {
    const { onClick, ...rest } = props;
    return <ButtonLink {...rest} {...base} onClick={onClick} />;
  }
  if ("type" in props && !!props.type && !(props.type === "reset")) {
    const { type, ...rest } = props;
    return <ButtonLink {...rest} {...base} type={type} />;
  }
  throw new Error("type, href, linkTo or onClick is required!");
})<TypeCardButtonLinkProps>`
  flex: 0 0;
  display: flex;
`;

export const LinkPlaceholder = styled.div`
  ${onDesktop`
    min-height: 4ex;
  `}

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
