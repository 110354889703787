import { ReactNode } from "react";
import styled from "styled-components";
import ContractPageCard from "../../formatting/ContractPageCard";
import { FlowPage } from "../../formatting/Page";
import BenefitList from "../../shared/elements/BenefitList";
import { ContractBreadcrumb } from "../contract-landing/ReturnToLandingPage";
import { EditableStatusMessage } from "../status/ContractStatus";

export const ActivatedContractBenefitList = styled(BenefitList)`
  text-align: left;
`;

export default function ActivatedContractTypePage({
  children,
}: {
  children: ReactNode;
}) {
  return (
    <FlowPage>
      <ContractPageCard>
        <ContractBreadcrumb title="Oppgradering" />
        <EditableStatusMessage />
        {children}
      </ContractPageCard>
    </FlowPage>
  );
}
