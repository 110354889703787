import {
  useRef,
  type Dispatch,
  type SetStateAction,
  useEffect,
  HTMLAttributes,
  ReactNode,
} from "react";
import FormGroup from "../../forms/FormGroup";
import LabeledBooleanCheckbox from "../../forms/LabeledBooleanCheckbox";
import { BooleanRadioField } from "../../forms/fields/RadioField";
import { type MembershipConsent } from "./useGetMembershipConsents";
import { validateConsentAnswers } from "./validateConsent";
import { type ConsentNodeAnswer } from "./ConsentAnswers";
import styled from "styled-components";
import { HightlightedConsentBox } from "./HightlightedConsentBox";

const ConsentsForm = styled.div``;

function MembershipConsentsFormInner({
  rawConsents,
  answeredConsents,
  onChange,
}: {
  rawConsents: MembershipConsent[];
  answeredConsents: ConsentNodeAnswer[];
  onChange(externalKey: string, consented: boolean | null | undefined): void;
}) {
  const inputs = useRef<{ [key: string]: HTMLInputElement }>({});

  useEffect(() => {
    const validation = validateConsentAnswers(
      rawConsents ?? [],
      answeredConsents,
    );

    for (const [key, input] of Object.entries(inputs.current)) {
      const error = validation.invalidConsents?.find(
        (e) => e.externalKey === key,
      );

      input.setCustomValidity(error ? error.message : "");
    }
  }, [rawConsents, answeredConsents]);

  return (
    <ConsentsForm>
      <h3>Dine samtykker</h3>
      {rawConsents.map((consent) => {
        const { text, description, children, labelChildren, externalKey } =
          consent;
        const name = `consent_${externalKey}`;
        const match = answeredConsents.find(
          (x) => x.externalKey === externalKey,
        );

        const content = (
          <FormGroup key={externalKey}>
            <BooleanRadioField
              label={text}
              description={description}
              value={match ? match.consented : null}
              onChange={(checked: boolean) => {
                onChange(externalKey, checked);

                for (const child of consent.children) {
                  onChange(child.externalKey, checked);
                }
              }}
              name={name}
              inputRef={(c, input) => {
                if (c === true) {
                  if (input) inputs.current[externalKey] = input;
                  else delete inputs.current[externalKey];
                }
              }}
            />
            {match?.consented && children.length ? (
              <>
                <p>{labelChildren}</p>
                {children.map(({ externalKey, text }) => {
                  const subMatch = answeredConsents.find(
                    (x) => x.externalKey === externalKey,
                  );
                  return (
                    <LabeledBooleanCheckbox
                      key={externalKey}
                      name={`consent_${externalKey}`}
                      label={text}
                      value={subMatch ? subMatch.consented : false}
                      onChange={(checked: boolean) =>
                        onChange(externalKey, checked)
                      }
                      ref={(input) => {
                        if (input) inputs.current[externalKey] = input;
                        else delete inputs.current[externalKey];
                      }}
                    />
                  );
                })}
              </>
            ) : null}
          </FormGroup>
        );

        if (consent.consentId === "10") {
          return (
            <HightlightedConsentBox
              key={externalKey}
              checked={match?.consented ?? false}
            >
              {content}
            </HightlightedConsentBox>
          );
        }

        return content;
      })}
    </ConsentsForm>
  );
}

export default function MembershipConsentsForm({
  rawConsents,
  answeredConsents,
  setAnsweredConsents,
}: {
  rawConsents: MembershipConsent[];
  answeredConsents: ConsentNodeAnswer[];
  setAnsweredConsents: Dispatch<SetStateAction<ConsentNodeAnswer[]>>;
}) {
  function onChange(changedConsentId: string, changedConsented: boolean) {
    setAnsweredConsents((prevAnsweredConsents) => {
      //Checks if the answered consent contains child-consents
      const rawMatch = rawConsents.find(
        (consent) => consent.consentId === changedConsentId,
      );
      const rawChildren = rawMatch?.children || [];
      const updatedChildConsents = rawChildren.reduce((acc, childConsent) => {
        const childMatch = acc.find(
          (x) => x.externalKey === childConsent.consentId,
        );

        if (!childMatch) {
          //if parent is true and child-consents are not answered, they should default to false, as we render checkboxes for child-consents
          const neweAnswer: ConsentNodeAnswer = {
            externalKey: childConsent.consentId,
            consented: false,
          };
          acc.push(neweAnswer);
          return acc;
        }
        if (childMatch.consented && !changedConsented) {
          //likewise if parent is false and child-consent is true, they should default to false
          return changeConsent(acc, childConsent.consentId, false);
        }
        return acc;
      }, prevAnsweredConsents);

      const match = updatedChildConsents.find(
        (y) => y.externalKey === changedConsentId,
      );

      if (match) {
        return changeConsent(
          updatedChildConsents,
          changedConsentId,
          changedConsented,
        );
      }

      const newConsent: ConsentNodeAnswer = {
        externalKey: changedConsentId,
        consented: changedConsented,
      };
      return [...updatedChildConsents, newConsent];
    });
  }

  function changeConsent(
    changedAnsweredConsents: ConsentNodeAnswer[],
    externalKey: string,
    consented: boolean,
  ): ConsentNodeAnswer[] {
    return changedAnsweredConsents.map((y) => ({
      ...y,
      consented: y.externalKey === externalKey ? consented : y.consented,
    }));
  }

  if (!rawConsents.length) {
    return null;
  }

  return (
    <MembershipConsentsFormInner
      rawConsents={rawConsents}
      answeredConsents={answeredConsents}
      onChange={onChange}
    />
  );
}
