import { type LocationDescriptorObject } from "@naf/teamscheme";
import { useEffect } from "react";
import { StaticLoadingScreen } from "../shared/LoadingScreen";
import { handleRedirectCallback, loginWithRedirect } from "./AuthProvider";
import {
  useLoaderData,
  useNavigate,
  type LoaderFunction,
} from "react-router-dom";

interface LoginCallbackLoaderResult {
  redirect?: LocationDescriptorObject;
}

export const loginCallbackLoader: LoaderFunction = async ({
  request,
}): Promise<LoginCallbackLoaderResult | null> => {
  if (typeof window === "undefined") return null;

  const { searchParams } = new URL(request.url);

  if (searchParams.has("code") && searchParams.has("state")) {
    return await handleRedirectCallback();
  }

  if (searchParams.get("success") === "true") {
    await loginWithRedirect({ targetUrl: "/" });
  }

  return {};
};

loginCallbackLoader.hydrate = false;

export function LoginCallbackPage() {
  const navigate = useNavigate();
  const loaderData = useLoaderData() as LoginCallbackLoaderResult | null;
  const redirect = loaderData?.redirect;

  useEffect(() => {
    if (redirect) navigate(redirect);
  }, [redirect, navigate]);

  return <StaticLoadingScreen />;
}
