import { ButtonLink } from "@naf/teamscheme";
import { DismissIcon } from "@naf/teamscheme";
import {
  type ReactNode,
  useState,
  useCallback,
  createContext,
  useContext,
} from "react";
import { EVENTS, useTracking } from "../../Tracking";
import BottomPanel from "../../layout/BottomPanel";
import { useRole, useRoleName } from "../ContractContext";
import { type ContractUpdate } from "./ContractUpdate";
import {
  useContractUpdatesClient,
  useGetContractClient,
  useOnUpdate,
} from "./ContractUpdates";
import ContractUpdatesModal from "./ContractUpdatesModal";
import { useContractReducerContext } from "../ContractReducer";
import { type ContractViewModel } from "../model/ContractViewModel";
import UpdateType from "./UpdateType";
import { useLoadContractContext } from "../LoadContractContext";
import styled from "styled-components";

interface IContractFieldChangesContext {
  isUpdated: boolean;
}

const ContractFieldChangesContext =
  createContext<IContractFieldChangesContext | null>(null);

export function ContractFieldChangesProvider({
  children,
}: {
  children: ReactNode;
}) {
  const { dispatch } = useContractReducerContext();
  const { updated } = useLoadContractContext();

  const updatesClient = useContractUpdatesClient();
  const contractClient = useGetContractClient();

  const updateContract = useCallback(
    function doUpdateFields(updated: ContractViewModel) {
      dispatch({
        type: "update",
        value: (prev) =>
          prev.documentHash !== updated.documentHash ? updated : prev,
      });
    },
    [dispatch],
  );

  const [from, setFrom] = useState(new Date(updated));

  const checkAndApplyUpdates = useCallback(
    async function () {
      const changesResult = await updatesClient.fetch({ from }).promise;

      if (changesResult?.items.length) {
        const updatedContract = await contractClient.fetch().promise;

        updateContract(updatedContract);
      }
    },
    [updatesClient.fetch, updateContract, contractClient.fetch, from],
  );

  useOnUpdate(
    UpdateType.Fields,
    useCallback(() => {
      updatesClient.fetch({
        from,
      });
    }, [updatesClient.fetch, from]),
  );

  return (
    <ContractFieldChangesContext.Provider
      value={{
        isUpdated: !updatesClient.isLoading && !updatesClient.error,
      }}
    >
      {children}
      <UnreadChanges
        onRead={() => updated && setFrom(updated)}
        unreadChanges={updatesClient.result?.items ?? []}
        from={from}
      />
      {updatesClient.error ? (
        <BottomPanel>
          <span>Kunne ikke hente endringene til kontrakten.</span>
          <ButtonLink
            variant="primary"
            onClick={checkAndApplyUpdates}
            isLoading={updatesClient.isLoading}
          >
            Prøv på nytt
          </ButtonLink>
        </BottomPanel>
      ) : null}
    </ContractFieldChangesContext.Provider>
  );
}

export function useContractFieldChangesContext() {
  const context = useContext(ContractFieldChangesContext);
  if (!context) throw new Error("No ContractFieldChangesContext found!");
  return context;
}

const UpdatesPanel = styled.div`
  flex: 1;
  display: flex;
  max-width: 750px;
  justify-content: space-between;
  align-items: center;
  text-align: left;

  button {
    background: none;
    border: none;
    color: black;
    padding: 0;
    cursor: pointer;
  }

  button:not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing.space16};
  }
`;

function UnreadChanges({
  onRead: readUpdates,
  unreadChanges,
  from,
}: {
  onRead(): void;
  unreadChanges: ContractUpdate[];
  from: Date;
}) {
  const { state } = useContractReducerContext();
  const updated = state?.updated;
  const { other } = useRoleName();
  const tracking = useTracking();
  const role = useRole();
  const changesByOther = unreadChanges
    .filter((c) => c.role !== role)
    .filter((c) => new Date(c.date) > from);
  return (
    <ContractUpdatesModal
      onRead={() => {
        readUpdates();

        tracking.event({
          ...EVENTS.CONTRACT_UPDATES_MODAL.OK,
          label: `${changesByOther.length} updates`,
        });
      }}
      updates={changesByOther}
    >
      {({ open }) =>
        from && updated && from < updated && changesByOther.length ? (
          <BottomPanel>
            <UpdatesPanel>
              <ButtonLink
                variant="secondary"
                onClick={() => {
                  open();

                  tracking.event({
                    ...EVENTS.CONTRACT_UPDATES_MODAL.OPEN,
                    label: `${changesByOther.length} updates`,
                  });
                }}
              >
                Se endringer gjort av {other}
              </ButtonLink>{" "}
              <button
                type="button"
                onClick={() => {
                  readUpdates();

                  tracking.event({
                    ...EVENTS.CONTRACT_UPDATES_MODAL.DISMISS,
                    label: `${changesByOther.length} updates`,
                  });
                }}
              >
                <DismissIcon size={14} />
              </button>
            </UpdatesPanel>
          </BottomPanel>
        ) : null
      }
    </ContractUpdatesModal>
  );
}
