import { onDesktop } from "@naf/teamscheme-core";
import {
  type HTMLAttributes,
  type InputHTMLAttributes,
  forwardRef,
} from "react";
import { type ReactNode } from "react";
import styled, { css } from "styled-components";

const RadioWrap = styled.div`
  display: inline-flex;
  width: fit-content;
  height: calc(
    ${({ theme }) => theme.fontStyle.article.articleText["font-size"]} *
      ${({ theme }) => theme.fontStyle.article.articleText["line-height"]}
  );
  align-items: center;
  margin-top: 2px; /* Align with top of text */

  @media (max-width: ${({ theme }) => theme.breakpoints.s}) {
    height: calc(
      ${({ theme }) => theme.fontStyle.bodyText.bodyText["font-size"]} *
        ${({ theme }) => theme.fontStyle.bodyText.bodyText["line-height"]}
    );
  }
`;

const StyledRadio = styled.div`
  min-width: ${({ theme }) => theme.spacing.space24};
  width: ${({ theme }) => theme.spacing.space24};
  height: ${({ theme }) => theme.spacing.space24};
  border-radius: 50%;
  box-sizing: border-box;
  border: 2px solid;
  background: ${({ theme }) => theme.themeLight.background.default};
  border-color: ${({ theme }) => theme.themeLight.border.heavy};
`;

export function RadioButtonElement() {
  return (
    <RadioWrap>
      <StyledRadio />
    </RadioWrap>
  );
}

export const RadioInputElement = styled.input`
  opacity: 0;
  position: absolute;
  left: 0;
  cursor: pointer;
  width: ${({ theme }) => theme.spacing.space24};
  height: ${({ theme }) => theme.spacing.space24};

  &:focus-visible ~ ${RadioWrap} > ${StyledRadio} {
    box-shadow: 0 0 0 3px ${({ theme }) => theme.focus.color};
  }

  &:active ~ ${RadioWrap} > ${StyledRadio} {
    background: ${({ theme }) => theme.nafColor.neutral.neutral1};
  }

  &:checked ~ ${RadioWrap} > ${StyledRadio} {
    border: ${({ theme }) => theme.spacing.space8} solid;
    border-color: ${({ theme }) =>
      theme.themeLight.componentColors.interactiveElement.active};
    background: ${({ theme }) => theme.themeLight.typography.defaultText};
  }
`;

export const RadioLabel = styled.label`
  position: relative;
  display: flex;
  align-items: flex-start;
  cursor: pointer;

  & > div {
    ${({ theme }) => theme.fontStyle.article.articleText};

    @media (max-width: ${({ theme }) => theme.breakpoints.s}) {
      ${({ theme }) => theme.fontStyle.bodyText.bodyText};
    }
  }
`;

export const RadioInput = forwardRef<
  HTMLInputElement,
  {
    label: ReactNode;
  } & InputHTMLAttributes<HTMLInputElement>
>(function RadioInput({ label, ...props }, ref) {
  return (
    <RadioLabel>
      <RadioInputElement ref={ref} {...props} type="radio" />
      <RadioButtonElement />
      <RadioLabelWrapper>{label}</RadioLabelWrapper>
    </RadioLabel>
  );
});

export const RadioLabelWrapper = styled.div`
  display: flex;
  flex: 1;
  margin-left: ${({ theme }) => theme.spacing.space8};
`;

const GroupWrapper = styled(function ({
  stacked: _,
  ...props
}: HTMLAttributes<HTMLDivElement> & { stacked?: boolean }) {
  return <div {...props} />;
})`
  display: flex;
  flex-direction: column;
  ${RadioLabel} {
    margin-bottom: ${({ theme }) => theme.spacing.space8};
  }

  ${({ stacked, theme }) => onDesktop`
    flex-wrap: wrap;
    flex-direction: ${stacked ? "column" : "row"};

    ${RadioLabel} {
      ${!stacked && css`margin-right: ${theme.spacing.space16};`}
    }
  `}
`;

export interface RadioOption<T> {
  value: T;
  key: string;
  label: ReactNode;
}

export interface InputRadioGroupProps<T> {
  name: string;
  value: T;
  options: RadioOption<T>[];
  onChange(value: T): void;
  stacked?: boolean;
  inputProps?: Omit<
    InputHTMLAttributes<HTMLInputElement>,
    "name" | "onChange" | "checked"
  >;
  inputRef?(value: T, input: HTMLInputElement | null): void;
}

export function RadioGroup<T>({
  name,
  value,
  options,
  onChange,
  stacked = false,
  inputProps,
  inputRef,
}: InputRadioGroupProps<T>) {
  return (
    <GroupWrapper stacked={stacked}>
      {options.map(({ value: optionValue, key, label }) => (
        <RadioInput
          {...inputProps}
          ref={inputRef && ((input) => inputRef(optionValue, input))}
          key={key}
          name={name}
          label={label}
          checked={value === optionValue}
          onChange={() => onChange(optionValue)}
        />
      ))}
    </GroupWrapper>
  );
}
