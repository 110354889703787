import { Role } from "#vehicle-contract/common/lib/model/contract/Role";

export const BUYER = 1;
export const SELLER = 0;

const ROLES = { BUYER, SELLER };

export const ALL_ROLES = [Role.Buyer, Role.Seller];

export default ROLES;
