import { ButtonLinkModal, FlyoutModal } from "@naf/teamscheme";
import { ContractExplanationCard } from "./ContractExplanationCard";
import { Info as InfoIconOutline } from "@styled-icons/material-outlined/Info";

export interface ContractExplanationModalProps {
  isOpen: boolean;
  onClose(): void;
}

export default function ContractExplanationModal(
  props: ContractExplanationModalProps,
) {
  return (
    <FlyoutModal {...props}>
      <ContractExplanationCard />
    </FlyoutModal>
  );
}

export function ContractExplanationButtonLinkModal() {
  return (
    <ButtonLinkModal
      title="Hva betyr feltene?"
      variant="secondary"
      icon={<InfoIconOutline size={24} />}
    >
      <ContractExplanationCard />
    </ButtonLinkModal>
  );
}
