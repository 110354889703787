import { DateString as TeamSchemeDateString } from "@naf/teamscheme";
import { HTMLAttributes, ReactNode } from "react";
import { DateString as CommonDateString } from "#vehicle-contract/common/lib/model/common/DateString";

export type DateTimeCellsProps = {
  value: CommonDateString | TeamSchemeDateString | Date | null | undefined;
  placeholder?: ReactNode;
} & HTMLAttributes<HTMLTableCellElement>;

// TODO: consolidate these two. Use TeamSchemeDateString everywhere

export default function DateTimeCells({
  value,
  placeholder,
  ...props
}: DateTimeCellsProps) {
  if (!value) return <td colSpan={2}>{placeholder}</td>;

  const date = new Date(value);

  return (
    <>
      <td {...props}>{date.toLocaleDateString()}</td>
      <td {...props} title={date.toISOString().split("T")[1]}>
        {date.toLocaleTimeString()}
      </td>
    </>
  );
}
