import { createContext, useContext } from "react";
import type { Dispatch, SetStateAction } from "react";
import { Role } from "#vehicle-contract/common/lib/model/contract/Role";
import type { ContractViewModel } from "./model/ContractViewModel";

export interface ContractResult {
  data: ContractViewModel;
  role: Role;
}

export interface ILoadContractContext {
  contract: ContractResult;
  updateContract: Dispatch<SetStateAction<ContractViewModel>>;
  loaded: Date;
  updated: Date;
}

export const LoadContractContext = createContext<ILoadContractContext | null>(
  null,
);

export function useLoadContractContext() {
  const context = useContext(LoadContractContext);
  if (!context) throw new Error("No LoadContractContext found!");
  return context;
}
