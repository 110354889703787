import styled from "styled-components";
import { ContractType } from "#vehicle-contract/common/lib/model/contract/SalesContract";
import { useCustomer } from "../../../auth/CustomerProvider";
import { useContract } from "../../ContractContext";
import { AboutContractTypes } from "../AboutContractTypes";
import { PremiumUpgradeCard } from "../PremiumCard";
import { Loading } from "./Loading";
import {
  CompletedMembershipUpgrade,
  CustomerMembershipUpgrade,
} from "./MembershipUpgrade";
import { ModalContentProps } from "./ModalContentProps";
import {
  UPGRADED_TO_COMPLETE_TITLE,
  UpgradedToCompleteIngress,
} from "./UpgradedText";
import { SimpleUpgradeCard } from "../contract-upgrade/SimpleCard";

const Section = styled.section`
  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing.space64};
  }
`;

export default function UpgradeModalContent({ closeModal }: ModalContentProps) {
  const contract = useContract();
  const customer = useCustomer();

  if (customer.isLoading) return <Loading />;

  if (contract.contractType?.current === ContractType.Member) {
    return (
      <>
        <h1>{UPGRADED_TO_COMPLETE_TITLE}</h1>
        <CompletedMembershipUpgrade
          customerId={contract.contractType.upgradedCustomerId}
        />
      </>
    );
  }

  if (contract.contractType?.current === ContractType.Premium) {
    return (
      <>
        <h1>{UPGRADED_TO_COMPLETE_TITLE}</h1>
        <UpgradedToCompleteIngress />
      </>
    );
  }

  if (customer.result?.hasMembership) {
    return (
      <>
        <h1>Oppgrader kontrakten</h1>
        <CustomerMembershipUpgrade
          customer={customer.result}
          closeModal={closeModal}
        />
      </>
    );
  }

  if (
    contract.contractType?.current &&
    [ContractType.Standard, ContractType.Simple].includes(
      contract.contractType.current,
    )
  ) {
    return (
      <div>
        <h1>Oppgrader kontrakten</h1>
        <Section>
          <PremiumUpgradeCard onClick={closeModal} customer={customer.result} />
        </Section>
        <Section>
          <AboutContractTypes />
        </Section>
      </div>
    );
  }

  return (
    <div>
      <h1>Velg type kontrakt</h1>
      <Section>
        <SimpleUpgradeCard onClick={closeModal} />
        <PremiumUpgradeCard onClick={closeModal} customer={customer.result} />
      </Section>
      <Section>
        <AboutContractTypes />
      </Section>
    </div>
  );
}
