import { LocationDescriptorObject } from "@naf/teamscheme";

export enum NavigationDirection {
  Back = 0,
  Forward = 1,
}
export function addNavigationDirection(
  location: LocationDescriptorObject,
  direction: NavigationDirection,
) {
  const prevState = typeof location.state === "object" ? location.state : {};

  return {
    ...location,
    state: {
      ...prevState,
      direction,
    },
  };
}
