import { onDesktop } from "@naf/teamscheme-core";
import { ITheme, onMinWidthXtraLarge } from "@naf/teamscheme-core";
import { HtmlHTMLAttributes } from "react";
import styled, { useTheme } from "styled-components";
import AboutButtonLink from "../about/AboutButtonLink";
import { AppPages } from "../routing/AppPages";
import ExternalButtonLink from "../shared/ExternalButtonLink";
import {
  CustomerServiceEmailButtonLink,
  CustomerServicePhoneButtonLink,
  CustomerServicePhoneFromAbroadButtonLink,
} from "../shared/elements/CustomerService";
import { NafLogo } from "../shared/logos/NafLogo";

const PageFooterWrapper = styled.div`
  background: ${({ theme }) => theme.nafColor.primary.spruce};
  color: ${({ theme }) => theme.nafColor.signature.white};
`;

const PageFooterContent = styled.div`
  position: relative;
  max-width: ${(props) => props.theme.skeleton.maxWidth};
  padding: ${({ theme }) =>
    `${theme.spacing.space64} ${theme.spacing.space24} ${theme.spacing.space40}`};
  margin: auto;

  ${({ theme }) =>
    onDesktop`
    padding: ${theme.spacing.space80} ${theme.spacing.space24} ${theme.spacing.space64}
  `}
`;

const InlineBlockDiv = styled.div`
  display: inline-block;
`;

const PageFooterLinkWrapper = styled(
  (props: HtmlHTMLAttributes<HTMLDivElement>) => {
    const { children, ...rest } = props;
    return (
      <div {...rest}>
        <InlineBlockDiv>{children}</InlineBlockDiv>
      </div>
    );
  },
)`
  &:not(:first-of-type) {
    margin-top: ${({ theme }) => theme.spacing.space8};
  }
`;

const FooterLinkLabel = styled.span`
  ${({ theme }) => theme.fontStyle.article.articleTextSubHeader};
  font-size: ${({ theme }) => theme.spacing.space16};
  margin-bottom: -${({ theme }) => theme.spacing.space8};
`;

const FooterSectionHeader = styled.h3`
  margin: 0 0 ${({ theme }) => theme.spacing.space24};
`;

const ContractLinksWrapper = styled.section`
  grid-area: contractLinks;
`;
const UsefulFromNafLinksWrapper = styled.section`
  grid-area: nafLinks;
`;
const HelpLinksWrapper = styled.section`
  grid-area: helpLinks;
`;

const PageFooterBody = styled.div`
  display: grid;
  grid-template-areas:
    "contractLinks"
    "nafLinks"
    "helpLinks";
  grid-template-rows: repeat(3, auto);
  grid-template-columns: 1fr;

  section {
    margin-bottom: ${({ theme }) => theme.spacing.space56};
  }

  ${({ theme }) => onDesktop`
    grid-template-areas: "contractLinks nafLinks helpLinks";
    grid-template-columns: repeat(3, 1fr);
    column-gap: ${theme.spacing.space12};
  `}

  ${onMinWidthXtraLarge`
    grid-template-areas: "contractLinks nafLinks helpLinks";
    grid-template-rows: 1fr;
    grid-template-columns: repeat(4, 1fr);
  `}
`;

const HorizontalSeparator = styled.hr`
  border-color: ${({ theme }) => theme.nafColor.primary.park};
  margin: ${({ theme }) =>
    `${theme.spacing.space32} auto ${theme.spacing.space16}`};
`;

const CopyrightSpan = styled.span`
  ${({ theme }) => theme.fontStyle.bodyText.bodyText};
  margin-right: 0;
  margin-bottom: ${({ theme }) => theme.spacing.space16};

  ${({ theme }) =>
    onDesktop`
      margin-right: ${theme.spacing.space40};
      margin-bottom: 0;
    `};
`;
const CopyrightPrivacyWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${onDesktop`
    flex-direction: row;
    align-items: center;
  `}
`;

const TwoLineWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.spacing.space16};
`;

function ContractLinks() {
  return (
    <ContractLinksWrapper>
      <FooterSectionHeader>NAF Kjøpekontrakt</FooterSectionHeader>
      <PageFooterLinkWrapper>
        <AboutButtonLink variant="negative" page={AppPages.AboutHowItWorks}>
          Slik fungerer det
        </AboutButtonLink>
      </PageFooterLinkWrapper>
      <PageFooterLinkWrapper>
        <AboutButtonLink variant="negative" page={AppPages.AboutFaq}>
          Vanlige spørsmål
        </AboutButtonLink>
      </PageFooterLinkWrapper>
      <PageFooterLinkWrapper>
        <AboutButtonLink variant="negative" page={AppPages.AboutTerms}>
          Vilkår
        </AboutButtonLink>
      </PageFooterLinkWrapper>
    </ContractLinksWrapper>
  );
}

function NafLinks() {
  return (
    <UsefulFromNafLinksWrapper>
      <FooterSectionHeader>Nyttig fra NAF</FooterSectionHeader>
      <PageFooterLinkWrapper>
        <ExternalButtonLink
          variant="negative"
          href="https://www.naf.no/medlemskap/medlemsfordeler/naf-veihjelp/"
        >
          Veihjelp
        </ExternalButtonLink>
      </PageFooterLinkWrapper>
      <PageFooterLinkWrapper>
        <ExternalButtonLink
          variant="negative"
          href="https://www.naf.no/medlemskap/medlemsfordeler/juridisk-radgivning/"
        >
          Juridisk rådgivning
        </ExternalButtonLink>
      </PageFooterLinkWrapper>
    </UsefulFromNafLinksWrapper>
  );
}

const LogoAnchorWrapper = styled.a`
  display: flex;
`;
function HelpLinks() {
  return (
    <HelpLinksWrapper>
      <FooterSectionHeader>Trenger du hjelp?</FooterSectionHeader>
      <TwoLineWrapper>
        <FooterLinkLabel>Ring oss (8-16 virkedager):</FooterLinkLabel>
        <PageFooterLinkWrapper>
          <CustomerServicePhoneButtonLink />
        </PageFooterLinkWrapper>
      </TwoLineWrapper>
      <TwoLineWrapper>
        <FooterLinkLabel>Send oss en e-post:</FooterLinkLabel>
        <PageFooterLinkWrapper>
          <CustomerServiceEmailButtonLink />
        </PageFooterLinkWrapper>
      </TwoLineWrapper>
      <TwoLineWrapper>
        <FooterLinkLabel>Fra utlandet:</FooterLinkLabel>
        <PageFooterLinkWrapper>
          <CustomerServicePhoneFromAbroadButtonLink />
        </PageFooterLinkWrapper>
      </TwoLineWrapper>
    </HelpLinksWrapper>
  );
}

export function PageFooter() {
  const theme = useTheme() as ITheme;
  return (
    <PageFooterWrapper>
      <PageFooterContent>
        <PageFooterBody>
          <ContractLinks />
          <NafLinks />
          <HelpLinks />
        </PageFooterBody>
        <div>
          <InlineBlockDiv>
            <LogoAnchorWrapper href="/">
              <NafLogo size={parseFloat(theme.spacing.space56)} />
            </LogoAnchorWrapper>
          </InlineBlockDiv>
          <HorizontalSeparator />
          <CopyrightPrivacyWrapper>
            <CopyrightSpan>© Norges Automobil-Forbund</CopyrightSpan>
            <AboutButtonLink
              variant="negative"
              size="small"
              page={AppPages.AboutPrivacy}
            >
              Personvern og cookies
            </AboutButtonLink>
          </CopyrightPrivacyWrapper>
        </div>
      </PageFooterContent>
    </PageFooterWrapper>
  );
}
