import { type DateString } from "@naf/teamscheme";
import { useMemo, useState } from "react";
import { timeSinceText } from "./timeSinceText";

export default function TimeSince({ date }: { date: Date | DateString }) {
  const [now] = useState(new Date());

  const text = useMemo(() => timeSinceText(date, now), [now, date]);

  return <span>{text}</span>;
}
